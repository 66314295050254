import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { colors } from "../../../app/theme/colors";
import AppButton from "../../../components/appButton/appButton";
import { useAppDispatch } from "../../../app/redux/hooks";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { useDashboardCardModalStyles } from "./dashboardCardModalStyles";
import { useEffect } from "react";

interface Props {
  open: any;
  handleClose: any;
  title: string;
  icon: any;
  table: any;
  id: string;
  closeOnDisable: boolean;
}

export default function DashboardCardModal({
  handleClose,
  open,
  icon,
  table,
  title,
  id,
  closeOnDisable,
}: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { classes } = useDashboardCardModalStyles();
  const theme = useTheme();

  const handleDialogClose = () => {
    handleClose();
  };

  useEffect(() => {
    if (closeOnDisable) {
      handleClose();
    }
  }, [closeOnDisable]);

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      fullWidth
      maxWidth="md"
      id={id}
      PaperProps={{ sx: { borderRadius: "15px", overflow: "hidden" } }}
    >
      <IconButton
        id="partners_modal_icon_close"
        edge="end"
        color="primary"
        onClick={handleDialogClose}
        aria-label="close"
        sx={{
          position: "absolute",
          top: theme.spacing(1),
          right: theme.spacing(2),
        }}
      >
        <CloseIcon className={classes.closeIcon} />
      </IconButton>
      <DialogContent className={classes.dialogContent}>
        <Grid container padding={"5px"}>
          <Grid
            item
            md={12}
            xs={12}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Grid item display={"flex"} alignItems={"center"}>
              {icon}
              <Typography
                variant="h4"
                sx={{ color: "#635F5F" }}
                fontWeight={"bold"}
              >
                {title}
              </Typography>
            </Grid>
            {/* <Grid item>
              <IconButton id="partners_modal_icon_filter">
                <FilterAltOutlinedIcon
                  sx={{ fontSize: 26, mr: 0.5, color: "#635F5F" }}
                />
              </IconButton>
            </Grid> */}
          </Grid>
          <Grid item md={12} xs={12} mt={2}>
            {table}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <AppButton
          id="partners_modal_button_close"
          onClick={handleClose}
          color={colors.primary}
          hover={colors.primary}
          label={t("buttons.close")}
        />
      </DialogActions>
    </Dialog>
  );
}
