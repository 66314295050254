import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";
import { colors } from "../../app/theme/colors";

export const useTextualAdvertisingSectionStyles = makeStyles()(
  (theme: Theme) => ({
    card: {
      cursor: "pointer",
      transition: "transform 0.2s ease-in-out",
      "&:hover": {
        transform: "scale(1.01)",
      },
    },
    box: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    cardMedia: {
      width: 300,
    },
    label: {
      marginLeft: "5px",
      color: colors.primary,
    },
    content: {
      marginLeft: "5px",
    },
    icons: {
      color: colors.primary,
    },
    contentWrapper: {
      display: "flex",
      alignItems: "center",
      margin: "10px 0",
    },
    description: {
      color: colors.primary,
    },
    iconWrapper:{
      position: "absolute", top: "10px", right: "10px"
    },
    titleWrapper:{
      marginLeft: "13px", marginTop: "13px"
    },
    companyTitleWrapper:{
      padding: "10px"
    },
    starIcon:{
      color: "#CDB537"
    },
    companyTitleLabel:{
      float: "right",
      fontWeight: "bold",
      borderBottom: "2px solid currentColor",
    }
  })
);
