export function IsCompanyNameValid(name: string): boolean {
  return name.trim().length > 0;
}

export function IsEmailValid(name: string): boolean {
  return name.trim().length > 0;
}

export function IsMessageValid(name: string): boolean {
  return name.trim().length > 0;
}

export function IsTaxIdValid(name: string): boolean {
  return name.trim().length > 0;
}

export function IsPhoneNumberValid(name: string): boolean {
  return name.trim().length > 0;
}

export function IsPackageValidate(name: string): boolean {
  return name.trim().length > 0;
}
