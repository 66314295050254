import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";
import { colors } from "../../app/theme/colors";

export const useLayoutStyles = makeStyles()((theme: Theme) => ({
  container: {
    padding: 0,
    position: "relative",
    minHeight: "80vh",
  },
  header: {
    width: "100%",
  },
  footerContainer: {
    // position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    marginTop: 30
  },
}));
