import {
  Avatar,
  Box,
  Card,
  CardContent,
  CircularProgress,
  DialogContent,
  Grid,
  IconButton,
  Link,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import { useSearchResultLayoutMobileStyles } from "./searchResultLayoutMobileStyles";
import { CompanyModel } from "../../models/company/companyModel";
import { colors } from "../../app/theme/colors";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import StarIcon from "@mui/icons-material/Star";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTiktok } from "@fortawesome/free-brands-svg-icons";
import { CompanySocialMediaModel } from "../../models/company/companySocialMediaModel";
import { Env_UM } from "../../app/redux/api_um";
import { useAppDispatch, useAppSelector } from "../../app/redux/hooks";
import { selectCurrentAccount } from "../../slices/authSlice";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useEffect, useState } from "react";
import SearchResultLayoutPopoverMenu from "./searchResultsLayoutPopoverMenu";
import {
  removeCategory,
  removeClient,
  removeFavorite,
  removePartner,
  removePotentialPartner,
  saveClient,
  saveFavorite,
  savePartner,
  savePotentialPartner,
  selectCategoryStatus,
  selectClientStatus,
  selectPartnerStatus,
  selectPotentialPartnerStatus,
} from "../../slices/searchResultsActionsSlice";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import AppButton from "../../components/appButton/appButton";
import {
  selectCompanyById,
  selectLoadingFullDetailsStatus,
} from "../../slices/companySlice";
import { getCompanyRating } from "../../slices/overallRatingSlice";
import OverallRatingModal from "../modals/overallRatingModal/overallRatingModal";

interface ClickedButtonsState {
  partner: boolean;
  potentialPartner: boolean;
  client: boolean;
  saveActivity: boolean;
  favorite: boolean;
}

interface Props {
  company: CompanyModel | null;
  onClick?: any;
  setSelectedCompany: any;
  setAddFavoriteId: any;
  overallRatingStatus: string;
  selectedCompany?: CompanyModel;
}

export default function SearchResultLayout({
  company,
  onClick,
  setSelectedCompany,
  overallRatingStatus,
  setAddFavoriteId,
  selectedCompany,
}: Props) {
  const { t } = useTranslation();
  const { classes } = useSearchResultLayoutMobileStyles();
  const dispatch = useAppDispatch();

  const fullDetailsStatus = useAppSelector(selectLoadingFullDetailsStatus);
  const currentAccount = useAppSelector(selectCurrentAccount);
  const potentialPartnerStatus = useAppSelector(selectPotentialPartnerStatus);
  const clientStatus = useAppSelector(selectClientStatus);
  const savePartnerStatus = useAppSelector(selectPartnerStatus);
  const saveActivityStatus = useAppSelector(selectCategoryStatus);
  const companyById = useAppSelector(selectCompanyById);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const openSettings = Boolean(anchorEl);
  const [open, setOpen] = useState(false);

  const [isOpenOverallRatingModal, setIsOpenOverallRatingModal] =
    useState<boolean>(false);

  const [isFavoriteRemoved, setIsFavoriteRemoved] = useState<boolean>(false);

  const [clickedButtons, setClickedButtons] = useState<ClickedButtonsState>({
    partner: false,
    potentialPartner: false,
    client: false,
    saveActivity: false,
    favorite: false,
  });

  const handleButtonClick = (buttonName: keyof ClickedButtonsState) => {
    setSelectedCompany(company);
    if (company) {
      switch (buttonName) {
        case "partner":
          setClickedButtons((prevState) => ({
            ...prevState,
            partner: true,
          }));
          dispatch(savePartner(company?.generalData.id));
          break;
        case "potentialPartner":
          setClickedButtons((prevState) => ({
            ...prevState,
            potentialPartner: true,
          }));
          dispatch(savePotentialPartner(company?.generalData.id));
          break;
        case "client":
          setClickedButtons((prevState) => ({
            ...prevState,
            client: true,
          }));
          dispatch(saveClient(company?.generalData.id));
          break;
        case "favorite":
          setIsFavoriteRemoved(true);
          setAddFavoriteId(company.generalData.id);
          dispatch(saveFavorite(company?.generalData.id));
          break;
        default:
          break;
      }
    }
  };

  const handleButtonClickRemove = (buttonName: keyof ClickedButtonsState) => {
    setSelectedCompany(company);
    if (company) {
      switch (buttonName) {
        case "partner":
          setClickedButtons((prevState) => ({
            ...prevState,
            partner: false,
          }));
          dispatch(removePartner(company?.generalData.id));
          break;
        case "potentialPartner":
          setClickedButtons((prevState) => ({
            ...prevState,
            potentialPartner: false,
          }));
          dispatch(removePotentialPartner(company?.generalData.id));
          break;
        case "client":
          setClickedButtons((prevState) => ({
            ...prevState,
            client: false,
          }));
          dispatch(removeClient(company?.generalData.id));
          break;
        case "saveActivity":
          dispatch(removeCategory(company?.generalData.id));

          break;
        case "favorite":
          setIsFavoriteRemoved(false);
          setAddFavoriteId(company.generalData.id);
          dispatch(removeFavorite(company?.generalData.id));
          break;
        default:
          break;
      }
    }
  };

  const handleShare = (websiteName: string) => {
    const url = `http://localhost:3000/${websiteName}`;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        setOpen(true);
      })
      .catch((err) => {});
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const stars = Array(5)
    .fill(null)
    .map((_, i) => {
      if (i < Math.floor(company?.generalData?.numberOfStars ?? 0)) {
        return (
          <Grid item key={i}>
            <StarIcon
              fontSize="small"
              sx={{
                color: "#F0E700",
              }}
            />
          </Grid>
        );
      } else {
        return null;
      }
    });

  const handleSocialMediaClick = (event: any, url: any) => {
    event.preventDefault();
    window.open(url, "_blank");
  };

  useEffect(() => {
    if (companyById) {
      dispatch(getCompanyRating(companyById?.generalData.id));
    }
  }, [companyById]);

  return (
    <>
      <Grid mt={2}>
        <Card
          id="searchResultLayout-mobile_card"
          // onClick={onClick}
          className={`${classes.card} ${
            company?.generalData.isPremium === true ? classes.paidCard : ""
          }`}
        >
          <Grid container display="flex" alignItems={"center"}>
            <Grid
              container
              display={"flex"}
              sx={{
                background:
                  company?.generalData.isPremium === true
                    ? colors.gold
                    : "white",
                height: "35px",
                padding: "5px",
              }}
            >
              <Grid item xs={6}>
                {company?.generalData.numberOfStars != null && (
                  <Grid
                    container
                    justifyContent={
                      company.generalData.isPremium !== false
                        ? "center"
                        : "left"
                    }
                  >
                    {stars}
                  </Grid>
                )}
              </Grid>

              {/* <Grid item xs={6} sx={{ textAlign: "right" }}>
                <Box
                  sx={{ ml: "auto" }}
                  id="searchResultLayout-mobile_box_social-media"
                >
                  {company?.socialMedias
                    ?.reduce(
                      (
                        uniqueSocialMedias: CompanySocialMediaModel[],
                        socialMedia: CompanySocialMediaModel
                      ) => {
                        const existingSocialMedia = uniqueSocialMedias.find(
                          (sm) =>
                            sm.typeName.toLowerCase() ===
                            socialMedia.typeName.toLowerCase()
                        );
                        if (!existingSocialMedia) {
                          uniqueSocialMedias.push(socialMedia);
                        }
                        return uniqueSocialMedias;
                      },
                      []
                    )
                    .map((socialMedia, index) => {
                      let socialMediaWithColor = { ...socialMedia, color: "" };

                      switch (socialMedia.typeName.toLowerCase()) {
                        case "facebook":
                          return (
                            <FacebookOutlinedIcon
                              key={index}
                              id="searchResultLayout_icon_facebook"
                              className={classes.facebookIcon}
                              onClick={(event) =>
                                handleSocialMediaClick(
                                  event,
                                  socialMediaWithColor.url
                                )
                              }
                            />
                          );
                        case "instagram":
                          return (
                            <InstagramIcon
                              key={index}
                              id="searchResultLayout_icon_instagram"
                              className={classes.instagramIcon}
                              onClick={(event) =>
                                handleSocialMediaClick(
                                  event,
                                  socialMediaWithColor.url
                                )
                              }
                            />
                          );
                        case "twitter":
                          return (
                            <TwitterIcon
                              key={index}
                              id="searchResultLayout_icon_twitter"
                              className={classes.twitterIcon}
                              onClick={(event) =>
                                handleSocialMediaClick(
                                  event,
                                  socialMediaWithColor.url
                                )
                              }
                            />
                          );
                        case "linkedin":
                          return (
                            <LinkedInIcon
                              key={index}
                              id="searchResultLayout_icon_linkedIn"
                              className={classes.linkedInIcon}
                              onClick={(event) =>
                                handleSocialMediaClick(
                                  event,
                                  socialMediaWithColor.url
                                )
                              }
                            />
                          );
                        case "youtube":
                          return (
                            <YouTubeIcon
                              key={index}
                              id="searchResultLayout_icon_youtube"
                              className={classes.youtubeIcon}
                              onClick={(event) =>
                                handleSocialMediaClick(
                                  event,
                                  socialMediaWithColor.url
                                )
                              }
                            />
                          );
                        case "tiktok":
                          return (
                            <FontAwesomeIcon
                              icon={faTiktok}
                              key={index}
                              id="searchResultLayout_icon_tiktok"
                              className={classes.tiktokIcon}
                              onClick={(event) =>
                                handleSocialMediaClick(
                                  event,
                                  socialMediaWithColor.url
                                )
                              }
                            />
                          );
                        default:
                          return null;
                      }
                    })}
                </Box>
              </Grid> */}
            </Grid>

            <Grid item xs={6} mt={1}>
              <div style={{ position: "relative" }}>
                {company?.generalData.logoBase64 === null ? (
                  <Grid
                    container
                    display="flex"
                    justifyContent=""
                    alignItems="center"
                  >
                    <Avatar className={classes.avatar}>
                      {company?.generalData.name
                        ? company.generalData.name
                            .split(" ")
                            .slice(0, 2)
                            .map((word: any) => word[0])
                            .join("")
                            .toUpperCase()
                        : ""}
                    </Avatar>
                  </Grid>
                ) : (
                  <Grid
                    container
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <CardMedia
                      id="searchResultLayout_img_logo"
                      component="img"
                      className={classes.cardMedia}
                      image={
                        Env_UM.Prod + "/" + company?.generalData?.logoBase64
                      }
                      style={{
                        backgroundColor: "inherit",
                        position: "relative",
                      }}
                    />
                  </Grid>
                )}
                {company?.generalData.isPremium === true && (
                  <Box className={classes.premiumMarkWrapper}>
                    <Typography variant="body2" sx={{ fontSize: "10px" }}>
                      {t("searchResultLayout.cardPremium")}
                    </Typography>
                  </Box>
                )}
              </div>
            </Grid>
            <Grid item md={12} xs={6} sx={{ textAlign: "right" }}>
              {currentAccount ? (
                <>
                  <IconButton onClick={handleOpenPopover}>
                    <MoreHorizIcon sx={{ fontSize: "30px" }} />
                  </IconButton>
                  <Popover
                    open={openSettings && Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClosePopover}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <DialogContent sx={{ padding: "10px" }}>
                      <Grid
                        container
                        justifyContent="center"
                        alignItems={"center"}
                        direction={"column"}
                        sx={{ width: "100%" }}
                      >
                        <SearchResultLayoutPopoverMenu
                          isCategory={false}
                          buttonTitle="partner"
                          clickedButtons={clickedButtons.partner}
                          handleButtonClick={handleButtonClick}
                          handleButtonClickRemove={handleButtonClickRemove}
                          savePartnerStatus={savePartnerStatus}
                          displayTitle={t(
                            "dashboardCard.dashboardCardAssociateTitle"
                          )}
                        />
                        <SearchResultLayoutPopoverMenu
                          isCategory={false}
                          buttonTitle="potentialPartner"
                          clickedButtons={clickedButtons.potentialPartner}
                          handleButtonClick={handleButtonClick}
                          handleButtonClickRemove={handleButtonClickRemove}
                          savePartnerStatus={potentialPartnerStatus}
                          displayTitle={t(
                            "dashboardCard.dashboardCardPotentialAssociateTitle"
                          )}
                        />
                        <SearchResultLayoutPopoverMenu
                          isCategory={false}
                          buttonTitle="client"
                          clickedButtons={clickedButtons.client}
                          handleButtonClick={handleButtonClick}
                          handleButtonClickRemove={handleButtonClickRemove}
                          savePartnerStatus={clientStatus}
                          displayTitle={t(
                            "dashboardCard.dashboardCardClientTitle"
                          )}
                        />
                        {/* <SearchResultLayoutPopoverMenu
                          isCategory={true}
                          buttonTitle="saveActivity"
                          clickedButtons={clickedButtons.saveActivity}
                          handleButtonClick={handleButtonClick}
                          handleButtonClickRemove={handleButtonClickRemove}
                          savePartnerStatus={saveActivityStatus}
                          displayTitle={t("searchResultLayout.saveActivity")}
                          company={company}
                        /> */}
                      </Grid>
                    </DialogContent>
                  </Popover>
                  {company?.generalData.websiteName && (
                    <Tooltip
                      title={`${t("searchResultLayout.cardShareTitle")} ${
                        company.generalData.websiteName
                      }`}
                    >
                      <IconButton
                        onClick={() =>
                          handleShare(company.generalData.websiteName || "")
                        }
                      >
                        <ShareOutlinedIcon
                          sx={{ fontSize: "30px", color: "#4889F4" }}
                        />
                      </IconButton>
                    </Tooltip>
                  )}

                  {isFavoriteRemoved ? (
                    <>
                      <IconButton
                        onClick={() => handleButtonClickRemove("favorite")}
                      >
                        <BookmarkIcon
                          sx={{ fontSize: "30px", color: "#FFBD03" }}
                        />
                      </IconButton>
                    </>
                  ) : (
                    <>
                      <IconButton onClick={() => handleButtonClick("favorite")}>
                        <BookmarkBorderOutlinedIcon
                          sx={{ fontSize: "30px", color: "#FFBD03" }}
                        />
                      </IconButton>
                    </>
                  )}
                </>
              ) : (
                <>
                  {company?.socialMedias
                    ?.reduce(
                      (
                        uniqueSocialMedias: CompanySocialMediaModel[],
                        socialMedia: CompanySocialMediaModel
                      ) => {
                        const existingSocialMedia = uniqueSocialMedias.find(
                          (sm) =>
                            sm.typeName.toLowerCase() ===
                            socialMedia.typeName.toLowerCase()
                        );
                        if (!existingSocialMedia) {
                          uniqueSocialMedias.push(socialMedia);
                        }
                        return uniqueSocialMedias;
                      },
                      []
                    )
                    .map((socialMedia, index) => {
                      let socialMediaWithColor = {
                        ...socialMedia,
                        color: "",
                      };
                      let showIcon =
                        socialMediaWithColor.url.includes("https://");

                      switch (socialMedia.typeName.toLowerCase()) {
                        case "facebook":
                          return showIcon ? (
                            <FacebookOutlinedIcon
                              key={index}
                              id="searchResultLayout_icon_facebook"
                              className={classes.facebookIcon}
                              onClick={(event) =>
                                handleSocialMediaClick(
                                  event,
                                  socialMediaWithColor.url
                                )
                              }
                            />
                          ) : null;
                        case "instagram":
                          return showIcon ? (
                            <InstagramIcon
                              key={index}
                              id="searchResultLayout_icon_instagram"
                              className={classes.instagramIcon}
                              onClick={(event) =>
                                handleSocialMediaClick(
                                  event,
                                  socialMediaWithColor.url
                                )
                              }
                            />
                          ) : null;
                        case "twitter":
                          return showIcon ? (
                            <TwitterIcon
                              key={index}
                              id="searchResultLayout_icon_twitter"
                              className={classes.twitterIcon}
                              onClick={(event) =>
                                handleSocialMediaClick(
                                  event,
                                  socialMediaWithColor.url
                                )
                              }
                            />
                          ) : null;
                        case "linkedin":
                          return showIcon ? (
                            <LinkedInIcon
                              key={index}
                              id="searchResultLayout_icon_linkedIn"
                              className={classes.linkedInIcon}
                              onClick={(event) =>
                                handleSocialMediaClick(
                                  event,
                                  socialMediaWithColor.url
                                )
                              }
                            />
                          ) : null;
                        case "youtube":
                          return showIcon ? (
                            <YouTubeIcon
                              key={index}
                              id="searchResultLayout_icon_youtube"
                              className={classes.youtubeIcon}
                              onClick={(event) =>
                                handleSocialMediaClick(
                                  event,
                                  socialMediaWithColor.url
                                )
                              }
                            />
                          ) : null;
                        case "tiktok":
                          return showIcon ? (
                            <FontAwesomeIcon
                              icon={faTiktok}
                              key={index}
                              id="searchResultLayout_icon_tiktok"
                              className={classes.tiktokIcon}
                              onClick={(event) =>
                                handleSocialMediaClick(
                                  event,
                                  socialMediaWithColor.url
                                )
                              }
                            />
                          ) : null;
                        default:
                          return null;
                      }
                    })}
                </>
              )}
            </Grid>
            <Grid item xs={6}>
              <Grid item xs={12} ml={2}>
                <Typography variant="h6">
                  {company?.generalData.name}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <CardContent>
            <Grid
              container
              display={"flex"}
              alignContent={"center"}
              justifyContent={"center"}
            >
              <Grid item xs={12} display={"flex"} mt={1}>
                <LocationCityIcon className={classes.icons} />
                <Typography variant="body1" className={classes.label}>
                  {t("searchResultLayout.cardAddress")}
                </Typography>
                <Typography
                  variant="body1"
                  className={classes.contactContent}
                  component={"a"}
                  href={`https://maps.google.com/maps?q=${encodeURIComponent(
                    `${
                      company?.generalData.place
                    }, ${company?.generalData.street.replace("bb", "").trim()}`
                  )}`}
                  target="_blank"
                >
                  {`${company?.generalData.place}, ${company?.generalData.street}`}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                {company?.websites?.slice(0, 4).map((website, index) => (
                  <Grid container key={index} alignItems="center" mt={1}>
                    <OpenInNewOutlinedIcon className={classes.icons} />
                    <Typography variant="body1" className={classes.label}>
                      {t("searchResultLayout.cardWeb")}
                    </Typography>
                    <Typography
                      variant="body1"
                      className={classes.contactContent}
                      component={"a"}
                      href={
                        website.url.startsWith("http")
                          ? website.url
                          : `http://${website.url}`
                      }
                      target="_blank"
                    >
                      {website.url}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
              <Grid item xs={12}>
                {company?.phones?.slice(0, 4).map((phone, index) => (
                  <Grid container key={index} alignItems="center" mt={1}>
                    <LocalPhoneOutlinedIcon className={classes.icons} />
                    <Typography variant="body1" className={classes.label}>
                      {t("searchResultLayout.cardPhone")}
                    </Typography>
                    <Typography
                      sx={{ textDecoration: "none" }}
                      variant="body1"
                      className={classes.contactContent}
                      component="a"
                      href={`tel:${phone.phoneNumber}`}
                    >
                      {phone.phoneNumber}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
              <Grid xs={12}>
                {company?.emails?.slice(0, 4).map((email, index) => (
                  <Grid container key={index} alignItems="center" mt={1}>
                    <EmailOutlinedIcon className={classes.icons} />
                    <Typography variant="body1" className={classes.label}>
                      {t("searchResultLayout.cardEmail")}
                    </Typography>
                    <Link
                      href={`mailto:${email.emailAddress}`}
                      underline="none"
                    >
                      <Typography
                        id="fullDetailsModal_value_email"
                        variant="body1"
                        className={classes.contactContent}
                        component="a"
                        href={`mailto:${email.emailAddress}`}
                      >
                        {email.emailAddress}
                      </Typography>
                    </Link>
                  </Grid>
                ))}
              </Grid>
              <Grid item md={12} xs={12} mt={2}>
                <AppButton
                  label={t("searchResultLayout.cardShowMore")}
                  color={colors.primary}
                  hover={colors.primary}
                  onClick={onClick}
                  startIcon={
                    fullDetailsStatus === "Pending" ? (
                      <CircularProgress size={15} />
                    ) : null
                  }
                  disabled={fullDetailsStatus === "Pending"}
                />
              </Grid>
              <Grid item md={12} xs={12} mt={2}>
                <Typography variant="body1" className={classes.description}>
                  {company?.generalData.shortDescription?.slice(0, 500)}
                </Typography>
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
                lg={12}
                display="flex"
                justifyContent={"right"}
                mt={2}
              >
                {company?.categories.map((category, i) => (
                  <Box
                    key={i}
                    className={classes.activityTagWrapper}
                    sx={{ textAlign: "right", margin: "2px" }}
                  >
                    <Typography
                      variant="body2"
                      sx={{ textAlign: "center", fontSize: "8px" }}
                    >
                      {`${category.parentCategoryName}${
                        category.childCategoryNames.length ? " - " : ""
                      }${category.childCategoryNames}`}
                    </Typography>
                  </Box>
                ))}
              </Grid>
              <Grid
                item
                display={"flex"}
                xs={12}
                ml={1}
                mt={1}
                justifyContent={"flex-end"}
              >
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "bold", fontSize: "15px" }}
                >
                  {company?.generalData.ratingValue}
                </Typography>
                <StarIcon
                  sx={{ color: "#f1b607", cursor: "pointer" }}
                  onClick={() => setIsOpenOverallRatingModal(true)}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <OverallRatingModal
          handleClose={() => setIsOpenOverallRatingModal(false)}
          open={isOpenOverallRatingModal}
          company={company || null}
          setSelectedCompany={setSelectedCompany}
          selectedCompany={selectedCompany || null}
        />
      </Grid>
    </>
  );
}
