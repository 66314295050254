type Colors = {
  primary: string;
  secondary: string;
  sectionTitle: string;
  inputLabel: string;
  validationErrorMessage: string;
  green: string;
  red: string;
  lightBlue: string;
  backgroundContent: string;
  searchLayout: string;
  gradient: string;
  subHeader: string;
  advertisementRequest: string;
  gold: string;
  avatar: string;
  categoryTag: string;
  error: string;
  white: string;
};

export const colors: Colors = {
  primary: "#4285F4",
  avatar: "rgba(66, 133, 244, 0.8)",
  categoryTag: "rgba(66, 133, 244, 0.8)",
  secondary: "#225588",
  searchLayout: "#E6EFFE87",
  sectionTitle: "#4F5459",
  inputLabel: "#6A6B6B",
  validationErrorMessage: "#ffae1a",
  green: "#4CAF50",
  lightBlue: "#00B2FF",
  red: "rgba(255, 73, 71, 0.8)",
  backgroundContent: "rgba(234, 236, 237, 0.4)",
  gradient:
    "linear-gradient(180deg, #4285F4 0%, rgba(66, 133, 244, 0.785385) 91.98%, rgba(66, 133, 244, 0.73) 99.99%, rgba(66, 133, 244, 0) 100%)",
  subHeader: "#ECECEC",
  advertisementRequest: "#F5F5F5",
  gold: "#EBD8ADBA",
  error: "#f45842",
  white: "#ffff"
};
