import axios from "axios";

export const Env_Poslovni_Imenik = {
  Local: "https://localhost:5001",
  Qa: "https://www.poslovniimenik-bih-qa.api.midenas.rs",
  Prod: "https://admin.api.poslovniimenik.net",
};

export const api_poslovni_imenik = axios.create({
  baseURL: Env_Poslovni_Imenik.Prod + "/api",
});