import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  IconButton,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import AppButton from "../../components/appButton/appButton";
import CollectionsIcon from "@mui/icons-material/Collections";
import { Close } from "@mui/icons-material";
import { colors } from "../../app/theme/colors";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { motion } from "framer-motion";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import MySiteGalleryModel from "../../models/mySite/mySiteGalleryModel";
import EditIcon from "@mui/icons-material/Edit";
import { saveMySiteSlider } from "../../slices/mySiteSlice";
import { useAppDispatch } from "../../app/redux/hooks";
import MySiteSliderModel from "../../models/mySite/mySiteSliderModel";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useTranslation } from "react-i18next";
import { ApiStatus } from "../../models/app/apiStatus";

interface Props {
  status: string;
  selectedGalleryFiles?: File[];
  setSelectedGalleryFiles: any;
  setIsOpenModal: any;
  mySiteSliderSection: MySiteSliderModel[] | null;
  setMySiteSliderSection: any;
  selectedItems: any;
  clickedCube: string | null;
  cubeType: string;
  selectMySiteSlider: MySiteSliderModel[] | null;
  handleRemoveSlider: any;
}

export default function MySiteSliderSection({
  selectedGalleryFiles,
  setSelectedGalleryFiles,
  setIsOpenModal,
  clickedCube,
  mySiteSliderSection,
  selectedItems,
  setMySiteSliderSection,
  cubeType,
  handleRemoveSlider,
  selectMySiteSlider,
  status,
}: Props) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [buttonText, setButtonText] = useState<string>("");
  const [buttonIcon, setButtonIcon] = useState<any>(null);
  const [selectedForRemoval, setSelectedForRemoval] = useState<number[] | null>(
    null
  );

  const isPhotosEmpty = mySiteSliderSection?.every(
    (item) => item.base64Photos.length === 0
  );

  const [isDeleteModeActive, setIsDeleteModeActive] = useState<boolean>(false);

  const handleEdit = () => {
    if (selectedForRemoval) {
      setSelectedForRemoval(null);
      setIsDeleteModeActive(false);
    } else {
      if (selectedGalleryFiles) {
        setSelectedForRemoval(
          Array.from(Array(selectedGalleryFiles.length).keys())
        );
        setIsDeleteModeActive(true);
      }
    }
  };

  const handleSave = () => {
    if (mySiteSliderSection) {
      dispatch(saveMySiteSlider(mySiteSliderSection));
    }
  };

  const handleRemoveImageFromGallery = (
    indexToRemove: number,
    photoIndexToRemove: number
  ) => {
    setMySiteSliderSection((prevGallery: MySiteGalleryModel[] | undefined) => {
      if (!prevGallery) return undefined;

      const newGallery = prevGallery.map((item, index) => {
        if (index === indexToRemove && item.base64Photos) {
          return {
            ...item,
            base64Photos: item.base64Photos.filter(
              (_, i) => i !== photoIndexToRemove
            ),
          };
        }
        return item;
      });

      return newGallery;
    });
  };

  useEffect(() => {
    if (mySiteSliderSection) {
      const photoToUpdate = mySiteSliderSection.find(
        (slider) => slider.orderNumber === selectedItems[cubeType]?.orderNumber
      );
      if (photoToUpdate) {
        const hasId =
          photoToUpdate.id !== "00000000-0000-0000-0000-000000000000";
        if (hasId) {
          setButtonText(t("buttons.edit") as string);
          setButtonIcon(<EditIcon />);
        } else {
          setButtonText(t("buttons.save") as string);
          setButtonIcon(<CheckCircleOutlineIcon />);
        }
      }
    }
  }, [mySiteSliderSection, cubeType, clickedCube, selectMySiteSlider, t]);

  return (
    <>
      <Grid
        container
        display={"flex"}
        justifyContent={"flex-start"}
        alignItems={"center"}
        mt={1}
        padding={"10px"}
      >
        <Grid
          item
          md={12}
          justifyContent={"flex-start"}
          alignItems={"center"}
          display={"flex"}
        >
          <Grid item>
            <ViewCarouselIcon sx={{ color: "rgba(75, 138, 244, 0.8)" }} />
          </Grid>
          <Grid item>
            <Typography variant="body2" sx={{ marginLeft: "5px" }}>
              {t("mySite.mySiteSliderTitle")}
            </Typography>
          </Grid>
        </Grid>
        {mySiteSliderSection && clickedCube && selectedItems[clickedCube] && (
          <>
            {mySiteSliderSection &&
              mySiteSliderSection.length > 0 &&
              mySiteSliderSection.map((item, index) => (
                <Grid item key={index}>
                  <div style={{ position: "relative", marginLeft: "5px" }}>
                    <motion.div
                      style={{ display: "flex" }}
                      animate={
                        isDeleteModeActive &&
                        selectedForRemoval &&
                        selectedForRemoval.includes(index)
                          ? {
                              rotate: [0, -5, 5, -5, 5, -2, 2, 0],
                            }
                          : {}
                      }
                    >
                      {item.orderNumber ===
                        selectedItems[clickedCube].orderNumber &&
                        item.base64Photos &&
                        item.base64Photos.map((base64, photoIndex) => (
                          <div
                            key={photoIndex}
                            style={{ position: "relative" }}
                          >
                            <img
                              src={base64}
                              alt={`image-${index}-${photoIndex}`}
                              style={{
                                width: "100px",
                                height: "100px",
                                marginRight: "10px",
                                position: "relative",
                                opacity:
                                  isDeleteModeActive &&
                                  selectedForRemoval &&
                                  selectedForRemoval.includes(index)
                                    ? 0.5
                                    : 1,
                              }}
                            />
                            {isDeleteModeActive && (
                              <IconButton
                                size="small"
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                  backgroundColor: "rgba(255,0,0,0.5)",
                                }}
                                onClick={() =>
                                  handleRemoveImageFromGallery(
                                    index,
                                    photoIndex
                                  )
                                }
                              >
                                <Close />
                              </IconButton>
                            )}
                          </div>
                        ))}
                    </motion.div>
                  </div>
                </Grid>
              ))}
            <Grid
              item
              md={12}
              xs={12}
              justifyContent={"flex-start"}
              alignItems={"center"}
              display={"flex"}
            >
              {mySiteSliderSection && mySiteSliderSection.length > 0 && (
                <Grid
                  item
                  display={"flex"}
                  alignItems={"center"}
                  onClick={() => {
                    if (!isPhotosEmpty) {
                      handleEdit();
                    }
                  }}
                >
                  <IconButton>
                    <DeleteIcon sx={{ color: colors.red }} />
                  </IconButton>
                  <Typography
                    sx={{
                      color: colors.red,
                      cursor: isPhotosEmpty ? "default" : "pointer",
                      pointerEvents: isPhotosEmpty ? "none" : "auto",
                    }}
                  >
                    {t("mySite.mySiteGalleryRemovePhotos")}
                  </Typography>
                </Grid>
              )}
              <Grid
                item
                ml={1}
                display={"flex"}
                alignItems={"center"}
                onClick={() => {
                  if (
                    mySiteSliderSection.reduce((total, item) => {
                      if (
                        item.orderNumber ===
                        selectedItems[clickedCube]?.orderNumber
                      ) {
                        return total + item.base64Photos.length;
                      }
                      return total;
                    }, 0) < 5
                  ) {
                    setIsOpenModal(true);
                  }
                }}
              >
                <IconButton>
                  <AddIcon
                    sx={{
                      color:
                        mySiteSliderSection.reduce((total, item) => {
                          if (
                            item.orderNumber ===
                            selectedItems[clickedCube]?.orderNumber
                          ) {
                            return total + item.base64Photos.length;
                          }
                          return total;
                        }, 0) >= 5
                          ? "grey"
                          : colors.primary,
                    }}
                  />
                </IconButton>
                <Typography
                  sx={{
                    color:
                      mySiteSliderSection.reduce((total, item) => {
                        if (
                          item.orderNumber ===
                          selectedItems[clickedCube]?.orderNumber
                        ) {
                          return total + item.base64Photos.length;
                        }
                        return total;
                      }, 0) >= 5
                        ? "grey"
                        : colors.primary,
                    cursor:
                      mySiteSliderSection.reduce((total, item) => {
                        if (
                          item.orderNumber ===
                          selectedItems[clickedCube]?.orderNumber
                        ) {
                          return total + item.base64Photos.length;
                        }
                        return total;
                      }, 0) >= 5
                        ? "default"
                        : "pointer",
                  }}
                >
                  {t("mySite.mySiteGalleryAdd")}
                </Typography>
              </Grid>
            </Grid>
            {mySiteSliderSection && mySiteSliderSection.length > 0 && (
              <>
                <Grid item md={12} xs={12} mb={1}>
                  <Typography variant="body2" sx={{ marginLeft: "5px" }}>
                    {t("mySite.mySiteMaxNumber")}
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={12}
                  xs={12}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  display={"flex"}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      marginLeft: "5px",
                      color:
                        mySiteSliderSection.reduce((total, item) => {
                          if (
                            item.orderNumber ===
                            selectedItems[clickedCube]?.orderNumber
                          ) {
                            return total + item.base64Photos.length;
                          }
                          return total;
                        }, 0) >= 5
                          ? colors.red
                          : colors.green,
                    }}
                  >
                    {mySiteSliderSection.reduce((total, item) => {
                      if (
                        item.orderNumber ===
                        selectedItems[clickedCube]?.orderNumber
                      ) {
                        return total + item.base64Photos.length;
                      }
                      return total;
                    }, 0)}{" "}
                    {t("mySite.mySiteGalleryFrom")} 5{" "}
                    {t("mySite.mySiteGalleryPhotography")}
                  </Typography>
                </Grid>
              </>
            )}
          </>
        )}
        <Grid
          item
          md={12}
          justifyContent={"flex-end"}
          alignItems={"center"}
          display={"flex"}
        >
          {mySiteSliderSection && mySiteSliderSection.length > 0 && (
            <Grid item>
              <Tooltip title={"mySite.mySiteRemoveSliderTooltip"}>
                <AppButton
                  startIcon={<DeleteIcon />}
                  label={t("buttons.delete")}
                  color={colors.red}
                  hover={colors.red}
                  onClick={handleRemoveSlider}
                />
              </Tooltip>
            </Grid>
          )}

          <Grid item ml={1}>
            <AppButton
              startIcon={
                status === ApiStatus.Pending ? (
                  <CircularProgress size={15} sx={{ color: colors.primary }} />
                ) : (
                  buttonIcon
                )
              }
              label={buttonText}
              color={colors.green}
              hover={colors.green}
              onClick={handleSave}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
