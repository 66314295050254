import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { colors } from "../../../app/theme/colors";
import AppButton from "../../../components/appButton/appButton";
import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import logo from "../../../images/logoFull.png";
import CustomOutlinedInput from "../../../components/outlinedInput/customOutlinedInput";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SetPasswordModel from "../../../models/auth/setPasswordModel";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import RemoveCircleOutlineTwoToneIcon from "@mui/icons-material/RemoveCircleOutlineTwoTone";
import { useForgotSetPasswordModalStyles } from "./forgotSetPasswordModalStyles";
import {
  resetForgotPasswordStatus,
  resetSetForgotPasswordStatus,
  selectForgotPasswordStatus,
  selectSetForgotPasswordStatus,
  setForgotPasswordClientAccount,
} from "../../../slices/authSlice";
import SetForgotPasswordModel from "../../../models/auth/setForgotPasswordModel";
import { ApiStatus } from "../../../models/app/apiStatus";
import { toast } from "react-toastify";
import {
  INIT_FORGOT_PASSWORD,
  INIT_SET_FORGOT_PASSWORD_MODEL,
  INIT_SET_PASSWORD,
} from "../../../models/init_data/initData";
import ForgotPasswordRequest from "../../../models/auth/forgotPasswordRequest";

interface Props {
  open: any;
  handleClose: any;
  setForgotPassword: SetPasswordModel;
  setSetForgotPassword: React.Dispatch<React.SetStateAction<SetPasswordModel>>;
  setForgotPasswordAccount: React.Dispatch<
    React.SetStateAction<SetForgotPasswordModel>
  >;
  forgotPasswordAccount: SetForgotPasswordModel;
  setIsOpenForgotPasswordModal: React.Dispatch<React.SetStateAction<boolean>>;
  setForgotPasswordModal: React.Dispatch<
    React.SetStateAction<ForgotPasswordRequest>
  >;
}

interface PasswordValidation {
  greaterThanOrEqualTo8: boolean;
  atLeastOneUpperCase: boolean;
  atLeastOneNumber: boolean;
  atLeastOneSpecialCharacter: boolean;
  passwordConfirm: boolean;
}

export default function ForgotSetPasswordModal({
  handleClose,
  open,
  setForgotPassword,
  setSetForgotPassword,
  forgotPasswordAccount,
  setForgotPasswordAccount,
  setIsOpenForgotPasswordModal,
  setForgotPasswordModal,
}: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { classes } = useForgotSetPasswordModalStyles();
  const theme = useTheme();

  const forgotPassStatus = useAppSelector(selectSetForgotPasswordStatus);

  const [isPasswordVisible, setIsPasswordVisible] = useState(true);
  const [isPasswordVisibleConfirm, setIsPasswordVisibleConfirm] = useState(true);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const passwordValidation: PasswordValidation = {
    greaterThanOrEqualTo8: setForgotPassword.newPassword.length >= 8,
    atLeastOneUpperCase: /[A-Z]/.test(setForgotPassword.newPassword),
    atLeastOneNumber: /\d/.test(setForgotPassword.newPassword),
    atLeastOneSpecialCharacter: /[!@#$%^&*(),.?":{}|<>]/.test(
      setForgotPassword.newPassword
    ),
    passwordConfirm:
      setForgotPassword.newPassword !== "" &&
      setForgotPassword.confirmNewPassword !== "" &&
      setForgotPassword.newPassword === setForgotPassword.confirmNewPassword,
  };

  const handleForgotSetPasswordModalChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    setSetForgotPassword((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === "code") {
      setForgotPasswordAccount((prevState) => ({
        ...prevState,
        code: value,
      }));
    } else if (name === "newPassword") {
      setForgotPasswordAccount((prevState) => ({
        ...prevState,
        newPassword: value,
      }));
    }

    setPasswordError(false);
  };

  const handleSetForgotPassword = () => {
    dispatch(setForgotPasswordClientAccount(forgotPasswordAccount));
  };

  const handleDialogClose = () => {
    setEmailError(false);
    setPasswordError(false);

    handleClose();
  };

  useEffect(() => {
    if (forgotPassStatus === ApiStatus.Fulfilled) {
      setIsOpenForgotPasswordModal(false);
      handleDialogClose();
      setForgotPasswordAccount(INIT_SET_FORGOT_PASSWORD_MODEL);
      setSetForgotPassword(INIT_SET_PASSWORD);
      setForgotPasswordModal(INIT_FORGOT_PASSWORD);
      dispatch(resetSetForgotPasswordStatus());
    }
  }, [forgotPassStatus]);

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      fullWidth
      maxWidth="xs"
      id="setPasswordModal-dialog"
      PaperProps={{ sx: { borderRadius: "15px", overflow: "hidden" } }}
    >
      <IconButton
        id="setPasswordModal-iconButton-close"
        edge="end"
        color="inherit"
        onClick={handleDialogClose}
        aria-label="close"
        sx={{
          position: "absolute",
          top: theme.spacing(1),
          right: theme.spacing(2),
        }}
      >
        <CloseIcon className={classes.closeIcon} />
      </IconButton>
      <DialogContent className={classes.dialogContent}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          padding={"5px"}
          sx={{ textAlign: "center" }}
        >
          <Grid item md={12} xs={12}>
            <img
              src={logo}
              alt="Logo"
              className={classes.logoImage}
              id="forgotSetPasswordModal-img-logo"
            />
          </Grid>
          <Grid item md={12} xs={12} mt={5}>
            <CustomOutlinedInput
              id="forgotSetPasswordModal-input-code"
              type="text"
              label={t("forgotPasswordModal.inputCode")}
              width="100%"
              name="code"
              onChange={handleForgotSetPasswordModalChange}
              value={setForgotPassword.code}
              error={emailError}
              errorMessage={t("loginModal.loginErrorEmail")}
            />
            {setForgotPassword.code === "" && (
              <Typography sx={{ textAlign: "start", color: colors.error }}>
                {t("forgotPasswordModal.codeMessage")}
              </Typography>
            )}
          </Grid>
          <Grid item md={12} xs={12} mt={3}>
            <CustomOutlinedInput
              id="forgotSetPasswordModal-input-newPassword"
              type={isPasswordVisible ? "password" : "text"}
              label={t("forgotPasswordModal.inputNewPassword")}
              name="newPassword"
              width="100%"
              labelColor={colors.primary}
              onChange={handleForgotSetPasswordModalChange}
              value={setForgotPassword.newPassword}
              error={emailError}
              errorMessage={t("loginModal.loginErrorEmail")}
              endAdornment={
                isPasswordVisible ? (
                  <VisibilityOffIcon
                    sx={{ cursor: "pointer", color: colors.primary }}
                    onClick={() => setIsPasswordVisible(false)}
                  />
                ) : (
                  <VisibilityIcon
                    onClick={() => setIsPasswordVisible(true)}
                    sx={{ cursor: "pointer", color: colors.primary }}
                  />
                )
              }
            />
          </Grid>
          <Grid item md={12} xs={12} mt={3}>
            <CustomOutlinedInput
              id="forgotSetPasswordModal-input-confirmPassword"
              label={t("forgotPasswordModal.inputConfirmNewPassword")}
              width="100%"
              type={isPasswordVisibleConfirm ? "password" : "text"}
              name="confirmNewPassword"
              labelColor={colors.primary}
              onChange={handleForgotSetPasswordModalChange}
              value={setForgotPassword.confirmNewPassword}
              error={passwordError}
              endAdornment={
                isPasswordVisibleConfirm ? (
                  <VisibilityOffIcon
                    sx={{ cursor: "pointer", color: colors.primary }}
                    onClick={() => setIsPasswordVisibleConfirm(false)}
                  />
                ) : (
                  <VisibilityIcon
                    onClick={() => setIsPasswordVisibleConfirm(true)}
                    sx={{ cursor: "pointer", color: colors.primary }}
                  />
                )
              }
              errorMessage={t("loginModal.loginErrorPassword")}
            />
          </Grid>
          <Box my={2}>
            {
              <Typography
                variant="body1"
                sx={{
                  color: passwordValidation.greaterThanOrEqualTo8
                    ? colors.green
                    : colors.validationErrorMessage,
                  display: "flex",
                  my: 1,
                }}
              >
                {passwordValidation.greaterThanOrEqualTo8 ? (
                  <CheckCircleTwoToneIcon sx={{ mr: 1 }} />
                ) : (
                  <RemoveCircleOutlineTwoToneIcon sx={{ mr: 1 }} />
                )}
                {t("setPasswordModal.setPasswordMinimumEightCharacter")}
              </Typography>
            }

            {
              <Typography
                variant="body1"
                sx={{
                  color: passwordValidation.atLeastOneUpperCase
                    ? colors.green
                    : colors.validationErrorMessage,
                  display: "flex",
                  my: 1,
                }}
              >
                {passwordValidation.atLeastOneUpperCase ? (
                  <CheckCircleTwoToneIcon sx={{ mr: 1 }} />
                ) : (
                  <RemoveCircleOutlineTwoToneIcon sx={{ mr: 1 }} />
                )}
                {t("setPasswordModal.setPasswordCapitalLetter")}
              </Typography>
            }

            {
              <Typography
                variant="body1"
                sx={{
                  color: passwordValidation.atLeastOneNumber
                    ? colors.green
                    : colors.validationErrorMessage,
                  display: "flex",
                  my: 1,
                }}
              >
                {passwordValidation.atLeastOneNumber ? (
                  <CheckCircleTwoToneIcon sx={{ mr: 1 }} />
                ) : (
                  <RemoveCircleOutlineTwoToneIcon sx={{ mr: 1 }} />
                )}
                {t("setPasswordModal.setPasswordNumber")}
              </Typography>
            }

            {
              <Typography
                variant="body1"
                sx={{
                  color: passwordValidation.atLeastOneSpecialCharacter
                    ? colors.green
                    : colors.validationErrorMessage,
                  display: "flex",
                  my: 1,
                }}
              >
                {passwordValidation.atLeastOneSpecialCharacter ? (
                  <CheckCircleTwoToneIcon sx={{ mr: 1 }} />
                ) : (
                  <RemoveCircleOutlineTwoToneIcon sx={{ mr: 1 }} />
                )}
                {t("setPasswordModal.setPasswordSpecialCharacter")}
              </Typography>
            }

            {
              <Typography
                variant="body1"
                sx={{
                  color: passwordValidation.passwordConfirm
                    ? colors.green
                    : colors.validationErrorMessage,
                  display: "flex",
                  my: 1,
                }}
              >
                {passwordValidation.passwordConfirm ? (
                  <CheckCircleTwoToneIcon sx={{ mr: 1 }} />
                ) : (
                  <RemoveCircleOutlineTwoToneIcon sx={{ mr: 1 }} />
                )}
                {t("setPasswordModal.setPasswordConfirmPassword")}
              </Typography>
            }
          </Box>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <AppButton
          id="forgotSetPasswordModal_button_close"
          onClick={handleSetForgotPassword}
          color={colors.primary}
          hover={colors.primary}
          label={t("buttons.forgotPass")}
          width="100%"
          startIcon={
            forgotPassStatus === ApiStatus.Pending ? (
              <CircularProgress size={15} sx={{ color: colors.primary }} />
            ) : null
          }
          disabled={
            (!passwordValidation.atLeastOneNumber &&
              !passwordValidation.atLeastOneSpecialCharacter &&
              !passwordValidation.atLeastOneUpperCase &&
              !passwordValidation.greaterThanOrEqualTo8 &&
              !passwordValidation.passwordConfirm) ||
            forgotPassStatus === ApiStatus.Pending
          }
        />
      </DialogActions>
    </Dialog>
  );
}
