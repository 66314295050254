import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";
import { colors } from "../../app/theme/colors";
import bosniaFlag from "../../images/ba-03.png";

export const useAdvertisementSectionStyles = makeStyles()((theme: Theme) => ({
  text: {
    color: "white",
  },
  img: {
    width: "60%",
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
  },
  title: {
    color: "white",
    fontWeight: "bold",
    marginBottom: "5px",
  },
  description: {
    color: "white",
    fontWeight: "normal",
  },
  imageSectionWrapper: {
    textAlign: "center",
  },
  autocompleteTextField: {
    "& .MuiInputBase-root": {
      borderRadius: "5px",
      background: "white",
    },
  },
  autocomplete: {
    "& .MuiOutlinedInput-root.Mui-focused": {
      "& > fieldset": {
        borderColor: colors.sectionTitle,
      },
    },
  },
  aboutText: {
    color: "white",
  },
  aboutUsImg: {
    width: "60%",
  },
  descTitle: {
    color: "white",
  },
  descText: {
    color: "white",
  },
  icon: {
    color: `${colors.green} !important`,
    marginRight: "10px",
    fill: "white",
  },
  formWrapper: {
    padding: "0 20px",
  },
  buttonWrapper: {
    padding: "10px 20px",
  },
  descriptionWrapper: {
    display: "flex",
    alignItems: "center",
  },
  phoneSectionWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    alignContent: "center",
    alignItems: "center",
  },
  errorMessage: {
    textAlign: "start",
    color: colors.validationErrorMessage,
    fontSize: 12,
  },
  descriptionBackground: {
    background: `url(${bosniaFlag}) center/cover no-repeat`,
    backgroundSize: "80%",
  },
}));
