import { Grid, useMediaQuery, useTheme } from "@mui/material";
import MobileHeaderSecondary from "../../features/header/mobileHeaderSecondary/mobileHeaderSecondary";
import SecondaryHeader from "../../features/header/secondaryHeader/secondaryHeader";
import SubHeader from "../../features/header/subHeader/subHeader";
import Sidebar from "../../features/sidebar/sidebar";
import { useAppDispatch, useAppSelector } from "../../app/redux/hooks";
import { selectCurrentAccount } from "../../slices/authSlice";
import { useDashboardPageStyles } from "./dashboardPageStyles";
import DashboardSection from "../../features/dashboardSection/dashboardSection";
import { CompanyClientsRequest } from "../../models/company/companyClientsRequest";
import { useEffect, useState } from "react";
import {
  getAccountCompanyClients,
  getAccountFavorites,
  getAccountPartnerCompanies,
  getAccountPotentialPartnerCompanies,
  selectAccountFavorites,
  selectAccountPartnerCompanies,
  selectAccountPotentialPartnerCompanies,
  selectCompanyClients,
} from "../../slices/onboardingSlice";

export default function DashboardPage() {
  const dispatch = useAppDispatch();
  const { classes } = useDashboardPageStyles();
  const theme = useTheme();

  const currentAccount = useAppSelector(selectCurrentAccount);
  const selectedCopanyClients = useAppSelector(selectCompanyClients);
  const selectedAccountFavorites = useAppSelector(selectAccountFavorites);
  const selectedAccountPartnerCompanies = useAppSelector(
    selectAccountPartnerCompanies
  );
  const selectedAccountPotentialParterCompanies = useAppSelector(
    selectAccountPotentialPartnerCompanies
  );

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const INIT_COMPANY_CLIENTS: CompanyClientsRequest = {
    address: null,
    categoryName: null,
    cityName: null,
    companyName: null,
  };

  const [companyClients, setCompanyClietns] =
    useState<CompanyClientsRequest>(INIT_COMPANY_CLIENTS);
  const [accountFavorites, setAccountFavorites] =
    useState<CompanyClientsRequest>(INIT_COMPANY_CLIENTS);
  const [accountPartnerCompanies, setAccountPartnerCompanies] =
    useState<CompanyClientsRequest>(INIT_COMPANY_CLIENTS);
  const [
    accountPotentialPartnerCompanies,
    setAccountPotentialPartnerCompanies,
  ] = useState<CompanyClientsRequest>(INIT_COMPANY_CLIENTS);

  useEffect(() => {
    dispatch(getAccountCompanyClients(companyClients));
    dispatch(getAccountFavorites(accountFavorites));
    dispatch(getAccountPartnerCompanies(accountPartnerCompanies));
    dispatch(
      getAccountPotentialPartnerCompanies(accountPotentialPartnerCompanies)
    );
  }, []);

  return (
    <>
      <SubHeader />
      <div className={classes.container}>
        <Grid container>
          {currentAccount && isDesktop && (
            <Grid item md={0.3}>
              <Sidebar />
            </Grid>
          )}
          <Grid item xs={12} md={isDesktop && currentAccount ? 11.7 : 12}>
            <Grid container>
              <Grid item xs={12}>
                {isDesktop && <SecondaryHeader />}
                {isMobile && <MobileHeaderSecondary />}
              </Grid>
              <Grid item xs={12} className={classes.dashboardSectionWrapper}>
                <DashboardSection
                  setAccountFavorites={setAccountFavorites}
                  accountFavorites={accountFavorites}
                  accountClients={companyClients}
                  accountPartners={accountPartnerCompanies}
                  accountPotentialPartners={accountPotentialPartnerCompanies}
                  companyClients={selectedCopanyClients}
                  selectedAccountFavorites={selectedAccountFavorites}
                  selectedAccountPartner={selectedAccountPartnerCompanies}
                  selectedAccountPotentialPartners={
                    selectedAccountPotentialParterCompanies
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
