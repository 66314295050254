import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import aboutUs from "../../images/aboutUs.png";
import { colors } from "../../app/theme/colors";
import AppButton from "../../components/appButton/appButton";
import SendIcon from "@mui/icons-material/Send";
import { useAdvertisementRequestSectionStyles } from "./advertisementRequestSectionStyles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import EmailIcon from "@mui/icons-material/Email";
import { useEffect, useState } from "react";
import CustomOutlinedInput from "../../components/outlinedInput/customOutlinedInput";
import { useTranslation } from "react-i18next";
import { AdvertisementRequestModel } from "../../models/advertisementRequestModel";
import { INIT_ADVERTISEMENT_REQUEST } from "../../models/init_data/initData";
import { useAppDispatch, useAppSelector } from "../../app/redux/hooks";
import {
  getAllPlaces,
  selectAllPlaces,
  selectStreetsByPlaceId,
} from "../../slices/addressSlice";
import { selectOrderStatus, sendOrderRequest } from "../../slices/companySlice";
import {
  IsCompanyNameValid,
  IsEmailValid,
  IsMessageValid,
  IsPackageValidate,
  IsPhoneNumberValid,
} from "../../common/validations/validations";
import React from "react";

const GradientBackground = styled(Grid)(({ theme }) => ({
  alignItems: "center",
  backgroundRepeat: "no-repeat",
  background: `linear-gradient(90deg, ${colors.advertisementRequest} 50%, ${colors.primary} 50%)`,

  backgroundPosition: "center",
  [theme.breakpoints.down("sm")]: {
    background: `linear-gradient(180deg, ${colors.advertisementRequest} 50%, ${colors.primary} 50%)`,
  },
}));

export const StyledCheckCircleIcon = styled(CheckCircleIcon)({
  backgroundColor: "white",
  color: colors.green,
  borderRadius: "50%",
  marginRight: "10px",
});

type ButtonName = "paket1" | "paket2" | "paket3";

export default function AdvertisementRequestSection() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { classes } = useAdvertisementRequestSectionStyles();
  const theme = useTheme();

  const orderStatus = useAppSelector(selectOrderStatus);

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const [selectedPlace, setSelectedPlace] = useState(null);
  const [selectedStreet, setSelectedStreet] = useState(null);

  const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false);

  const [advertisementRequest, setAdvertisementRequest] =
    useState<AdvertisementRequestModel>(INIT_ADVERTISEMENT_REQUEST);

  const packageText = t("orderRequestPage.orderPackageOneText");
  const packageTextSecond = t("orderRequestPage.orderPackageTwoText");

  const [buttonText, setButtonText] = useState(
    t("buttons.orderByPhone") as string
  );
  const [phoneLinkMobile, setPhoneLinkMobile] = useState("tel:+38751255310");

  const [validate, setValidate] = useState<boolean>(false);

  const [outlined, setOutlined] = useState({
    package1: true,
    package2: true,
    package3: true,
  });
  const [isChecked, setIsChecked] = useState(false);

  function handleClickEmailButton() {
    const {
      companyName,
      email,
      homeNumber,
      message,
      choosenPackage,
      phoneNumber,
      taxId,
    } = advertisementRequest;
    const subject = "Narudžba";

    let body = `Porudžbina,
  
      Detalji porudžbine:
      ---------------
      Izabrani paket: ${choosenPackage}
      Kompanija: ${companyName}
      PIB: ${taxId}
      E-pošta: ${email}
      Kućni broj: ${homeNumber}
      Poruka: ${message}
      Broj telefona: ${phoneNumber};
      `;

    if (selectedPlace && selectedStreet) {
      body += `
      
      Izabrani grad: ${selectedPlace}
      Izabrana ulica: ${selectedStreet}`;
    }

    body += "\n\nHvala!";

    const mailtoLink = `mailto:info@poslovniimenik.net?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    const link = document.createElement("a");
    link.href = mailtoLink;
    link.target = "_blank";
    link.dispatchEvent(new MouseEvent("click"));
    setAdvertisementRequest(INIT_ADVERTISEMENT_REQUEST);
    setValidate(false);
  }
  const isValid = () => {
    setValidate(true);
    if (
      !IsCompanyNameValid(advertisementRequest.companyName) ||
      !IsEmailValid(advertisementRequest.email) ||
      !IsMessageValid(advertisementRequest.message) ||
      !IsPackageValidate(advertisementRequest.choosenPackage)
    ) {
      return false;
    }

    if (
      !advertisementRequest.phoneNumber &&
      (advertisementRequest.companyName ||
        advertisementRequest.email ||
        advertisementRequest.message ||
        advertisementRequest.choosenPackage)
    ) {
      return false;
    }

    return true;
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (isValid()) {
      if (advertisementRequest.phoneNumber !== "") {
        dispatch(sendOrderRequest(advertisementRequest));
      }
    }
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleRecaptchaChange = (value: any) => {
    if (value) {
      setIsRecaptchaVerified(true);
    } else {
      setIsRecaptchaVerified(false);
    }
  };

  const handleFormChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setAdvertisementRequest((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleClick = (buttonName: ButtonName) => {
    setAdvertisementRequest((prevRequest) => ({
      ...prevRequest,
      choosenPackage:
        buttonName === "paket1"
          ? "paket 1"
          : buttonName === "paket2"
          ? "paket 2"
          : "paket 3",
    }));

    setOutlined({
      package1: buttonName === "paket1" ? !outlined.package1 : true,
      package2: buttonName === "paket2" ? !outlined.package2 : true,
      package3: buttonName === "paket3" ? !outlined.package3 : true,
    });
  };

  const handleClickPhone = () => {
    setButtonText("+387 51 255 310");
    setTimeout(() => {
      setButtonText(t("buttons.orderByPhone") as string);
    }, 5000);
  };

  const handleResetButtons = () => {
    setOutlined({
      package1: true,
      package2: true,
      package3: true,
    });
  };

  const handleResetFields = () => {
    setAdvertisementRequest(INIT_ADVERTISEMENT_REQUEST);
    setSelectedPlace(null);
    setSelectedStreet(null);
    handleResetButtons();
    setIsChecked(false);
  };

  useEffect(() => {
    dispatch(getAllPlaces());
  }, []);

  useEffect(() => {
    if (orderStatus === "Fulfilled") {
      handleResetFields();
      setValidate(false);
    }
  }, [orderStatus]);

  useEffect(() => {
    if (selectedPlace === null || selectedStreet === null) {
      setSelectedStreet(null);
      setAdvertisementRequest((prevData) => ({
        ...prevData,
        homeNumber: "",
      }));
    }
  }, [selectedPlace, selectedStreet]);

  return (
    <GradientBackground container>
      <FormControl>
        {isDesktop && (
          <Grid container spacing={1}>
            <Grid item md={6} xs={12}>
              <Grid item md={12} mt={4} mb={3}>
                <Typography
                  textAlign={"center"}
                  variant="h6"
                  className={classes.title}
                >
                  {t("orderRequestPage.orderTitle")}
                </Typography>
              </Grid>
              <Grid
                container
                className={classes.formWrapper}
                spacing={1}
                mb={2}
              >
                <Grid item md={4} xs={12}>
                  <Button
                    fullWidth
                    sx={{
                      backgroundColor: outlined.package1
                        ? "white"
                        : colors.primary,
                      color: outlined.package1 ? colors.primary : "white",
                      border:
                        validate &&
                        !IsPackageValidate(advertisementRequest.choosenPackage)
                          ? `1px solid ${colors.validationErrorMessage}`
                          : "none",
                      boxShadow:
                        "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
                      "&:hover": {
                        backgroundColor: colors.primary,
                        color: "white",
                        border: "none",
                      },
                    }}
                    variant={outlined.package1 ? "text" : "contained"}
                    onClick={() => handleClick("paket1")}
                    startIcon={
                      outlined.package1 ? (
                        <RadioButtonUncheckedIcon />
                      ) : (
                        <CheckCircleIcon
                          sx={{
                            color: colors.green,
                            background: "white",
                            borderRadius: "100%",
                          }}
                        />
                      )
                    }
                  >
                    <Typography variant="body1">
                      {t("orderRequestPage.orderPackageOne")}
                    </Typography>
                  </Button>
                  {validate &&
                    !IsPackageValidate(advertisementRequest.choosenPackage) && (
                      <span className={classes.errorMessage}>
                        {t("validation.validationPackage")}
                      </span>
                    )}
                </Grid>
                <Grid item md={4} xs={12}>
                  <Button
                    fullWidth
                    sx={{
                      backgroundColor: outlined.package2
                        ? "white"
                        : colors.primary,
                      color: outlined.package2 ? colors.primary : "white",
                      border:
                        validate &&
                        !IsPackageValidate(advertisementRequest.choosenPackage)
                          ? `1px solid ${colors.validationErrorMessage}`
                          : "none",
                      boxShadow:
                        "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
                      "&:hover": {
                        backgroundColor: colors.primary,
                        color: "white",
                        border: "none",
                      },
                    }}
                    variant={outlined.package2 ? "text" : "contained"}
                    onClick={() => handleClick("paket2")}
                    startIcon={
                      outlined.package2 ? (
                        <RadioButtonUncheckedIcon />
                      ) : (
                        <CheckCircleIcon
                          sx={{
                            color: colors.green,
                            background: "white",
                            borderRadius: "100%",
                          }}
                        />
                      )
                    }
                  >
                    <Typography variant="body1">
                      {t("orderRequestPage.orderPackageTwo")}
                    </Typography>
                  </Button>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Button
                    fullWidth
                    sx={{
                      backgroundColor: outlined.package3
                        ? "white"
                        : colors.primary,
                      color: outlined.package3 ? colors.primary : "white",
                      border:
                        validate &&
                        !IsPackageValidate(advertisementRequest.choosenPackage)
                          ? `1px solid ${colors.validationErrorMessage}`
                          : "none",
                      boxShadow:
                        "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
                      "&:hover": {
                        backgroundColor: colors.primary,
                        color: "white",
                        border: "none",
                      },
                    }}
                    variant={outlined.package3 ? "text" : "contained"}
                    onClick={() => handleClick("paket3")}
                    startIcon={
                      outlined.package3 ? (
                        <RadioButtonUncheckedIcon />
                      ) : (
                        <CheckCircleIcon
                          sx={{
                            color: colors.green,
                            background: "white",
                            borderRadius: "100%",
                          }}
                        />
                      )
                    }
                  >
                    <Typography variant="body1">
                      {t("orderRequestPage.orderPackageThree")}
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  lg={6}
                  md={12}
                  mt={4}
                  className={classes.formWrapper}
                >
                  <CustomOutlinedInput
                    width="100%"
                    label={t("orderRequestPage.orderFormCompanyName")}
                    value={advertisementRequest.companyName}
                    onChange={(e) => handleFormChange(e)}
                    name={"companyName"}
                    labelColor={colors.primary}
                    error={
                      validate &&
                      !IsCompanyNameValid(advertisementRequest.companyName)
                    }
                    errorMessage={t("validation.validationCompanyName")}
                  />
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={12}
                  mt={4}
                  className={classes.formWrapper}
                >
                  <CustomOutlinedInput
                    width="100%"
                    label={t("orderRequestPage.orderFormTaxId")}
                    value={advertisementRequest.taxId}
                    onChange={(e) => handleFormChange(e)}
                    name={"taxId"}
                    labelColor={colors.primary}
                  />
                </Grid>
                <Grid
                  item
                  lg={4}
                  md={12}
                  mt={4}
                  className={classes.formWrapper}
                >
                  <CustomOutlinedInput
                    width="100%"
                    label={t("orderRequestPage.orderFormPlace") ?? ""}
                    value={advertisementRequest.place}
                    onChange={(e) => handleFormChange(e)}
                    name={"place"}
                    labelColor={colors.primary}
                  />
                </Grid>
                <Grid
                  item
                  lg={4}
                  md={12}
                  mt={4}
                  className={classes.formWrapper}
                >
                  <CustomOutlinedInput
                    width="100%"
                    label={t("orderRequestPage.orderFormStreet") ?? ""}
                    value={advertisementRequest.street}
                    onChange={(e) => handleFormChange(e)}
                    name={"street"}
                    labelColor={colors.primary}
                    disabled={advertisementRequest.place === ""}
                  />
                </Grid>
                <Grid
                  item
                  lg={4}
                  md={12}
                  mt={4}
                  className={classes.formWrapper}
                >
                  <CustomOutlinedInput
                    width="100%"
                    label={t("orderRequestPage.orderFormHomeNumber")}
                    value={advertisementRequest.homeNumber}
                    onChange={(e) => handleFormChange(e)}
                    name={"homeNumber"}
                    labelColor={colors.primary}
                    disabled={advertisementRequest.street === ""}
                  />
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={12}
                  mt={4}
                  className={classes.formWrapper}
                >
                  <CustomOutlinedInput
                    width="100%"
                    label={t("orderRequestPage.orderFormEmail")}
                    value={advertisementRequest.email}
                    onChange={(e) => handleFormChange(e)}
                    name={"email"}
                    labelColor={colors.primary}
                    error={
                      validate &&
                      !IsCompanyNameValid(advertisementRequest.email)
                    }
                    errorMessage={t("validation.validationEmail")}
                  />
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={12}
                  mt={4}
                  className={classes.formWrapper}
                >
                  <CustomOutlinedInput
                    width="100%"
                    label={t("orderRequestPage.orderFormPhoneNumber")}
                    value={advertisementRequest.phoneNumber}
                    onChange={(e) => handleFormChange(e)}
                    name={"phoneNumber"}
                    labelColor={colors.primary}
                    error={
                      validate &&
                      !IsCompanyNameValid(advertisementRequest.phoneNumber)
                    }
                    errorMessage={t("validation.validationPhoneNumber")}
                  />
                </Grid>
                <Grid
                  item
                  lg={12}
                  md={12}
                  mt={4}
                  className={classes.formWrapper}
                >
                  <CustomOutlinedInput
                    width="100%"
                    label={t("orderRequestPage.orderFormMessage")}
                    value={advertisementRequest.message}
                    onChange={(e) => handleFormChange(e)}
                    name={"message"}
                    multiline
                    rows={6}
                    labelColor={colors.primary}
                    error={
                      validate &&
                      !IsCompanyNameValid(advertisementRequest.message)
                    }
                    errorMessage={t("validation.validationMessage")}
                  />
                </Grid>
                <Grid item md={12} mt={2} className={classes.formWrapper}>
                  <FormControlLabel
                    sx={{ color: colors.primary }}
                    control={
                      <Checkbox
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                        sx={{
                          color: colors.primary,
                          "&.Mui-checked": {
                            color: colors.primary,
                          },
                        }}
                      />
                    }
                    label={t("termsAndContitions.error")}
                  />
                  <Typography variant="body1" sx={{ color: colors.primary }}>
                    {t("termsAndContitions.description")}
                  </Typography>
                </Grid>
              </Grid>
              {/* <Grid item md={12} xs={12}>
                <label htmlFor="name">Name</label>
                <input type="text" id="name" className="input" />
                <ReCAPTCHA sitekey={"6LdyDn0mAAAAAOykS6_65mT2IwX_-yTEncYD8JuH"}/>
                <button>Submit</button>
            </Grid> */}

              <Grid
                container
                className={classes.formWrapper}
                spacing={1}
                my={3}
              >
                <Grid item lg={4} md={12} xs={12} >
                  <AppButton
                    label={t("buttons.orderOnline")}
                    color={colors.primary}
                    hover={colors.primary}
                    disabled={orderStatus === "Pending" || isChecked !== true}
                    startIcon={
                      orderStatus === "Pending" ? (
                        <CircularProgress size={"1em"} />
                      ) : (
                        <SendIcon />
                      )
                    }
                    width="100%"
                    onClick={handleSubmit}
                  />
                </Grid>
                {isDesktop && (
                  <Grid item lg={4} md={12} xs={12}>
                    <AppButton
                      label={buttonText}
                      color={colors.primary}
                      hover={colors.primary}
                      startIcon={<PhoneInTalkIcon />}
                      onClick={handleClickPhone}
                      disabled={isChecked !== true}
                      href={
                        buttonText === t("buttons.orderByPhone")
                          ? null
                          : "tel:+381692128500"
                      }
                      width="100%"
                    />
                  </Grid>
                )}

                <Grid item lg={4} md={12} xs={12}>
                  <AppButton
                    label={t("buttons.orderByEmail")}
                    color={colors.primary}
                    hover={colors.primary}
                    startIcon={<EmailIcon />}
                    width="100%"
                    onClick={handleClickEmailButton}
                    disabled={isChecked !== true}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
              className={classes.descriptionBackground}
            >
              <Grid item md={12} className={classes.descriptionWrapper}>
                <Grid container alignItems="center">
                  <Grid
                    item
                    md={12}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <StyledCheckCircleIcon />
                    <Typography variant="h6" className={classes.descTitle}>
                      {t("orderRequestPage.orderPackageOne")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Grid container direction="column" className={classes.icon}>
                      <Typography variant="body1" className={classes.descTitle}>
                        {packageText?.split("- ").map((item, index) => (
                          <React.Fragment key={index}>
                            {index > 0 && <br />}
                            {`${index === 0 ? "" : "- "}${item}`}
                          </React.Fragment>
                        ))}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={12} className={classes.descriptionWrapper}>
                <Grid container alignItems="center">
                  <Grid
                    item
                    md={12}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <StyledCheckCircleIcon />
                    <Typography variant="h6" className={classes.descTitle}>
                      {t("orderRequestPage.orderPackageTwo")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Grid container direction="column" className={classes.icon}>
                      <Typography variant="body1" className={classes.descTitle}>
                        {packageTextSecond?.split("- ").map((item, index) => (
                          <React.Fragment key={index}>
                            {index > 0 && <br />}
                            {`${index === 0 ? "" : "- "}${item}`}
                          </React.Fragment>
                        ))}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={12} className={classes.descriptionWrapper}>
                <Grid container alignItems="center">
                  <Grid
                    item
                    md={12}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <StyledCheckCircleIcon />
                    <Typography variant="h6" className={classes.descTitle}>
                      {t("orderRequestPage.orderPackageThree")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Grid container direction="column" className={classes.icon}>
                      <Typography variant="body1" className={classes.descText}>
                        {t("orderRequestPage.orderPackageThreeText")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={12} className={classes.descriptionWrapper}>
                <Grid container direction="column">
                  <Typography variant="h6" className={classes.descTitle}>
                    {t("orderRequestPage.orderNoteTitle")}
                  </Typography>
                  <Typography variant="body1" className={classes.descText}>
                    {t("orderRequestPage.orderNoteText")}
                  </Typography>
                  <Typography
                    variant="body1"
                    className={classes.descText}
                    mt={1}
                  >
                    {t("orderRequestPage.orderNoteSub")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        {isMobile && (
          <Grid container spacing={1}>
            <Grid
              item
              md={6}
              xs={12}
              style={{ background: colors.advertisementRequest }}
            >
              <Grid item md={12} mt={4} mb={3}>
                <Typography
                  textAlign={"center"}
                  variant="h6"
                  className={classes.title}
                >
                  {t("orderRequestPage.orderTitle")}
                </Typography>
              </Grid>
              <Grid
                container
                className={classes.formWrapper}
                spacing={1}
                mb={2}
              >
                <Grid item md={4} xs={12}>
                  <Button
                    fullWidth
                    sx={{
                      backgroundColor: outlined.package1
                        ? "white"
                        : colors.primary,
                      color: outlined.package1 ? colors.primary : "white",
                      border: "none",
                      boxShadow:
                        "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
                      "&:hover": {
                        backgroundColor: colors.primary,
                        color: "white",
                        border: "none",
                      },
                    }}
                    variant={outlined.package1 ? "text" : "contained"}
                    onClick={() => handleClick("paket1")}
                    startIcon={
                      outlined.package1 ? (
                        <RadioButtonUncheckedIcon />
                      ) : (
                        <CheckCircleIcon
                          sx={{
                            color: colors.green,
                            background: "white",
                            borderRadius: "100%",
                          }}
                        />
                      )
                    }
                  >
                    <Typography variant="body1">
                      {t("orderRequestPage.orderPackageOne")}
                    </Typography>
                  </Button>
                  <Grid container direction="column" my={1}>
                    <Typography variant="body1" sx={{ color: colors.primary }}>
                      {t("orderRequestPage.orderPackageOneText")}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Button
                    fullWidth
                    sx={{
                      backgroundColor: outlined.package2
                        ? "white"
                        : colors.primary,
                      color: outlined.package2 ? colors.primary : "white",
                      border: "none",
                      boxShadow:
                        "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
                      "&:hover": {
                        backgroundColor: colors.primary,
                        color: "white",
                        border: "none",
                      },
                    }}
                    variant={outlined.package2 ? "text" : "contained"}
                    onClick={() => handleClick("paket2")}
                    startIcon={
                      outlined.package2 ? (
                        <RadioButtonUncheckedIcon />
                      ) : (
                        <CheckCircleIcon
                          sx={{
                            color: colors.green,
                            background: "white",
                            borderRadius: "100%",
                          }}
                        />
                      )
                    }
                  >
                    <Typography variant="body1">
                      {t("orderRequestPage.orderPackageTwo")}
                    </Typography>
                  </Button>
                  <Grid container direction="column" my={1}>
                    <Typography variant="body1" sx={{ color: colors.primary }}>
                      {t("orderRequestPage.orderPackageTwoText")}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Button
                    fullWidth
                    sx={{
                      backgroundColor: outlined.package3
                        ? "white"
                        : colors.primary,
                      color: outlined.package3 ? colors.primary : "white",
                      border: "none",
                      boxShadow:
                        "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
                      "&:hover": {
                        backgroundColor: colors.primary,
                        color: "white",
                        border: "none",
                      },
                    }}
                    variant={outlined.package3 ? "text" : "contained"}
                    onClick={() => handleClick("paket3")}
                    startIcon={
                      outlined.package3 ? (
                        <RadioButtonUncheckedIcon />
                      ) : (
                        <CheckCircleIcon
                          sx={{
                            color: colors.green,
                            background: "white",
                            borderRadius: "100%",
                          }}
                        />
                      )
                    }
                  >
                    <Typography variant="body1">
                      {t("orderRequestPage.orderPackageThree")}
                    </Typography>
                  </Button>
                  <Grid container direction="column" my={1}>
                    <Typography variant="body1" sx={{ color: colors.primary }}>
                      {t("orderRequestPage.orderPackageThreeText")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  lg={6}
                  md={12}
                  sm={12}
                  xs={12}
                  className={classes.formWrapper}
                >
                  <CustomOutlinedInput
                    label={t("orderRequestPage.orderFormCompanyName")}
                    value={advertisementRequest.companyName}
                    onChange={(e) => handleFormChange(e)}
                    name={"companyName"}
                    labelColor={colors.primary}
                    width="100%"
                    error={
                      validate &&
                      !IsCompanyNameValid(advertisementRequest.companyName)
                    }
                    errorMessage={t("validation.validationCompanyName")}
                  />
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={12}
                  sm={12}
                  xs={12}
                  mt={2}
                  className={classes.formWrapper}
                >
                  <CustomOutlinedInput
                    label={t("orderRequestPage.orderFormTaxId")}
                    placeholder={""}
                    value={advertisementRequest.taxId}
                    onChange={(e) => handleFormChange(e)}
                    name={"taxId"}
                    labelColor={colors.primary}
                    width="100%"
                  />
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={12}
                  sm={12}
                  xs={12}
                  className={classes.formWrapper}
                  mt={2}
                >
                  <CustomOutlinedInput
                    width="100%"
                    label={t("orderRequestPage.orderFormPlace") ?? ""}
                    value={advertisementRequest.place}
                    onChange={(e) => handleFormChange(e)}
                    name={"place"}
                    labelColor={colors.primary}
                  />
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={12}
                  sm={12}
                  xs={12}
                  className={classes.formWrapper}
                  mt={2}
                >
                  <CustomOutlinedInput
                    width="100%"
                    label={t("orderRequestPage.orderFormStreet") ?? ""}
                    value={advertisementRequest.street}
                    onChange={(e) => handleFormChange(e)}
                    name={"street"}
                    labelColor={colors.primary}
                  />
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={12}
                  sm={12}
                  xs={12}
                  className={classes.formWrapper}
                  mt={2}
                >
                  <CustomOutlinedInput
                    label={t("orderRequestPage.orderFormHomeNumber")}
                    value={advertisementRequest.homeNumber}
                    onChange={(e) => handleFormChange(e)}
                    name={"homeNumber"}
                    labelColor={colors.primary}
                    width="100%"
                  />
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={12}
                  sm={12}
                  xs={12}
                  className={classes.formWrapper}
                  mt={2}
                >
                  <CustomOutlinedInput
                    label={t("orderRequestPage.orderFormEmail")}
                    value={advertisementRequest.email}
                    onChange={(e) => handleFormChange(e)}
                    name={"email"}
                    labelColor={colors.primary}
                    width="100%"
                    error={
                      validate && !IsEmailValid(advertisementRequest.email)
                    }
                    errorMessage={t("validation.validationEmail")}
                  />
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={12}
                  sm={12}
                  xs={12}
                  className={classes.formWrapper}
                  mt={2}
                >
                  <CustomOutlinedInput
                    label={t("orderRequestPage.orderFormPhoneNumber")}
                    value={advertisementRequest.phoneNumber}
                    onChange={(e) => handleFormChange(e)}
                    name={"phoneNumber"}
                    labelColor={colors.primary}
                    width="100%"
                    error={
                      validate &&
                      !IsPhoneNumberValid(advertisementRequest.phoneNumber)
                    }
                    errorMessage={t("validation.validationPhoneNumber")}
                  />
                </Grid>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className={classes.formWrapper}
                  mt={2}
                >
                  <CustomOutlinedInput
                    label={t("orderRequestPage.orderFormMessage")}
                    value={advertisementRequest.message}
                    onChange={(e) => handleFormChange(e)}
                    name={"message"}
                    multiline
                    rows={5}
                    labelColor={colors.primary}
                    width="100%"
                    error={
                      validate && !IsMessageValid(advertisementRequest.message)
                    }
                    errorMessage={t("validation.validationMessage")}
                  />
                </Grid>
                <Grid item md={12} mt={2} className={classes.formWrapper}>
                  <FormControlLabel
                    sx={{ color: colors.primary }}
                    control={
                      <Checkbox
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                        sx={{
                          color: colors.primary,
                          "&.Mui-checked": {
                            color: colors.primary,
                          },
                        }}
                      />
                    }
                    label={t("termsAndContitions.error")}
                  />
                  <Typography variant="body1" sx={{ color: colors.primary }}>
                    {t("termsAndContitions.description")}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                className={classes.formWrapper}
                spacing={1}
                my={2}
              >
                <Grid item md={12} xs={12}>
                  <AppButton
                    label={t("buttons.orderOnline")}
                    color={colors.primary}
                    hover={colors.primary}
                    startIcon={
                      orderStatus === "Pending" ? (
                        <CircularProgress size={"1em"} />
                      ) : (
                        <SendIcon />
                      )
                    }
                    width="100%"
                    onClick={handleSubmit}
                    disabled={orderStatus === "Pending" || isChecked !== true}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <AppButton
                    id="subHeader_button_call"
                    startIcon={<PhoneInTalkIcon />}
                    label={buttonText}
                    color={colors.primary}
                    hover={colors.primary}
                    href={phoneLinkMobile}
                    onClick={() => setPhoneLinkMobile("tel:+381692128500")}
                    width={isMobile ? "100%" : "30%"}
                    disabled={isChecked !== true}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <AppButton
                    label={t("buttons.orderByEmail")}
                    color={colors.primary}
                    hover={colors.primary}
                    startIcon={<EmailIcon />}
                    width="100%"
                    onClick={handleClickEmailButton}
                    disabled={isChecked !== true}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
              style={{ background: colors.primary }}
            >
              <img
                src={aboutUs}
                alt="about_us"
                className={classes.aboutUsImg}
              />
            </Grid>
          </Grid>
        )}
      </FormControl>
    </GradientBackground>
  );
}
