import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { colors } from "../../../app/theme/colors";
import AppButton from "../../../components/appButton/appButton";
import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import { useTranslation } from "react-i18next";
import { theme } from "../../../app/theme/theme";
import CloseIcon from "@mui/icons-material/Close";
import { useChangePasswordModal } from "./changePasswordModalStyles";
import CustomOutlinedInput from "../../../components/outlinedInput/customOutlinedInput";
import { useEffect, useState } from "react";
import SetPasswordModel from "../../../models/auth/setPasswordModel";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import RemoveCircleOutlineTwoToneIcon from "@mui/icons-material/RemoveCircleOutlineTwoTone";
import {
  changePassword,
  resetChangePassword,
  selectChangePasswordStatus,
  selectCurrentAccount,
} from "../../../slices/authSlice";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { toast } from "react-toastify";
import { ApiStatus } from "../../../models/app/apiStatus";

interface Props {
  open: any;
  handleClose: any;
}

interface PasswordValidation {
  greaterThanOrEqualTo8: boolean;
  atLeastOneUpperCase: boolean;
  atLeastOneNumber: boolean;
  atLeastOneSpecialCharacter: boolean;
  passwordConfirm: boolean;
}

export default function ChangePasswordModal({ handleClose, open }: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { classes } = useChangePasswordModal();

  const currentAccount = useAppSelector(selectCurrentAccount);
  const changePasswordStatus = useAppSelector(selectChangePasswordStatus);

  const INIT_CHANGE_PASSWORD: SetPasswordModel = {
    email: currentAccount?.email,
    newPassword: "",
    oldPassword: "",
    confirmNewPassword: "",
  };

  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [changePasswordState, setChangePasswordState] =
    useState<SetPasswordModel>({
      ...INIT_CHANGE_PASSWORD,
      email: currentAccount?.email,
      newPassword: "",
      confirmNewPassword: "",
      oldPassword: "",
    });
  const dataToSend = {
    email: changePasswordState.email,
    newPassword: changePasswordState.newPassword,
    oldPassword: changePasswordState.oldPassword,
  };
  const [isPasswordVisibleOld, setIsPasswordVisibleOld] = useState(false);
  const [isPasswordVisibleNew, setIsPasswordVisibleNew] = useState(false);
  const [isPasswordVisibleConfirm, setIsPasswordVisibleConfirm] =
    useState(false);

  const handleTogglePasswordVisibilityOld = () => {
    setIsPasswordVisibleOld((prev) => !prev);
  };

  const handleTogglePasswordVisibilityNew = () => {
    setIsPasswordVisibleNew((prev) => !prev);
  };

  const handleTogglePasswordVisibilityConfirm = () => {
    setIsPasswordVisibleConfirm((prev) => !prev);
  };
  const handleSave = () => {
    dispatch(changePassword(dataToSend));
  };

  const handleDialogClose = () => {
    handleClose();
  };

  const passwordValidation: PasswordValidation = {
    greaterThanOrEqualTo8: changePasswordState.newPassword.length >= 8,
    atLeastOneUpperCase: /[A-Z]/.test(changePasswordState.newPassword),
    atLeastOneNumber: /\d/.test(changePasswordState.newPassword),
    atLeastOneSpecialCharacter: /[!@#$%^&*(),.?":{}|<>]/.test(
      changePasswordState.newPassword
    ),
    passwordConfirm:
      changePasswordState.newPassword !== "" &&
      changePasswordState.confirmNewPassword !== "" &&
      changePasswordState.newPassword ===
        changePasswordState.confirmNewPassword,
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setChangePasswordState((prevState) => ({
      ...prevState,
      [id === "changePassword-input-confirmPassword"
        ? "confirmNewPassword"
        : id === "changePassword-input-newPassword"
        ? "newPassword"
        : "oldPassword"]: value,
    }));
    setPasswordError(false);
  };

  const resetChangePasswordState = () => {
    setChangePasswordState(INIT_CHANGE_PASSWORD);
  };

  useEffect(() => {
    if (changePasswordStatus === "Fulfilled") {
      handleClose();
      resetChangePasswordState();
      dispatch(resetChangePassword());
    }
  }, [changePasswordStatus]);

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      fullWidth
      id="rateUsModal_dialog"
      PaperProps={{ sx: { borderRadius: "15px", overflow: "hidden" } }}
    >
      <IconButton
        edge="end"
        color="inherit"
        onClick={handleDialogClose}
        aria-label="close"
        sx={{
          position: "absolute",
          top: theme.spacing(1),
          right: theme.spacing(2),
        }}
      >
        <CloseIcon className={classes.closeIcon} />
      </IconButton>
      <DialogContent className={classes.dialogContent}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ textAlign: "center" }}
        >
          <Grid item md={12}>
            <Typography
              variant="h4"
              sx={{
                color: colors.primary,
                fontWeight: "bold",
              }}
            >
              {t("changePassword.title")}
            </Typography>
          </Grid>
          <Grid item md={12} xs={12} mt={1}>
            <Typography variant="body2" sx={{ color: colors.primary }}>
              {t("changePassword.subtitle")}
            </Typography>
          </Grid>
          <Grid item md={12} xs={12} mt={1}>
            <CustomOutlinedInput
              type={isPasswordVisibleOld ? "text" : "password"}
              id="setPasswordModal-input-oldPassword"
              label={t("changePassword.oldPassword")}
              width="100%"
              labelColor={colors.primary}
              onChange={handlePasswordChange}
              value={changePasswordState.oldPassword}
              error={emailError}
              endAdornment={
                isPasswordVisibleOld ? (
                  <IconButton onClick={handleTogglePasswordVisibilityOld}>
                    <VisibilityIcon
                      sx={{ cursor: "pointer", color: colors.primary }}
                    />
                  </IconButton>
                ) : (
                  <IconButton onClick={handleTogglePasswordVisibilityOld}>
                    <VisibilityOffIcon
                      sx={{ cursor: "pointer", color: colors.primary }}
                    />
                  </IconButton>
                )
              }
            />
          </Grid>
          <Grid item md={12} xs={12} mt={1.5}>
            <CustomOutlinedInput
              type={isPasswordVisibleNew ? "text" : "password"}
              id="changePassword-input-newPassword"
              label={t("changePassword.newPassword")}
              width="100%"
              labelColor={colors.primary}
              onChange={handlePasswordChange}
              value={changePasswordState.newPassword}
              endAdornment={
                isPasswordVisibleNew ? (
                  <IconButton onClick={handleTogglePasswordVisibilityNew}>
                    <VisibilityIcon
                      sx={{ cursor: "pointer", color: colors.primary }}
                    />
                  </IconButton>
                ) : (
                  <IconButton onClick={handleTogglePasswordVisibilityNew}>
                    <VisibilityOffIcon
                      sx={{ cursor: "pointer", color: colors.primary }}
                    />
                  </IconButton>
                )
              }
            />
          </Grid>
          <Grid item md={12} xs={12} mt={1.5}>
            <CustomOutlinedInput
              type={isPasswordVisibleConfirm ? "text" : "password"}
              id="changePassword-input-confirmPassword"
              label={t("changePassword.confirmPassword")}
              width="100%"
              labelColor={colors.primary}
              onChange={handlePasswordChange}
              value={changePasswordState.confirmNewPassword}
              error={passwordError}
              endAdornment={
                isPasswordVisibleConfirm ? (
                  <IconButton onClick={handleTogglePasswordVisibilityConfirm}>
                    <VisibilityIcon
                      sx={{ cursor: "pointer", color: colors.primary }}
                    />
                  </IconButton>
                ) : (
                  <IconButton onClick={handleTogglePasswordVisibilityConfirm}>
                    <VisibilityOffIcon
                      sx={{ cursor: "pointer", color: colors.primary }}
                    />
                  </IconButton>
                )
              }
            />
          </Grid>
          <Grid item md={12} xs={12}>
            {
              <Typography
                variant="body1"
                sx={{
                  color: passwordValidation.greaterThanOrEqualTo8
                    ? colors.green
                    : colors.validationErrorMessage,
                  display: "flex",
                  my: 1,
                }}
              >
                {passwordValidation.greaterThanOrEqualTo8 ? (
                  <CheckCircleTwoToneIcon sx={{ mr: 1 }} />
                ) : (
                  <RemoveCircleOutlineTwoToneIcon sx={{ mr: 1 }} />
                )}
                {t("setPasswordModal.setPasswordMinimumEightCharacter")}
              </Typography>
            }

            {
              <Typography
                variant="body1"
                sx={{
                  color: passwordValidation.atLeastOneUpperCase
                    ? colors.green
                    : colors.validationErrorMessage,
                  display: "flex",
                  my: 1,
                }}
              >
                {passwordValidation.atLeastOneUpperCase ? (
                  <CheckCircleTwoToneIcon sx={{ mr: 1 }} />
                ) : (
                  <RemoveCircleOutlineTwoToneIcon sx={{ mr: 1 }} />
                )}
                {t("setPasswordModal.setPasswordCapitalLetter")}
              </Typography>
            }

            {
              <Typography
                variant="body1"
                sx={{
                  color: passwordValidation.atLeastOneNumber
                    ? colors.green
                    : colors.validationErrorMessage,
                  display: "flex",
                  my: 1,
                }}
              >
                {passwordValidation.atLeastOneNumber ? (
                  <CheckCircleTwoToneIcon sx={{ mr: 1 }} />
                ) : (
                  <RemoveCircleOutlineTwoToneIcon sx={{ mr: 1 }} />
                )}
                {t("setPasswordModal.setPasswordNumber")}
              </Typography>
            }

            {
              <Typography
                variant="body1"
                sx={{
                  color: passwordValidation.atLeastOneSpecialCharacter
                    ? colors.green
                    : colors.validationErrorMessage,
                  display: "flex",
                  my: 1,
                }}
              >
                {passwordValidation.atLeastOneSpecialCharacter ? (
                  <CheckCircleTwoToneIcon sx={{ mr: 1 }} />
                ) : (
                  <RemoveCircleOutlineTwoToneIcon sx={{ mr: 1 }} />
                )}
                {t("setPasswordModal.setPasswordSpecialCharacter")}
              </Typography>
            }

            {
              <Typography
                variant="body1"
                sx={{
                  color: passwordValidation.passwordConfirm
                    ? colors.green
                    : colors.validationErrorMessage,
                  display: "flex",
                  my: 1,
                }}
              >
                {passwordValidation.passwordConfirm ? (
                  <CheckCircleTwoToneIcon sx={{ mr: 1 }} />
                ) : (
                  <RemoveCircleOutlineTwoToneIcon sx={{ mr: 1 }} />
                )}
                {t("setPasswordModal.setPasswordConfirmPassword")}
              </Typography>
            }
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        className={classes.dialogActions}
        sx={{ justifyContent: "center" }}
      >
        <Grid container>
          <Grid item md={12} display={"flex"} justifyContent={"center"}>
            <AppButton
              id="rateUsModal_button_close"
              color={colors.green}
              hover={colors.green}
              label={t("buttons.save")}
              width="100%"
              onClick={handleSave}
              startIcon={
                changePasswordStatus === ApiStatus.Pending ? (
                  <CircularProgress size={15} sx={{ color: colors.primary }} />
                ) : null
              }
              disabled={
                (
                  (!passwordValidation.atLeastOneNumber &&
                    !passwordValidation.atLeastOneSpecialCharacter &&
                    !passwordValidation.atLeastOneUpperCase &&
                    !passwordValidation.greaterThanOrEqualTo8 &&
                    !passwordValidation.passwordConfirm) ||
                  changePasswordStatus === ApiStatus.Pending
                )
              }
            />
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
