import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import companyReducer from "../../slices/companySlice";
import addressReducer from "../../slices/addressSlice";
import categoryReducer from "../../slices/categorySlice";
import displayLanguageReducer from "../../slices/displayLanguageSlice";
import notificationReducer from "../../slices/notificationSlice";
import authReducer from "../../slices/authSlice";
import onboardingReducer from "../../slices/onboardingSlice";
import searchResultsActionsReducer from "../../slices/searchResultsActionsSlice";
import overallRatingReducer from "../../slices/overallRatingSlice";
import dashboardReducer from "../../slices/dashboardSlice";
import mySiteReducer from "../../slices/mySiteSlice";

export const store = configureStore({
  reducer: {
    company: companyReducer,
    address: addressReducer,
    category: categoryReducer,
    displayLanguage: displayLanguageReducer,
    notification: notificationReducer,
    auth: authReducer,
    onboarding: onboardingReducer,
    searchResultsActions: searchResultsActionsReducer,
    overallRating: overallRatingReducer,
    dashboard: dashboardReducer,
    mySite: mySiteReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
