import React from "react";
import {
  Box,
  IconButton,
  MenuItem,
  MenuList,
  Popover,
  Typography,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import AddIcCallIcon from "@mui/icons-material/Call";
import ClearIcon from "@mui/icons-material/Clear";
import { colors } from "../../app/theme/colors";
import PublicIcon from "@mui/icons-material/Public";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import CollectionsIcon from "@mui/icons-material/Collections";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import { useTranslation } from "react-i18next";

interface Props {
  handleClickCube: any;
  cubeType: any;
  handleOpenMenu: any;
  selectedItem: any;
  clickedCube: any;
  anchorEl: any;
  handleClose: any;
  options: any;
  handleSelectItem: any;
  isActive: boolean;
  handleClearItem: any;
  companyWebsiteStatus: string;
}

export default function MySiteSectionCube({
  cubeType,
  handleClickCube,
  handleOpenMenu,
  selectedItem = {},
  clickedCube,
  anchorEl,
  handleClose,
  options,
  handleSelectItem,
  isActive,
  handleClearItem,
  companyWebsiteStatus,
}: Props) {
  const {t} = useTranslation();
  const { value = "default" } = selectedItem;

  const getClearTooltipTitle = (value: string) => {
    switch (value) {
      case "Photo":
        return t("mySite.mySiteRemovePhotoTooltip");
      case "Text":
        return t("mySite.mySiteRemoveTextTooltip");
      case "Phone":
        return  t("mySite.mySiteRemovePhoneTooltip");
      case "SocialMedia":
        return t("mySite.mySiteRemoveSocialMediaTooltip");
      case "Video":
        return t("mySite.mySiteRemoveVideoTooltip");
      case "Gallery":
        return t("mySite.mySiteRemoveGalleryTooltip");
      case "Slider":
        return t("mySite.mySiteRemoveSliderTooltip");
      default:
        return t("mySite.mySiteRemoveDefaultTooltip");
    }
  };

  return (
    <Box
      sx={{
        ml: value === "first" ? 0 : 1,
        height: 100,
        width: 100,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        background:
          value === "Photo" ||
          value === "SocialMedia" ||
          value === "Text" ||
          value === "Video" ||
          value === "Gallery" ||
          value === "Slider" ||
          value === "Phone"
            ? "rgba(75, 138, 244, 0.25)"
            : "rgba(75, 138, 244, 1)",
        position: "relative",
      }}
      onClick={() => handleClickCube(cubeType)}
    >
      <IconButton onClick={handleOpenMenu(cubeType)}>
        {companyWebsiteStatus === "Pending" ? (
          <CircularProgress color="inherit" />
        ) : value === "Photo" ? (
          <Tooltip title={t("mySite.mySiteEditPhotoTooltip")}>
            <AddAPhotoIcon
              sx={{ color: "rgba(75, 138, 244, 0.8)", fontSize: "40px" }}
            />
          </Tooltip>
        ) : value === "Text" ? (
          <Tooltip title={t("mySite.mySiteEditTextTooltip")}>
            <TextFieldsIcon
              sx={{ color: "rgba(75, 138, 244, 0.8)", fontSize: "40px" }}
            />
          </Tooltip>
        ) : value === "Phone" ? (
          <Tooltip title={t("mySite.mySiteEditPhoneTooltip")}>
            <AddIcCallIcon
              sx={{ color: "rgba(75, 138, 244, 0.8)", fontSize: "40px" }}
            />
          </Tooltip>
        ) : value === "SocialMedia" ? (
          <Tooltip title={t("mySite.mySiteEditSocialMediaTooltip")}>
            <PublicIcon
              sx={{ color: "rgba(75, 138, 244, 0.8)", fontSize: "40px" }}
            />
          </Tooltip>
        ) : value === "Video" ? (
          <Tooltip title={t("mySite.mySiteEditVideoTooltip")}>
            <VideoCallIcon
              sx={{ color: "rgba(75, 138, 244, 0.8)", fontSize: "40px" }}
            />
          </Tooltip>
        ) : value === "Gallery" ? (
          <Tooltip title={t("mySite.mySiteEditGalleryTooltip")}>
            <CollectionsIcon
              sx={{ color: "rgba(75, 138, 244, 0.8)", fontSize: "40px" }}
            />
          </Tooltip>
        ) : value === "Slider" ? (
          <Tooltip title={t("mySite.mySiteEditSliderTooltip")}>
            <ViewCarouselIcon
              sx={{ color: "rgba(75, 138, 244, 0.8)", fontSize: "40px" }}
            />
          </Tooltip>
        ) : (
          <Tooltip
            title={t("mySite.mySiteAddTooltip")}
          >
            <AddIcon sx={{ color: colors.white, fontSize: "40px" }} />
          </Tooltip>
        )}
      </IconButton>
      <Popover
        id={`mainHeader_popover_services_${cubeType}`}
        open={clickedCube === cubeType && Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuList>
          {options.map((option: any) => (
            <MenuItem
              key={option.value}
              onClick={() => handleSelectItem(option.value)}
            >
              <Typography variant="body1" sx={{ textTransform: "none" }}>
                {option.label}
              </Typography>
            </MenuItem>
          ))}
        </MenuList>
      </Popover>
      {isActive && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            zIndex: 1,
          }}
        >
          <Tooltip title={getClearTooltipTitle(value)}>
            <IconButton
              onClick={() => {
                if (
                  selectedItem &&
                  selectedItem.orderNumber !== undefined &&
                  selectedItem.orderNumber !== null
                ) {
                  handleClearItem(
                    selectedItem.orderNumber,
                    selectedItem.value,
                    selectedItem.id
                  );
                }
              }}
            >
              <ClearIcon sx={{ color: "rgba(75, 138, 244, 0.8)" }} />
            </IconButton>
          </Tooltip>
        </Box>
      )}
      {isActive && (
        <Box
          sx={{
            position: "absolute",
            width: 0,
            height: 0,
            borderLeft: "5px solid transparent",
            borderRight: "5px solid transparent",
            borderBottom: `5px solid ${colors.primary}`,
            bottom: -5,
            left: "50%",
            transform: "translateX(-50%)",
          }}
        />
      )}
    </Box>
  );
}
