import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ActivitiesRequestModel from "../models/onboarding/activitiesRequestModel";
import {
  saveActivities as saveActivitiesApi,
  getMainActivities as getMainActivitiesApi,
  updateAfterSelectingCategories as updateAfterSelectingCategoriesApi,
  getAccountCompanyClients as getAccountCompanyClientsApi,
  getAccountFavorite as getAccountFavoriteApi,
  getAccountPartnerCompanies as getAccountPartnerCompaniesApi,
  getAccountPotentialPartnerCompanies as getAccountPotentialPartnerCompaniesApi,
  deleteAccountFavorite as deleteAccountFavoriteApi,
  deleteAccountClients as deleteAccountClientsApi,
  deleteAccountPartner as deleteAccountPartnerApi,
  deleteAccountPotentialPartner as deleteAccountPotentialPartnerApi,
} from "../app/redux/onboardingApi";
import ActivitiesResponseModel from "../models/onboarding/activitesResponseModel";
import { CategoryShortDetail } from "../models/company/categoryShortDetail";
import { RootState } from "../app/redux/store";
import { ApiStatus } from "../models/app/apiStatus";
import { CompanyEntity } from "../models/company/companyEntity";
import { CompanyClientsRequest } from "../models/company/companyClientsRequest";
import { setErrorNotification, setPendingNotification, setSuccessNotification } from "./notificationSlice";
import i18n from "../i18n";
import { ApiResponse } from "../models/app/apiResponse";

type OnboardingState = {
  activities: ActivitiesResponseModel[] | null;
  mainActivities: CategoryShortDetail[] | null;
  accountCompanyClients: CompanyEntity[] | null;
  accountPartnerCompanies: CompanyEntity[] | null;
  accountPotentialPartnerCompanies: CompanyEntity[] | null;
  accountFavorites: CompanyEntity[] | null;
  statuses: {
    savedActivities: ApiStatus;
    deletedAccountFavorite: ApiStatus;
    savedActivitiesSettings: ApiStatus;
    deletedAccountClient: ApiStatus;
    deletedAccountPartner: ApiStatus;
    deletedAccountPotentialPartner: ApiStatus;
  };
};

const initialState: OnboardingState = {
  activities: [],
  mainActivities: [],
  accountCompanyClients: null,
  accountFavorites: null,
  accountPartnerCompanies: null,
  accountPotentialPartnerCompanies: null,
  statuses: {
    savedActivities: ApiStatus.Idle,
    savedActivitiesSettings: ApiStatus.Idle,
    deletedAccountFavorite: ApiStatus.Idle,
    deletedAccountClient: ApiStatus.Idle,
    deletedAccountPartner: ApiStatus.Idle,
    deletedAccountPotentialPartner: ApiStatus.Idle,
  },
};

export const selectMainActivities = (state: RootState) =>
  state.onboarding.mainActivities;
export const selectCompanyClients = (state: RootState) =>
  state.onboarding.accountCompanyClients;
export const selectAccountFavorites = (state: RootState) =>
  state.onboarding.accountFavorites;
export const selectAccountPartnerCompanies = (state: RootState) =>
  state.onboarding.accountPartnerCompanies;
export const selectAccountPotentialPartnerCompanies = (state: RootState) =>
  state.onboarding.accountPotentialPartnerCompanies;
export const selectSavedActivitiesStatus = (state: RootState) =>
  state.onboarding.statuses.savedActivities;
export const selectSavedActivitiesSettingsStatus = (state: RootState) =>
  state.onboarding.statuses.savedActivitiesSettings;
export const selectDeletedAccountFavoriteStatus = (state: RootState) =>
  state.onboarding.statuses.deletedAccountFavorite;
export const selectDeletedClientStatus = (state: RootState) =>
  state.onboarding.statuses.deletedAccountClient;
export const selectDeletedPartnerStatus = (state: RootState) =>
  state.onboarding.statuses.deletedAccountPartner;
export const selectDeletedPotentialPartnerStatus = (state: RootState) =>
  state.onboarding.statuses.deletedAccountPotentialPartner;

export const saveActivities = createAsyncThunk<
  ActivitiesResponseModel | null,
  ActivitiesRequestModel,
  { rejectValue: string }
>(
  "sureVotes/save_activity",
  async (request: ActivitiesRequestModel, { rejectWithValue, dispatch }) => {
    try {
      const response = await saveActivitiesApi(request);
      if (response.succeeded) {
        dispatch(
          setSuccessNotification(i18n.t("messageKey.ActivitySelection_Success"))
        );
        return response.data;
      } else {
        dispatch(
          setErrorNotification(i18n.t("messageKey." + response.messageKey))
        );
        return null;
      }
    } catch (error) {
      console.error("Error saving activities:", error);
      const errorResponse = error as ApiResponse<null>;
      const messageKey = errorResponse.messageKey;
      if (messageKey) {
        dispatch(setErrorNotification(i18n.t("messageKey." + messageKey)));
        return rejectWithValue(messageKey);
      }
      return rejectWithValue("Unknown error occurred");
    }
  }
);

export const saveActivitiesSettings = createAsyncThunk<
  ActivitiesResponseModel | null,
  ActivitiesRequestModel,
  { rejectValue: string }
>(
  "sureVotes/save_activities_settings",
  async (request: ActivitiesRequestModel, { rejectWithValue, dispatch }) => {
    try {
      const response = await saveActivitiesApi(request);
      if (response.succeeded) {
        dispatch(
          setSuccessNotification(i18n.t("messageKey.ActivitySelection_Success"))
        );
        return response.data;
      } else {
        dispatch(
          setErrorNotification(i18n.t("messageKey." + response.messageKey))
        );
        return null;
      }
    } catch (error) {
      console.error("Error saving activities:", error);
      const errorResponse = error as ApiResponse<null>;
      const messageKey = errorResponse.messageKey;
      if (messageKey) {
        dispatch(setErrorNotification(i18n.t("messageKey." + messageKey)));
        return rejectWithValue(messageKey);
      }
      return rejectWithValue("Unknown error occurred");
    }
  }
);

export const getMainActivities = createAsyncThunk(
  "onboardingSlice/get_main_categories",
  async () => {
    const response = await getMainActivitiesApi();
    return response.data;
  }
);

export const getAccountCompanyClients = createAsyncThunk(
  "onboardingSlice/get_account_company_clients",
  async (request: CompanyClientsRequest) => {
    const response = await getAccountCompanyClientsApi(request);
    return response.data;
  }
);

export const getAccountFavorites = createAsyncThunk(
  "onboardingSlice/get_account_favorites",
  async (request: CompanyClientsRequest) => {
    const response = await getAccountFavoriteApi(request);
    return response.data;
  }
);

export const getAccountPartnerCompanies = createAsyncThunk(
  "onboardingSlice/get_account_partner_companies",
  async (request: CompanyClientsRequest) => {
    const response = await getAccountPartnerCompaniesApi(request);
    return response.data;
  }
);

export const getAccountPotentialPartnerCompanies = createAsyncThunk(
  "onboardingSlice/get_account_potential_partner_companies",
  async (request: CompanyClientsRequest) => {
    const response = await getAccountPotentialPartnerCompaniesApi(request);
    return response.data;
  }
);

export const updateAfterSelectingCategories = createAsyncThunk(
  "onboardingSlice/updateAfterSelectingCategoriesApi",
  async () => {
    const response = await updateAfterSelectingCategoriesApi();
    return response.data;
  }
);

export const deleteAccountFavorite = createAsyncThunk(
  "onboardingSlice/delete_account_favorites",
  async (request: string, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setPendingNotification(i18n.t("messageKey.WidgetDeleteMessageFavorites_Pending")));
      const response = await deleteAccountFavoriteApi(request); 
      const result = response.data;
      if (result) {
        
        dispatch(
          setSuccessNotification(i18n.t("messageKey.WidgetDeleteMessageFavorites_Success"))
        );
      }
      return result;
    } catch (error) {
      dispatch(
        setErrorNotification(i18n.t("messageKey.WidgetDeleteMessageFavorites_Error"))
      );
      console.error("Error while saving site text:", error);
      throw error; 
    }
  }
);

export const deleteAccountClients = createAsyncThunk(
  "onboardingSlice/delete_account_clients",
  async (request: string, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setPendingNotification(i18n.t("messageKey.WidgetDeleteMessageClients_Pending")));
      const response = await deleteAccountClientsApi(request); 
      const result = response.data;
      if (result) {
        
        dispatch(
          setSuccessNotification(i18n.t("messageKey.WidgetDeleteMessageClients_Success"))
        );
      }
      return result;
    } catch (error) {
      dispatch(
        setErrorNotification(i18n.t("messageKey.WidgetDeleteMessageClients_Error"))
      );
      console.error("Error while saving site text:", error);
      throw error; 
    }
  }
);

export const deleteAccountPartner = createAsyncThunk(
  "onboardingSlice/delete_account_partner",
  async (request: string, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setPendingNotification(i18n.t("messageKey.WidgetDeleteMessagePartners_Pending")));
      const response = await deleteAccountPartnerApi(request); 
      const result = response.data;
      if (result) {
        
        dispatch(
          setSuccessNotification(i18n.t("messageKey.WidgetDeleteMessagePartners_Success"))
        );
      }
      return result;
    } catch (error) {
      dispatch(
        setErrorNotification(i18n.t("messageKey.WidgetDeleteMessagePartners_Error"))
      );
      console.error("Error while saving site text:", error);
      throw error; 
    }
  }
);

export const deleteAccountPotentialPartner = createAsyncThunk(
  "onboardingSlice/delete_account_potential_partner",
  async (request: string, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setPendingNotification(i18n.t("messageKey.WidgetDeleteMessagePotentialPartners_Pending")));
      const response = await deleteAccountPotentialPartnerApi(request); 
      const result = response.data;
      if (result) {
        
        dispatch(
          setSuccessNotification(i18n.t("messageKey.WidgetDeleteMessagePotentialPartners_Success"))
        );
      }
      return result;
    } catch (error) {
      dispatch(
        setErrorNotification(i18n.t("messageKey.WidgetDeleteMessagePotentialPartners_Error"))
      );
      console.error("Error while saving site text:", error);
      throw error; 
    }
  }
);

export const resetSavedActivitiesStatus = createAsyncThunk(
  "authSlice/reset-error-message",
  async () => {
    return initialState.statuses.savedActivities;
  }
);

export const resetSavedActivitiesSettingsStatus = createAsyncThunk(
  "authSlice/reset-saved-activities-status-settings",
  async () => {
    return initialState.statuses.savedActivitiesSettings;
  }
);


export const resetDeletedAccountFavoriteStatus = createAsyncThunk(
  "authSlice/reset_deleted_account_favorite_status",
  async () => {
    return initialState.statuses.deletedAccountFavorite;
  }
);

export const resetDeletedAccountClientStatus = createAsyncThunk(
  "authSlice/reset_deleted_account_client_status",
  async () => {
    return initialState.statuses.deletedAccountClient;
  }
);

export const resetDeletedAccountPartnerStatus = createAsyncThunk(
  "authSlice/reset_deleted_account_partner_status",
  async () => {
    return initialState.statuses.deletedAccountPartner;
  }
);

export const resetDeletedAccountPotentialPartnerStatus = createAsyncThunk(
  "authSlice/reset_deleted_account_potential_partner_status",
  async () => {
    return initialState.statuses.deletedAccountPotentialPartner;
  }
);

const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(saveActivities.pending, (state, action) => {
        state.statuses.savedActivities = ApiStatus.Pending;
      })
      .addCase(saveActivities.fulfilled, (state, action) => {
        state.statuses.savedActivities = ApiStatus.Fulfilled;
      })
      .addCase(saveActivities.rejected, (state, action) => {})

      .addCase(saveActivitiesSettings.pending, (state, action) => {
        state.statuses.savedActivitiesSettings = ApiStatus.Pending;
      })
      .addCase(saveActivitiesSettings.fulfilled, (state, action) => {
        state.statuses.savedActivitiesSettings = ApiStatus.Fulfilled;
      })
      .addCase(saveActivitiesSettings.rejected, (state, action) => {
        state.statuses.savedActivitiesSettings = ApiStatus.Rejected;
      })
      .addCase(resetSavedActivitiesStatus.fulfilled, (state, action) => {
        state.statuses.savedActivities = initialState.statuses.savedActivities;
      })
      .addCase(resetSavedActivitiesSettingsStatus.fulfilled, (state, action) => {
        state.statuses.savedActivitiesSettings = initialState.statuses.savedActivitiesSettings;
      })
      .addCase(getMainActivities.fulfilled, (state, action) => {
        state.mainActivities = action.payload;
      })
      .addCase(getAccountCompanyClients.fulfilled, (state, action) => {
        state.accountCompanyClients = action.payload;
      })
      .addCase(getAccountFavorites.fulfilled, (state, action) => {
        state.accountFavorites = action.payload;
      })
      .addCase(getAccountPartnerCompanies.fulfilled, (state, action) => {
        state.accountPartnerCompanies = action.payload;
      })
      .addCase(
        getAccountPotentialPartnerCompanies.fulfilled,
        (state, action) => {
          state.accountPotentialPartnerCompanies = action.payload;
        }
      )
      .addCase(deleteAccountFavorite.fulfilled, (state, action) => {
        state.statuses.deletedAccountFavorite = ApiStatus.Fulfilled;
      })
      .addCase(deleteAccountFavorite.pending, (state, action) => {
        state.statuses.deletedAccountFavorite = ApiStatus.Pending;
      })
      .addCase(deleteAccountClients.fulfilled, (state, action) => {
        state.statuses.deletedAccountClient = ApiStatus.Fulfilled;
      })
      .addCase(deleteAccountClients.pending, (state, action) => {
        state.statuses.deletedAccountClient = ApiStatus.Pending;
      })
      .addCase(deleteAccountPartner.fulfilled, (state, action) => {
        state.statuses.deletedAccountPartner = ApiStatus.Fulfilled;
      })
      .addCase(deleteAccountPartner.pending, (state, action) => {
        state.statuses.deletedAccountPartner = ApiStatus.Pending;
      })
      .addCase(deleteAccountPotentialPartner.fulfilled, (state, action) => {
        state.statuses.deletedAccountPotentialPartner = ApiStatus.Fulfilled;
      })
      .addCase(deleteAccountPotentialPartner.pending, (state, action) => {
        state.statuses.deletedAccountPotentialPartner = ApiStatus.Pending;
      })
      .addCase(resetDeletedAccountFavoriteStatus.fulfilled, (state, action) => {
        state.statuses.deletedAccountFavorite =
          initialState.statuses.deletedAccountFavorite;
      })
      .addCase(resetDeletedAccountClientStatus.fulfilled, (state, action) => {
        state.statuses.deletedAccountClient =
          initialState.statuses.deletedAccountClient;
      })
      .addCase(resetDeletedAccountPartnerStatus.fulfilled, (state, action) => {
        state.statuses.deletedAccountPartner =
          initialState.statuses.deletedAccountPartner;
      })
      .addCase(
        resetDeletedAccountPotentialPartnerStatus.fulfilled,
        (state, action) => {
          state.statuses.deletedAccountPotentialPartner =
            initialState.statuses.deletedAccountPotentialPartner;
        }
      );
  },
});

export default onboardingSlice.reducer;
