import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";
import { colors } from "../../app/theme/colors";

export const useMySectionStyles = makeStyles()((theme: Theme) => ({
  title: {
    color: "#635F5FBA",
  },
  subtitle: {
    color: "#635F5FBA",
    marginLeft: "5px",
  },
  sectionTitle: {
    color: "white",
    fontWeight: "bold",
  },
  iconWrapper: {
    display: "flex",
  },
  icon: {
    color: "white",
    fontSize: "50px",
  },
  label: {
    color: "#F9F9F9",
    marginLeft: "5px",
    textDecoration: "none",
  },
  closeIcon: {
    color: "white",
  },
  chip: {
    background: "white",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "#C8C7C7",
  },
  closeIconChip: {
    fontSize: "12px!important",
    color: "red!important",
  },
  dashboardCardWrapper: {
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
    textAlign: "center",
    maxWidth: "100%",
    width: "100%",
    margin: "auto",
    cursor: "pointer",
  },
  dashboardCardTitleWrapper: {
    textAlign: "center",
  },
  dashboardCardShowMoreWrapper: {
    textAlign: "center",
    paddingBottom: "5px",
  },
  dashboardCardBox: {
    width: "200px",
    textAlign: "center",
    margin: "auto",
    borderRadius: "20px",
  },
  dashboardCardBottomLine: {
    bottom: 0,
    left: 0,
    width: "100%",
    height: "5px",
  },
  ratingCardWrapper: {
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
    textAlign: "center",
    maxWidth: "100%",
    width: "100%",
    margin: "auto",
    cursor: "pointer",
    minHeight: "300px",
  },
  ratingCardTitleWrapper: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  ratingCardTitleDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  ratingCardContentWrapper: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  ratingCardValue: {
    color: "#635F5FBA",
    fontWeight: "bold",
  },
  ratingCardValueWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  activitiesCardWrapper: {
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
    textAlign: "center",
    maxWidth: "100%",
    width: "100%",
    margin: "auto",
    cursor: "pointer",
    minHeight: "300px",
  },
  activitiesCardTitleWrapper: {
    textAlign: "center",
  },
  ratingChartContainer: {
    maxWidth: "100%",
    margin: "auto",
  },
  mySiteChartWrapper: {
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
    textAlign: "center",
    maxWidth: "100%",
    width: "100%",
    margin: "auto",
    cursor: "pointer",
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  mySiteChartContainer: {
    maxWidth: "100%",
    margin: "auto",
  },
  dashboardCardWrapperDisabled: {
    pointerEvents: "none",
    opacity: 0.5,
  },
  dialog: {
    borderRadius: "15px",
    background: "rgba(75, 138, 244, 1)",
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(2),
  },
  dialogContent: {
    background: "rgba(75, 138, 244, 1)",
  },
  gridContainer: {
    justifyContent: "center",
    alignItems: "center",
    padding: "5px",
    textAlign: "center",
  },
  logoImage: {
    width: "25%",
    height: "auto",
    margin: "auto",
    marginTop: "-20px",
  },
  customOutlinedInput: {
    label: "test",
    width: "100%",
    labelColor: colors.primary,
  },
  forgotPasswordText: {
    textAlign: "right",
    marginTop: "5px",
    color: "#635F5F",
    cursor: "pointer",
  },
  dialogActions: {
    paddingLeft: "25px",
    paddingRight: "25px",
    paddingBottom: "25px",
    textAlign: "center",
  },
  visibilityIcon: {
    cursor: "pointer",
    color: colors.primary,
  },
  autocompleteTextField: {
    "& .MuiInputBase-root": {
      borderRadius: "5px",
      background: "white",
    },
  },
  autocomplete: {
    "& .MuiOutlinedInput-root.Mui-focused": {
      "& > fieldset": {
        borderColor: colors.sectionTitle,
      },
    },
  },
  displayDataMobileWrapper: {
    width: "300px",
    height: "600px",
    border: "2px solid #000",
    borderRadius: "20px",
    overflow: "hidden",
    position: "relative",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.3)",
    margin: "0 auto",
    marginTop: "25px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  displayDateMobileContentWrapper: {
    width: "100%",
    height: "100%",
    backgroundColor: "#f3f3f3",
    padding: "20px",
    flexDirection: "column",
    justifyContent: "",
    alignItems: "center",
    overflowY: "auto",
  },
  displayDataMobileBottomWrapper: {
    width: "100%",
    height: "60px",
    backgroundColor: "#000",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  displayDataMobileDots: {
    background: "white",
    borderRadius: "50%",
    height: "20px",
    width: "20px",
  },
  mobileImgGallery: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
  deleteIcon: {
    color: colors.red
  }
}));
