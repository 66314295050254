import {
  AppBar,
  Toolbar,
  Grid,
  List,
  ListItem,
  ListItemText,
  Divider,
  Drawer,
  Collapse,
  Avatar,
  ListItemButton,
  ListItemIcon,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import logoFull from "../../../images/logoFull.png";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../app/router/routes";
import MenuIcon from "@mui/icons-material/Menu";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import Zoom from "@mui/material/Zoom";
import { useMobileHeaderSecondaryStyles } from "./mobileHeaderSecondaryStyles";
import { colors } from "../../../app/theme/colors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import { logout, selectCurrentAccount } from "../../../slices/authSlice";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import ImportContactsOutlinedIcon from "@mui/icons-material/ImportContactsOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import { LogoutOutlined } from "@mui/icons-material";
import dashboardBackground from "../../../images/sidebarBackground.jpg";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import { getMainActivities } from "../../../slices/onboardingSlice";
import {
  getAccountCategories,
  getAccountSubcategories,
} from "../../../slices/categorySlice";
import { faqMessage, resetCompany } from "../../../slices/companySlice";
import ChangePasswordModal from "../../modals/changePasswordModal/changePasswordModal";
import SettingsActivitiesSelectionModal from "../../modals/settingsActivitiesSelectionModal/settingsActivitiesSelectionModal";
import FaqModal from "../../modals/faqModal/faqModal";
import ManageClientsAccountsModal from "../../modals/clientAccountModal/manageClientsAccountsModal";
import FaqRequest from "../../../models/faq/faqRequest";
import { INIT_FAQ } from "../../../models/init_data/initData";

type Anchor = "left";

interface Props {
  handleSearch?: any;
}

const GradientAppBar = styled(AppBar)(({ theme }) => ({
  backgroundRepeat: "no-repeat",
  backgroundColor: colors.primary,
  backgroundSize: "37%",
}));
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));
const MobileHeaderSecondary = ({ handleSearch }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { classes } = useMobileHeaderSecondaryStyles();

  const currentAccount = useAppSelector(selectCurrentAccount);

  const [servicesMenuOpen, setServicesMenuOpen] = useState(false);
  const [settingsMenuOpen, setSettingsMenuOpen] = useState(false);
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [settingsState, setSettingsState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [faqMessageState, setFaqMessageState] = useState<FaqRequest>(INIT_FAQ);

  const [isOpenManageClientsModal, setIsOpenManageClientsModal] =
    useState<boolean>(false);
  const [isOpenChangePasswordModal, setIsOpenChangePasswordModal] =
    useState<boolean>(false);
  const [isOpenActivitySelectionModal, setIsOpenActivitySelectionModal] =
    useState<boolean>(false);
  const [isOpenFaqModal, setIsOpenFaqModal] = useState<boolean>(false);
  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open || servicesMenuOpen });
      setSettingsState({
        ...settingsState,
        [anchor]: open || settingsMenuOpen,
      });
    };

  const handleFaqChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFaqMessageState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSaveFaq = () => {
    dispatch(faqMessage(faqMessageState));
  };

  const handleButtonClick = (route: string) => {
    navigate(route);
    dispatch(resetCompany());
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate(routes.home);
    dispatch(resetCompany());
  };

  useEffect(() => {
    if (servicesMenuOpen) {
      setState({
        ...state,
        left: true,
      });
    }
  }, [servicesMenuOpen]);

  useEffect(() => {
    if (settingsMenuOpen) {
      setSettingsState({
        ...settingsState,
        left: true,
      });
    }
  }, [settingsMenuOpen]);
  const list = (anchor: Anchor) => (
    <Grid
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      className={classes.list}
    >
      <DrawerHeader
        id="sidebar_drawer_header"
        sx={{
          position: "relative",
          backgroundImage: `url(${dashboardBackground})`,
          backgroundSize: "cover",
          border: 0,
          marginBottom: "30px",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            opacity: 0.7,
            backgroundColor: colors.primary,
          }}
        />
        <Avatar
          id="sidebar_avatar"
          alt="User Avatar"
          src="/path/to/avatar.jpg"
          sx={{
            position: "absolute",
            bottom: -30,
            left: 86,
            transform: "translateX(-50%)",
            zIndex: 1,
            width: 72,
            height: 72,
            border: "2px solid white",
          }}
        >
          {currentAccount && currentAccount.firstName && currentAccount.lastName
            ? `${currentAccount.firstName.charAt(
                0
              )}${currentAccount.lastName.charAt(0)}`
            : null}
        </Avatar>
      </DrawerHeader>
      <List>
        <React.Fragment>
          <ListItem
            disablePadding
            sx={{ display: "flex", alignItems: "center" }}
            id="mobileHeader_home"
            onClick={() => handleButtonClick(routes.home)}
          >
            <ListItemButton>
              <ListItemIcon
                className={classes.listItemText}
                sx={{
                  minWidth: 0,
                  justifyContent: "center",
                }}
              >
                <HomeOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                id="mobileHeader_homeText"
                className={classes.listItemText}
                sx={{ marginLeft: "5px" }}
              >
                {t("header.homePage")}
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <Divider className={classes.divider} variant="middle" />
          <ListItem
            onClick={() => setServicesMenuOpen(!servicesMenuOpen)}
            disablePadding
            sx={{ display: "flex", alignItems: "center" }}
            id="mobileHeader_services"
          >
            <ListItemButton>
              <ListItemIcon
                className={classes.listItemText}
                sx={{
                  minWidth: 0,
                  justifyContent: "center",
                }}
              >
                <MiscellaneousServicesIcon />
              </ListItemIcon>
              <ListItemText
                id="mobileHeader_servicesText"
                className={classes.listItemText}
                sx={{ marginLeft: "5px" }}
              >
                {t("header.services")}
              </ListItemText>
              {servicesMenuOpen ? (
                <ExpandLessIcon className={classes.expandIcon} />
              ) : (
                <ExpandMoreIcon className={classes.expandIcon} />
              )}
            </ListItemButton>
          </ListItem>
          <Collapse in={servicesMenuOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                disablePadding
                sx={{ display: "flex", alignItems: "center" }}
                onClick={() => handleButtonClick(routes.updateData)}
                id="mobileHeader_updateData"
              >
                <ListItemButton>
                  <ListItemText
                    id="mobileHeader_servicesText"
                    className={classes.listItemText}
                    sx={{ marginLeft: "5px" }}
                  >
                    {t("header.updateData")}
                  </ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem
                disablePadding
                sx={{ display: "flex", alignItems: "center" }}
                onClick={() => handleButtonClick(routes.advertisement)}
                id="mobileHeader_advertisement"
              >
                <ListItemButton>
                  <ListItemText
                    id="mobileHeader_servicesText"
                    className={classes.listItemText}
                    sx={{ marginLeft: "5px" }}
                  >
                    {t("header.advertisement")}
                  </ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem
                disablePadding
                sx={{ display: "flex", alignItems: "center" }}
                id="mobileHeader_digitalMarketing"
                onClick={() => handleButtonClick(routes.digitalMarketing)}
              >
                <ListItemButton>
                  <ListItemText
                    id="mobileHeader_servicesText"
                    className={classes.listItemText}
                    sx={{ marginLeft: "5px" }}
                  >
                    {t("header.digitalMarketing")}
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            </List>
          </Collapse>
          <Divider className={classes.divider} variant="middle" />
          <ListItem
            disablePadding
            sx={{ display: "flex", alignItems: "center" }}
            id="mobileHeader_editions"
            onClick={() => handleButtonClick(routes.editions)}
          >
            <ListItemButton>
              <ListItemIcon
                className={classes.listItemText}
                sx={{
                  minWidth: 0,
                  justifyContent: "center",
                }}
              >
                <ImportContactsOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                id="mobileHeader_editionsText"
                className={classes.listItemText}
                sx={{ marginLeft: "5px" }}
              >
                {t("header.editions")}
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <Divider className={classes.divider} variant="middle" />

          <ListItem
            disablePadding
            sx={{ display: "flex", alignItems: "center" }}
            id="mobileHeader_about"
            onClick={() => handleButtonClick(routes.about)}
          >
            <ListItemButton>
              <ListItemIcon
                className={classes.listItemText}
                sx={{
                  minWidth: 0,
                  justifyContent: "center",
                }}
              >
                <InfoOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                id="mobileHeader_aboutText"
                className={classes.listItemText}
                sx={{ marginLeft: "5px" }}
              >
                {t("header.aboutUs")}
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <Divider className={classes.divider} variant="middle" />

          <ListItem
            disablePadding
            sx={{ display: "flex", alignItems: "center" }}
            id="mobileHeader_contact"
            onClick={() => handleButtonClick(routes.contact)}
          >
            <ListItemButton>
              <ListItemIcon
                className={classes.listItemText}
                sx={{
                  minWidth: 0,
                  justifyContent: "center",
                }}
              >
                <LocalPhoneOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                id="mobileHeader_contactText"
                className={classes.listItemText}
                sx={{ marginLeft: "5px" }}
              >
                {t("header.contact")}
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <Divider className={classes.divider} variant="middle" />

          <ListItem
            disablePadding
            sx={{ display: "flex", alignItems: "center" }}
            id="mobileHeader_dashboard"
            onClick={() => handleButtonClick(routes.dashboard)}
          >
            <ListItemButton>
              <ListItemIcon
                className={classes.listItemText}
                sx={{
                  minWidth: 0,
                  justifyContent: "center",
                }}
              >
                <DashboardOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                id="mobileHeader_dashboard"
                className={classes.listItemText}
                sx={{ marginLeft: "5px" }}
              >
                {t("sidebar.dashboard")}
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <Divider className={classes.divider} variant="middle" />
          {currentAccount?.isWebsite && (
            <ListItem
              disablePadding
              sx={{ display: "flex", alignItems: "center" }}
              id="mobileHeader_mySite"
              onClick={() => handleButtonClick(routes.mySite)}
            >
              <ListItemButton>
                <ListItemIcon
                  className={classes.listItemText}
                  sx={{
                    minWidth: 0,
                    justifyContent: "center",
                  }}
                >
                  <LanguageOutlinedIcon />
                </ListItemIcon>
                <ListItemText
                  id="mobileHeader_mySite"
                  className={classes.listItemText}
                  sx={{ marginLeft: "5px" }}
                >
                  {t("sidebar.mySite")}
                </ListItemText>
              </ListItemButton>
            </ListItem>
          )}

          <Divider className={classes.divider} variant="middle" />
          <ListItem
            onClick={() => setSettingsMenuOpen(!settingsMenuOpen)}
            disablePadding
            sx={{ display: "flex", alignItems: "center" }}
            id="mobileHeader_settings"
          >
            <ListItemButton>
              <ListItemIcon
                className={classes.listItemText}
                sx={{
                  minWidth: 0,
                  justifyContent: "center",
                }}
              >
                <MiscellaneousServicesIcon />
              </ListItemIcon>
              <ListItemText
                id="mobileHeader_settings"
                className={classes.listItemText}
                sx={{ marginLeft: "5px" }}
              >
                {t("sidebar.settings")}
              </ListItemText>
              {settingsMenuOpen ? (
                <ExpandLessIcon className={classes.expandIcon} />
              ) : (
                <ExpandMoreIcon className={classes.expandIcon} />
              )}
            </ListItemButton>
          </ListItem>
          <Collapse in={settingsMenuOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button
                onClick={() => {
                  setIsOpenActivitySelectionModal(true);
                  dispatch(getMainActivities());
                  dispatch(getAccountCategories());
                  dispatch(getAccountSubcategories());
                }}
                id="mobileHeader_activity"
              >
                <ListItemText
                  id="mobileHeader_activityText"
                  className={classes.listItemText}
                >
                  {t("sidebar.activity")}
                </ListItemText>
              </ListItem>
              <ListItem
                button
                onClick={() => setIsOpenChangePasswordModal(true)}
                id="mobileHeader_password"
              >
                <ListItemText
                  id="mobileHeader_passwordText"
                  className={classes.listItemText}
                >
                  {t("sidebar.password")}
                </ListItemText>
              </ListItem>
              <ListItem
                button
                id="mobileHeader_faq"
                onClick={() => setIsOpenFaqModal(true)}
              >
                <ListItemText
                  id="mobileHeader_faqText"
                  className={classes.listItemText}
                >
                  {t("sidebar.support")}
                </ListItemText>
              </ListItem>
              <ListItem
                button
                id="mobileHeader_user"
                onClick={() => setIsOpenManageClientsModal(true)}
              >
                <ListItemText
                  id="mobileHeader_user"
                  className={classes.listItemText}
                >
                  {t("sidebar.user")}
                </ListItemText>
              </ListItem>
            </List>
          </Collapse>
          <Divider className={classes.divider} variant="middle" />

          <ListItem
            disablePadding
            sx={{ display: "flex", alignItems: "center" }}
            id="mobileHeader_logout"
            onClick={() => handleLogout()}
          >
            <ListItemButton>
              <ListItemIcon
                className={classes.listItemText}
                sx={{
                  minWidth: 0,
                  justifyContent: "center",
                }}
              >
                <LogoutOutlined />
              </ListItemIcon>
              <ListItemText
                id="mobileHeader_logout"
                className={classes.listItemText}
                sx={{ marginLeft: "5px" }}
              >
                {t("sidebar.logout")}
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </React.Fragment>
      </List>
    </Grid>
  );

  return (
    <GradientAppBar position="static">
      <Toolbar>
        <Grid container>
          <Grid item xs={6} justifyContent="flex-start">
            {(["left"] as Anchor[]).map((anchor) => (
              <React.Fragment key={anchor}>
                <MenuIcon
                  id="header-menu-icon"
                  style={{ marginTop: "12px" }}
                  onClick={toggleDrawer(anchor, true)}
                />
                <Drawer
                  id="header-drawer"
                  anchor={anchor}
                  open={state[anchor] || settingsState[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                >
                  {list(anchor)}
                </Drawer>
              </React.Fragment>
            ))}
          </Grid>
          <Grid item xs={6} className={classes.logoWrapper}>
            <img
              id="header-logo"
              src={logoFull}
              className={classes.logoFull}
              onClick={() => handleButtonClick(routes.home)}
            />
          </Grid>
        </Grid>
      </Toolbar>
      <ChangePasswordModal
        open={isOpenChangePasswordModal}
        handleClose={() => setIsOpenChangePasswordModal(false)}
      />
      <SettingsActivitiesSelectionModal
        open={isOpenActivitySelectionModal}
        handleClose={() => setIsOpenActivitySelectionModal(false)}
      />
      <FaqModal
        open={isOpenFaqModal}
        handleClose={() => setIsOpenFaqModal(false)}
        handleFaqChange={handleFaqChange}
        faqMessageState={faqMessageState}
        handleSaveFaq={handleSaveFaq}
      />
      <ManageClientsAccountsModal
        open={isOpenManageClientsModal}
        handleClose={() => setIsOpenManageClientsModal(false)}
      />
    </GradientAppBar>
  );
};

export default MobileHeaderSecondary;
