import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/redux/store";
import { AppNotification } from "../models/app/appNotification";
import { NotificationType } from "../models/app/notificationType";

interface NotificationState {
  notification: AppNotification | null;
}

const initialState: NotificationState = {
  notification: null,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setPendingNotification: (state, action: PayloadAction<string>) => {
      state.notification = {
        type: NotificationType.Pending,
        message: action.payload,
      };
    },
    setSuccessNotification: (state, action: PayloadAction<string>) => {
      state.notification = {
        type: NotificationType.Success,
        message: action.payload,
      };
    },
    setInfoNotification: (state, action: PayloadAction<string>) => {
      state.notification = {
        type: NotificationType.Info,
        message: action.payload,
      };
    },
    setWarningNotification: (state, action: PayloadAction<string>) => {
      state.notification = {
        type: NotificationType.Warning,
        message: action.payload,
      };
    },
    setErrorNotification: (state, action: PayloadAction<string>) => {
      state.notification = {
        type: NotificationType.Error,
        message: action.payload,
      };
    },
    clearNotification: (state) => {
      state.notification = null;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  setPendingNotification,
  setSuccessNotification,
  setInfoNotification,
  setWarningNotification,
  setErrorNotification,
  clearNotification,
} = notificationSlice.actions;

export const selectNotification = (state: RootState) =>
  state.notification.notification;

export default notificationSlice.reducer;
