import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/redux/store";
import { ApiStatus } from "../models/app/apiStatus";
import {
  savePartner as savePartnerApi,
  savePotentialPartner as savePotentialPartnerApi,
  saveClient as saveClientApi,
  saveCategory as saveCategoryApi,
  saveFavorite as saveFavoriteApi,
  removeFavorite as removeFavoriteApi,
  removeClient as removeClientApi,
  removePartner as removePartnerApi,
  removePotentialPartner as removePotentialPartnerApi,
  removeCategory as removeCategoryApi,
} from "../app/redux/searchResultsActionsApi";

type OnboardingState = {
  statuses: {
    savePartner: ApiStatus;
    savePotentialPartner: ApiStatus;
    saveClient: ApiStatus;
    saveCategory: ApiStatus;
    saveFavorite: ApiStatus;
    removeFavorite: ApiStatus;
  };
};

const initialState: OnboardingState = {
  statuses: {
    savePartner: ApiStatus.Idle,
    savePotentialPartner: ApiStatus.Idle,
    saveClient: ApiStatus.Idle,
    saveCategory: ApiStatus.Idle,
    saveFavorite: ApiStatus.Idle,
    removeFavorite: ApiStatus.Idle,
  },
};

export const selectPartnerStatus = (state: RootState) =>
  state.searchResultsActions.statuses.savePartner;
export const selectPotentialPartnerStatus = (state: RootState) =>
  state.searchResultsActions.statuses.savePotentialPartner;
export const selectClientStatus = (state: RootState) =>
  state.searchResultsActions.statuses.saveClient;
export const selectCategoryStatus = (state: RootState) =>
  state.searchResultsActions.statuses.saveCategory;
export const selectFavoriteStatus = (state: RootState) =>
  state.searchResultsActions.statuses.saveFavorite;
export const selectRemovedFavoriteStatus = (state: RootState) =>
  state.searchResultsActions.statuses.removeFavorite;

export const savePartner = createAsyncThunk(
  "searchResultsActionsSlice/save_partner",
  async (companyId: string) => {
    const response = await savePartnerApi(companyId);
    return response.data;
  }
);

export const savePotentialPartner = createAsyncThunk(
  "searchResultsActionsSlice/save_potential_partners",
  async (companyId: string) => {
    const response = await savePotentialPartnerApi(companyId);
    return response.data;
  }
);

export const saveClient = createAsyncThunk(
  "searchResultsActionsSlice/save_client",
  async (companyId: string) => {
    const response = await saveClientApi(companyId);
    return response.data;
  }
);

export const saveCategory = createAsyncThunk(
  "searchResultsActionsSlice/save_category",
  async (request: string[] ) => {
    const response = await saveCategoryApi(request);
    return response.data;
  }
);

export const saveFavorite = createAsyncThunk(
  "searchResultsActionsSlice/save_favorite",
  async (companyId: string) => {
    const response = await saveFavoriteApi(companyId);
    return response.data;
  }
);

export const removeFavorite = createAsyncThunk(
  "searchResultsActionsSlice/remove_favorite",
  async (companyId: string) => {
    const response = await removeFavoriteApi(companyId);
    return response.data;
  }
);

export const removePartner = createAsyncThunk(
  "searchResultsActionsSlice/remove_partner",
  async (companyId: string) => {
    const response = await removePartnerApi(companyId);
    return response.data;
  }
);

export const removePotentialPartner = createAsyncThunk(
  "searchResultsActionsSlice/remove_potential_partner",
  async (companyId: string) => {
    const response = await removePotentialPartnerApi(companyId);
    return response.data;
  }
);

export const removeClient = createAsyncThunk(
  "searchResultsActionsSlice/remove_client",
  async (companyId: string) => {
    const response = await removeClientApi(companyId);
    return response.data;
  }
);

export const removeCategory = createAsyncThunk(
  "searchResultsActionsSlice/remove_category",
  async (companyId: string) => {
    const response = await removeCategoryApi(companyId);
    return response.data;
  }
);

export const resetSavedPartner = createAsyncThunk(
  "searchResultsActionsSlice/reset_partner_status",
  async () => {
    return initialState.statuses.savePartner;
  }
);

export const resetSavedPotentialPartners = createAsyncThunk(
  "searchResultsActionsSlice/reset_potential_partner_status",
  async () => {
    return initialState.statuses.savePotentialPartner;
  }
);

export const resetSavedClient = createAsyncThunk(
  "searchResultsActionsSlice/reset_client_status",
  async () => {
    return initialState.statuses.saveClient;
  }
);

export const resetSavedCategory = createAsyncThunk(
  "searchResultsActionsSlice/reset_category_status",
  async () => {
    return initialState.statuses.saveCategory;
  }
);

export const resetSavedFavorite = createAsyncThunk(
  "searchResultsActionsSlice/reset_favorite_status",
  async () => {
    return initialState.statuses.saveFavorite;
  }
);

export const resetRemovedFavorite = createAsyncThunk(
  "searchResultsActionsSlice/reset_removed_favorite_status",
  async () => {
    return initialState.statuses.removeFavorite;
  }
);

const searchResultsActionsSlice = createSlice({
  name: "searchResultsActions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(savePartner.pending, (state, action) => {
        state.statuses.savePartner = ApiStatus.Pending;
      })
      .addCase(savePartner.fulfilled, (state, action) => {
        state.statuses.savePartner = ApiStatus.Fulfilled;
      })
      .addCase(savePotentialPartner.pending, (state, action) => {
        state.statuses.savePotentialPartner = ApiStatus.Pending;
      })
      .addCase(savePotentialPartner.fulfilled, (state, action) => {
        state.statuses.savePotentialPartner = ApiStatus.Fulfilled;
      })
      .addCase(saveClient.pending, (state, action) => {
        state.statuses.saveClient = ApiStatus.Pending;
      })
      .addCase(saveClient.fulfilled, (state, action) => {
        state.statuses.saveClient = ApiStatus.Fulfilled;
      })
      .addCase(saveCategory.pending, (state, action) => {
        state.statuses.saveCategory = ApiStatus.Pending;
      })
      .addCase(saveCategory.fulfilled, (state, action) => {
        state.statuses.saveCategory = ApiStatus.Fulfilled;
      })
      .addCase(saveFavorite.pending, (state, action) => {
        state.statuses.saveFavorite = ApiStatus.Pending;
      })
      .addCase(saveFavorite.fulfilled, (state, action) => {
        state.statuses.saveFavorite = ApiStatus.Fulfilled;
      })
      .addCase(removeFavorite.pending, (state, action) => {
        state.statuses.removeFavorite = ApiStatus.Pending;
      })
      .addCase(removeFavorite.fulfilled, (state, action) => {
        state.statuses.removeFavorite = ApiStatus.Fulfilled;
      });
  },
});

export default searchResultsActionsSlice.reducer;
