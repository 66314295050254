import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  styled,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { colors } from "../../app/theme/colors";
import AppButton from "../../components/appButton/appButton";
import SendIcon from "@mui/icons-material/Send";
import { useAdvertisementSectionStyles } from "./advertisementSectionStyles";
import advertisementImg from "../../images/advertisementSection.png";
import { useTranslation } from "react-i18next";
import BasicInput from "../../components/basicInput/basicInput";
import { useEffect, useState } from "react";
import { AdvertisementModel } from "../../models/editions/advertisementModel";
import { useAppDispatch, useAppSelector } from "../../app/redux/hooks";
import {
  getAllPlaces,
  getStreetsByPlaceId,
  selectAllPlaces,
  selectStreetsByPlaceId,
} from "../../slices/addressSlice";
import CustomOutlinedInput from "../../components/outlinedInput/customOutlinedInput";
import {
  selectAdvertisementStatus,
  sendAdvertisementRequest,
} from "../../slices/companySlice";
import { toast, ToastContainer } from "react-toastify";
import {
  IsCompanyNameValid,
  IsEmailValid,
  IsMessageValid,
  IsPackageValidate,
} from "../../common/validations/validations";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import EmailIcon from "@mui/icons-material/Email";
import { StyledCheckCircleIcon } from "../advertisementRequestSection/advertisementRequestSection";
import React from "react";

type ButtonName = "paket1" | "paket2" | "paket3";

const GradientBackground = styled(Grid)(({ theme }) => ({
  alignItems: "center",
  backgroundColor: colors.primary,
  backgroundPosition: "center",
  padding: "40px",
  [theme.breakpoints.down("md")]: {
    padding: "20px",
  },
}));

export default function AdvertisementSection() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { classes } = useAdvertisementSectionStyles();
  const theme = useTheme();
  const places = useAppSelector(selectAllPlaces);

  const advertisementStatus = useAppSelector(selectAdvertisementStatus);
  const streetsByPlaceId = useAppSelector(selectStreetsByPlaceId);

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const INIT_ADVERTISEMENT: AdvertisementModel = {
    companyName: "",
    email: "",
    homeNumber: "",
    phoneNumber: "",
    place: "",
    street: "",
    taxId: "",
    message: "",
    choosenPackage: "",
  };

  const [selectedPlace, setSelectedPlace] = useState(null);
  const [selectedStreet, setSelectedStreet] = useState(null);

  const [outlined, setOutlined] = useState({
    package1: true,
    package2: true,
    package3: true,
  });

  const [advertisement, setAdvertisement] =
    useState<AdvertisementModel>(INIT_ADVERTISEMENT);

  const [buttonText, setButtonText] = useState(
    t("buttons.orderByPhone") as string
  );
  const packageText = t("orderRequestPage.orderPackageOneText");
  const packageTextSecond = t("orderRequestPage.orderPackageTwoText");

  const [phoneLinkMobile, setPhoneLinkMobile] = useState("tel:+38751255310");

  const [isChecked, setIsChecked] = useState(false);
  const [validate, setValidate] = useState<boolean>(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const isValid = () => {
    setValidate(true);
    if (
      !IsCompanyNameValid(advertisement.companyName) ||
      !IsEmailValid(advertisement.email)
    ) {
      return false;
    }

    if (
      !advertisement.phoneNumber &&
      (advertisement.companyName || advertisement.email)
    ) {
      return false;
    }

    return true;
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (isValid()) {
      if (advertisement.phoneNumber !== "") {
        dispatch(sendAdvertisementRequest(advertisement));
      }
      setValidate(false);
    }
  };

  const handleClick = (buttonName: ButtonName) => {
    setAdvertisement((prevRequest) => ({
      ...prevRequest,
      choosenPackage:
        buttonName === "paket1"
          ? "paket 1"
          : buttonName === "paket2"
          ? "paket 2"
          : "paket 3",
    }));

    setOutlined({
      package1: buttonName === "paket1" ? !outlined.package1 : true,
      package2: buttonName === "paket2" ? !outlined.package2 : true,
      package3: buttonName === "paket3" ? !outlined.package3 : true,
    });
  };

  function handleClickEmailButton() {
    const {
      companyName,
      email,
      homeNumber,
      message,
      choosenPackage,
      phoneNumber,
      taxId,
    } = advertisement;
    const subject = "Narudžba";

    let body = `Porudžbina,
  
      Detalji porudžbine:
      ---------------
      Izabrani paket: ${choosenPackage}
      Kompanija: ${companyName}
      PIB: ${taxId}
      E-pošta: ${email}
      Kućni broj: ${homeNumber}
      Poruka: ${message}
      Broj telefona: ${phoneNumber};
      `;

    if (selectedPlace && selectedStreet) {
      body += `
      
      Izabrani grad: ${selectedPlace}
      Izabrana ulica: ${selectedStreet}`;
    }

    body += "\n\nHvala!";

    const mailtoLink = `mailto:info@poslovniimenik.net?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    const link = document.createElement("a");
    link.href = mailtoLink;
    link.target = "_blank";
    link.dispatchEvent(new MouseEvent("click"));
    setAdvertisement(INIT_ADVERTISEMENT);
    setValidate(false);
  }

  const handleFormChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setAdvertisement((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleResetFields = () => {
    setAdvertisement(INIT_ADVERTISEMENT);
    setSelectedPlace(null);
    setSelectedStreet(null);
    setIsChecked(false);
  };

  useEffect(() => {
    dispatch(getAllPlaces());
  }, []);

  useEffect(() => {
    if (advertisementStatus === "Fulfilled") {
      handleResetFields();
    }
  }, [advertisementStatus]);

  return (
    <GradientBackground container>
      <Grid item xs={12} md={6} justifyContent="flex-start">
        <Typography variant="h4" className={classes.title}>
          {t("advertisementPage.advertisementTitle")}
        </Typography>
        <Typography variant="body1" className={classes.text}>
          {t("advertisementPage.advertisementText")}
        </Typography>
        <Grid container spacing={2} mt={1}>
          <Grid item md={4} xs={12}>
            <Button
              fullWidth
              sx={{
                backgroundColor: outlined.package1 ? "white" : colors.primary,
                color: outlined.package1 ? colors.primary : "white",
                border:
                  validate && !IsPackageValidate(advertisement.choosenPackage)
                    ? `1px solid ${colors.validationErrorMessage}`
                    : "none",
                boxShadow:
                  "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
                "&:hover": {
                  backgroundColor: colors.primary,
                  color: "white",
                  border: "none",
                },
              }}
              variant={outlined.package1 ? "text" : "contained"}
              onClick={() => handleClick("paket1")}
              startIcon={
                outlined.package1 ? (
                  <RadioButtonUncheckedIcon />
                ) : (
                  <CheckCircleIcon
                    sx={{
                      color: colors.green,
                      background: "white",
                      borderRadius: "100%",
                    }}
                  />
                )
              }
            >
              <Typography variant="body1">
                {t("orderRequestPage.orderPackageOne")}
              </Typography>
            </Button>
            {validate && !IsPackageValidate(advertisement.choosenPackage) && (
              <span className={classes.errorMessage}>
                {t("validation.validationPackage")}
              </span>
            )}
          </Grid>
          <Grid item md={4} xs={12}>
            <Button
              fullWidth
              sx={{
                backgroundColor: outlined.package2 ? "white" : colors.primary,
                color: outlined.package2 ? colors.primary : "white",
                border:
                  validate && !IsPackageValidate(advertisement.choosenPackage)
                    ? `1px solid ${colors.validationErrorMessage}`
                    : "none",
                boxShadow:
                  "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
                "&:hover": {
                  backgroundColor: colors.primary,
                  color: "white",
                  border: "none",
                },
              }}
              variant={outlined.package2 ? "text" : "contained"}
              onClick={() => handleClick("paket2")}
              startIcon={
                outlined.package2 ? (
                  <RadioButtonUncheckedIcon />
                ) : (
                  <CheckCircleIcon
                    sx={{
                      color: colors.green,
                      background: "white",
                      borderRadius: "100%",
                    }}
                  />
                )
              }
            >
              <Typography variant="body1">
                {t("orderRequestPage.orderPackageTwo")}
              </Typography>
            </Button>
          </Grid>
          <Grid item md={4} xs={12}>
            <Button
              fullWidth
              sx={{
                backgroundColor: outlined.package3 ? "white" : colors.primary,
                color: outlined.package3 ? colors.primary : "white",
                border:
                  validate && !IsPackageValidate(advertisement.choosenPackage)
                    ? `1px solid ${colors.validationErrorMessage}`
                    : "none",
                boxShadow:
                  "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
                "&:hover": {
                  backgroundColor: colors.primary,
                  color: "white",
                  border: "none",
                },
              }}
              variant={outlined.package3 ? "text" : "contained"}
              onClick={() => handleClick("paket3")}
              startIcon={
                outlined.package3 ? (
                  <RadioButtonUncheckedIcon />
                ) : (
                  <CheckCircleIcon
                    sx={{
                      color: colors.green,
                      background: "white",
                      borderRadius: "100%",
                    }}
                  />
                )
              }
            >
              <Typography variant="body1">
                {t("orderRequestPage.orderPackageThree")}
              </Typography>
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item lg={6} md={12} xs={12} mt={4}>
            <CustomOutlinedInput
              width="100%"
              label={t("orderRequestPage.orderFormCompanyName")}
              value={advertisement.companyName}
              onChange={(e) => handleFormChange(e)}
              name={"companyName"}
              error={validate && !IsCompanyNameValid(advertisement.companyName)}
              errorMessage={t("validation.validationCompanyName")}
            />
          </Grid>
          <Grid item lg={6} md={12} xs={12} mt={4}>
            <CustomOutlinedInput
              width="100%"
              label={t("orderRequestPage.orderFormTaxId")}
              value={advertisement.taxId}
              onChange={(e) => handleFormChange(e)}
              name={"taxId"}
            />
          </Grid>
          <Grid item lg={4} md={12} xs={12} mt={4}>
            <CustomOutlinedInput
              width="100%"
              label={t("orderRequestPage.orderFormPlace") ?? ""}
              value={advertisement.place}
              onChange={(e) => handleFormChange(e)}
              name={"place"}
            />
          </Grid>
          <Grid item lg={4} md={12} xs={12} mt={4}>
            <CustomOutlinedInput
              width="100%"
              label={t("orderRequestPage.orderFormStreet") ?? ""}
              value={advertisement.street}
              onChange={(e) => handleFormChange(e)}
              name={"street"}
              disabled={advertisement.place === ""}
            />
          </Grid>
          <Grid item lg={4} md={12} xs={12} mt={4}>
            <CustomOutlinedInput
              width="100%"
              label={t("orderRequestPage.orderFormHomeNumber")}
              value={advertisement.homeNumber}
              onChange={(e) => handleFormChange(e)}
              name={"homeNumber"}
              disabled={advertisement.street === ""}
            />
          </Grid>
          <Grid item lg={6} md={12} xs={12} mt={4}>
            <CustomOutlinedInput
              width="100%"
              label={t("orderRequestPage.orderFormEmail")}
              value={advertisement.email}
              onChange={(e) => handleFormChange(e)}
              name={"email"}
              error={validate && !IsCompanyNameValid(advertisement.email)}
              errorMessage={t("validation.validationEmail")}
            />
          </Grid>
          <Grid item lg={6} md={12} xs={12} mt={4}>
            <CustomOutlinedInput
              width="100%"
              label={t("orderRequestPage.orderFormPhoneNumber")}
              value={advertisement.phoneNumber}
              onChange={(e) => handleFormChange(e)}
              name={"phoneNumber"}
              error={validate && !IsCompanyNameValid(advertisement.phoneNumber)}
              errorMessage={t("validation.validationPhoneNumber")}
            />
          </Grid>
          <Grid item lg={12} md={12} xs={12} mt={4}>
            <CustomOutlinedInput
              width="100%"
              label={t("orderRequestPage.orderFormMessage")}
              value={advertisement.message}
              onChange={(e) => handleFormChange(e)}
              name={"message"}
              multiline
              rows={6}
              error={validate && !IsCompanyNameValid(advertisement.message)}
              errorMessage={t("validation.validationMessage")}
            />
          </Grid>
          <Grid item md={12}>
            <FormControlLabel
              sx={{ color: colors.white }}
              control={
                <Checkbox
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  sx={{
                    color: colors.white,
                    "&.Mui-checked": {
                      color: colors.white,
                    },
                  }}
                />
              }
              label={t("termsAndContitions.error")}
            />
            <Typography variant="body1" sx={{ color: colors.white }}>
              {t("termsAndContitions.description")}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1} my={2}>
          <Grid item md={4} xs={12}>
            <AppButton
              label={t("buttons.orderOnline")}
              color={colors.primary}
              hover={colors.primary}
              startIcon={
                advertisementStatus === "Pending" ? (
                  <CircularProgress size={"1em"} />
                ) : (
                  <SendIcon />
                )
              }
              width="100%"
              onClick={handleSubmit}
              disabled={advertisementStatus === "Pending" || isChecked !== true}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <AppButton
              id="subHeader_button_call"
              startIcon={<PhoneInTalkIcon />}
              label={buttonText}
              color={colors.primary}
              hover={colors.primary}
              href={phoneLinkMobile}
              onClick={() => setPhoneLinkMobile("tel:+381692128500")}
              width={isMobile ? "100%" : "100%"}
              disabled={isChecked !== true}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <AppButton
              label={t("buttons.orderByEmail")}
              color={colors.primary}
              hover={colors.primary}
              startIcon={<EmailIcon />}
              width="100%"
              onClick={handleClickEmailButton}
              disabled={isChecked !== true}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        justifyContent="center"
        sx={{ paddingLeft: isMobile ? "0px" : "50px" }}
      >
        <Grid item className={classes.descriptionWrapper} xs={12}>
          {isMobile ? (
            <Grid container alignItems="center">
              <StyledCheckCircleIcon />
              <Typography variant="h6" className={classes.descTitle}>
                {t("orderRequestPage.orderPackageOne")}
              </Typography>
              <Typography variant="body1" className={classes.descTitle}>
                {packageText?.split("- ").map((item, index) => (
                  <React.Fragment key={index}>
                    {index > 0 && <br />}
                    {`${index === 0 ? "" : "- "}${item}`}
                  </React.Fragment>
                ))}
              </Typography>
            </Grid>
          ) : (
            <Grid container alignItems="center">
              <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                <StyledCheckCircleIcon />
                <Typography variant="h6" className={classes.descTitle}>
                  {t("orderRequestPage.orderPackageOne")}
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="body1" className={classes.descTitle}>
                  {packageText?.split("- ").map((item, index) => (
                    <React.Fragment key={index}>
                      {index > 0 && <br />}
                      {`${index === 0 ? "" : "- "}${item}`}
                    </React.Fragment>
                  ))}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid
          item
          md={12}
          xs={12}
          className={classes.descriptionWrapper}
          my={isMobile ? 6 : 12}
        >
          {isMobile ? (
            <Grid container alignItems="center">
              <StyledCheckCircleIcon />
              <Typography variant="h6" className={classes.descTitle}>
                {t("orderRequestPage.orderPackageTwo")}
              </Typography>
              <Typography variant="body1" className={classes.descTitle}>
                {packageText?.split("- ").map((item, index) => (
                  <React.Fragment key={index}>
                    {index > 0 && <br />}
                    {`${index === 0 ? "" : "- "}${item}`}
                  </React.Fragment>
                ))}
              </Typography>
            </Grid>
          ) : (
            <Grid container alignItems="center">
              <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                <StyledCheckCircleIcon />
                <Typography variant="h6" className={classes.descTitle}>
                  {t("orderRequestPage.orderPackageTwo")}
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="body1" className={classes.descTitle}>
                  {packageTextSecond?.split("- ").map((item, index) => (
                    <React.Fragment key={index}>
                      {index > 0 && <br />}
                      {`${index === 0 ? "" : "- "}${item}`}
                    </React.Fragment>
                  ))}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid
          item
          md={12}
          xs={12}
          className={classes.descriptionWrapper}
          my={isMobile ? 6 : 12}
        >
          {isMobile ? (
            <Grid container alignItems="center">
              <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                <StyledCheckCircleIcon />
                <Typography variant="h6" className={classes.descTitle}>
                  {t("orderRequestPage.orderPackageThree")}
                </Typography>
              </Grid>

              <Typography variant="body1" className={classes.descText}>
                {t("orderRequestPage.orderPackageThreeText")}
              </Typography>
            </Grid>
          ) : (
            <Grid container alignItems="center">
              <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                <StyledCheckCircleIcon />
                <Typography variant="h6" className={classes.descTitle}>
                  {t("orderRequestPage.orderPackageThree")}
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="body1" className={classes.descText}>
                  {t("orderRequestPage.orderPackageThreeText")}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item md={12} xs={12} className={classes.descriptionWrapper}>
          <Grid container direction="column">
            <Typography variant="h6" className={classes.descTitle}>
              {t("orderRequestPage.orderNoteTitle")}
            </Typography>
            <Typography variant="body1" className={classes.descText}>
              {t("orderRequestPage.orderNoteText")}
            </Typography>
            <Typography variant="body1" className={classes.descText} mt={1}>
              {t("orderRequestPage.orderNoteSub")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </GradientBackground>
  );
}
