import { Chip, CircularProgress, Grid, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { useDashboardSectionStyles } from "./dashboardSectionStyles";
import CloseIcon from "@mui/icons-material/Close";
import AppButton from "../../components/appButton/appButton";
import { colors } from "../../app/theme/colors";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../app/redux/hooks";
import {
  getAccountCategories,
  getAccountSubcategories,
  selectAccountCategories,
} from "../../slices/categorySlice";
import SettingsActivitiesSelectionModal from "../modals/settingsActivitiesSelectionModal/settingsActivitiesSelectionModal";
import { useState } from "react";
import { getMainActivities } from "../../slices/onboardingSlice";

interface Props {
  title: string;
  count: string;
  id: string;
  onClick: any;
}

export default function DashboardActivitiesCard({
  count,
  id,
  onClick,
  title,
}: Props) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { classes } = useDashboardSectionStyles();
  const accountCategories = useAppSelector(selectAccountCategories);
  const [isOpenActivity, setIsOpenActivity] = useState<boolean>(false);

  if (!accountCategories) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }

  return (
    <motion.div
      id={id}
      onClick={onClick}
      className={classes.activitiesCardWrapper}
    >
      <Grid item md={12} className={classes.activitiesCardTitleWrapper}>
        <Typography variant="h4" className={classes.title}>
          {title}
        </Typography>
      </Grid>
      <Grid
        item
        md={12}
        className={
          accountCategories?.length > 4 ? classes.activitiesChipContainer : ""
        }
      >
        {accountCategories?.map((chip, index) => (
          <Grid
            item
            mt={1}
            md={12}
            sx={{
              justifyContent: "center",
              display: "flex",
            }}
            key={chip.id} // Added key prop
          >
            <Chip
              key={chip.id} // Removed redundant key prop from Chip component
              label={chip.name}
              sx={{
                width: "60%",
                backgroundColor: index % 2 === 0 ? "#4D8CF557" : "#EBEBEBCC",
                color: index % 2 === 0 ? "#635F5FBA" : "#635F5FBA",
                display: "flex",
                justifyContent: "center",
              }}
            />
          </Grid>
        ))}
      </Grid>
      <Grid item md={12} mt={2}>
        <AppButton
          label={t("buttons.manageActivities")}
          color={colors.primary}
          hover={colors.primary}
          width="60%"
          onClick={() => {
            setIsOpenActivity(true);
            dispatch(getMainActivities());
            dispatch(getAccountCategories());
            dispatch(getAccountSubcategories());
          }}
        />
      </Grid>
      <SettingsActivitiesSelectionModal
        handleClose={() => setIsOpenActivity(false)}
        open={isOpenActivity}
      />
    </motion.div>
  );
}
