import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { colors } from "../../../app/theme/colors";
import AppButton from "../../../components/appButton/appButton";
import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import {
  login as loginSlice,
  resetErrorMessage,
  resetResetPassword,
  resetResetPasswordStatus,
  selectErrorMessage,
  selectLoginResponse,
} from "../../../slices/authSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useActivitySelectionModalStyles } from "./activitySelectionModalStyles";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  getMainActivities,
  resetSavedActivitiesStatus,
  saveActivities,
  selectMainActivities,
  selectSavedActivitiesStatus,
  updateAfterSelectingCategories,
} from "../../../slices/onboardingSlice";
import { CategoryShortDetail } from "../../../models/company/categoryShortDetail";
import ActivitiesRequestModel from "../../../models/onboarding/activitiesRequestModel";
import { ApiStatus } from "../../../models/app/apiStatus";

interface Props {
  open: any;
  handleClose: any;
}

const top100Films = [
  { label: "Poljoprivreda", year: 1994 },
  { label: "Sumarstvo", year: 1972 },
  { label: "Turizam", year: 1974 },
];

export default function ActivitySelectionModal({ handleClose, open }: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { classes } = useActivitySelectionModalStyles();
  const theme = useTheme();
  const navigate = useNavigate();

  const loginResponse = useAppSelector(selectLoginResponse);
  const errorMessage = useAppSelector(selectErrorMessage);
  const mainActivities = useAppSelector(selectMainActivities);
  const savedActivitiesStatus = useAppSelector(selectSavedActivitiesStatus);
  const INIT_ACTIVTIES: ActivitiesRequestModel = {
    categoryIds: [],
  };

  const [savedActivities, setSavedActivities] =
    useState<ActivitiesRequestModel>(INIT_ACTIVTIES);

  const [isOpenSetPasswordModal, setIsOpenSetPasswordModal] =
    useState<boolean>(false);

  const [selectedActivities, setSelectedActivities] = useState<
    CategoryShortDetail[]
  >([]);

  const handleDialogClose = () => {
    handleClose();
  };

  const handleActivitySelect = (
    event: React.ChangeEvent<{}>,
    value: CategoryShortDetail | CategoryShortDetail[]
  ) => {
    const selectedValueArray = Array.isArray(value) ? value : [value];

    if (selectedValueArray.length > 3) {
      setSelectedActivities(selectedValueArray.slice(0, 3));
    } else {
      setSelectedActivities(selectedValueArray);
    }

    const limitedSelectedIds = selectedValueArray
      .slice(0, 3)
      .map((activity) => activity.id);

    setSavedActivities((prevState) => ({
      ...prevState,
      categoryIds: limitedSelectedIds,
    }));
  };

  const handleSaveActivities = () => {
    dispatch(saveActivities(savedActivities));
  };

  const resetActivitySelectionModalState = () => {
    setSelectedActivities([]);
    setSavedActivities(INIT_ACTIVTIES);
  };

  useEffect(() => {
    if (loginResponse && loginResponse?.isFirstLogin) {
      setIsOpenSetPasswordModal(true);
    }
  }, [loginResponse]);

  // useEffect(() => {
  //   if (errorMessage) {
  //     if (errorMessage === "Failed to login.") {
  //       toast.error(
  //         t("forgotPasswordModal.forgotPasswordErrorMessage"),
  //         {
  //           position: "bottom-left",
  //           autoClose: 3000,
  //           hideProgressBar: true,
  //           onClose: () => {
  //             dispatch(resetErrorMessage());
  //           },
  //         }
  //       );
  //     }
  //   }
  // }, [errorMessage]);

  useEffect(() => {
    if (savedActivitiesStatus === "Fulfilled") {
      dispatch(updateAfterSelectingCategories());
      resetActivitySelectionModalState();
      handleClose();
      dispatch(resetSavedActivitiesStatus());
      dispatch(resetResetPassword());
     
    }
  }, [savedActivitiesStatus]);

  useEffect(() => {
    if (open) {
      dispatch(getMainActivities());
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      fullWidth
      maxWidth="xs"
      id="activitySelectionModal_dialog"
      PaperProps={{ sx: { borderRadius: "15px", overflow: "hidden" } }}
    >
      <IconButton
        id="activitySelectionModal_button_close"
        edge="end"
        color="inherit"
        onClick={handleDialogClose}
        aria-label="close"
        sx={{
          position: "absolute",
          top: theme.spacing(1),
          right: theme.spacing(2),
        }}
        className={classes.closeIcon}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          padding={"5px"}
          sx={{ textAlign: "center" }}
        >
          <Grid container spacing={1}>
            <Grid item md={12} xs={12}>
              <Box marginBottom={-2}>
                <Typography variant="h3" sx={{ color: colors.primary }}>
                  {t("activitySelectionModal.activitySelectionTitle")}
                </Typography>
              </Box>
            </Grid>
            <Grid item md={12} xs={12}>
              <Typography
                variant="h5"
                sx={{ fontSize: "20px", color: colors.primary }}
              >
                {t("activitySelectionModal.activitySelectionSubtitle")}
              </Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <Typography variant="h5" sx={{ fontSize: "20px" }}>
                {t("activitySelectionModal.activitySelectionSubtitle2")}
              </Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <Typography
                variant="h6"
                sx={{ fontSize: "18px" }}
                dangerouslySetInnerHTML={{
                  __html: t(
                    "activitySelectionModal.activitySelectionCategories"
                  ),
                }}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <Autocomplete
                size="small"
                disablePortal
                fullWidth
                multiple
                id="activitySelectionModal-autocomplete-activities"
                options={mainActivities ? mainActivities : []}
                getOptionLabel={(option) => option.name}
                onChange={handleActivitySelect}
                value={selectedActivities}
                autoHighlight={true}
                renderTags={() => null}
                disableCloseOnSelect
                renderInput={(params) => (
                  <TextField
                    className={classes.autocompleteTextField}
                    {...params}
                    variant="outlined"
                    fullWidth
                    label={t(
                      "activitySelectionModal.activitySelectionAutocompleteLabel"
                    )}
                  />
                )}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <motion.div className={classes.motionDiv}>
                {selectedActivities.map((activity) => (
                  <motion.div
                    key={activity.name}
                    initial={{ scale: 0, rotate: -5 }}
                    animate={{ scale: 1, rotate: 0 }}
                    transition={{ duration: 0.2 }}
                  >
                    <Chip
                      className={classes.chip}
                      label={activity.name}
                      onDelete={() => {
                        setSelectedActivities((prevActivities) =>
                          prevActivities.filter((a) => a !== activity)
                        );
                      }}
                      deleteIcon={
                        <CloseIcon className={classes.closeIconChip} />
                      }
                    />
                  </motion.div>
                ))}
              </motion.div>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Grid container className={classes.buttonWrapper}>
          <Grid item md={12} xs={12}>
            <AppButton
              id="activitySelectionModal_button_save"
              color={colors.primary}
              hover={colors.primary}
              label={t("buttons.save")}
              width="100%"
              onClick={handleSaveActivities}
              disabled={
                savedActivitiesStatus === ApiStatus.Pending ? true : false
              }
              startIcon={
                savedActivitiesStatus === ApiStatus.Pending ? (
                  <CircularProgress size={15} sx={{ color: colors.primary }} />
                ) : null
              }
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <Typography
              className={classes.skipLabel}
              variant="body1"
              onClick={handleClose}
            >
              {t("activitySelectionModal.activitySelectionSkip")}
            </Typography>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
