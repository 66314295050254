import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";
import { colors } from "../../../app/theme/colors";

export const useActivitySelectionModalStyles = makeStyles()((theme: Theme) => ({
  dialog: {
    borderRadius: "15px",
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(2),
  },
  autocompleteTextField: {
    "& .MuiInputBase-root": {
      borderRadius: "5px",
      background: "white",
    },
  },
  autocomplete: {
    "& .MuiOutlinedInput-root.Mui-focused": {
      "& > fieldset": {
        borderColor: colors.sectionTitle,
      },
    },
  },
  gridContainer: {
    justifyContent: "center",
    alignItems: "center",
    padding: "5px",
    textAlign: "center",
  },
  logoImage: {
    width: "80%",
    height: "auto",
    margin: "auto",
  },
  customOutlinedInput: {
    label: "test",
    width: "100%",
    labelColor: colors.primary,
  },
  forgotPasswordText: {
    textAlign: "right",
    marginTop: "5px",
    color: "#635F5F",
    cursor: "pointer",
  },
  dialogActions: {
    paddingLeft: "25px",
    paddingRight: "25px",
    paddingBottom: "25px",
  },
  closeIcon: {
    color: "white",
  },
  visibilityIcon: {
    cursor: "pointer",
    color: colors.primary,
  },
  chip: {
    background: "white",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "#C8C7C7",
  },
  skipLabel: {
    textAlign: "center",
    fontSize: "12px",
    cursor: "pointer",
    color: "#635F5FAD",
  },
  buttonWrapper: {
    justifyContent: "center",
    alignItems: "center",
  },
  closeIconChip: {
    fontSize: "12px!important",
    color: "red!important",
  },
  motionDiv: {
    display: "flex",
    flexWrap: "wrap",
    gap: "10px",
    marginTop: "10px",
    justifyContent: "center",
  },
}));
