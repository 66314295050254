import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";

export const useContactFormStyles = makeStyles()((theme: Theme) => ({
  sectionTitle: {
    color: "white",
    fontWeight: "bold",
  },
  iconWrapper: {
    display: "flex",
  },
  icon: {
    color: "white",
    fontSize: "50px",
  },
  label: {
    color: "#F9F9F9",
    marginLeft: "5px",
    textDecoration: "none"
  },
}));
