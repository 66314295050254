import { Map as MapContainer, Marker } from "pigeon-maps";
import { colors } from "../../app/theme/colors";

export function Map() {
  return (
    <MapContainer
      height={400}
      defaultCenter={[44.769699225854566, 17.20874770236852]}
      defaultZoom={17}
    >
      <Marker
        width={50}
        height={50}
        color={colors.primary}
        anchor={[44.769699225854566, 17.20874770236852]}
      />
    </MapContainer>
  );
}
