import { Grid, styled, Typography, useTheme } from "@mui/material";
import aboutUs from "../../images/aboutUs.png";
import { colors } from "../../app/theme/colors";
import { useAboutUsSectionStyles } from "./aboutUsSectionStyles";
import { useAboutUsData } from "../../mockData/mockData";
import { useTranslation } from "react-i18next";

const GradientBackground = styled(Grid)(({ theme }) => ({
  alignItems: "center",
  backgroundRepeat: "no-repeat",
  backgroundColor: colors.primary,
  backgroundPosition: "center",
  padding: "40px",
  minHeight: "70vh",
  [theme.breakpoints.down("md")]: {
    padding: "20px",
  },
}));

export default function AboutUsSection() {
  const { t } = useTranslation();
  const { classes } = useAboutUsSectionStyles();
  const aboutData = useAboutUsData();

  return (
    <GradientBackground container>
      <Grid item xs={12} md={6} justifyContent="flex-start">
        <Typography variant="h4" className={classes.title}>
          {t("aboutUsPage.aboutTitle")}
        </Typography>
        {aboutData.map((about: any, index: number) => (
          <Typography key={index} variant="body1" className={classes.aboutText}>
            {about.text}
          </Typography>
        ))}
        <Typography className={classes.aboutText} sx={{ fontWeight: "bold" }}>
          {t("aboutUsPage.aboutLandmark")}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        container
        justifyContent="center"
        alignItems="center"
      >
        <img src={aboutUs} alt="about_us" className={classes.aboutUsImg} />
      </Grid>
    </GradientBackground>
  );
}
