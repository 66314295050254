import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/redux/store";
import { DisplayLanguage } from "../models/enums/displayLanguage";

const getCurrentLanguage = () => {
  let displayLanguage = DisplayLanguage.SerbianLatin;

  const localStorageTranslation = localStorage.getItem("i18nextLng");

  switch (localStorageTranslation) {
    case "rs":
      displayLanguage = DisplayLanguage.SerbianLatin;
      break;

    case "rsCyrl":
      displayLanguage = DisplayLanguage.SerbianCyrillic;
      break;

    case "en":
      displayLanguage = DisplayLanguage.English;
      break;

    case "en-US":
      displayLanguage = DisplayLanguage.English;
      break;

    default:
      displayLanguage = DisplayLanguage.SerbianLatin;
      break;
  }

  return displayLanguage;
};

type DisplayLanguageState = {
  displayLanguage: DisplayLanguage | null;
};

const initialState: DisplayLanguageState = {
  displayLanguage: getCurrentLanguage(),
};

export const selectDisplayLanguage = (state: RootState) =>
  state.displayLanguage.displayLanguage;

const displayLanguageSlice = createSlice({
  name: "displayLanguage",
  initialState,
  reducers: {
    setDisplayLanguage: (state, action) => {
      state.displayLanguage = action.payload;
    },
  },
});

export const { setDisplayLanguage } = displayLanguageSlice.actions;

export default displayLanguageSlice.reducer;
