import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/redux/store";
import { ApiStatus } from "../models/app/apiStatus";
import {
  saveMySiteText as saveMySiteApi,
  saveMySitePhone as saveMySitePhoneApi,
  saveMySitePhoto as saveMySitePhotoApi,
  saveMySiteVideo as mySiteVideoApi,
  saveMySiteSocialMedia as mySiteSocialMediaApi,
  saveMySiteGallery as mySiteGalleryApi,
  saveMySiteSlider as mySiteSliderApi,
  getCompanyWebsite as getCompanyWebsiteApi,
  removeWebsitePartition as removeWebsitePartitionApi,
  getMySiteView as getMySiteViewApi,
} from "../app/redux/mySiteApi";
import MySitePhotoModel from "../models/mySite/mySitePhotoModel";
import MySiteTextModel from "../models/mySite/mySiteTextModel";
import MySitePhoneModel from "../models/mySite/mySitePhoneModel";
import MySiteVideoModel from "../models/mySite/mySiteVideoModel";
import MySiteSocialMediaModel from "../models/mySite/mySiteSocialMediaModel";
import MySiteGalleryModel from "../models/mySite/mySiteGalleryModel";
import MySiteSliderModel from "../models/mySite/mySiteSliderModel";
import { MySiteDetailsResponse } from "../models/mySite/mySiteDetalisResponse";
import RemoveWebsitePartition from "../models/mySite/removeWebsitePartition";
import axios from "axios";
import { setErrorNotification, setPendingNotification, setSuccessNotification } from "./notificationSlice";
import i18n from "../i18n";

type MySiteState = {
  mySiteText: MySiteTextModel[] | null;
  mySitePhoto: MySitePhotoModel[] | null;
  mySitePhone: MySitePhoneModel[] | null;
  mySiteVideo: MySiteVideoModel[] | null;
  mySiteSocialMedia: MySiteSocialMediaModel[] | null;
  mySiteGallery: MySiteGalleryModel[] | null;
  mySiteSlider: MySiteSliderModel[] | null;
  mySiteDetails: MySiteDetailsResponse[] | null;
  mySiteView: MySiteDetailsResponse[] | null;
  details: string | null;
  statuses: {
    saveMySitePhoto: ApiStatus;
    saveMySitePhone: ApiStatus;
    saveMySiteText: ApiStatus;
    saveMySiteVideo: ApiStatus;
    saveMySiteSocialMedia: ApiStatus;
    saveMySiteGallery: ApiStatus;
    saveMySiteSlider: ApiStatus;
    removeWebsitePartition: ApiStatus;
    companyWebsite: ApiStatus;
    mySiteView: ApiStatus;
  };
};

const initialState: MySiteState = {
  mySiteText: null,
  mySitePhoto: null,
  mySitePhone: null,
  mySiteVideo: null,
  mySiteSocialMedia: null,
  mySiteGallery: null,
  mySiteSlider: null,
  mySiteDetails: null,
  mySiteView: null,
  details: null,
  statuses: {
    saveMySitePhoto: ApiStatus.Idle,
    saveMySitePhone: ApiStatus.Idle,
    saveMySiteText: ApiStatus.Idle,
    saveMySiteVideo: ApiStatus.Idle,
    saveMySiteSocialMedia: ApiStatus.Idle,
    saveMySiteGallery: ApiStatus.Idle,
    saveMySiteSlider: ApiStatus.Idle,
    removeWebsitePartition: ApiStatus.Idle,
    companyWebsite: ApiStatus.Idle,
    mySiteView: ApiStatus.Idle
  },
};

export const selectMySiteText = (state: RootState) => state.mySite.mySiteText;
export const selectMySitePhone = (state: RootState) => state.mySite.mySitePhone;
export const selectMySitePhoto = (state: RootState) => state.mySite.mySitePhoto;
export const selectMySiteVideo = (state: RootState) => state.mySite.mySiteVideo;
export const selectMySiteSocialMedia = (state: RootState) =>
  state.mySite.mySiteSocialMedia;
export const selectMySiteGallery = (state: RootState) =>
  state.mySite.mySiteGallery;
export const selectMySiteSlider = (state: RootState) =>
  state.mySite.mySiteSlider;

export const selectMySiteTextStatus = (state: RootState) =>
  state.mySite.statuses.saveMySiteText;
export const selectMySitePhoneStatus = (state: RootState) =>
  state.mySite.statuses.saveMySitePhone;
export const selectMySitePhotoStatus = (state: RootState) =>
  state.mySite.statuses.saveMySitePhoto;
export const selectMySiteVideoStatus = (state: RootState) =>
  state.mySite.statuses.saveMySiteVideo;
export const selectMySiteSocialMediaStatus = (state: RootState) =>
  state.mySite.statuses.saveMySiteSocialMedia;
export const selectMySiteGalleryStatus = (state: RootState) =>
  state.mySite.statuses.saveMySiteGallery;
export const selectMySiteSliderStatus = (state: RootState) =>
  state.mySite.statuses.saveMySiteSlider;

export const selectMySiteDetails = (state: RootState) =>
  state.mySite.mySiteDetails;

export const selectRemovedWebsitePartitionStatus = (state: RootState) =>
  state.mySite.statuses.removeWebsitePartition;

export const selectCompanyWebsiteStatus = (state: RootState) =>
  state.mySite.statuses.companyWebsite;

export const selectMySiteView = (state: RootState) => state.mySite.mySiteView;

export const selectMySiteViewStatus = (state: RootState) => state.mySite.statuses.mySiteView;

export const saveMySiteText = createAsyncThunk(
  "mySiteSlice/save_my_site",
  async (request: MySiteTextModel[], { rejectWithValue, dispatch }) => {
    try {
      dispatch(setPendingNotification(i18n.t("messageKey.MySiteText_Pending")));
      const response = await saveMySiteApi(request); 
      const result = response.data;
      if (result) {
        dispatch(
          setSuccessNotification(i18n.t("messageKey.MySiteSuccessMessageText_Success"))
        );
      }
      return result;
    } catch (error) {
      dispatch(
        setErrorNotification(i18n.t("messageKey.MySiteErrorMessageText_Error"))
      );
      console.error("Error while saving site text:", error);
      throw error; 
    }
  }
);

export const saveMySitePhone = createAsyncThunk(
  "mySiteSlice/save_my_site_phone",
  async (request: MySitePhoneModel[], { rejectWithValue, dispatch }) => {
    try {
      dispatch(setPendingNotification(i18n.t("messageKey.MySitePhone_Pending")));
      const response = await saveMySitePhoneApi(request); 
      const result = response.data;
      if (result) {
        
        dispatch(
          setSuccessNotification(i18n.t("messageKey.MySiteSuccessMessagePhone_Success"))
        );
      }
      return result;
    } catch (error) {
      dispatch(
        setErrorNotification(i18n.t("messageKey.MySiteErrorMessagePhone_Error"))
      );
      console.error("Error while saving site text:", error);
      throw error; 
    }
  }
);

export const saveMySitePhoto = createAsyncThunk(
  "mySiteSlice/save_my_site_photo",
  async (request: MySitePhotoModel[], { rejectWithValue, dispatch }) => {
    try {
      dispatch(setPendingNotification(i18n.t("messageKey.MySitePhoto_Pending")));
      const response = await saveMySitePhotoApi(request); 
      const result = response.data;
      if (result) {
        
        dispatch(
          setSuccessNotification(i18n.t("messageKey.MySiteSuccessMessagePhoto_Success"))
        );
      }
      return result;
    } catch (error) {
      dispatch(
        setErrorNotification(i18n.t("messageKey.MySiteErrorMessagePhoto_Error"))
      );
      console.error("Error while saving site text:", error);
      throw error; 
    }
  }
);

export const saveMySiteVideo = createAsyncThunk(
  "mySiteSlice/save_my_site_video",
  async (request: MySiteVideoModel[], { rejectWithValue, dispatch }) => {
    try {
      dispatch(setPendingNotification(i18n.t("messageKey.MySiteVideo_Pending")));
      const response = await mySiteVideoApi(request); 
      const result = response.data;
      if (result) {
        
        dispatch(
          setSuccessNotification(i18n.t("messageKey.MySiteSuccessMessageVideo_Success"))
        );
      }
      return result;
    } catch (error) {
      dispatch(
        setErrorNotification(i18n.t("messageKey.MySiteErrorMessageVideo_Error"))
      );
      console.error("Error while saving site text:", error);
      throw error; 
    }
  }
);

export const saveMySiteSocialMedia = createAsyncThunk(
  "mySiteSlice/save_my_site_social_media",
  async (request: MySiteSocialMediaModel[], { rejectWithValue, dispatch }) => {
    try {
      dispatch(setPendingNotification(i18n.t("messageKey.MySiteSocialMedia_Pending")));
      const response = await mySiteSocialMediaApi(request); 
      const result = response.data;
      if (result) {
        
        dispatch(
          setSuccessNotification(i18n.t("messageKey.MySiteSuccessMessageSocialMedia_Success"))
        );
      }
      return result;
    } catch (error) {
      dispatch(
        setErrorNotification(i18n.t("messageKey.MySiteErrorMessageSocialMedia_Error"))
      );
      console.error("Error while saving site text:", error);
      throw error; 
    }
  }
);

export const saveMySiteGallery = createAsyncThunk(
  "mySiteSlice/save_my_site_gallery",
  async (request: MySiteGalleryModel[], { rejectWithValue, dispatch }) => {
    try {
      dispatch(setPendingNotification(i18n.t("messageKey.MySiteGallery_Pending")));
      const response = await mySiteGalleryApi(request); 
      const result = response.data;
      if (result) {
        
        dispatch(
          setSuccessNotification(i18n.t("messageKey.MySiteSuccessMessageGallery_Success"))
        );
      }
      return result;
    } catch (error) {
      dispatch(
        setErrorNotification(i18n.t("messageKey.MySiteErrorMessageGallery_Error"))
      );
      console.error("Error while saving site text:", error);
      throw error; 
    }
  }
);

export const saveMySiteSlider = createAsyncThunk(
  "mySiteSlice/save_my_site_slider",
  async (request: MySiteSliderModel[], { rejectWithValue, dispatch }) => {
    try {
      dispatch(setPendingNotification(i18n.t("messageKey.MySiteSlider_Pending")));
      const response = await mySiteSliderApi(request); 
      const result = response.data;
      if (result) {
        
        dispatch(
          setSuccessNotification(i18n.t("messageKey.MySiteSuccessMessageSlider_Success"))
        );
      }
      return result;
    } catch (error) {
      dispatch(
        setErrorNotification(i18n.t("messageKey.MySiteErrorMessageSlider_Error"))
      );
      console.error("Error while saving site text:", error);
      throw error; 
    }
  }
);

export const removeWebsitePartition = createAsyncThunk(
  "mySiteSlice/remove_website_partition",
  async (request: RemoveWebsitePartition, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setPendingNotification(i18n.t("messageKey.MySitePartition_Pending")));
      const response = await removeWebsitePartitionApi(request); 
      const result = response.data;
      if (result) {
        
        dispatch(
          setSuccessNotification(i18n.t("messageKey.MySiteSuccessMessagePartition_Success"))
        );
      }
      return result;
    } catch (error) {
      dispatch(
        setErrorNotification(i18n.t("messageKey.MySiteErrorMessagePartition_Error"))
      );
      console.error("Error while saving site text:", error);
      throw error; 
    }
  }
);

export const getMySiteView = createAsyncThunk(
  "mySiteSlice/get_my_site_view",
  async (websiteName: string) => {
    const response = await getMySiteViewApi(websiteName);
    return response.data;
  }
);

export const fetchCompanyDetails = createAsyncThunk(
  "company/fetchCompanyDetails",
  async (companyName: string) => {
    const response = await axios.get(`/api/company/${companyName}`);
    return response.data;
  }
);

export const getCompanyWebsite = createAsyncThunk(
  "mySiteSlice/get_company_website",
  async () => {
    const response = await getCompanyWebsiteApi();
    return response.data;
  }
);

export const resetMySitePhoto = createAsyncThunk(
  "authSlice/reset_my_site_photo",
  async () => {
    return null;
  }
);

export const resetMySiteText = createAsyncThunk(
  "authSlice/reset_my_site_text",
  async () => {
    return null;
  }
);

export const resetMySitePhone = createAsyncThunk(
  "authSlice/reset_my_site_phone",
  async () => {
    return null;
  }
);

export const resetMySiteVideo = createAsyncThunk(
  "authSlice/reset_my_site_video",
  async () => {
    return null;
  }
);

export const resetMySiteSocialMedia = createAsyncThunk(
  "authSlice/reset_my_site_social_media",
  async () => {
    return null;
  }
);

export const resetMySiteGallery = createAsyncThunk(
  "authSlice/reset_my_site_gallery",
  async () => {
    return null;
  }
);

export const resetMySiteSlider = createAsyncThunk(
  "authSlice/reset_my_site_slider",
  async () => {
    return null;
  }
);

export const resetCompanyWebsiteStatus = createAsyncThunk(
  "authSlice/reset_company_website_status",
  async () => {
    return null;
  }
);


export const resetMySiteTextStatus = createAsyncThunk(
  "authSlice/reset_my_site_text_status",
  async () => {
    return initialState.statuses.saveMySiteText;
  }
);

export const resetMySitePhoneStatus = createAsyncThunk(
  "authSlice/reset_my_site_phone_status",
  async () => {
    return initialState.statuses.saveMySitePhone;
  }
);

export const resetMySitePhotoStatus = createAsyncThunk(
  "authSlice/reset_my_site_photo_status",
  async () => {
    return initialState.statuses.saveMySitePhoto;
  }
);

export const resetMySiteVideoStatus = createAsyncThunk(
  "authSlice/reset_my_site_video_status",
  async () => {
    return initialState.statuses.saveMySitePhoto;
  }
);

export const resetMySiteSocialMediaStatus = createAsyncThunk(
  "authSlice/reset_my_site_social_media_status",
  async () => {
    return initialState.statuses.saveMySiteSocialMedia;
  }
);

export const resetMySiteGalleryStatus = createAsyncThunk(
  "authSlice/reset_my_site_gallery_status",
  async () => {
    return initialState.statuses.saveMySiteGallery;
  }
);

export const resetMySiteSliderStatus = createAsyncThunk(
  "authSlice/reset_my_site_slider_status",
  async () => {
    return initialState.statuses.saveMySiteSlider;
  }
);

export const resetRemoveWebsitePartitionStatus = createAsyncThunk(
  "authSlice/reset_remove_website_partition",
  async () => {
    return initialState.statuses.saveMySiteSlider;
  }
);

export const resetMySiteViewStatus = createAsyncThunk(
  "authSlice/reset_my_site_view_status",
  async () => {
    return initialState.statuses.mySiteView;
  }
);

const mySiteSlice = createSlice({
  name: "mySite",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCompanyDetails.pending, (state) => {
      // state.status = "loading";
    });
    builder.addCase(fetchCompanyDetails.fulfilled, (state, action) => {
      // state.status = "succeeded";
      state.details = action.payload;
    });
    builder.addCase(fetchCompanyDetails.rejected, (state, action) => {
      // state.status = "failed";
      // state.error = action.error.message;
    });
    builder.addCase(saveMySitePhoto.pending, (state, action) => {
      state.statuses.saveMySitePhoto = ApiStatus.Pending;
    });
    builder.addCase(saveMySitePhoto.fulfilled, (state, action) => {
      state.mySitePhoto = action.payload;
      state.statuses.saveMySitePhoto = ApiStatus.Fulfilled;
    });
    builder.addCase(saveMySitePhoto.rejected, (state, action) => {
      state.statuses.saveMySitePhoto = ApiStatus.Rejected;
    });
    builder.addCase(saveMySitePhone.pending, (state, action) => {
      state.statuses.saveMySitePhone = ApiStatus.Pending;
    });
    builder.addCase(saveMySitePhone.fulfilled, (state, action) => {
      state.mySitePhone = action.payload;
      state.statuses.saveMySitePhone = ApiStatus.Fulfilled;
    });
    builder.addCase(saveMySitePhone.rejected, (state, action) => {
      state.statuses.saveMySitePhone = ApiStatus.Rejected;
    });
    builder.addCase(saveMySiteText.pending, (state, action) => {
      state.statuses.saveMySiteText = ApiStatus.Pending;
    });
    builder.addCase(saveMySiteText.fulfilled, (state, action) => {
      state.mySiteText = action.payload;
      state.statuses.saveMySiteText = ApiStatus.Fulfilled;
    });
    builder.addCase(saveMySiteText.rejected, (state, action) => {
      state.statuses.saveMySiteText = ApiStatus.Rejected;
    });
    builder.addCase(saveMySiteVideo.pending, (state, action) => {
      state.statuses.saveMySiteVideo = ApiStatus.Pending;
    });
    builder.addCase(saveMySiteVideo.fulfilled, (state, action) => {
      state.mySiteVideo = action.payload;
      state.statuses.saveMySiteVideo = ApiStatus.Fulfilled;
    });
    builder.addCase(saveMySiteVideo.rejected, (state, action) => {
      state.statuses.saveMySiteVideo = ApiStatus.Rejected;
    });
    builder.addCase(saveMySiteSocialMedia.pending, (state, action) => {
      state.statuses.saveMySiteSocialMedia = ApiStatus.Pending;
    });
    builder.addCase(saveMySiteSocialMedia.fulfilled, (state, action) => {
      state.mySiteSocialMedia = action.payload;
      state.statuses.saveMySiteSocialMedia = ApiStatus.Fulfilled;
    });
    builder.addCase(saveMySiteSocialMedia.rejected, (state, action) => {
      state.statuses.saveMySiteSocialMedia = ApiStatus.Rejected;
    });
    builder.addCase(saveMySiteGallery.pending, (state, action) => {
      state.statuses.saveMySiteGallery = ApiStatus.Pending;
    });
    builder.addCase(saveMySiteGallery.fulfilled, (state, action) => {
      state.mySiteGallery = action.payload;
      state.statuses.saveMySiteGallery = ApiStatus.Fulfilled;
    });
    builder.addCase(saveMySiteGallery.rejected, (state, action) => {
      state.statuses.saveMySiteGallery = ApiStatus.Rejected;
    });
    builder.addCase(saveMySiteSlider.pending, (state, action) => {
      state.statuses.saveMySiteSlider = ApiStatus.Pending;
    });
    builder.addCase(saveMySiteSlider.fulfilled, (state, action) => {
      state.mySiteSlider = action.payload;
      state.statuses.saveMySiteSlider = ApiStatus.Fulfilled;
    });
    builder.addCase(saveMySiteSlider.rejected, (state, action) => {
      state.statuses.saveMySiteSlider = ApiStatus.Rejected;
    });

    builder.addCase(getMySiteView.pending, (state, action) => {
      state.statuses.mySiteView = ApiStatus.Pending;
    });
    builder.addCase(getMySiteView.fulfilled, (state, action) => {
      state.mySiteView = action.payload;
      state.statuses.mySiteView = ApiStatus.Fulfilled;
    });
    builder.addCase(getMySiteView.rejected, (state, action) => {
      state.statuses.mySiteView = ApiStatus.Rejected;
    });

    builder.addCase(getCompanyWebsite.pending, (state, action) => {
      state.statuses.companyWebsite = ApiStatus.Pending;
    });
    builder.addCase(getCompanyWebsite.fulfilled, (state, action) => {
      state.mySiteDetails = action.payload;
      state.statuses.companyWebsite = ApiStatus.Fulfilled;
    });
    builder.addCase(getCompanyWebsite.rejected, (state, action) => {
      state.statuses.companyWebsite = ApiStatus.Rejected;
    });
    builder.addCase(removeWebsitePartition.pending, (state, action) => {
      state.statuses.removeWebsitePartition = ApiStatus.Pending;
    });
    builder.addCase(resetCompanyWebsiteStatus.fulfilled, (state, action) => {
      state.statuses.removeWebsitePartition =
        initialState.statuses.companyWebsite;
    });
    builder.addCase(removeWebsitePartition.fulfilled, (state, action) => {
      state.statuses.removeWebsitePartition = ApiStatus.Fulfilled;
    });
    builder.addCase(removeWebsitePartition.rejected, (state, action) => {
      state.statuses.removeWebsitePartition = ApiStatus.Rejected;
    });
    builder.addCase(resetMySiteTextStatus.fulfilled, (state, action) => {
      state.statuses.saveMySiteText = initialState.statuses.saveMySiteText;
    });
    builder.addCase(resetMySitePhoneStatus.fulfilled, (state, action) => {
      state.statuses.saveMySitePhone = initialState.statuses.saveMySitePhone;
    });
    builder.addCase(resetMySitePhoto.fulfilled, (state, action) => {
      state.mySitePhoto = initialState.mySitePhoto;
    });
    builder.addCase(resetMySitePhotoStatus.fulfilled, (state, action) => {
      state.statuses.saveMySitePhoto = initialState.statuses.saveMySitePhoto;
    });
    builder.addCase(resetMySiteVideo.fulfilled, (state, action) => {
      state.mySiteVideo = initialState.mySiteVideo;
    });
    builder.addCase(resetMySiteVideoStatus.fulfilled, (state, action) => {
      state.statuses.saveMySiteVideo = initialState.statuses.saveMySiteVideo;
    });
    builder.addCase(resetMySiteSocialMedia.fulfilled, (state, action) => {
      state.mySiteSocialMedia = initialState.mySiteSocialMedia;
    });
    builder.addCase(resetMySiteSocialMediaStatus.fulfilled, (state, action) => {
      state.statuses.saveMySiteSocialMedia =
        initialState.statuses.saveMySiteSocialMedia;
    });
    builder.addCase(resetMySiteGallery.fulfilled, (state, action) => {
      state.mySiteGallery = initialState.mySiteGallery;
    });
    builder.addCase(resetMySiteGalleryStatus.fulfilled, (state, action) => {
      state.statuses.saveMySiteGallery =
        initialState.statuses.saveMySiteGallery;
    });
    builder.addCase(resetMySiteSlider.fulfilled, (state, action) => {
      state.mySiteSlider = initialState.mySiteSlider;
    });
    builder.addCase(resetMySiteSliderStatus.fulfilled, (state, action) => {
      state.statuses.saveMySiteSlider = initialState.statuses.saveMySiteSlider;
    });
    builder.addCase(
      resetRemoveWebsitePartitionStatus.fulfilled,
      (state, action) => {
        state.statuses.removeWebsitePartition =
          initialState.statuses.removeWebsitePartition;
      }
    );
  },
});

export default mySiteSlice.reducer;
