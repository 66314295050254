import bank from "../../images/bank.png";
import municipality from "../../images/municipal.png";
import construction from "../../images/construction.png";
import industry from "../../images/industry.png";
import science from "../../images/science.png";
import education from "../../images/education.png";
import insurance from "../../images/insurance.png";
import logoT from "../../images/logoT_black.png";
import other from "../../images/other.png";
import tractor from "../../images/tractor.png";
import production from "../../images/production.png";
import traffic from "../../images/traffic.png";
import forestry from "../../images/forestry.png";
import trade from "../../images/trade.png";
import tourism from "../../images/tourism.png";
import services from "../../images/services.png";
import hammer from "../../images/hammer.png";
import medicine from "../../images/healt.png";

function GetImageByCategory( categoryName: any) {
  switch (categoryName) {
    case "BANKE":
      return bank;
    case "DRŽAVNI ORGANI":
      return municipality;
    case "GRAĐEVINARSTVO":
      return construction;
    case "INDUSTRIJA":
      return industry;
    case "NAUKA I KULTURA":
      return science;
    case "OBRAZOVANJE":
      return education;
    case "OSIGURAVAJUĆA DRUŠTVA":
      return insurance;
    case "OSTALE DJELATNOSTI":
      return other;
    case "POLJOPRIVREDA":
      return tractor;
    case "PROIZVODNJA":
      return production;
    case "SAOBRAĆAJ":
      return traffic;
    case "ŠUMARSTVO":
      return forestry;
    case "TRGOVINA":
      return trade;
    case "TURIZAM":
      return tourism;
    case "USLUGE":
      return services;
    case "ZANATSTVO":
      return hammer;
    case "ZDRAVSTVO":
      return medicine;
    default:
      return logoT;
  }
}

export default GetImageByCategory;
