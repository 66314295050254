import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";
import { colors } from "../../app/theme/colors";
import bosniaFlag from "../../images/ba-03.png";

export const useAdvertisementRequestSectionStyles = makeStyles()(
  (theme: Theme) => ({
    aboutText: {
      color: "white",
    },
    aboutUsImg: {
      width: "60%",
    },
    title: {
      color: colors.primary,
    },
    descTitle: {
      color: "white",
    },
    descText: {
      color: "white",
    },
    icon: {
      color: `${colors.green} !important`,
      marginRight: "10px",
      fill: "white",
    },
    formWrapper: {
      padding: "0 20px",
    },
    buttonWrapper: {
      padding: "10px 20px",
    },
    descriptionBackground: {
      background: `url(${bosniaFlag}) center/cover no-repeat`,
      backgroundSize: "80%",
    },
    descriptionWrapper: {
      display: "flex",
      alignItems: "center",
      padding: "0 20px",
    },
    autocompleteTextField: {
      "& .MuiInputBase-root": {
        borderRadius: "5px",
        background: "white",
      },
    },
    autocomplete: {
      "& .MuiOutlinedInput-root.Mui-focused": {
        "& > fieldset": {
          borderColor: colors.sectionTitle,
        },
      },
    },
    phoneSectionWrapper: {
      display: "flex",
      justifyContent: "flex-start",
      alignContent: "center",
      alignItems: "center",
    },
    errorMessage: {
      textAlign: "start",
      color: colors.validationErrorMessage,
      fontSize: 12,
    },
  })
);
