import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../app/redux/hooks";
import { useMySectionStyles } from "./mySiteSectionStyles";
import { colors } from "../../app/theme/colors";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import MySiteGalleryModel from "../../models/mySite/mySiteGalleryModel";

interface Props {
  handleClose: any;
  open: any;
  setSelectedFiles: any;
  selectedFiles: File[];
  mySiteGallerySection: MySiteGalleryModel[] | null;
  setMySiteGallerySection: any;
  selectedItems: any;
  clickedCube: string | null;
}

export default function UploadGalleryModal({
  handleClose,
  open,
  setSelectedFiles,
  selectedFiles,
  mySiteGallerySection,
  setMySiteGallerySection,
  selectedItems,
  clickedCube,
}: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { classes } = useMySectionStyles();

  const handleDialogClose = () => {
    handleClose();
  };
  
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      const newBase64Photos = Array.from(files).map((file) => {
        return new Promise<string>((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result as string);
          reader.onerror = (error) => reject(error);
        });
      });

      Promise.all(newBase64Photos)
        .then((base64Strings) => {
          const updatedGallerySection = mySiteGallerySection
            ? [...mySiteGallerySection]
            : [];

          if (clickedCube) {
            const galleryObjectIndex = updatedGallerySection.findIndex(
              (item) =>
                item.orderNumber === selectedItems[clickedCube]?.orderNumber
            );

            if (galleryObjectIndex !== -1) {
              updatedGallerySection[galleryObjectIndex].base64Photos = [
                ...(updatedGallerySection[galleryObjectIndex].base64Photos || []),
                ...base64Strings,
              ];
            } else {
              console.error(
                "Gallery object not found for order number:",
                selectedItems[clickedCube]?.orderNumber
              );
            }
          }

          setMySiteGallerySection(updatedGallerySection);
          setSelectedFiles(Array.from(files));
          handleClose();
        })
        .catch((error) => {
          console.error("Error reading file:", error);
        });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      fullWidth
      maxWidth="xs"
      id="rateUsModal_dialog"
      PaperProps={{
        sx: {
          borderRadius: "15px",
          overflow: "hidden",
          background: "rgba(210, 226, 252, 1)",
        },
      }}
    >
      <DialogContent>
        <Grid container justifyContent="center" alignItems="center">
          <Grid
            item
            md={12}
            display={"flex"}
            alignItems="center"
            sx={{ textAlign: "left" }}
          >
            <FileUploadIcon
              sx={{ fontSize: "50px", color: "rgba(133, 136, 144, 1)" }}
            />
            <Typography variant="body2" sx={{ ml: 1 }}>
              <b>{t("mySite.mySiteUploadImgModalTitle")}</b> <br />
              <Typography
                variant="body1"
                component="span"
                sx={{ color: "rgba(133, 136, 144, 0.7)" }}
              >
                {t("mySite.mySiteUploadImgModalSubtitle")}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        className={classes.dialogActions}
        sx={{ justifyContent: "center" }}
      >
        <Grid container>
          <Grid item md={12}>
            <input
              type="file"
              accept="image/*"
              id="fileInput"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <label htmlFor="fileInput">
              <button
                style={{
                  backgroundColor: colors.primary,
                  color: "#fff",
                  padding: "10px 20px",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                onClick={() => document.getElementById("fileInput")?.click()}
              >
                {t("mySite.mySiteUploadImgModalChoose")}
              </button>
            </label>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
