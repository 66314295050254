import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { colors } from "../../../app/theme/colors";
import AppButton from "../../../components/appButton/appButton";
import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import { useTranslation } from "react-i18next";
import { theme } from "../../../app/theme/theme";
import CloseIcon from "@mui/icons-material/Close";
import { selectCurrentAccount } from "../../../slices/authSlice";
import { useManageClientsAccountsModalStyles } from "./manageClientsAccountsModalStyles";
import CustomOutlinedInput from "../../../components/outlinedInput/customOutlinedInput";
import { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import AddClientAccountModal from "./addClientAccountModal";
import AddClientModel, {
  ClientAccount,
} from "../../../models/addClient/addClientModel";
import { EmptyGuid } from "../../../models/init_data/initData";
import {
  deleteClient,
  getClients,
  resetDeletedClientStatus,
  resetSaveClient,
  resetSaveClientStatus,
  saveClient,
  selectClients,
  selectDeletedClientStatus,
  selectSaveClientStatus,
} from "../../../slices/companySlice";
import { toast } from "react-toastify";
import DeleteClientAccountModel from "../../../models/addClient/deleteClientAccountModel";
import InfoIcon from "@mui/icons-material/Info";

interface Props {
  open: any;
  handleClose: any;
}
export default function ManageClientsAccountsModal({
  handleClose,
  open,
}: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { classes } = useManageClientsAccountsModalStyles();

  const currentAccount = useAppSelector(selectCurrentAccount);
  const clients = useAppSelector(selectClients) || [];
  const saveClientStatus = useAppSelector(selectSaveClientStatus);
  const deleteClientStatus = useAppSelector(selectDeletedClientStatus);

  const INIT_ADD_CLIENT: AddClientModel = {
    clientAccount: {
      associationId: EmptyGuid,
      companyId: EmptyGuid,
      email: "",
      firstName: "",
      id: EmptyGuid,
      isActive: false,
      isDeleted: false,
      lastName: "",
      phone: "",
      roleName: "User",
      rolePriority: 0,
    },
  };
  const INIT_DELETE_CLIENT: DeleteClientAccountModel = {
    id: EmptyGuid,
    isDeleted: true,
  };

  const [operationType, setOperationType] = useState<string>("");
  const [isOpenAddClientAccountModal, setIsOpenAddClientAccountModal] =
    useState<boolean>(false);
  const [addClient, setAddClient] = useState<AddClientModel>(INIT_ADD_CLIENT);
  const [deleteClientState, setDeleteClientState] =
    useState<DeleteClientAccountModel>(INIT_DELETE_CLIENT);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setAddClient((prevState) => ({
      ...prevState,
      clientAccount: {
        ...prevState.clientAccount,
        [name]: value,
      },
    }));
  };

  const handleDeleteClient = (id: string) => {
    setDeleteClientState((prevState) => ({
      ...prevState,
      id: id,
    }));
  };

  const handleSave = () => {
    dispatch(saveClient(addClient));
  };

  const handleDialogClose = () => {
    handleClose();
  };

  const handleEditClient = (client: ClientAccount) => {
    setOperationType("Update");
    setAddClient((prevState) => ({
      ...prevState,
      clientAccount: {
        ...prevState.clientAccount,
        firstName: client.firstName,
        lastName: client.lastName,
        phone: client.phone,
        associationId: client.associationId,
        companyId: client.companyId,
        email: client.email,
        id: client.id,
        isActive: client.isActive,
        isDeleted: client.isDeleted,
        roleName: client.roleName,
        rolePriority: client.rolePriority,
      },
    }));
    setIsOpenAddClientAccountModal(true);
  };

  useEffect(() => {
    if (saveClientStatus === "Fulfilled") {
      if (currentAccount?.companyId) {
        dispatch(getClients(currentAccount.companyId));
      }
      setAddClient(INIT_ADD_CLIENT);
      dispatch(resetSaveClient());
      setIsOpenAddClientAccountModal(false);
      dispatch(resetSaveClientStatus());
    }
  }, [saveClientStatus, operationType]);

  useEffect(() => {
    if (deleteClientStatus === "Fulfilled") {
      if (currentAccount?.companyId) {
        dispatch(getClients(currentAccount.companyId));
      }
      dispatch(resetDeletedClientStatus());
    }
  }, [deleteClientStatus]);

  useEffect(() => {
    if (currentAccount?.companyId) {
      dispatch(getClients(currentAccount.companyId));
    }
  }, [open]);

  useEffect(() => {
    if (deleteClientState.id !== EmptyGuid) {
      dispatch(deleteClient(deleteClientState));
    }
  }, [deleteClientState]);

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      fullWidth
      maxWidth="xl"
      id="rateUsModal_dialog"
      PaperProps={{ sx: { borderRadius: "15px", overflow: "hidden" } }}
    >
      <IconButton
        edge="end"
        color="inherit"
        onClick={handleDialogClose}
        aria-label="close"
        sx={{
          position: "absolute",
          top: theme.spacing(1),
          right: theme.spacing(2),
        }}
      >
        <CloseIcon className={classes.closeIcon} />
      </IconButton>
      <DialogContent className={classes.dialogContent}>
        <Grid
          container
          justifyContent="center"
          spacing={1}
          alignItems="center"
          sx={{ textAlign: "center" }}
        >
          <Grid item md={12}>
            <Typography
              variant="h4"
              sx={{
                color: colors.primary,
                fontWeight: "bold",
              }}
            >
              {t("manageClientsTable.manageClient")}
            </Typography>
          </Grid>
          <Grid item md={12} justifyContent={"flex-start"} display={"flex"}>
            <AppButton
              color={colors.primary}
              hover={colors.primary}
              label={t("manageClientsTable.addClient")}
              startIcon={<AddIcon />}
              onClick={() => {
                setAddClient(INIT_ADD_CLIENT);
                setOperationType("Add");

                setIsOpenAddClientAccountModal(true);
              }}
            />
            <Tooltip title={t("manageClientsTable.tooltip")}>
              <InfoIcon style={{ color: colors.primary, marginLeft: 3 }} />
            </Tooltip>
          </Grid>
          <Grid item md={12}>
            <TableContainer>
              <Table size="small" className={classes.table}>
                <TableHead id="clients_table_head">
                  <TableRow sx={{ color: "#635F5F", fontWeight: "bold" }}>
                    <TableCell className={classes.headTableCell}>
                      {t("widgetTable.widgetTableAction")}
                    </TableCell>
                    <TableCell className={classes.headTableCell}>
                      {t("manageClientsTable.firstName")}
                    </TableCell>
                    <TableCell className={classes.headTableCell}>
                      {t("manageClientsTable.lastName")}
                    </TableCell>
                    <TableCell className={classes.headTableCell}>
                      {t("manageClientsTable.email")}
                    </TableCell>
                    <TableCell className={classes.headTableCell}>
                      {t("manageClientsTable.phone")}
                    </TableCell>
                    <TableCell className={classes.headTableCell} colSpan={2}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {t("buttons.delete")}
                      </div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {clients.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        border: "none",
                        "&:nth-of-type(odd)": {
                          backgroundColor:
                            index % 2 === 0
                              ? "rgba(66, 133, 244, 0.1)"
                              : "#FFFFFF",
                        },
                      }}
                    >
                      <TableCell className={classes.tableCell}>
                        <Tooltip title={t("manageClientsTable.updateClient")}>
                          <IconButton
                            id="clients_table_icon_edit"
                            onClick={() => handleEditClient(row)}
                          >
                            <EditIcon sx={{ color: colors.primary }} />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.tableCell}
                      >
                        {row?.firstName}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {row?.lastName}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {row?.email}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {row?.phone}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableCell}
                        sx={{ padding: 0 }}
                      >
                        <Tooltip
                          title={`${t("manageClientsTable.deleteTooltip")} ${
                            row.firstName
                          } ${row.lastName}.`}
                        >
                          <IconButton
                            id="clients_table_icon_delete"
                            onClick={() => handleDeleteClient(row.id)}
                          >
                            <DeleteIcon className={classes.deleteIcon} />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        className={classes.dialogActions}
        sx={{ justifyContent: "center" }}
      >
        <Grid
          container
          display={"flex"}
          justifyContent={"flex-end"}
          spacing={1}
        >
          <Grid item>
            <AppButton
              id="rateUsModal_button_close"
              color={colors.primary}
              hover={colors.primary}
              label={t("buttons.close")}
              onClick={handleClose}
            />
          </Grid>
        </Grid>
      </DialogActions>
      <AddClientAccountModal
        handleClose={() => setIsOpenAddClientAccountModal(false)}
        open={isOpenAddClientAccountModal}
        addClient={addClient}
        handleInputChange={handleInputChange}
        handleSave={handleSave}
        saveClientStatus={saveClientStatus}
        operationType={operationType}
      />
    </Dialog>
  );
}
