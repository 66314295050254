import { ApiResponse } from "../../models/app/apiResponse";
import { CategoryShortDetail } from "../../models/company/categoryShortDetail";
import { CompanyEntity } from "../../models/company/companyEntity";
import { CompanyClientsRequest } from "../../models/company/companyClientsRequest";
import ActivitiesResponseModel from "../../models/onboarding/activitesResponseModel";
import ActivitiesRequestModel from "../../models/onboarding/activitiesRequestModel";
import { api_um } from "./api_um";
import { api_poslovni_imenik } from "./api_poslovni_imenik";

export const saveActivities: any = async (request: ActivitiesRequestModel) => {
  return api_poslovni_imenik.post("/account-category", request) as Promise<
    ApiResponse<ActivitiesResponseModel | null>
  >;
};

export const getMainActivities: any = async () => {
  return api_poslovni_imenik.get(
    "/category/get-all-main-categories"
  ) as Promise<ApiResponse<CategoryShortDetail | null>>;
};

export const updateAfterSelectingCategories: any = async () => {
  return api_um.post(
    "/client-accounts/update-after-selecting-categories"
  ) as Promise<ApiResponse<any>>;
};

export const getAccountCompanyClients: any = async (
  request: CompanyClientsRequest
) => {
  return api_poslovni_imenik.post(
    "/account-company-client/get",
    request
  ) as Promise<ApiResponse<CompanyEntity>>;
};

export const getAccountFavorite: any = async (
  request: CompanyClientsRequest
) => {
  return api_poslovni_imenik.post("/account-favorite/get", request) as Promise<
    ApiResponse<CompanyEntity>
  >;
};

export const getAccountPartnerCompanies: any = async (
  request: CompanyClientsRequest
) => {
  return api_poslovni_imenik.post(
    "/account-partner-company/get",
    request
  ) as Promise<ApiResponse<CompanyEntity>>;
};

export const getAccountPotentialPartnerCompanies: any = async (
  request: CompanyClientsRequest
) => {
  return api_poslovni_imenik.post(
    "/account-potential-partner-company/get",
    request
  ) as Promise<ApiResponse<CompanyEntity>>;
};

export const deleteAccountFavorite: any = async (companyId: string) => {
  return api_poslovni_imenik.delete(
    "/account-favorite?companyId=" + companyId
  ) as Promise<ApiResponse<[]>>;
};

export const deleteAccountClients: any = async (companyId: string) => {
  return api_poslovni_imenik.delete(
    "/account-company-client?companyId=" + companyId
  ) as Promise<ApiResponse<[]>>;
};

export const deleteAccountPartner: any = async (companyId: string) => {
  return api_poslovni_imenik.delete(
    "/account-partner-company?companyId=" + companyId
  ) as Promise<ApiResponse<[]>>;
};

export const deleteAccountPotentialPartner: any = async (companyId: string) => {
  return api_poslovni_imenik.delete(
    "/account-potential-partner-company?companyId=" + companyId
  ) as Promise<ApiResponse<[]>>;
};
