import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useLanguageSwithcherStyles = makeStyles()((theme: Theme) => ({
  select: {
    ".MuiSelect-select.MuiSelect-outlined": {
      border: "none",
    },
    ".MuiInputBase-root.MuiOutlinedInput-root": {
      border: "none",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiSelect-icon": {
      marginRight: theme.spacing(1),
    },
  },
  menuItem: {
    display: "flex",
    alignItems: "center",
  },
  countryIcon: {
    borderRadius: "20%",
    marginRight: theme.spacing(1),
    width: 18,
  },
  menuItemText: {
    flexGrow: 1,
  },
}));
