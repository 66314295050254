import { Grid, Rating, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { useDashboardSectionStyles } from "./dashboardSectionStyles";
import DashboardRatingChart from "./dashboardRatingChart";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../app/redux/hooks";
import { selectRatingForDashboard } from "../../slices/dashboardSlice";

interface Props {
  title: string;
  count: string;
  id: string;
  onClick: any;
  numberOfVisits: string;
}

export const DashboardRatingCard = ({
  title,
  count,
  id,
  onClick,
  numberOfVisits,
}: Props) => {
  const { t } = useTranslation();
  const { classes } = useDashboardSectionStyles();
  const ratingForDashboard = useAppSelector(selectRatingForDashboard);
  
  const determineRating = (numberOfVisits: string) => {
    const visits = parseInt(numberOfVisits);
    if (ratingForDashboard) {
      if (ratingForDashboard?.numberOfRatings === 1) {
        return t("dashboardRatingCard.ratingOne");
      } else if (
        ratingForDashboard?.numberOfRatings >= 2 &&
        ratingForDashboard?.numberOfRatings <= 4
      ) {
        return t("dashboardRatingCard.ratingTwo");
      } else if (
        ratingForDashboard?.numberOfRatings >= 5 &&
        ratingForDashboard?.numberOfRatings <= 10
      ) {
        return t("dashboardRatingCard.ratingThree");
      } else if (
        ratingForDashboard?.numberOfRatings >= 11 &&
        ratingForDashboard?.numberOfRatings <= 20
      ) {
        return t("dashboardRatingCard.ratingThree");
      } else if (
        ratingForDashboard?.numberOfRatings % 100 !== 11 &&
        ratingForDashboard?.numberOfRatings % 10 === 1
      ) {
        return t("dashboardRatingCard.ratingOne");
      } else if (
        [11, 12, 13, 14, 15, 16, 17, 18, 19].includes(
          ratingForDashboard?.numberOfRatings % 100
        )
      ) {
        return t("dashboardRatingCard.ratingThree");
      } else if (
        ratingForDashboard?.numberOfRatings % 10 === 0 ||
        [5, 6, 7, 8, 9].includes(ratingForDashboard?.numberOfRatings % 10)
      ) {
        return t("dashboardRatingCard.ratingThree");
      } else {
        return t("dashboardRatingCard.ratingTwo");
      }
    }
  };

  return (
    <motion.div id={id} onClick={onClick} className={classes.ratingCardWrapper}>
      <Grid item md={12} className={classes.ratingCardTitleWrapper}>
        <div className={classes.ratingCardTitleDiv}>
          <Typography variant="h4" className={classes.title}>
            {title}
          </Typography>
          <Typography variant="body2" className={classes.subtitle}>
            2023
          </Typography>
        </div>
      </Grid>
      <Grid item md={12} mt={2} className={classes.ratingCardContentWrapper}>
        <Typography variant="h5" sx={{ color: "#635F5FBA" }}>
          {t("dashboardRatingCard.ratingContentFirstPart")}{" "}
          <b>{ratingForDashboard?.numberOfRatings}</b>{" "}
          {determineRating(numberOfVisits)}{" "}
          {t("dashboardRatingCard.ratingContentSecondPart")}
        </Typography>
      </Grid>
      <Grid item md={12} className={classes.ratingCardContentWrapper}>
        <div className={classes.ratingCardValueWrapper}>
          <Typography variant="h3" className={classes.ratingCardValue}>
            {ratingForDashboard?.rating}
          </Typography>
          <Rating
            name="read-only"
            value={ratingForDashboard?.rating || 0}
            precision={0.1}
            readOnly
            sx={{ ml: 1, verticalAlign: "middle", marginBottom: 1.4 }}
          />
        </div>
      </Grid>
      <Grid item md={12} mt={2} className={classes.ratingCardContentWrapper}>
        <DashboardRatingChart ratingForDashboard={ratingForDashboard} />
      </Grid>
    </motion.div>
  );
};
