import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/redux/store";
import { ApiStatus } from "../models/app/apiStatus";
import {
  getCompanyRatingForDashboard as getCompanyRatingForDashboardApi,
  getMySiteVisitsStatistic as getMySiteVisitsStatisticApi,
} from "../app/redux/dashboardApi";
import DashboardRatingResponse from "../models/dashboard/dashboardRatingResponse";
import DashboardWebsiteVisitsStatisticResponse from "../models/dashboard/dashboardWebsiteVisitsStatisticResponse";

type OnboardingState = {
  ratingForDashboard: DashboardRatingResponse | null;
  mySiteStatistic: DashboardWebsiteVisitsStatisticResponse[] | null,
  statuses: {
    savePartner: ApiStatus;
  };
};

const initialState: OnboardingState = {
  ratingForDashboard: null,
  mySiteStatistic: null,
  statuses: {
    savePartner: ApiStatus.Idle,
  },
};

export const selectRatingForDashboard = (state: RootState) =>
  state.dashboard.ratingForDashboard;
export const selectMySiteStatistic = (state: RootState) =>
  state.dashboard.mySiteStatistic;

export const getCompanyRatingForDashboard = createAsyncThunk(
  "dashboardSlice/get_rating",
  async () => {
    const response = await getCompanyRatingForDashboardApi();
    return response.data;
  }
);

export const getMySiteVisitsStatistic = createAsyncThunk(
  "dashboardSlice/get_mySite_statistic",
  async (request: number) => {
    const response = await getMySiteVisitsStatisticApi(request);
    return response;
  }
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCompanyRatingForDashboard.fulfilled, (state, action) => {
      state.ratingForDashboard = action.payload;
    });
    builder.addCase(getMySiteVisitsStatistic.fulfilled, (state, action) => {
      state.mySiteStatistic = action.payload;
    });
  },
});

export default dashboardSlice.reducer;
