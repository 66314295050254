import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/redux/store";
import { CategoryShortDetail } from "../models/company/categoryShortDetail";
import { Categories } from "../app/redux/poslovniImenikApi";
import {
  getAccountCategories as getAccountCategoriesApi,
  getAllSubcategories as getAllSubcategoriesApi,
  getAccountSubcategories as getAccountSubcategoriesApi,
} from "../app/redux/searchResultsActionsApi";
import GetAllSubcategoriesRequest from "../models/categories/accountSubcategoriesRequest";
import AccountSubcategoryModel from "../models/categories/accountSubcategoryModel";

type CategoryState = {
  categoryShortDetails: CategoryShortDetail[] | null;
  acocuntCategories: CategoryShortDetail[] | null;
  accountSubcategories: AccountSubcategoryModel[] | null;
  subcategories: AccountSubcategoryModel[] | null;
};

const initialState: CategoryState = {
  categoryShortDetails: null,
  acocuntCategories: null,
  accountSubcategories: null,
  subcategories: null,
};

export const selectCategoryShortDetails = (state: RootState) =>
  state.category.categoryShortDetails;

export const selectAccountCategories = (state: RootState) =>
  state.category.acocuntCategories;

export const selectAccountSubcategories = (state: RootState) =>
  state.category.accountSubcategories;

export const selectSubcategories = (state: RootState) =>
  state.category.subcategories;

export const getAllCategoryShortDetails = createAsyncThunk(
  "Category/Get_Category_Short_Details",
  async (_: void) => {
    const response = await Categories.GetAllCategoryShortDetails();
    return response.data;
  }
);

export const getAccountCategories = createAsyncThunk(
  "Category/Get_Account_Categories",
  async () => {
    const response = await getAccountCategoriesApi();
    return response.data;
  }
);

export const getAllSubcategories = createAsyncThunk(
  "Category/Get_All_Subcategories",
  async (request: GetAllSubcategoriesRequest) => {
    const response = await getAllSubcategoriesApi(request);
    return response.data;
  }
);
export const getAccountSubcategories = createAsyncThunk(
  "Category/Get_Account_Subcategories",
  async () => {
    const response = await getAccountSubcategoriesApi();
    return response.data;
  }
);
const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllCategoryShortDetails.fulfilled, (state, action) => {
      state.categoryShortDetails = action.payload;
    });
    builder.addCase(getAccountCategories.fulfilled, (state, action) => {
      state.acocuntCategories = action.payload;
    });
    builder.addCase(getAllSubcategories.fulfilled, (state, action) => {
      state.accountSubcategories = action.payload;
    });
    builder.addCase(getAccountSubcategories.fulfilled, (state, action) => {
      state.subcategories = action.payload;
    });
  },
});

export default categorySlice.reducer;
