import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";

export const usePdfViewPageStyles = makeStyles()((theme: Theme) => ({
  container: {
    width: "100%",
    overflowX: "hidden",
    background: "black",
  },
  header: {
    width: "100%",
  },
  footerContainer: {
    bottom: 0,
    left: 0,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));
