export const formatTimeTo24Hours = (time: string) => {
  const timeParts = time.split(" ");
  const hoursMinutes = timeParts[0].split(":");
  let hours = parseInt(hoursMinutes[0]);
  const minutes = parseInt(hoursMinutes[1]);

  if (timeParts[1].toLowerCase() === "pm" && hours < 12) {
    hours += 12;
  } else if (timeParts[1].toLowerCase() === "am" && hours === 12) {
    hours = 0;
  }

  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;
};
