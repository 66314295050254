import belPrintLogo from "../images/belPrintLogo.jpg";
import bmbLogo from "../images/bmb.png";
import hotelAlmiraLogo from "../images/hotelAlmira.png";
import hotelDamisLogo from "../images/hotelDamisLogo.png";
import hotelMatic from "../images/hotelMatic.png";
import catalog from "../images/koriceKatalog.png";
import cosmopolit from "../images/cosmopolit.jpg";
import autootpad from "../images/autootpa.jpg";
import tourism from "../images/tourism.png";
import dentis from "../images/dentis.png";
import building from "../images/building.png";
import fodder from "../images/fodder.png";
import devices from "../images/devices.png";
import electronicEdition from "../images/electronic_editions.png";
import {
  bmbImages,
  hotelAlmiraImages,
  printImages,
} from "../features/imageGallery/imageGalleryData";
import { ActivityTypes } from "./activityTypes";
import { useTranslation } from "react-i18next";

const activityBackground = {
  tourism: tourism,
  dentis: dentis,
  building: building,
  fodder: fodder,
};
export const cardData = [
  {
    id: "49a690ca-3a20-451f-b452-2c496766f8ba",
    logo: bmbLogo,
    title: "BMB GROUP DOO PJ KONCEPT",
    city: "Banja Luka",
    web: "http://www.konceptbmb.ba",
    email: "info@bmbgrop.com",
    street: "Jovana Dučića 74C",
    secondWeb: "http://www.konceptbmb.ba",
    phone: " 051/923 141",
    secondEmail: "koncept.bmb@gmail.com",
    fax: "051/932 140",
    thirdEmail: "koncept.bmb@gmail.com",
    images: bmbImages,
    orderPriority: "free",
    activity: ActivityTypes.Production,
    starRaiting: null,
    description:
      "ARHITEKTONSKO-DIZAJNERSKI PRODAJNI CENTAR,MULTIDISCIPLINARNI PRISTUP U OBLASTI PROJEKTOVANJA,UNUTRAŠNJEG UREĐENJA,OPREMANJA I KONSALTINGA,STRUČNOG NADZORA U JAVNIM I PRIVATNIM OBJEKTIMA",
  },
  {
    id: "ed569455-78d5-4e56-a19c-42b05ebcab73",
    orderPriority: "paid",
    logo: belPrintLogo,
    title: "BEL PRINT DOO",
    city: "Čelinac",
    web: "http://www.belcafe.ba",
    email: "tripak@teol.net",
    street: "Majdanpečka 9",
    secondWeb: "http://www.tripak.ba",
    phone: "051/923 141",
    secondEmail: "koncept.bmb@gmail.com",
    fax: "051/555 557",
    thirdEmail: "info@belcafe.ba",
    images: printImages,
    activity: ActivityTypes.Production,
    starRaiting: null,
    description:
      "PREDUZEĆE ZA PRERADU KAFE, Partner:TRIPAK DOO, RESTORAN I SVADBENI SALON ATOS ",
  },
  {
    id: "86b3897e-120c-4de1-8a6d-c50d35205277",
    orderPriority: "paid",
    logo: hotelAlmiraLogo,
    title: "HOTEL ALMIRA",
    city: "Mostar",
    web: "http://www.almira-hotel.ba",
    email: "info@almira-hotel.ba",
    street: "Rade Bitange bb",
    secondWeb: "http://www.almira-travel.ba",
    phone: "036/554 310",
    secondEmail: "koncept.bmb@gmail.com",
    fax: "051/555 557",
    thirdEmail: "a.travel@bih.net.ba",
    images: hotelAlmiraImages,
    activity: ActivityTypes.Tourism,
    starRaiting: 4,
    description:
      "Hotelski smještaj i usluga,gastro ponuda u sklopu hotela,Turistička agencija Almira travel-turistički aranžmani,putovanja u svijetu,prodaja smještaja i avio karata,turistički izleti(Međugorje,Počitelj,Blagaj,Vodopad Kravica),Tranfer od aerodroma",
  },
  {
    id: "d1415f3a-5759-4e06-b683-a80d28049042",
    orderPriority: "paid",
    logo: cosmopolit,
    title: "HOTEL COSMOPOLIT",
    city: "Sarajevo",
    web: "http://www.cosmopolithotel.com/",
    email: "info@almira-hotel.ba",
    street: "Radićeva 15",
    secondWeb: "http://www.almira-travel.ba",
    phone: "033/251 100",
    secondEmail: "koncept.bmb@gmail.com",
    fax: "051/555 557",
    thirdEmail: "a.travel@bih.net.ba",
    images: hotelAlmiraImages,
    activity: ActivityTypes.Tourism,
    starRaiting: 3,
    description:
      "HOTEL NUDI KLIMATIZOVANE SOBE,BESPLATAN WI-FI,RESTORAN SA JELIMA NACIONALNE KUHINJE,BESPLATAN PARKING I PRISTUP OSOBAMA SA INVALIDITETOM,ORGANIZOVANJE OBILASKA GRADA.",
  },
  {
    id: "f8a54aad-5d0f-41c8-a7f9-e52607bd5f8b",
    orderPriority: "free",
    logo: hotelDamisLogo,
    title: "HOTEL DAMIS",
    city: "Pale",
    web: "http://www.hoteldamis.com",
    email: "recepcija@hoteldamis.com",
    street: "Karađorđeva 20",
    secondWeb: "http://www.almira-travel.ba",
    phone: "036/554 310",
    secondEmail: "koncept.bmb@gmail.com",
    fax: "051/555 557",
    thirdEmail: "a.travel@bih.net.ba",
    images: hotelAlmiraImages,
    activity: ActivityTypes.Tourism,
    starRaiting: 5,
    description:
      "HOTEL SA 3***-HOTEL RASPOLAŽE SA 70LEŽAJA RASPOREĐENIH U 4 APARTMANA,2 DUPLEKSA I 22 SOBE,SALA ZA SEMINARE KAPACITETA 60 MJESTA I KONGRESE DO 120 MJESTA,RESTORAN RASPOLAŽE SA 250 KONZUMNIH MJESTA I SPECIJALITETIMA DOMAĆE KUHINJE",
  },
  {
    id: "bccd8f72-cf16-45c7-a40b-85ada511ce2b",
    orderPriority: "free",
    logo: autootpad,
    title: "AUTO OTPAD MARIC",
    city: "Laktaši",
    web: "http://www.autootpadmaric.com/",
    email: "recepcija@hoteldamis.com",
    street: "Klašnice 20",
    secondWeb: "http://www.almira-travel.ba",
    phone: "036/554 310",
    secondEmail: "koncept.bmb@gmail.com",
    fax: "051/555 557",
    thirdEmail: "a.travel@bih.net.ba",
    images: hotelAlmiraImages,
    starRaiting: null,
    activity: ActivityTypes.Services,
    description:
      "HOTEL SA 3***-HOTEL RASPOLAŽE SA 70LEŽAJA RASPOREĐENIH U 4 APARTMANA,2 DUPLEKSA I 22 SOBE,SALA ZA SEMINARE KAPACITETA 60 MJESTA I KONGRESE DO 120 MJESTA,RESTORAN RASPOLAŽE SA 250 KONZUMNIH MJESTA I SPECIJALITETIMA DOMAĆE KUHINJE",
  },
  {
    id: "1e4f3278-2453-4a45-af73-9e20b4685324",
    orderPriority: "free",
    logo: hotelMatic,
    title: "HOTEL VILLA MATIĆ",
    city: "Neum",
    web: "http://www.ashaauto.com",
    email: "ashaauto@ashaauto.com",
    street: "Zagrebačka 16",
    secondWeb: "http://www.almira-travel.ba",
    phone: "036/554 310",
    secondEmail: "koncept.bmb@gmail.com",
    fax: "051/555 557",
    thirdEmail: "",
    images: hotelAlmiraImages,
    activity: ActivityTypes.Tourism,
    starRaiting: 2,
    description:
      "MODERNO OPREMLJEN SMJEŠTAJ SVEGA 100m UDALJENOSTI OD PLAŽE JE GARANCIJA UGODNOG I NEZABORAVNOG BORAVKA U NEUMU.U RESTORANU SE SLUŽE SPECIJALITETI DOMAĆE KUHINJE,INTERNACIONALNE,VRHUNSKI RIBLJI I MESNI SPECIJALITETI I VRHUNSKA VINA",
  },
];

export const useAboutUsData = () => {
  const { t } = useTranslation();
  return [
    {
      text: t("aboutUsPage.aboutText"),
    },
    {
      text: t("aboutUsPage.aboutTextSecond"),
    },
    {
      text: t("aboutUsPage.aboutTextThird"),
    },
    {
      text: t("aboutUsPage.aboutTextFourth"),
    },
  ];
};

export const useDigitalMarketingData = () => {
  const { t } = useTranslation();

  return [
    {
      text: t("digitalMarketingPage.digitalMarketingText"),
    },
    {
      text: t("digitalMarketingPage.digitalMarketingTextSecond"),
    },
    {
      text: t("digitalMarketingPage.digitalMarketingTextThird"),
    },
  ];
};

export const useEditionsData = () => {
  const { t } = useTranslation();
  return [
    {
      id: "ed569455-78d5-4e56-a19c-42b05ebcab73",
      logo: devices,
      title: t("editionsPage.onlineTitle"),
      text: t("editionsPage.onlineText"),
      text2: t("editionsPage.onlineTextSecond"),
      text3: t("editionsPage.onlineTextThird"),
      text4: t("editionsPage.onlineTextFourth"),
      text5: t("editionsPage.onlineTextFifth"),
      hasButton: false,
      width: "75%",
    },
    {
      id: "49a690ca-3a20-451f-b452-2c496766f8ba",
      logo: catalog,
      title: t("editionsPage.catalogTitle"),
      textFirst: t("editionsPage.catalogTextFirst"),
      text2: t("editionsPage.catalogTextSecond"),
      text3: t("editionsPage.catalogTextThird"),
      hasButton: true,
      width: "30%",
    },
    {
      id: "ed569455-78d5-4e56-a19c-42b05ebcab73",
      logo: electronicEdition,
      title: t("editionsPage.cdTitle"),
      text: t("editionsPage.cdText"),
      text2: t("editionsPage.cdTextSecond"),
      text3: t("editionsPage.cdTextThird"),
      text4: t("editionsPage.cdTextFourth"),
      text5: t("editionsPage.cdTextFifth"),
      hasButton: false,
      width: "80%",
    },
  ];
};

export const textualAdvertisingData = [
  {
    id: "bfb5a92b-2786-4322-9428-1a6526047c49",
    title: "ORALNA HIRURGIJA - Banja Luka",
    description:
      "SPECIJALIZOVANA AMBULANTA ZA UGRADNJU IMPLANTATA I IMPLANTOPROTETIKU. MODERNO OPREMLJENA HIRURŠKA SALA PO SVJETSKIM STANDARDIMA. USLUGE KOJE NAŠI PACIJENTI MOGU DOBITI SU: IMPLANTOLOGIJA,ORALNA HIRURGIJA,STOMATOLOŠKA PROTETIKA,ESTETSKA I OPŠTA STOMATOLO",
    orderPriority: "free",
    activity: activityBackground.dentis,
    companyTitle: "ORALNA HIRURGIJA IMPLANTODENT",
  },
  {
    id: "817bf6ea-2531-43a9-bb52-75909c2fdd54",
    title: "STOČNA HRANA - Rapić",
    description:
      "Proizvodnja i prodaja stočne hrane- potpune i dopunske krmne smjese za perad,goveda i svinje,PJ. Farmofit-Fabrika stočne hrane,Poljoprivredna apoteka,Transport stočne hrane vlastititim kamionima.",
    isPremium: false,
    categoryIcon: activityBackground.fodder,
    companyTitle: "RAPIĆ DOO-Kompanija",
  },
  {
    id: "fad07f29-2f02-4333-9ff8-569b0c44ae96",
    title: "BUŠENJA I MINIRANJA",
    description:
      "IZVOĐENJE RADOVA BUŠENJA I MINIRANJA(U OBAVLJANJU RADOVA KORISTIMO VLASTITE BUŠAĆE GARNITURE),RASPOLAGANJE VOZILIMA ZA PREVOZ EKSPLOZIVNIH SREDSTAVA(ATEST ADR STANDARD). IZRADA MINSKO-EKSPLOZIVNIH SREDSTAVA(INDUSTRIJSKI EKSPLOZIV)U RUDNICIMA.",
    orderPriority: "paid",
    activity: activityBackground.building,
    companyTitle: "D.D. MINEKS DOO",
  },
  {
    id: "d5600d7b-acae-40fe-bf1d-31854fc040c9",
    title: "HOTEL VILLA MATIĆ, NEUM",
    description:
      "MODERNO OPREMLJEN SMJEŠTAJ SVEGA 100m UDALJENOSTI OD PLAŽE JE GARANCIJA UGODNOG I NEZABORAVNOG BORAVKA U NEUMU. U RESTORANU SE SLUŽE SPECIJALITETI DOMAĆE KUHINJE,INTERNACIONALNE,VRHUNSKI RIBLJI I MESNI SPECIJALITETI I VRHUNSKA VINA",
    orderPriority: "free",
    activity: activityBackground.tourism,
    companyTitle: "HOTEL VILLA MATIĆ",
  },

  {
    id: "282ce64b-7a3a-4175-8de0-7b8a0adbd671",
    title: "HOTEL VILLA MATIĆ, NEUM",
    description:
      "MODERNO OPREMLJEN SMJEŠTAJ SVEGA 100m UDALJENOSTI OD PLAŽE JE GARANCIJA UGODNOG I NEZABORAVNOG BORAVKA U NEUMU. U RESTORANU SE SLUŽE SPECIJALITETI DOMAĆE KUHINJE,INTERNACIONALNE,VRHUNSKI RIBLJI I MESNI SPECIJALITETI I VRHUNSKA VINA",
    orderPriority: "free",
    activity: activityBackground.tourism,
    companyTitle: "HOTEL VILLA MATIĆ",
  },

  {
    id: "342ce09c-f3da-4d98-a22b-d354c261360b",
    title: "HOTEL VILLA MATIĆ, NEUM",
    description:
      "MODERNO OPREMLJEN SMJEŠTAJ SVEGA 100m UDALJENOSTI OD PLAŽE JE GARANCIJA UGODNOG I NEZABORAVNOG BORAVKA U NEUMU. U RESTORANU SE SLUŽE SPECIJALITETI DOMAĆE KUHINJE,INTERNACIONALNE,VRHUNSKI RIBLJI I MESNI SPECIJALITETI I VRHUNSKA VINA",
    orderPriority: "free",
    activity: activityBackground.tourism,
    companyTitle: "HOTEL VILLA MATIĆ",
  },

  {
    id: "08572a84-c7ca-45c8-8ecf-223c1e3798be",
    title: "HOTEL VILLA MATIĆ, NEUM",
    description:
      "MODERNO OPREMLJEN SMJEŠTAJ SVEGA 100m UDALJENOSTI OD PLAŽE JE GARANCIJA UGODNOG I NEZABORAVNOG BORAVKA U NEUMU. U RESTORANU SE SLUŽE SPECIJALITETI DOMAĆE KUHINJE,INTERNACIONALNE,VRHUNSKI RIBLJI I MESNI SPECIJALITETI I VRHUNSKA VINA",
    orderPriority: "free",
    activity: activityBackground.tourism,
    companyTitle: "HOTEL VILLA MATIĆ",
  },

  {
    id: "fb178843-f067-42c9-9256-834780a27210",
    title: "HOTEL VILLA MATIĆ, NEUM",
    description:
      "MODERNO OPREMLJEN SMJEŠTAJ SVEGA 100m UDALJENOSTI OD PLAŽE JE GARANCIJA UGODNOG I NEZABORAVNOG BORAVKA U NEUMU. U RESTORANU SE SLUŽE SPECIJALITETI DOMAĆE KUHINJE,INTERNACIONALNE,VRHUNSKI RIBLJI I MESNI SPECIJALITETI I VRHUNSKA VINA",
    orderPriority: "free",
    activity: activityBackground.tourism,
    companyTitle: "HOTEL VILLA MATIĆ",
  },

  {
    id: "310a7e13-ca73-46ea-a392-a8c3ae2b327c",
    title: "HOTEL VILLA MATIĆ, NEUM",
    description:
      "MODERNO OPREMLJEN SMJEŠTAJ SVEGA 100m UDALJENOSTI OD PLAŽE JE GARANCIJA UGODNOG I NEZABORAVNOG BORAVKA U NEUMU. U RESTORANU SE SLUŽE SPECIJALITETI DOMAĆE KUHINJE,INTERNACIONALNE,VRHUNSKI RIBLJI I MESNI SPECIJALITETI I VRHUNSKA VINA",
    orderPriority: "free",
    activity: activityBackground.tourism,
    companyTitle: "HOTEL VILLA MATIĆ",
  },
];
