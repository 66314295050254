import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../models/app/apiStatus";
import { AppDispatch, RootState } from "../app/redux/store";
import { Auth } from "../app/redux/authApi";
import jwt_decode from "jwt-decode";
import { LoginResponse } from "../models/auth/loginResponse";
import { LoginRequest } from "../models/auth/loginRequest";
import SetPasswordModel from "../models/auth/setPasswordModel";
import SetPasswordResponse from "../models/auth/setPasswordResponse";
import RefreshTokenRequest from "../models/auth/refreshTokenRequest";
import ForgotPasswordRequest from "../models/auth/forgotPasswordRequest";
import SetForgotPasswordModel from "../models/auth/setForgotPasswordModel";
import {
  setErrorNotification,
  setInfoNotification,
  setPendingNotification,
  setSuccessNotification,
} from "./notificationSlice";
import i18n from "../i18n";
import { ApiResponse } from "../models/app/apiResponse";

interface AuthState {
  accessToken: string | null;
  loginResponse: LoginResponse | null;
  currentAccount: CurrentAccount | null;
  errorMessage: string | null;
  resetPassword: SetPasswordResponse | null;
  changePassword: SetPasswordResponse | null;
  requestStatus: {
    login: ApiStatus;
    resetPassword: ApiStatus;
    changePassword: ApiStatus;
    forgotPassword: ApiStatus;
    setForgotPassword: ApiStatus;
  };
  tokenExpirationTime: number | null;
}

const savedCurrentAccount = localStorage.getItem("currentAccount");

const initialState: AuthState = {
  accessToken: localStorage.getItem("accessToken"),
  loginResponse: null,
  errorMessage: null,
  resetPassword: null,
  changePassword: null,
  currentAccount: savedCurrentAccount ? JSON.parse(savedCurrentAccount) : null,
  requestStatus: {
    login: ApiStatus.Idle,
    resetPassword: ApiStatus.Idle,
    changePassword: ApiStatus.Idle,
    forgotPassword: ApiStatus.Idle,
    setForgotPassword: ApiStatus.Idle,
  },
  tokenExpirationTime: null,
};

const isTokenExpired = (expirationTime: number): boolean => {
  return Date.now() >= expirationTime * 1000;
};

export const selectLoginStatus = (state: RootState) =>
  state.auth.requestStatus.login;

export const selectCurrentAccount = (state: RootState) =>
  state.auth.currentAccount;

export const selectLoginResponse = (state: RootState) =>
  state.auth.loginResponse;

export const selectErrorMessage = (state: RootState) => state.auth.errorMessage;

export const selectResetPasswordResponse = (state: RootState) =>
  state.auth.resetPassword;

export const selectResetPasswordStatus = (state: RootState) =>
  state.auth.requestStatus.resetPassword;

export const selectChangePasswordStatus = (state: RootState) =>
  state.auth.requestStatus.changePassword;

export const selectForgotPasswordStatus = (state: RootState) =>
  state.auth.requestStatus.forgotPassword;

export const selectSetForgotPasswordStatus = (state: RootState) =>
  state.auth.requestStatus.setForgotPassword;

export const selectLoginRequestStatus = (state: RootState) =>
  state.auth.requestStatus.login;

const clearTokensFromLocalStorage = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
};

const setTokenExpirationTimeout = (expirationTime: number) => {
  const currentTime = Date.now();
  const expiresInMs = expirationTime * 1000 - currentTime;

  if (expiresInMs > 0) {
    setTimeout(clearTokensFromLocalStorage, expiresInMs);
  }
};

export const login = createAsyncThunk<
  LoginResponse | null,
  LoginRequest,
  { rejectValue: string }
>(
  "Auth/Login",
  async (request: LoginRequest, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setPendingNotification(i18n.t("messageKey.Login_Pending")));
      const response = await Auth.Login(request);
      const result = response.data;
      if (result) {
        if (result.isFirstLogin) {
          dispatch(
            setInfoNotification(
              i18n.t("messageKey.Login_NeedResetPasswordOnFirstLogin")
            )
          );
        } else {
          dispatch(
            setSuccessNotification(i18n.t("messageKey." + response.messageKey))
          );
        }
      }
      return result;
    } catch (error) {
      const errorResponse = error as ApiResponse<null>;
      const messageKey = errorResponse.messageKey;
      if (messageKey) {
        dispatch(setErrorNotification(i18n.t("messageKey." + messageKey)));
      }
      return rejectWithValue(messageKey);
    }
  }
);

// export const login = createAsyncThunk<
//   LoginResponse | null,
//   LoginRequest,
//   { rejectValue: string }
// >(
//   "Auth/Login",
//   async (request: LoginRequest, { rejectWithValue, dispatch }) => {
//     try {
//       const response = await Auth.Login(request);
//       const result = response.data;

//       if (!result) {
//         throw new Error("Empty response from server");
//       }

//       const accessToken = result?.accessToken;

//       localStorage.setItem("accessToken", accessToken);

//       return result;
//     } catch (error) {

//       return rejectWithValue("Failed to login.");
//     }
//   }
// );

export const refreshToken = createAsyncThunk(
  "auth/refresh-token",
  async (request: RefreshTokenRequest) => {
    const response = await Auth.RefreshTokenClientAccount(request);
    return response;
  }
);

export const resetPassword = createAsyncThunk(
  "authSlice/reset-password",
  async (request: SetPasswordModel) => {
    const response = await Auth.ResetPassword(request);
    return response.data;
  }
);

export const changePassword = createAsyncThunk<
  SetPasswordResponse | null,
  SetPasswordModel,
  { rejectValue: string }
>(
  "authSlice/change-password",
  async (request: SetPasswordModel, { rejectWithValue, dispatch }) => {
    try {
      const response = await Auth.ChangePassword(request);

      if (response.succeeded) {
        dispatch(
          setSuccessNotification(i18n.t("messageKey." + response.messageKey))
        );
        return response.data; 
      } else {
        dispatch(
          setErrorNotification(i18n.t("messageKey." + response.messageKey))
        );
        return null;
      }
    } catch (error) {
      const errorResponse = error as ApiResponse<null>; 
      const messageKey = errorResponse.messageKey;
      if (messageKey) {
        dispatch(setErrorNotification(i18n.t("messageKey." + messageKey)));
        return rejectWithValue(messageKey);
      }
      return rejectWithValue("Unknown error occurred");
    }
  }
);

export const forgotPasswordClientAccount = createAsyncThunk<
  string | null,
  ForgotPasswordRequest,
  { rejectValue: string }
>(
  "authSlice/forgot-password",
  async (request: ForgotPasswordRequest, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setPendingNotification(i18n.t("messageKey.ForgotPassword_Pending")));
      const response = await Auth.ForgotPasswordClientAccount(request);

      if (response.succeeded) {
        dispatch(
          setSuccessNotification(i18n.t("messageKey." + response.messageKey))
        );
        return response.messageKey; 
      } else {
        dispatch(
          setErrorNotification(i18n.t("messageKey." + response.messageKey))
        );
        return rejectWithValue(response.messageKey);
      }
    } catch (error) {
      const errorResponse = error as ApiResponse<null>;
      const messageKey = errorResponse.messageKey;
      if (messageKey) {
        dispatch(setErrorNotification(i18n.t("messageKey." + messageKey)));
        return rejectWithValue(messageKey); 
      }
      return rejectWithValue("Unknown error occurred");
    }
  }
);


export const setForgotPasswordClientAccount = createAsyncThunk<
  string | null,
  SetForgotPasswordModel,
  { rejectValue: string }
>(
  "authSlice/set_forgot-password",
  async (request: SetForgotPasswordModel, { rejectWithValue, dispatch }) => {
    try {
      const response = await Auth.SetForgotPasswordClientAccount(request); 

      if (response.succeeded) {
        dispatch(
          setSuccessNotification(i18n.t("messageKey." + response.messageKey))
        );
        return response.messageKey;
      } else {
        dispatch(
          setErrorNotification(i18n.t("messageKey." + response.messageKey))
        );
        return rejectWithValue(response.messageKey);
      }
    } catch (error) {
      const errorResponse = error as ApiResponse<null>;
      const messageKey = errorResponse.messageKey;
      if (messageKey) {
        dispatch(setErrorNotification(i18n.t("messageKey." + messageKey)));
        return rejectWithValue(messageKey);
      }
      return rejectWithValue("Unknown error occurred");
    }
  }
);

export const resetResetPassword = createAsyncThunk(
  "authSlice/reset-reset-password",
  async () => {
    return initialState.resetPassword;
  }
);

export const resetResetPasswordStatus = createAsyncThunk(
  "authSlice/reset-reset-password-status",
  async () => {
    return initialState.requestStatus.resetPassword;
  }
);

export const resetLoginStatus = createAsyncThunk(
  "authSlice/reset-login-status",
  async () => {
    return initialState.requestStatus.login;
  }
);

export const resetChangePassword = createAsyncThunk(
  "authSlice/reset-change_password",
  async () => {
    return initialState.requestStatus.login;
  }
);

export const resetForgotPasswordStatus = createAsyncThunk(
  "authSlice/reset-forgot_password_status",
  async () => {
    return initialState.requestStatus.forgotPassword;
  }
);

export const resetSetForgotPasswordStatus = createAsyncThunk(
  "authSlice/reset-set-forgot_password_status",
  async () => {
    return initialState.requestStatus.setForgotPassword;
  }
);

export const resetCurrentAccount = createAsyncThunk(
  "authSlice/reset-current-password",
  async () => {
    return initialState.currentAccount;
  }
);

export const resetErrorMessage = createAsyncThunk(
  "authSlice/reset-error-message",
  async () => {
    return null;
  }
);

// Logout thunk
export const performLogout = createAsyncThunk(
  "auth/performLogout",
  async (_, { dispatch }) => {
    clearTokensFromLocalStorage();
    dispatch(logout());
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.loginResponse = null;
      state.requestStatus.login = ApiStatus.Idle;
      state.accessToken = null;
      state.currentAccount = null;
      localStorage.removeItem("accessToken");
      localStorage.removeItem("currentAccount");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.requestStatus.login = ApiStatus.Pending;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.requestStatus.login = ApiStatus.Fulfilled;
        state.loginResponse = action.payload;

        const accessToken = action.payload?.accessToken;
        const refreshToken = action.payload?.refreshToken;

        if (accessToken && refreshToken) {
          try {
            const decodedToken = jwt_decode(accessToken) as DecodedToken;
            const isWebsiteValue = decodedToken.isWebsite === ("True" as any);

            state.currentAccount = {
              firstName: decodedToken.given_name ?? "",
              lastName: decodedToken.family_name ?? "",
              email: decodedToken.email ?? "",
              id: decodedToken.primarysid ?? "",
              roleName: decodedToken.role ?? "",
              companyId: decodedToken.company_id ?? "",
              phone: "",
              rolePriority: 0,
              isMidenas: false,
              isActive: true,
              isDeleted: false,
              associationId: decodedToken.association_id ?? "",
              isWebsite: isWebsiteValue,
            };

            localStorage.setItem("accessToken", accessToken);
            localStorage.setItem("refreshToken", refreshToken);
            localStorage.setItem(
              "currentAccount",
              JSON.stringify(state.currentAccount)
            );

            if (decodedToken.exp !== undefined) {
              const expirationTime = decodedToken.exp;
              setTokenExpirationTimeout(expirationTime);
            }
          } catch (error) {}
        }
      })
      .addCase(login.rejected, (state, action) => {
        state.requestStatus.login = ApiStatus.Rejected;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.requestStatus.resetPassword = ApiStatus.Fulfilled;
        state.resetPassword = action.payload;
      })
      .addCase(resetResetPassword.fulfilled, (state, action) => {
        state.resetPassword = initialState.resetPassword;
      })
      .addCase(resetResetPasswordStatus.fulfilled, (state) => {
        state.requestStatus.resetPassword =
          initialState.requestStatus.resetPassword;
      })
      .addCase(changePassword.pending, (state, action) => {
        state.requestStatus.changePassword = ApiStatus.Pending;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.requestStatus.changePassword = ApiStatus.Fulfilled;
        state.changePassword = action.payload;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.requestStatus.changePassword = ApiStatus.Rejected;
      })

      .addCase(forgotPasswordClientAccount.pending, (state, action) => {
        state.requestStatus.forgotPassword = ApiStatus.Pending;
      })
      .addCase(forgotPasswordClientAccount.fulfilled, (state, action) => {
        state.requestStatus.forgotPassword = ApiStatus.Fulfilled;
      })
      .addCase(forgotPasswordClientAccount.rejected, (state, action) => {
        state.requestStatus.forgotPassword = ApiStatus.Rejected;
      })

      .addCase(setForgotPasswordClientAccount.pending, (state, action) => {
        state.requestStatus.setForgotPassword = ApiStatus.Pending;
      })
      .addCase(setForgotPasswordClientAccount.fulfilled, (state, action) => {
        state.requestStatus.setForgotPassword = ApiStatus.Fulfilled;
      })
      .addCase(setForgotPasswordClientAccount.rejected, (state, action) => {
        state.requestStatus.setForgotPassword = ApiStatus.Rejected;
      })

      .addCase(resetLoginStatus.fulfilled, (state) => {
        state.requestStatus.login = initialState.requestStatus.login;
      })
      .addCase(resetForgotPasswordStatus.fulfilled, (state) => {
        state.requestStatus.forgotPassword =
          initialState.requestStatus.forgotPassword;
      })
      .addCase(resetSetForgotPasswordStatus.fulfilled, (state) => {
        state.requestStatus.setForgotPassword =
          initialState.requestStatus.setForgotPassword;
      })
      .addCase(resetChangePassword.fulfilled, (state) => {
        state.requestStatus.changePassword =
          initialState.requestStatus.changePassword;
      })
      .addCase(resetErrorMessage.fulfilled, (state) => {
        state.errorMessage = null;
      })
      .addCase(resetCurrentAccount.fulfilled, (state) => {
        state.currentAccount = initialState.currentAccount;
      })
      .addCase(performLogout.fulfilled, (state) => {
        state = initialState;
      });
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;

interface DecodedToken {
  given_name?: string;
  family_name?: string;
  email?: string;
  primarysid?: string;
  role?: string;
  isWebsite: boolean;
  exp?: number;
  company_id: string;
  association_id: string;
}

interface CurrentAccount {
  firstName: string;
  lastName: string;
  email: string;
  id: string;
  roleName: string;
  phone: string;
  rolePriority: number;
  isMidenas: boolean;
  isActive: boolean;
  isDeleted: boolean;
  associationId: string;
  companyId: string | null;
  isWebsite: boolean;
}
