import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  Grow,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { colors } from "../../app/theme/colors";
import BasicInput from "../../components/basicInput/basicInput";
import { useContactFormStyles } from "./contactFormStyles";
import backgroundImg from "../../images/ba-03.png";
import PinDropIcon from "@mui/icons-material/PinDrop";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LanguageIcon from "@mui/icons-material/Language";
import AppButton from "../../components/appButton/appButton";
import { useEffect, useState } from "react";
import ContactRequest from "../../models/contactRequest";
import { StyledCheckCircleIcon } from "../advertisementRequestSection/advertisementRequestSection";
import { useTranslation } from "react-i18next";
import SendIcon from "@mui/icons-material/Send";
import CustomOutlinedInput from "../../components/outlinedInput/customOutlinedInput";
import { useAppDispatch, useAppSelector } from "../../app/redux/hooks";
import {
  selectContactStatus,
  sendContactRequest,
} from "../../slices/companySlice";
import {
  IsCompanyNameValid,
  IsEmailValid,
  IsMessageValid,
} from "../../common/validations/validations";
import { useNavigate } from "react-router-dom";
import { routes } from "../../app/router/routes";

const GradientBackground = styled(Grid)(({ theme }) => ({
  alignItems: "center",
  backgroundImage: `url(${backgroundImg})`,
  backgroundRepeat: "no-repeat",
  backgroundColor: colors.primary,
  backgroundPosition: "center",
  backgroundSize: "35%",
  [theme.breakpoints.down("md")]: {
    backgroundSize: "100%",
    backgroundPosition: "bottom",
  },
}));

export default function ContactForm() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const { classes } = useContactFormStyles();

  const contactStatus = useAppSelector(selectContactStatus);

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const INIT_CONTACT_REQUEST: ContactRequest = {
    companyName: "",
    contactPerson: "",
    email: "",
    message: "",
  };

  const [contact, setContact] = useState<ContactRequest>(INIT_CONTACT_REQUEST);
  const [isVisibleNotification, setIsVisibleNotification] = useState(false);

  const [validate, setValidate] = useState<boolean>(false);

  const [isChecked, setIsChecked] = useState(false);

  const handlePhoneClick = (phoneNumber: string) => {
    window.location.href = `tel:${phoneNumber}`;
  };

  const isValid = () => {
    setValidate(true);
    if (
      !IsCompanyNameValid(contact.companyName) ||
      !IsEmailValid(contact.email) ||
      !IsMessageValid(contact.message)
    ) {
      return false;
    }

    return true;
  };

  const handleWebsiteClick = (route: string) => {
    navigate(route);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (isValid()) {
      dispatch(sendContactRequest(contact));
      setValidate(false);
    }
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleContactFormChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setContact((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleContactRequest = () => {
    dispatch(sendContactRequest(contact));
  };

  const handleResetFields = () => {
    setContact(INIT_CONTACT_REQUEST);

    setIsChecked(false);
  };

  useEffect(() => {
    if (contactStatus === "Fulfilled") {
      handleResetFields();
    }
  }, [contactStatus]);

  return (
    <GradientBackground container>
      <Grid item md={12} xs={12} sx={{ px: isMobile ? 2 : 6 }} mt={1}>
        <Typography variant="h4" className={classes.sectionTitle}>
          {t("contactUsPage.contactTitle")}
        </Typography>
      </Grid>
      <Grid
        item
        md={6}
        sx={{ px: isMobile ? 2 : 6, py: 6 }}
        justifyContent="center"
        id="contact_form_grid"
      >
        {isVisibleNotification ? (
          <Grid item md={8} xs={12} justifyContent="center" container>
            <Grid item md={12} textAlign={"center"}>
              <Grow in={isVisibleNotification} timeout={400}>
                <StyledCheckCircleIcon style={{ fontSize: "50px" }} />
              </Grow>
            </Grid>
            <Grid item md={12} textAlign={"center"}>
              <Grow in={isVisibleNotification} timeout={600}>
                <Typography variant={"h3"} className={classes.label}>
                  {t("contactUsPage.contactSuccessTitle")}
                </Typography>
              </Grow>
            </Grid>
            <Grid item md={12} textAlign={"center"}>
              <Grow in={isVisibleNotification} timeout={800}>
                <Typography variant={"body1"} className={classes.label}>
                  {t("contactUsPage.contactSuccessMessage")}
                </Typography>
              </Grow>
            </Grid>
          </Grid>
        ) : (
          <Grid item md={8} xs={12} justifyContent="center" container>
            <FormControl onSubmit={handleSubmit}>
              <Grid item xs={12}>
                <CustomOutlinedInput
                  validate
                  width="100%"
                  label={t("contactUsPage.contactFormCompanyName")}
                  onChange={(e) => handleContactFormChange(e)}
                  name={"companyName"}
                  value={contact.companyName}
                  error={validate && !IsCompanyNameValid(contact.companyName)}
                  errorMessage={t("validation.validationCompanyName")}
                />
              </Grid>
              <Grid item xs={12} mt={2}>
                <CustomOutlinedInput
                  width="100%"
                  label={t("contactUsPage.contactFormContactPerson")}
                  onChange={(e) => handleContactFormChange(e)}
                  name={"contactPerson"}
                  value={contact.contactPerson}
                />
              </Grid>
              <Grid item xs={12} mt={2}>
                <CustomOutlinedInput
                  validate
                  width="100%"
                  label={t("contactUsPage.contactFormEmail") ?? ""}
                  onChange={(e) => handleContactFormChange(e)}
                  name={"email"}
                  value={contact.email}
                  error={validate && !IsEmailValid(contact.email)}
                  errorMessage={t("validation.validationEmail")}
                />
              </Grid>
              <Grid item xs={12} mt={2}>
                <CustomOutlinedInput
                  validate
                  width="100%"
                  label={t("contactUsPage.contactFormMessage") ?? ""}
                  multiline
                  rows={5}
                  onChange={(e) => handleContactFormChange(e)}
                  name={"message"}
                  value={contact.message}
                  error={validate && !IsMessageValid(contact.message)}
                  errorMessage={t("validation.validationMessage")}
                />
              </Grid>
              <Grid item md={12} mt={2} textAlign="left">
                <FormControlLabel
                  sx={{ color: "white" }}
                  control={
                    <Checkbox
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "white",
                        },
                      }}
                    />
                  }
                  label={t("termsAndContitions.error")}
                />
                <Typography variant="body1" sx={{ color: "white" }}>
                  {t("termsAndContitions.description")}
                </Typography>
              </Grid>
              <Grid item md={12} xs={12} mt={2} textAlign="center">
                <AppButton
                  type={"submit"}
                  label={t("buttons.send")}
                  color={colors.primary}
                  hover={colors.primary}
                  width="50%"
                  onClick={handleSubmit}
                  disabled={contactStatus === "Pending" || isChecked !== true}
                  startIcon={
                    contactStatus === "Pending" ? (
                      <CircularProgress size={"1em"} />
                    ) : (
                      <SendIcon />
                    )
                  }
                />
              </Grid>
            </FormControl>
          </Grid>
        )}
      </Grid>
      <Grid item md={6} sx={{ px: 2 }}>
        <Grid
          item
          md={12}
          xs={12}
          display="flex"
          className={classes.iconWrapper}
        >
          <PinDropIcon className={classes.icon} />
          <Typography
            variant="h5"
            className={classes.label}
            component={"a"}
            href={
              "https://www.google.com/maps/place/Rade+Vranje%C5%A1evi%C4%87+12,+Banja+Luka+78000,+Bosnia+and+Herzegovina/data=!4m2!3m1!1s0x475e036cc4391a63:0x45b15858a5c27ddf?sa=X&ved=2ahUKEwiz0aHY_bX_AhVI9LsIHQJ2ATsQ8gF6BAgSEAI"
            }
            target="_blank"
          >
            Bosna i Hercegovina <br/> Rade Vranješević 12 <br />
            Banja Luka 78000
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          xs={12}
          display="flex"
          className={classes.iconWrapper}
          my={3}
        >
          <div style={{ display: "flex" }}>
            <EmailIcon className={classes.icon} />
            <Typography variant="h5" className={classes.label}>
              <a
                href="mailto:info@telekommarketing.com?subject=Kontakt"
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  cursor: "pointer",
                }}
              >
                info@poslovniimenik.net
              </a>
              <br />
              <a
                href="mailto:info@telekommarketing.com?subject=Kontakt"
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  cursor: "pointer",
                }}
              >
               telekombluka@gmail.com
              </a>
            </Typography>
          </div>
        </Grid>

        <Grid
          item
          md={12}
          xs={12}
          display="flex"
          className={classes.iconWrapper}
        >
          <div style={{ display: "flex" }}>
            <LocalPhoneIcon className={classes.icon} />
            <Typography variant="h5" className={classes.label}>
              <span
                onClick={() => handlePhoneClick("+38751255310")}
                style={{ cursor: "pointer" }}
              >
                +387 51 255 310
              </span>
              <br />
              <span
                onClick={() => handlePhoneClick("+38751255311")}
                style={{ cursor: "pointer" }}
              >
                +387 51 255 311
              </span>
            </Typography>
          </div>
        </Grid>
        <Grid
          item
          md={12}
          xs={12}
          display="flex"
          className={classes.iconWrapper}
          my={3}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <LanguageIcon
              style={{ cursor: "pointer" }}
              className={classes.icon}
              onClick={() => handleWebsiteClick(routes.home)}
            />
            <Typography
              style={{ cursor: "pointer" }}
              variant="h5"
              className={classes.label}
              onClick={() => handleWebsiteClick(routes.home)}
            >
              www.poslovniimenik.net <br />
              www.telekommarketing.com
            </Typography>
          </div>
        </Grid>
      </Grid>
    </GradientBackground>
  );
}
