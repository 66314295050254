import {
  Box,
  Container,
  Grid,
  Typography,
  styled,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../app/redux/hooks";
import { useTranslation } from "react-i18next";
import { MySiteDetailsResponse } from "../../models/mySite/mySiteDetalisResponse";
import { useEffect, useState } from "react";
import {
  getMySiteView,
  resetMySiteViewStatus,
  selectMySiteViewStatus,
} from "../../slices/mySiteSlice";
import { useNavigate, useParams } from "react-router-dom";
import YouTube from "react-youtube";
import { colors } from "../../app/theme/colors";
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaLinkedin,
  FaTiktok,
} from "react-icons/fa";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { routes } from "../../app/router/routes";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { PartitionType } from "../../models/enums/partitionType";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import "./imageGallery.css"

interface Props {
  mySiteView: MySiteDetailsResponse[] | null;
  companyWebsiteName: any | null;
}

const GradientBackground = styled(Grid)(({ theme }) => ({
  backgroundPosition: "center",
  [theme.breakpoints.down("md")]: {
    backgroundSize: "100%",
    backgroundPosition: "bottom",
  },
}));

const VideoWrapper = styled("div")({
  position: "relative",
  paddingBottom: "56.25%",
  height: 0,
  overflow: "hidden",
  maxWidth: "100%",
  background: "#000",

  "& iframe": {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
});

const JustifiedText = styled("div")({
  textAlign: "justify",
});

export default function MySiteViewSection({
  mySiteView,
  companyWebsiteName,
}: Props) {
  const navigate = useNavigate();
  const { companyName } = useParams();

  const mySiteViewStatus = useAppSelector(selectMySiteViewStatus);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [index, setIndex] = useState(0);
  const [lightboxOpen, setLightboxOpen] = useState(false);

  useEffect(() => {
    if (companyWebsiteName) {
      dispatch(getMySiteView(companyWebsiteName));
    }
  }, [companyWebsiteName]);

  useEffect(() => {
    if (companyName) {
      dispatch(getMySiteView(companyName));
    }
  }, [companyName]);

  useEffect(() => {
    if (mySiteViewStatus === "Rejected") {
      navigate(routes.home);
      const timer = setTimeout(() => {
        dispatch(resetMySiteViewStatus());
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [mySiteViewStatus]);

  return (
    <Container maxWidth="lg">
      <GradientBackground
        container
        spacing={5}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid item md={12} xs={12} mt={2} textAlign={"center"}>
          {mySiteViewStatus === "Pending" ? (
            <CircularProgress sx={{ color: colors.primary }} />
          ) : mySiteView ? (
            mySiteView
              .slice()
              .sort((a, b) => a.orderNumber - b.orderNumber)
              .map((mySite) => (
                <div key={mySite.id}>
                  {mySite.partitionType === 2 && (
                    <Grid item md={12} xs={12}>
                      <JustifiedText
                        dangerouslySetInnerHTML={{ __html: mySite.text }}
                      />
                    </Grid>
                  )}
                  {mySite.partitionType === 1 && (
                    <Grid item md={12} my={2}>
                      <VideoWrapper>
                        <YouTube videoId={mySite.text.split("=")[1]} />
                      </VideoWrapper>
                    </Grid>
                  )}
                  <Grid container display={"flex"} justifyContent={"center"}>
                    {mySite.phones &&
                      mySite.phones.map((phone) => (
                        <Grid item key={phone.phoneNumber} md={12} xs={12}>
                          <Typography
                            mb={1}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              (window.location.href = `tel:${phone.phoneNumber}`)
                            }
                          >
                            <LocalPhoneIcon
                              sx={{ color: colors.primary, marginRight: 1 }}
                            />
                            <span
                              style={{
                                color: colors.primary,
                                marginRight: "5px",
                              }}
                            >
                              {phone.name}:
                            </span>{" "}
                            {phone.phoneNumber}
                          </Typography>
                        </Grid>
                      ))}
                  </Grid>
                  {mySite.photoPath && (
                    <Grid item md={12} xs={12}>
                      <img
                        src={mySite.photoPath}
                        alt="Site"
                        style={{ width: "100%", height: "100%" }}
                      />
                    </Grid>
                  )}
                  <Grid
                    item
                    container
                    justifyContent="center"
                    spacing={2}
                    my={1}
                    alignItems={"center"}
                  >
                    {mySite.socialMedias &&
                      mySite.socialMedias.map((socialMedia) => (
                        <Grid item key={socialMedia.socialMediaType}>
                          {socialMedia.name === "Facebook" && (
                            <IconButton
                              aria-label="Facebook"
                              href={socialMedia.link}
                              target="_blank"
                            >
                              <FaFacebook
                                style={{ color: "rgba(24, 119, 242, 1)" }}
                              />
                            </IconButton>
                          )}
                          {socialMedia.name === "Instagram" && (
                            <IconButton
                              aria-label="Instagram"
                              href={socialMedia.link}
                              target="_blank"
                            >
                              <FaInstagram
                                style={{ color: "rgba(193, 53, 132, 1)" }}
                              />
                            </IconButton>
                          )}
                          {socialMedia.name === "YouTube" && (
                            <IconButton
                              aria-label="YouTube"
                              href={socialMedia.link}
                              target="_blank"
                            >
                              <FaYoutube
                                style={{ color: "rgba(255, 0, 0, 1)" }}
                              />
                            </IconButton>
                          )}
                          {socialMedia.name === "LinkedIn" && (
                            <IconButton
                              aria-label="LinkedIn"
                              href={socialMedia.link}
                              target="_blank"
                            >
                              <FaLinkedin
                                style={{ color: "rgba(0, 119, 181, 1)" }}
                              />
                            </IconButton>
                          )}
                          {socialMedia.name === "TikTok" && (
                            <IconButton
                              aria-label="TikTok"
                              href={socialMedia.link}
                              target="_blank"
                            >
                              <FaTiktok style={{ color: "rgba(0, 0, 0, 1)" }} />
                            </IconButton>
                          )}
                          {socialMedia.socialMediaType === 6 && (
                            <IconButton href={socialMedia.link} target="_blank">
                              <img
                                src={socialMedia.base64Photo}
                                alt={socialMedia.name}
                                style={{ height: 25, width: 25 }}
                              />
                            </IconButton>
                          )}
                        </Grid>
                      ))}
                  </Grid>

                  <Grid item md={12} mb={2} sx={{display: "flex", justifyContent: "center"}}>
                    {mySite &&
                      mySite.photoPaths &&
                      mySite.partitionType === PartitionType.Slider && (
                        <ImageGallery
                          items={mySite.photoPaths.map((path) => ({
                            original: path,
                          }))}
                          showPlayButton={false}
                          additionalClass="image-gallery"
                        />
                      )}
                  </Grid>
                  {mySite.partitionType === PartitionType.Gallery && (
                    <Grid item md={12}>
                      <>
                        <PhotoAlbum
                          layout="columns"
                          photos={mySite.photoPaths.map((path, idx) => ({
                            src: path,
                            title: `Image ${idx}`,
                            alt: `Image ${idx}`,
                            width: 150,
                            height: 150,
                          }))}
                          targetRowHeight={200}
                          onClick={({ index }) => {
                            setIndex(index);
                            setLightboxOpen(true);
                          }}
                          spacing={5}
                          padding={2}
                        />
                        <Lightbox
                          plugins={[Fullscreen, Zoom]}
                          index={index}
                          open={lightboxOpen}
                          close={() => {
                            setIndex(-1);
                            setLightboxOpen(false);
                          }}
                          slides={mySite.photoPaths.map((path) => ({
                            src: path,
                            title: "",
                          }))}
                        />
                      </>
                    </Grid>
                  )}
                </div>
              ))
          ) : (
            <Typography>{t("No data available")}</Typography>
          )}
        </Grid>
      </GradientBackground>
    </Container>
  );
}
