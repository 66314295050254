import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";
import { colors } from "../../../app/theme/colors";

export const useMobileHeaderSecondaryStyles = makeStyles()((theme: Theme) => ({
  logoShort: {
    width: "40px",
    height: "40px",
  },

  logoWrapper: {
    display: "flex",
    alignItems: "center",
  },
  logoFull: {
    width: "180px",
  },

  buttonsWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    flexGrow: 1,
  },
  logo: {
    width: "220px",
  },
  inputWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  searchIcon: {
    color: "#929292",
    marginRight: "5px",
  },
  typography: {
    background: "rgba(249, 249, 249, 0.2)",
    borderRadius: "5px",
    marginTop: "5px",
    display: "flex",
    alignItems: "flex-start",
    cursor: "pointer",
  },
  searchIconFilters: {
    color: "white",
    marginRight: "5px",
  },
  arrowBackIcon: {
    cursor: "pointer",
  },
  inputGrid: {
    display: "flex",
    alignItems: "flex-stat",
  },
  autocompleteTextField: {
    "& .MuiInputBase-root": {
      borderRadius: "5px",
      background: "white",
    },
  },
  autocomplete: {
    "& .MuiOutlinedInput-root.Mui-focused": {
      "& > fieldset": {
        borderColor: colors.sectionTitle,
      },
    },
  },
  advanceSearchWrapper: {
    display: "flex",
  },
  list: {
    color: colors.primary,
    // justifyContent: "flex-start"
  },
  divider: {
    background: "white",
    opacity: 0.6,
  },
  listItemText: {
    color: colors.primary,
    alignItems: "flex-start",
  },
  closeIcon: {
    color: colors.primary,
  },
  iconWrapper: {
    padding: "5px",
  },
  listItem: {
    display: "flex",
    alignItems: "flex-start",
  },

  listItemTextServices: {
    // marginLeft: "60px",
    color: colors.primary,
  },
  listItemTextSettings: {
    // marginLeft: "40px",
    color: colors.primary,
  },
  expandIcon: {
    color: colors.primary,
    marginRight: "25px",
  },
}));
