import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../models/app/apiStatus";
import {
  AdvertisementRequest,
  Companies,
  ContactRequestForm,
  OrderRequest,
  UpdateDataRequest,
  saveClient as saveClientApi,
  getClients as getCleintsApi,
  deleteClient as deleteClientApi,
  faqMessage as faqMessageApi,
} from "../app/redux/poslovniImenikApi";
import { RootState } from "../app/redux/store";
import { CompanyFullDetailsModel } from "../models/company/companyFullDetailsModel";
import CategoryResponseModel from "../models/company/categoryResponseModel";
import { BannerModel } from "../models/bannerModel";
import { AdvanceSearchFilterModel } from "../models/advanceSearchFilterModel";
import { CompanySearchResponse } from "../models/company/companySearchResponse";
import { AdvertisementRequestModel } from "../models/advertisementRequestModel";
import ContactRequest from "../models/contactRequest";
import { UpdateDataModel } from "../models/editions/updatedDataModel";
import { AdvertisementModel } from "../models/editions/advertisementModel";
import {
  setErrorNotification,
  setPendingNotification,
  setSuccessNotification,
} from "./notificationSlice";
import { ApiResponse } from "../models/app/apiResponse";
import i18n from "../i18n";
import AddClientModel, {
  ClientAccount,
} from "../models/addClient/addClientModel";
import DeleteClientAccountModel from "../models/addClient/deleteClientAccountModel";
import FaqRequest from "../models/faq/faqRequest";

type CompanyState = {
  companySearchResponse: CompanySearchResponse | null;
  companyById: CompanyFullDetailsModel | null;
  categories: CategoryResponseModel[] | null;
  banners: BannerModel[] | null;
  orderRequest: AdvertisementRequestModel | null;
  advertisementRequest: AdvertisementModel | null;
  updateDataRequest: UpdateDataModel | null;
  contactRequest: ContactRequest | null;
  client: AddClientModel | null;
  clients: ClientAccount[] | null;
  message: string | null;
  statuses: {
    savedCompany: ApiStatus;
    getCompany: ApiStatus;
    search: ApiStatus;
    orderRequest: ApiStatus;
    advertisementRequest: ApiStatus;
    updateDataRequest: ApiStatus;
    contactRequest: ApiStatus;
    saveClient: ApiStatus;
    deleteClient: ApiStatus;
    faqMessage: ApiStatus;
  };
};

const initialState: CompanyState = {
  companySearchResponse: null,
  companyById: null,
  message: null,
  categories: null,
  banners: null,
  advertisementRequest: null,
  contactRequest: null,
  orderRequest: null,
  updateDataRequest: null,
  client: null,
  clients: null,
  statuses: {
    savedCompany: ApiStatus.Idle,
    getCompany: ApiStatus.Idle,
    search: ApiStatus.Idle,
    orderRequest: ApiStatus.Idle,
    advertisementRequest: ApiStatus.Idle,
    updateDataRequest: ApiStatus.Idle,
    contactRequest: ApiStatus.Idle,
    saveClient: ApiStatus.Idle,
    deleteClient: ApiStatus.Idle,
    faqMessage: ApiStatus.Idle,
  },
};

export const selectCompanies = (state: RootState) =>
  state.company.companySearchResponse;
export const selectCompaniesPagination = (state: RootState) =>
  state.company.companySearchResponse;
export const selectCompanySearchResponse = (state: RootState) =>
  state.company.companySearchResponse;
export const selectCompanyById = (state: RootState) =>
  state.company.companyById;
export const selectAllCategories = (state: RootState) =>
  state.company.categories;
export const selectAllBanners = (state: RootState) => state.company.banners;
export const selectLoadingFullDetailsStatus = (state: RootState) =>
  state.company.statuses.getCompany;
export const selectSearchStatus = (state: RootState) =>
  state.company.statuses.search;
export const selectAdvertisementStatus = (state: RootState) =>
  state.company.statuses.advertisementRequest;
export const selectUpdateDataStatus = (state: RootState) =>
  state.company.statuses.updateDataRequest;
export const selectOrderStatus = (state: RootState) =>
  state.company.statuses.orderRequest;
export const selectContactStatus = (state: RootState) =>
  state.company.statuses.contactRequest;

export const selectSaveClientStatus = (state: RootState) =>
  state.company.statuses.saveClient;
export const selectDeletedClientStatus = (state: RootState) =>
  state.company.statuses.deleteClient;
export const selectFaqMessageStatus = (state: RootState) =>
  state.company.statuses.faqMessage;

export const selectClient = (state: RootState) => state.company.client;
export const selectClients = (state: RootState) => state.company.clients;

export const getCompaniesAuthorized = createAsyncThunk(
  "Company/Get_Companies_Authorized",
  async (request: AdvanceSearchFilterModel, { rejectWithValue }) => {
    try {
      const response: ApiResponse<CompanySearchResponse> =
        await Companies.GetCompaniesAuthorized(request);
      if (!response || !response.data) {
        throw new Error("Network response was not ok");
      }
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      } else {
        return rejectWithValue("An unknown error occurred");
      }
    }
  }
);

export const getCompaniesUnauthorized = createAsyncThunk(
  "Company/Get_Companies_Unauthorized",
  async (request: AdvanceSearchFilterModel) => {
    const response = await Companies.GetCompaniesUnauthorized(request);
    return response.data;
  }
);

export const getCompanyFullDetailsById = createAsyncThunk(
  "Company/Get_Company_Full_Details_By_Id",
  async (id: string) => {
    const response = await Companies.GetCompanyFullDetailsById(id);
    return response.data;
  }
);

export const getAllCategories = createAsyncThunk(
  "Company/Get_All_Categories",
  async () => {
    const response = await Companies.GetAllCategories();
    return response.data;
  }
);

export const getAllBanners = createAsyncThunk(
  "Company/Get_All_Banners",
  async () => {
    const response = await Companies.GetAllBanners();
    return response.data;
  }
);

export const getClients = createAsyncThunk(
  "Company/Get_clients",
  async (companyId: string) => {
    const response = await getCleintsApi(companyId);
    return response.data;
  }
);

export const saveClient = createAsyncThunk<
  any, 
  AddClientModel,
  { rejectValue: string }
>(
  "mySiteSlice/save_my_site_social_media",
  async (request: AddClientModel, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setPendingNotification(i18n.t("messageKey.CreateAccount_Pending")));
      const response = await saveClientApi(request); 

      if (response.succeeded) {
        dispatch(
          setSuccessNotification(i18n.t("messageKey." + response.messageKey))
        );
        return response.data;
      } else {
        dispatch(
          setErrorNotification(i18n.t("messageKey." + response.messageKey))
        );
        return rejectWithValue(response.messageKey);
      }
    } catch (error) {
      console.error("Error saving client:", error);
      const errorResponse = error as ApiResponse<null>; 
      const messageKey = errorResponse.messageKey;
      if (messageKey) {
        dispatch(setErrorNotification(i18n.t("messageKey." + messageKey)));
        return rejectWithValue(messageKey);
      }
      return rejectWithValue("Unknown error occurred");
    }
  }
);

export const deleteClient = createAsyncThunk<
  any,
  DeleteClientAccountModel,
  { rejectValue: string }
>(
  "mySiteSlice/delete_my_site_social_media",
  async (request: DeleteClientAccountModel, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setPendingNotification(i18n.t("messageKey.DeleteClient_Pending")));
      const response = await deleteClientApi(request);

      if (response.succeeded) {
        dispatch(
          setSuccessNotification(i18n.t("messageKey.DeleteAccount_Success"))
        );
        return response.data;
      } else {
        dispatch(
          setErrorNotification(i18n.t("messageKey." + response.messageKey))
        );
        return rejectWithValue(response.messageKey);
      }
    } catch (error) {
      console.error("Error deleting client:", error);
      const errorResponse = error as ApiResponse<null>;
      const messageKey = errorResponse.messageKey;
      if (messageKey) {
        dispatch(setErrorNotification(i18n.t("messageKey." + messageKey)));
        return rejectWithValue(messageKey);
      }
      return rejectWithValue("Unknown error occurred");
    }
  }
);


export const faqMessage = createAsyncThunk(
  "mySiteSlice/faq_message",
  async (request: FaqRequest, { rejectWithValue, dispatch }) => {
    try {
      dispatch(
        setPendingNotification(i18n.t("messageKey.SendContactRequest_Pending"))
      );
      const response = await faqMessageApi(request);

      if (response.succeeded) {
        dispatch(
          setSuccessNotification(i18n.t("messageKey." + response.messageKey))
        );
        return response.data;
      } else {
        dispatch(
          setErrorNotification(i18n.t("messageKey." + response.messageKey))
        );
        return rejectWithValue(response.messageKey);
      }
    } catch (error) {
      console.error("Error sending FAQ message:", error);
      // dispatch(setErrorNotification("messageKey." + error.message)); // Handle error notification
      return rejectWithValue("Unknown error occurred");
    }
  }
);

export const sendOrderRequest = createAsyncThunk<
  AdvertisementRequestModel | null,
  AdvertisementRequestModel,
  { rejectValue: string }
>(
  "Company/Send_Order_Request",
  async (request: AdvertisementRequestModel, { rejectWithValue, dispatch }) => {
    try {
      dispatch(
        setPendingNotification(i18n.t("messageKey.orderRequest_Pending"))
      );
      const response = await OrderRequest.SendOrderRequest(request);
      dispatch(
        setSuccessNotification(i18n.t("messageKey.orderRequest_Success"))
      );
      return response.data;
    } catch (error) {
      const errorResponse = error as ApiResponse<null>;
      dispatch(setErrorNotification(i18n.t("messageKey.orderRequest_Error")));
      return rejectWithValue(errorResponse.messageKey);
    }
  }
);

export const sendUpdateDataRequest = createAsyncThunk<
  UpdateDataModel | null,
  UpdateDataModel,
  { rejectValue: string }
>(
  "Company/Send_Update_Data_Request",
  async (request: UpdateDataModel, { rejectWithValue, dispatch }) => {
    try {
      dispatch(
        setPendingNotification(i18n.t("messageKey.updateDataRequest_Pending"))
      );
      const response = await UpdateDataRequest.SendUpdateDataRequest(request);
      dispatch(
        setSuccessNotification(i18n.t("messageKey.updateDataRequest_Success"))
      );
      return response.data;
    } catch (error) {
      const errorResponse = error as ApiResponse<null>;
      dispatch(
        setErrorNotification(i18n.t("messageKey.updateDataRequest_Error"))
      );
      return rejectWithValue(errorResponse.messageKey);
    }
  }
);

export const sendAdvertisementRequest = createAsyncThunk<
  AdvertisementModel | null,
  AdvertisementModel,
  { rejectValue: string }
>(
  "Company/Send_Advertisement_Request",
  async (request: AdvertisementModel, { rejectWithValue, dispatch }) => {
    try {
      dispatch(
        setPendingNotification(i18n.t("messageKey.updateDataRequest_Pending"))
      );
      const response = await AdvertisementRequest.SendAdvertisementRequest(
        request
      );
      dispatch(
        setSuccessNotification(i18n.t("messageKey.updateDataRequest_Success"))
      );
      return response.data;
    } catch (error) {
      const errorResponse = error as ApiResponse<null>;
      dispatch(
        setErrorNotification(i18n.t("messageKey.advertisementRequest_Error"))
      );
      return rejectWithValue(errorResponse.messageKey);
    }
  }
);

export const sendContactRequest = createAsyncThunk<
  ContactRequest | null,
  ContactRequest,
  { rejectValue: string }
>(
  "Company/Send_Contact_Request",
  async (request: ContactRequest, { rejectWithValue, dispatch }) => {
    try {
      dispatch(
        setPendingNotification(i18n.t("messageKey.contactRequest_Pending"))
      );
      const response = await ContactRequestForm.SendContactRequest(request);
      dispatch(
        setSuccessNotification(i18n.t("messageKey.contactRequest_Success"))
      );
      return response.data;
    } catch (error) {
      const errorResponse = error as ApiResponse<null>;
      dispatch(setErrorNotification(i18n.t("messageKey.contactRequest_Error")));
      return rejectWithValue(errorResponse.messageKey);
    }
  }
);

export const resetCompanyById = createAsyncThunk(
  "authSlice/reset_company_by_id",
  async () => {
    return null;
  }
);

export const resetSaveClient = createAsyncThunk(
  "authSlice/reset_save_client",
  async () => {
    return null;
  }
);

export const resetSaveClientStatus = createAsyncThunk(
  "authSlice/reset_save_client_status",
  async () => {
    return null;
  }
);

export const resetDeletedClientStatus = createAsyncThunk(
  "authSlice/reset_deleted_client_status",
  async () => {
    return null;
  }
);

export const resetFaqMessageStatus = createAsyncThunk(
  "authSlice/reset_faq_message_status",
  async () => {
    return null;
  }
);

export const resetSearch = createAction("Company/Reset_Search");
// export const resetCompanyById = createAction("Company/Reset_Company_By_Id");
export const resetCompany = createAction("Company/Reset_Company");

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sendAdvertisementRequest.pending, (state, action) => {
        state.statuses.advertisementRequest = ApiStatus.Pending;
      })
      .addCase(sendAdvertisementRequest.fulfilled, (state, action) => {
        state.statuses.advertisementRequest = ApiStatus.Fulfilled;
      })
      .addCase(sendAdvertisementRequest.rejected, (state, action) => {
        state.statuses.advertisementRequest = ApiStatus.Rejected;
      })

      .addCase(getClients.pending, (state, action) => {})
      .addCase(getClients.fulfilled, (state, action) => {
        state.clients = action.payload;
      })
      .addCase(getClients.rejected, (state, action) => {})

      .addCase(faqMessage.pending, (state, action) => {
        state.statuses.faqMessage = ApiStatus.Pending;
      })
      .addCase(faqMessage.fulfilled, (state, action) => {
        state.statuses.faqMessage = ApiStatus.Fulfilled;
      })
      .addCase(faqMessage.rejected, (state, action) => {
        state.statuses.faqMessage = ApiStatus.Rejected;
      })

      .addCase(saveClient.pending, (state, action) => {
        state.statuses.saveClient = ApiStatus.Pending;
      })
      .addCase(saveClient.fulfilled, (state, action) => {
        state.statuses.saveClient = ApiStatus.Fulfilled;
        state.client = action.payload;
      })
      .addCase(saveClient.rejected, (state, action) => {
        state.statuses.saveClient = ApiStatus.Rejected;
      })

      .addCase(deleteClient.pending, (state, action) => {
        state.statuses.deleteClient = ApiStatus.Pending;
      })
      .addCase(deleteClient.fulfilled, (state, action) => {
        state.statuses.deleteClient = ApiStatus.Fulfilled;
      })
      .addCase(deleteClient.rejected, (state, action) => {
        state.statuses.deleteClient = ApiStatus.Rejected;
      })

      .addCase(resetSaveClient.fulfilled, (state, action) => {
        state.client = initialState.client;
      })
      .addCase(resetSaveClientStatus.fulfilled, (state, action) => {
        state.statuses.saveClient = initialState.statuses.saveClient;
      })
      .addCase(resetDeletedClientStatus.fulfilled, (state, action) => {
        state.statuses.deleteClient = initialState.statuses.deleteClient;
      })

      .addCase(sendUpdateDataRequest.pending, (state, action) => {
        state.statuses.updateDataRequest = ApiStatus.Pending;
      })
      .addCase(sendUpdateDataRequest.fulfilled, (state, action) => {
        state.statuses.updateDataRequest = ApiStatus.Fulfilled;
      })
      .addCase(sendUpdateDataRequest.rejected, (state, action) => {
        state.statuses.updateDataRequest = ApiStatus.Rejected;
      })

      .addCase(sendOrderRequest.pending, (state, action) => {
        state.statuses.orderRequest = ApiStatus.Pending;
      })
      .addCase(sendOrderRequest.fulfilled, (state, action) => {
        state.statuses.orderRequest = ApiStatus.Fulfilled;
      })
      .addCase(sendOrderRequest.rejected, (state, action) => {
        state.statuses.orderRequest = ApiStatus.Rejected;
      })

      .addCase(sendContactRequest.pending, (state, action) => {
        state.statuses.contactRequest = ApiStatus.Pending;
      })
      .addCase(sendContactRequest.fulfilled, (state, action) => {
        state.statuses.contactRequest = ApiStatus.Fulfilled;
      })
      .addCase(sendContactRequest.rejected, (state, action) => {
        state.statuses.contactRequest = ApiStatus.Rejected;
      })

      .addCase(getCompaniesAuthorized.pending, (state, action) => {
        state.statuses.search = ApiStatus.Pending;
      })
      .addCase(getCompaniesAuthorized.fulfilled, (state, action) => {
        state.companySearchResponse = action.payload;
        state.statuses.search = ApiStatus.Fulfilled;
      })
      .addCase(getCompaniesAuthorized.rejected, (state, action) => {
        state.statuses.search = ApiStatus.Rejected;
      })
      .addCase(getCompaniesUnauthorized.pending, (state, action) => {
        state.statuses.search = ApiStatus.Pending;
      })
      .addCase(getCompaniesUnauthorized.fulfilled, (state, action) => {
        state.companySearchResponse = action.payload;
        state.statuses.search = ApiStatus.Fulfilled;
      })
      .addCase(getCompaniesUnauthorized.rejected, (state, action) => {
        state.statuses.search = ApiStatus.Rejected;
      })
      .addCase(getCompanyFullDetailsById.fulfilled, (state, action) => {
        state.companyById = action.payload;
        state.statuses.getCompany = ApiStatus.Fulfilled;
      })
      .addCase(getCompanyFullDetailsById.pending, (state, action) => {
        state.statuses.getCompany = ApiStatus.Pending;
      })
      .addCase(getCompanyFullDetailsById.rejected, (state, action) => {
        state.statuses.getCompany = ApiStatus.Rejected;
      })
      .addCase(getAllCategories.fulfilled, (state, action) => {
        state.categories = action.payload;
      })
      .addCase(getAllBanners.fulfilled, (state, action) => {
        state.banners = action.payload;
      })
      .addCase(resetSearch, (state) => {
        state.companySearchResponse = initialState.companySearchResponse;
      })
      .addCase(resetCompanyById.fulfilled, (state) => {
        state.companyById = initialState.companyById;
      })
      .addCase(resetFaqMessageStatus.fulfilled, (state) => {
        state.statuses.faqMessage = initialState.statuses.faqMessage;
      })
      .addCase(resetCompany, (state) => {
        state.companySearchResponse = initialState.companySearchResponse;
      });
  },
});

export default companySlice.reducer;
