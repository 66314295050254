import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { colors } from "../../../app/theme/colors";
import AppButton from "../../../components/appButton/appButton";
import { useAppDispatch } from "../../../app/redux/hooks";
import { useTranslation } from "react-i18next";
import CustomOutlinedInput from "../../../components/outlinedInput/customOutlinedInput";
import { useFaqModalStyles } from "./faqModalStyles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { accordionData, frequentlyAskedQuestions } from "./faqModalData";
import { theme } from "../../../app/theme/theme";
import CloseIcon from "@mui/icons-material/Close";
import FaqRequest from "../../../models/faq/faqRequest";
import { BlurCircularSharp } from "@mui/icons-material";

interface Props {
  open: any;
  handleClose: any;
  handleFaqChange: any;
  faqMessageState: FaqRequest | null;
  handleSaveFaq: any;
  faqMessageStatus?: string;
}

export default function FaqModal({
  handleClose,
  open,
  handleFaqChange,
  faqMessageState,
  handleSaveFaq,
  faqMessageStatus
}: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { classes } = useFaqModalStyles();

  const handleDialogClose = () => {
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      fullWidth
      maxWidth="md"
      id="rateUsModal_dialog"
      PaperProps={{ sx: { borderRadius: "15px", overflow: "hidden" } }}
    >
      <IconButton
        edge="end"
        color="inherit"
        onClick={handleDialogClose}
        aria-label="close"
        sx={{
          position: "absolute",
          top: theme.spacing(1),
          right: theme.spacing(2),
        }}
      >
        <CloseIcon className={classes.closeIcon} />
      </IconButton>
      <DialogContent className={classes.dialogContent}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ textAlign: "center" }}
        >
          {frequentlyAskedQuestions.map((faq, index) => (
            <Grid
              key={index}
              item
              md={12}
              display={"flex"}
              justifyContent={"flex-start"}
              mt={1}
            >
              <Typography
                variant={
                  faq.isTitle ? "h4" : faq.isSubtitle ? "body2" : "body1"
                }
                sx={{
                  color: faq.color === "default" ? "" : colors.primary,
                  fontWeight: faq.isBold ? "bold" : "",
                  textAlign: faq.textAlign ? "left" : "",
                }}
              >
                {t(faq.content)}
              </Typography>
            </Grid>
          ))}
          <Grid item md={12}>
            {accordionData.map((accordion, index) => (
              <Accordion
                key={index}
                id={`panel${index + 1}-header`}
                sx={{ boxShadow: "none" }}
                defaultExpanded={accordion.isDefaultExpanded ? true : false}
              >
                <AccordionSummary
                  sx={{ color: accordion.color }}
                  expandIcon={
                    <ExpandMoreIcon sx={{ color: accordion.color }} />
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  {t(accordion.title)}
                </AccordionSummary>
                <AccordionDetails sx={{ display: "flex", textAlign: "left" }}>
                  {t(accordion.content)}
                </AccordionDetails>
              </Accordion>
            ))}
          </Grid>
          <Grid
            item
            md={12}
            display={"flex"}
            justifyContent={"flex-start"}
            mt={1}
            xs={12}
          >
            <Typography sx={{ color: colors.primary, fontWeight: "bold" }}>
              {t("faqPage.didntFindAnswer")}
            </Typography>
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
            display={"flex"}
            justifyContent={"flex-start"}
          >
            <Typography sx={{ color: colors.primary, fontWeight: "bold" }}>
              {t("faqPage.askUs")}
            </Typography>
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
            display={"flex"}
            justifyContent={"flex-start"}
          >
            <CustomOutlinedInput
              width="100%"
              multiline
              rows={5}
              value={faqMessageState?.question}
              name="question"
              onChange={handleFaqChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        className={classes.dialogActions}
        sx={{ justifyContent: "center" }}
      >
        <Grid container>
          <Grid
            item
            md={12}
            xs={12}
            display={"flex"}
            justifyContent={"flex-end"}
          >
            <AppButton
              id="rateUsModal_button_close"
              color={colors.primary}
              hover={colors.primary}
              label={t("buttons.send")}
              width="30%"
              onClick={handleSaveFaq}
              disabled={faqMessageStatus === "Pending" ? true : false}
              startIcon={faqMessageStatus === "Pending" ? <CircularProgress size={15} sx={{color: colors.primary}}/> : null}
            />
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
