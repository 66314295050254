export const routes = {
  home: "/",
  contact: "/contact",
  about: "/about-us",
  editions: "/editions",
  advertisementRequest: "/advertisement-request",
  updateData: "/update-data",
  advertisement: "/advertisement",
  digitalMarketing: "/digital-marketing",
  dashboard: "/dashboard",
  mySite: "/mySite",
  support: "/support",
  activities: "/activities",
  catalog: "/katalog_2024",
};
