import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Switch,
  FormControlLabel,
  Typography,
  styled,
  Grid,
} from "@mui/material";
import AppButton from "../appButton/appButton";
import { colors } from "../../app/theme/colors";
import { useTranslation } from "react-i18next";

interface CustomizeCookiesProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

const StyledDialogTitle = styled(DialogTitle)({
  textAlign: "left",
  fontSize: "1rem",
  fontWeight: "bold",
  color: "#000",
  padding: "16px 24px 8px",
});

const StyledDialogContent = styled(DialogContent)({
  padding: "8px 24px 24px",
});

const CookieDescription = styled(Typography)({
  fontSize: "0.875rem",
  color: "#000",
  marginBottom: "16px",
});

const BoldText = styled("span")({
  fontWeight: "bold",
});

const StyledFormControlLabel = styled(FormControlLabel)({
  display: "flex",
  margin: "10px 0",
  width: "100%",
  color: "#000",
});

const StyledSwitch = styled(Switch)({
  color: "#4285F4",
  "& .MuiSwitch-track": {
    backgroundColor: "#4285F4",
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#4285F4",
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#4285F4",
  },
});

const StyledDialogActions = styled(DialogActions)({
  display: "flex",
  justifyContent: "center",
  gap: "1rem",
  padding: "16px 24px",
});

const StyledButton = styled(Button)({
  padding: "8px 24px",
  fontSize: "0.875rem",
  fontWeight: "bold",
});

export const CustomizeCookies: React.FC<CustomizeCookiesProps> = ({
  isOpen,
  onRequestClose,
}) => {
  const { t } = useTranslation();
  const [functional, setFunctional] = useState(true);
  const [statistical, setStatistical] = useState(true);
  const [marketing, setMarketing] = useState(true);

  const savePreferences = () => {
    onRequestClose();
  };

  return (
    <Dialog open={isOpen} onClose={onRequestClose} maxWidth="sm" fullWidth>
      <StyledDialogTitle>{t("cookieConsent.modalTitle")}</StyledDialogTitle>
      <StyledDialogContent>
        <CookieDescription>
          {t("cookieConsent.modalDescFirst")}
          <ol>
            <li>
              {t("cookieConsent.modalDescSecond")}{" "}
              <BoldText>{t("cookieConsent.modalDescThird")}</BoldText>{" "}
              {t("cookieConsent.modalDescFourth")}
            </li>
            <li>
              {t("cookieConsent.modalDescFifth")}{" "}
              <BoldText>{t("cookieConsent.modalDescSixth")}</BoldText>{" "}
              {t("cookieConsent.modalDescSeventh")}
            </li>
            <li>
              {t("cookieConsent.modalDescEighth")}{" "}
              <BoldText>{t("cookieConsent.modalDescNinth")}</BoldText>{" "}
              {t("cookieConsent.modalDescTenth")}
            </li>
          </ol>
          {t("cookieConsent.modalDesc")}
        </CookieDescription>
        <Grid container>
          <Grid
            item
            md={3}
            xs={12}
            sx={{
              background: "rgb(250, 249, 246, 0.9)",
              border: "1px solid rgba(0, 0, 0, 0.1)",
            }}
          >
            <StyledFormControlLabel
              control={<StyledSwitch checked disabled />}
              label={t("cookieConsent.switchNecessary")}
            />
          </Grid>
          <Grid
            item
            md={3}
            xs={12}
            sx={{
              background: "rgb(250, 249, 246, 0.9)",
              border: "1px solid rgba(0, 0, 0, 0.1)",
            }}
          >
            <StyledFormControlLabel
              control={
                <StyledSwitch
                  checked={functional}
                  onChange={(e) => setFunctional(e.target.checked)}
                />
              }
              label={t("cookieConsent.switchFunctional")}
            />
          </Grid>
          <Grid
            item
            md={3}
            xs={12}
            sx={{
              background: "rgb(250, 249, 246, 0.9)",
              border: "1px solid rgba(0, 0, 0, 0.1)",
            }}
          >
            <StyledFormControlLabel
              control={
                <StyledSwitch
                  checked={statistical}
                  onChange={(e) => setStatistical(e.target.checked)}
                />
              }
              label={t("cookieConsent.switchStatistical")}
            />
          </Grid>
          <Grid
            item
            md={3}
            xs={12}
            sx={{
              background: "rgb(250, 249, 246, 0.9)",
              border: "1px solid rgba(0, 0, 0, 0.1)",
            }}
          >
            <StyledFormControlLabel
              control={
                <StyledSwitch
                  checked={marketing}
                  onChange={(e) => setMarketing(e.target.checked)}
                />
              }
              label={t("cookieConsent.swtichMarketing")}
            />
          </Grid>
        </Grid>
      </StyledDialogContent>
      <StyledDialogActions>
        <AppButton
          label={t("cookieConsent.saveSettings")}
          color={colors.green}
          hover={colors.green}
          onClick={savePreferences}
        />

        <AppButton
          label={t("buttons.close")}
          color={colors.primary}
          hover={colors.primary}
          onClick={onRequestClose}
        />
      </StyledDialogActions>
    </Dialog>
  );
};
