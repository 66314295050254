import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";
import { colors } from "../../../app/theme/colors";

export const useSubHeaderStyles = makeStyles()((theme: Theme) => ({
  phoneSectionWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    alignContent: "center",
    alignItems: "center",
  },
  icon: {
    fontSize: "35px",
    color: colors.primary
  },
  label: {
    fontWeight: "bold",
    marginLeft: "10px",
    color: colors.primary
  },
  marketingButtonSection: {
    display: "flex",
    justifyContent: "flex-end",
    alignContent: "center",
    alignItems: "center",
  },
  toolbar: {
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
  },
}));
