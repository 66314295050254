import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";
import { colors } from "../../app/theme/colors";

export const useEditionsSectionCardStyles = makeStyles()((theme: Theme) => ({
  card: {
    display: "flex",
    background: colors.searchLayout,
    borderRadius: "15px",
    width: "100%",
  },
  box: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  cardMedia: {
    width: 300,
  },
  label: {
    marginLeft: "5px",
    color: colors.primary,
  },
  content: {
    marginLeft: "5px",
  },
  icons: {
    color: colors.primary,
  },
  contentWrapper: {
    display: "flex",
    alignItems: "center",
    margin: "10px 0",
  },
  description: {
    color: colors.primary,
  },
  catalog: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  title: {
    color: colors.primary,
  },
}));
