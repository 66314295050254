import { Container, Grid, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../app/redux/hooks";
import { DashboardCard } from "./dashboardCard";
import { DashboardRatingCard } from "./dashboardRatingCard";
import DashboardMySiteChart from "./dashboardMySiteChart";
import { useEffect, useState } from "react";
import DashboardCardModal from "../modals/dashboardCardModal/dashboardCardModal";
import Diversity3OutlinedIcon from "@mui/icons-material/Diversity3Outlined";
import GroupsIcon from "@mui/icons-material/Groups";
import { MaterialSymbolsPartnerExchangeRounded } from "../tables/dashboardCardModalTable/clientsIcon";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { CompanyEntity } from "../../models/company/companyEntity";
import { CompanyClientsRequest } from "../../models/company/companyClientsRequest";
import DashboardCardModalTable from "../tables/dashboardCardModalTable/dashboardCardModalTable";
import {
  deleteAccountClients,
  deleteAccountFavorite,
  deleteAccountPartner,
  deleteAccountPotentialPartner,
  getAccountCompanyClients,
  getAccountFavorites,
  getAccountPartnerCompanies,
  getAccountPotentialPartnerCompanies,
  selectCompanyClients,
  selectDeletedAccountFavoriteStatus,
  selectDeletedClientStatus,
  selectDeletedPartnerStatus,
  selectDeletedPotentialPartnerStatus,
  selectSavedActivitiesSettingsStatus,
  selectSavedActivitiesStatus,
} from "../../slices/onboardingSlice";
import {
  getCompanyRatingForDashboard,
  getMySiteVisitsStatistic,
  selectMySiteStatistic,
  selectRatingForDashboard,
} from "../../slices/dashboardSlice";
import {
  getAccountCategories,
  selectAccountCategories,
} from "../../slices/categorySlice";
import DashboardActivitiesCard from "./dashboardActivitiesCard";

interface Props {
  companyClients: CompanyEntity[] | null;
  selectedAccountFavorites: CompanyEntity[] | null;
  selectedAccountPartner: CompanyEntity[] | null;
  selectedAccountPotentialPartners: CompanyEntity[] | null;
  accountFavorites: CompanyClientsRequest;
  accountClients: CompanyClientsRequest;
  accountPartners: CompanyClientsRequest;
  accountPotentialPartners: CompanyClientsRequest;
  setAccountFavorites: any;
}

const GradientBackground = styled(Grid)(({ theme }) => ({
  alignItems: "center",
  backgroundPosition: "center",
  [theme.breakpoints.down("md")]: {
    backgroundSize: "100%",
    backgroundPosition: "bottom",
  },
}));

export default function DashboardSection({
  companyClients,
  accountFavorites,
  accountClients,
  accountPartners,
  accountPotentialPartners,
  selectedAccountPartner,
  selectedAccountPotentialPartners,
  selectedAccountFavorites,
}: Props) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const savedActivityStatus = useAppSelector(
    selectSavedActivitiesSettingsStatus
  );
  const websiteStatistic = useAppSelector(selectMySiteStatistic);

  const selectedAccountClients = useAppSelector(selectCompanyClients);
  const deletedAccountClientStatus = useAppSelector(selectDeletedClientStatus);
  const deletedAccountPotentialPartnerStatus = useAppSelector(
    selectDeletedPotentialPartnerStatus
  );
  const deletedAccountPartnerStatus = useAppSelector(
    selectDeletedPartnerStatus
  );
  const deletedAccountFavoriteStatus = useAppSelector(
    selectDeletedAccountFavoriteStatus
  );
  const [isOpenPartnersModal, setIsOpenPartnersModal] =
    useState<boolean>(false);
  const [isOpenPotentialPartnersModal, setIsOpenPotentialPartnersModal] =
    useState<boolean>(false);
  const [isOpenClientsModal, setIsOpenClientsModal] = useState<boolean>(false);
  const [isOpenFavoritesModal, setIsOpenFavoritesModal] =
    useState<boolean>(false);

  const [selectedStatistic, setSelectedStatistic] = useState<number>(0);

  useEffect(() => {
    dispatch(getCompanyRatingForDashboard());
    dispatch(getAccountCategories());
    dispatch(getMySiteVisitsStatistic(selectedStatistic));
  }, []);

  useEffect(() => {
    if (savedActivityStatus === "Fulfilled") {
      dispatch(getAccountCategories());
    }
  }, [savedActivityStatus]);

  return (
    <Container maxWidth="xl" id="dashboardSection_container">
      <GradientBackground
        container
        spacing={5}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid
          item
          md={6}
          xs={12}
          justifyContent={"center"}
          textAlign={"center"}
        >
          <DashboardCard
            id="dashboardSection_card_partners"
            title={t("dashboardCard.dashboardCardAssociateTitle")}
            count={selectedAccountPartner ? selectedAccountPartner.length : 0}
            disabled={
              (selectedAccountPartner ?? []).length === 0 ? true : false
            }
            showMore={t("dashboardCard.dashboardCardAssociateShowMore")}
            backgroundColor="rgba(43, 167, 118, 0.4)"
            borderColor="rgba(43, 167, 118, 1)"
            bottomBorderColor="rgba(43, 167, 118, 1)"
            onClick={() => {
              setIsOpenPartnersModal(true);
            }}
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
          justifyContent={"center"}
          textAlign={"center"}
        >
          <DashboardCard
            id="dashboardSection_card_potential_associates"
            title={t("dashboardCard.dashboardCardPotentialAssociateTitle")}
            count={
              selectedAccountPotentialPartners
                ? selectedAccountPotentialPartners.length
                : 0
            }
            disabled={
              (selectedAccountPotentialPartners ?? []).length === 0
                ? true
                : false
            }
            showMore={t(
              "dashboardCard.dashboardCardPotentialAssociateShowMore"
            )}
            backgroundColor="rgba(77, 140, 245, 0.4)"
            borderColor="rgba(77, 140, 245, 1)"
            bottomBorderColor="rgba(77, 140, 245, 1)"
            onClick={() => {
              setIsOpenPotentialPartnersModal(true);
            }}
          />
        </Grid>

        <Grid
          item
          md={6}
          xs={12}
          justifyContent={"center"}
          textAlign={"center"}
        >
          <DashboardCard
            id="dashboardSection_card_clients"
            title={t("dashboardCard.dashboardCardClientTitle")}
            count={companyClients ? companyClients.length : 0}
            disabled={(companyClients ?? []).length === 0 ? true : false}
            showMore={t("dashboardCard.dashboardCardClientShowMore")}
            backgroundColor="rgba(190, 50, 92, 0.4)"
            borderColor="rgba(190, 50, 92, 1)"
            bottomBorderColor="rgba(190, 50, 92, 1)"
            onClick={() => {
              setIsOpenClientsModal(true);
            }}
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
          justifyContent={"center"}
          textAlign={"center"}
        >
          <DashboardCard
            id="dashboardSection_card_favorites"
            title={t("dashboardCard.dashboardCardFavoritesTitle")}
            count={
              selectedAccountFavorites ? selectedAccountFavorites.length : 0
            }
            disabled={
              (selectedAccountFavorites ?? []).length === 0 ? true : false
            }
            showMore={t("dashboardCard.dashboardCardFavoriteshowMore")}
            backgroundColor="rgba(223, 179, 55, 0.4)"
            borderColor="rgba(223, 179, 55, 1)"
            bottomBorderColor="rgba(223, 179, 55, 1)"
            onClick={() => {
              setIsOpenFavoritesModal(true);
            }}
          />
        </Grid>
        <Grid
          item
          md={12}
          xs={12}
          justifyContent={"center"}
          textAlign={"center"}
        >
          <DashboardMySiteChart
            websiteStatistic={websiteStatistic}
            selectedStatistic={selectedStatistic}
            setSelectedStatistic={setSelectedStatistic}
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
          justifyContent={"center"}
          textAlign={"center"}
        >
          <DashboardActivitiesCard
            id="dashboardSection_card_activities"
            title={t("dashboardCard.dashboardActivitiesCardTitle")}
            count="12"
            onClick={() => {}}
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
          justifyContent={"center"}
          textAlign={"center"}
        >
          <DashboardRatingCard
            id="dashboardSection_card_rating"
            title={t("dashboardCard.dashboardRatingCardTitle")}
            count="12"
            numberOfVisits="54"
            onClick={() => {}}
          />
        </Grid>
      </GradientBackground>
      <DashboardCardModal
        id="partners_modal"
        open={isOpenPartnersModal}
        handleClose={() => setIsOpenPartnersModal(false)}
        closeOnDisable={
          (selectedAccountPartner ?? []).length === 0 ? true : false
        }
        title={t("partnersModal.partnersModalTitle")}
        table={
          <DashboardCardModalTable
            rows={selectedAccountPartner}
            deletedStatus={deletedAccountPartnerStatus}
            tableColor="rgba(43, 167, 118, 0.1)"
            request={accountPartners}
            deleteCompany={deleteAccountPartner}
            getCompanies={getAccountPartnerCompanies}
            id="partners_modal_table"
            tooltipTitle={t("dashboardCard.dashboardRemove")}
            tooltipTitleSecond={t("dashboardCard.dashboardRemovePartner")}
          />
        }
        icon={
          <Diversity3OutlinedIcon
            sx={{ fontSize: 36, mr: 1, color: "rgba(43, 167, 118, 1)" }}
          />
        }
      />
      <DashboardCardModal
        id="potential_partners_modal"
        open={isOpenPotentialPartnersModal}
        handleClose={() => setIsOpenPotentialPartnersModal(false)}
        closeOnDisable={
          (selectedAccountPotentialPartners ?? []).length === 0 ? true : false
        }
        title={t("potentialPartnersModal.potentialPartnersModalTitle")}
        table={
          <DashboardCardModalTable
            rows={selectedAccountPotentialPartners}
            deletedStatus={deletedAccountPotentialPartnerStatus}
            tableColor="rgba(77, 140, 245, 0.1)"
            request={accountPotentialPartners}
            deleteCompany={deleteAccountPotentialPartner}
            getCompanies={getAccountPotentialPartnerCompanies}
            id="potential_partners_modal_table"
            tooltipTitle={t("dashboardCard.dashboardRemove")}
            tooltipTitleSecond={t("dashboardCard.dashboardRemovePotentialPartner")}
          />
        }
        icon={
          <GroupsIcon
            sx={{ fontSize: 36, mr: 1, color: "rgba(77, 140, 245, 1)" }}
          />
        }
      />
      <DashboardCardModal
        id="clients_modal"
        open={isOpenClientsModal}
        handleClose={() => setIsOpenClientsModal(false)}
        closeOnDisable={(companyClients ?? []).length === 0 ? true : false}
        title={t("clientsModal.clientsModalTitle")}
        table={
          <DashboardCardModalTable
            rows={selectedAccountClients}
            deletedStatus={deletedAccountClientStatus}
            tableColor="rgba(190, 50, 92, 0.1)"
            request={accountClients}
            deleteCompany={deleteAccountClients}
            getCompanies={getAccountCompanyClients}
            id="clients_modal_table"
            tooltipTitle={t("dashboardCard.dashboardRemove")}
            tooltipTitleSecond={t("dashboardCard.dashboardRemoveClient")}
          />
        }
        icon={
          <MaterialSymbolsPartnerExchangeRounded
            style={{
              fontSize: 36,
              marginRight: "8px",
              color: "rgba(190, 50, 92, 1)",
            }}
          />
        }
      />
      <DashboardCardModal
        id="favorites_modal"
        open={isOpenFavoritesModal}
        handleClose={() => setIsOpenFavoritesModal(false)}
        closeOnDisable={
          (selectedAccountFavorites ?? []).length === 0 ? true : false
        }
        title={t("favoritesModal.favoritesModalTitle")}
        table={
          <DashboardCardModalTable
            rows={selectedAccountFavorites}
            deletedStatus={deletedAccountFavoriteStatus}
            tableColor="rgba(223, 179, 55, 0.1)"
            request={accountFavorites}
            deleteCompany={deleteAccountFavorite}
            getCompanies={getAccountFavorites}
            id="favorites_modal_table"
            tooltipTitle={t("dashboardCard.dashboardRemove")}
            tooltipTitleSecond={t("dashboardCard.dashboardRemoveFavorite")}
          />
        }
        icon={
          <BookmarkIcon
            style={{
              fontSize: 36,
              marginRight: "8px",
              color: "rgba(223, 179, 55, 1)",
            }}
          />
        }
      />
    </Container>
  );
}
