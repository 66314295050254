import { AxiosResponse } from "axios";
import i18n from "../../i18n";
import { api_um } from "./api_um";
import {
  setErrorNotification,
  setWarningNotification,
} from "../../slices/notificationSlice";
import { logout } from "../../slices/authSlice";
import { api_poslovni_imenik } from "./api_poslovni_imenik";

export default function AddInterceptors(store: any) {
  const { dispatch } = store;

  // Request interceptor for Poslovni Imenik
  api_poslovni_imenik.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("accessToken");

      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Response interceptor for Poslovni Imenik
  api_poslovni_imenik.interceptors.response.use(
    (res: AxiosResponse) => {
      return res.data;
    },
    (err) => {
      if (err.code === "ERR_NETWORK") {
        dispatch(setErrorNotification(i18n.t("messageKey.NetworkError")));
      } else {
        const statusCode = err?.response?.status;

        if (statusCode === 401) {
          dispatch(
            setWarningNotification(i18n.t("messageKey.Logout_ExpiredToken"))
          );
          dispatch(logout());
        }
      }

      return Promise.reject(err?.response?.data);
    }
  );

  // Request interceptor for UM
  api_um.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("accessToken");

      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Response interceptor for UM
  api_um.interceptors.response.use(
    (res: AxiosResponse) => {
      return res.data;
    },
    (err) => {
      if (err.code === "ERR_NETWORK") {
        dispatch(setErrorNotification(i18n.t("messageKey.NetworkError")));
      } else {
        const statusCode = err?.response?.status;

        if (statusCode === 401) {
          dispatch(
            setWarningNotification(i18n.t("messageKey.Logout_ExpiredToken"))
          );
          dispatch(logout());
        }
      }

      return Promise.reject(err?.response?.data);
    }
  );
}
