import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Autocomplete,
  Checkbox,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { CategoryShortDetail } from "../../models/company/categoryShortDetail";
import { AdvanceSearchFilterModel } from "../../models/advanceSearchFilterModel";
import { useMainHeaderStyles } from "../../features/header/mainHeader/mainHeaderStyles";
import { useTranslation } from "react-i18next";

type Props = {
  advanceSearch: AdvanceSearchFilterModel;
  setAdvanceSearch: Dispatch<SetStateAction<AdvanceSearchFilterModel>>;
  categoryShortDetails: CategoryShortDetail[] | null;
  categories: CategoryShortDetail[];
  setCategories: Dispatch<SetStateAction<CategoryShortDetail[]>>;
};

export default function SelectCategories({
  advanceSearch,
  setAdvanceSearch,
  categoryShortDetails,
  categories,
  setCategories,
}: Props) {
  const { t } = useTranslation();
  const { classes } = useMainHeaderStyles();
  const theme = useTheme();
  const [selectedCount, setSelectedCount] = useState(0);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const onChange = (
    e: React.SyntheticEvent<Element, Event>,
    newCategories: CategoryShortDetail[]
  ) => {
    const selectedCategories = [...newCategories];

    const removedCategories = categories.filter(
      (category) => !selectedCategories.includes(category)
    );

    setCategories(selectedCategories);

    const selectedParentIds = selectedCategories
      .filter((category) => category.parentId)
      .map((category) => category.parentId);
    const updatedCategories = selectedCategories.filter(
      (category) =>
        !selectedParentIds.includes(category.id) ||
        !removedCategories.includes(category)
    );

    setCategories(updatedCategories);
    setSelectedCount(updatedCategories.length);

    setAdvanceSearch({
      ...advanceSearch,
      categoryIds: [...updatedCategories.map((x) => x.id)],
    });
  };

  return (
    <Autocomplete
      limitTags={1}
      size="small"
      multiple
      onChange={onChange}
      options={categoryShortDetails ?? []}
      disableCloseOnSelect
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option, { selected }) => (
        <li {...props} style={{ borderBottom: "1px solid rgba(0,0,0,0.1)" }}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{
              marginRight: 8,
              marginLeft: option.parentId ? 24 : 0,
            }}
            checked={selected}
          />
          {option.name}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t("header.categoryInput") ?? ""}
          variant="outlined"
          size="small"
          sx={{
            "& label.Mui-focused": {
              color: "white",
            },
            "& .MuiInputLabel-outlined": {
              transition: "all 0.2s",
            },
            "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
              transform: "translate(14px, -13px) scale(0.75)",
            },
          }}
          fullWidth
          className={classes.autocompleteTextField}
          value={categories
            .map((category) => category.name)
            .concat(selectedCount > 2 ? ` + ${selectedCount - 2} ostalih` : "")
            .join(", ")}
        />
      )}
      className={classes.autocomplete}
      sx={{
        width:isMobile ?"100%" : "80%",
        textAlign:"right",
        boxShadow: "0px 0px 7px rgba(0, 0, 0, 0.1)",
      }}
      id="select-category-and-subcategories"
    />
  );
}
