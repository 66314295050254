import React from "react";
import {
  useDayOfWeekTranslation,
  useMonthTranslation,
} from "./dashboardChartTranslation";
import { TooltipProps } from "recharts";
import { Grid, Typography } from "@mui/material";
import { colors } from "../../app/theme/colors";
import { useTranslation } from "react-i18next";

interface CustomTooltipProps extends TooltipProps<any, any> {
  selectedStatistic?: number | null;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  active,
  payload,
  label,
  selectedStatistic,
}) => {
  const { t } = useTranslation();
  const { dayOfWeekTranslation } = useDayOfWeekTranslation();
  const { monthTranslation } = useMonthTranslation();

  if (active && payload && payload.length) {
    let translatedLabel = label;

    if (selectedStatistic === 0) {
      translatedLabel = dayOfWeekTranslation(label);
    } else if (selectedStatistic === 1) {
      translatedLabel = monthTranslation(Number(label));
    }

    return (
      <Grid item md={12} style={tooltipStyles.container}>
        <Typography>{translatedLabel}</Typography>
        <Typography sx={{ color: colors.primary }}>
          {t("mySiteStatisticChart.numberOfVisits") ?? ""} {payload[0].value}
        </Typography>
      </Grid>
    );
  }

  return null;
};

const tooltipStyles = {
  container: {
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    border: "1px solid #ccc",
    padding: "10px",
    borderRadius: "5px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
  },
  label: {
    margin: 0,
    fontWeight: "bold",
  },
};

export default CustomTooltip;
