import { AdvanceSearchFilterModel } from "../advanceSearchFilterModel";
import { AdvertisementRequestModel } from "../advertisementRequestModel";
import { PagedResult } from "../app/pagedResult";
import ForgotPasswordRequest from "../auth/forgotPasswordRequest";
import { LoginRequest } from "../auth/loginRequest";
import SetForgotPasswordModel from "../auth/setForgotPasswordModel";
import SetPasswordModel from "../auth/setPasswordModel";
import { CompanyModel } from "../company/companyModel";
import FaqRequest from "../faq/faqRequest";
import MySitePhoneRequest from "../mySite/mySitePhoneModel";
import RemoveWebsitePartition from "../mySite/removeWebsitePartition";
import OverallRatingRequest from "../overallRating/overallRatingRequest";
import SearchResultsActionsRequest from "../searchResultsActions/searchResultsActionsRequest";

export const EmptyGuid = "00000000-0000-0000-0000-000000000000";

export const INIT_ADVERTISEMENT_REQUEST: AdvertisementRequestModel = {
  companyName: "",
  email: "",
  homeNumber: "",
  message: "",
  choosenPackage: "",
  phoneNumber: "",
  place: "",
  street: "",
  taxId: "",
};

export const INIT_PAGE_SIZE: number = 5;

export const INIT_ADVANCE_SEARCH: AdvanceSearchFilterModel = {
  companyName: "",
  shortDescription: "",
  categoryIds: [],
  placeIds: [],
  pageNumber: 1,
  pageSize: INIT_PAGE_SIZE,
};

export const INIT_PAGED_RESULT_COMPANY: PagedResult<CompanyModel> = {
  items: [],
  pageNumber: 1,
  pageSize: INIT_PAGE_SIZE,
  totalCount: 0,
  totalPages: 0,
};

export const INIT_LOGIN_REQUEST: LoginRequest = {
  email: "",
  password: "",
};

export const INIT_FORGOT_PASSWORD: ForgotPasswordRequest = {
  email: "",
};

export const INIT_SET_PASSWORD: SetPasswordModel = {
  email: "",
  newPassword: "",
  oldPassword: "",
  confirmNewPassword: "",
  code: "",
};

export const INIT_SET_FORGOT_PASSWORD_MODEL: SetForgotPasswordModel = {
  code: "",
  newPassword: "",
  email: "",
};

export const INIT_SEARCH_RESULTS_ACTIONS: SearchResultsActionsRequest = {
  companyId: null,
};

export const INIT_OVERALL_RATING: OverallRatingRequest = {
  companyId: "",
  rating: 0,
};

export const INIT_MY_SITE_PHONE: MySitePhoneRequest = {
  id: EmptyGuid,
  orderNumber: 0,
  phones: [],
};

export default interface TextSection {
  type: string;
  content: string;
  orderNumber: number;
}

export const INIT_REMOWE_WEBSITE_PARTITION: RemoveWebsitePartition = {
  websitePartitionId: EmptyGuid,
};

export const INIT_FAQ: FaqRequest = {
  question: "",
};
