import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useDashboardSectionStyles } from "./dashboardSectionStyles";
import CompanyRating from "../../models/dashboard/dashboardRatingResponse";
import { useTranslation } from "react-i18next";

interface Props {
  ratingForDashboard: any;
}

const DashboardRatingChart = ({ ratingForDashboard }: Props) => {
  const { t } = useTranslation();
  const { classes } = useDashboardSectionStyles();

  const chartData = ratingForDashboard?.companyRatingsByDate.map(
    (item: CompanyRating) => ({
      date: new Date(item.date).toLocaleDateString(),
      numberOfRatings: item.numberOfRatings,
    })
  );

  return (
    <ResponsiveContainer
      width="100%"
      height={150}
      className={classes.ratingChartContainer}
    >
      <LineChart
        data={chartData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Legend
          content={(props) => {
            const payload = props.payload;
            if (!payload) return null;
            return (
              <ul
                className="custom-legend"
                style={{ display: "flex", justifyContent: "center" }}
              >
                {payload.map((entry, index) => (
                  <li
                    key={`item-${index}`}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "10px",
                    }}
                  >
                    <span
                      style={{
                        backgroundColor: entry.color,
                        width: "10px",
                        height: "10px",
                        marginRight: "5px",
                        display: "inline-block",
                      }}
                    />
                    <span style={{ color: entry.color }}>{entry.value}</span>
                  </li>
                ))}
              </ul>
            );
          }}
        />
        <Line
          type="monotone"
          dataKey="numberOfRatings"
          stroke="#8884d8"
          activeDot={{ r: 8 }}
          name={t("dashbordRatingCard.numberOfRatings") || ""}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default DashboardRatingChart;
