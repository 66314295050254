import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";
import { colors } from "../../app/theme/colors";

export const useSearchResultLayoutStyles = makeStyles()((theme: Theme) => ({
  cardContainer: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  autocompleteTextField: {
    "& .MuiInputBase-root": {
      borderRadius: "5px",
      background: "white",
    },
  },
  autocomplete: {
    "& .MuiOutlinedInput-root.Mui-focused": {
      "& > fieldset": {
        borderColor: colors.sectionTitle,
      },
    },
  },
  card: {
    display: "flex",
    background: colors.searchLayout,
    borderRadius: "15px",
    position: "relative",
  },
  paidCard: {
    display: "flex",
    backgroundColor: colors.gold,
    borderRadius: "15px",
    cursor: "pointer",
  },
  box: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  cardMedia: {
    objectFit: "contain",
    padding: "15px",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    maxHeight: "300px",
  },

  gridItem: {
    justifyContent: "center",
    alignItems: "center",
  },
  label: {
    marginLeft: "5px",
    color: colors.primary,
  },
  content: {
    marginLeft: "5px",
  },
  icons: {
    color: colors.primary,
  },
  contentWrapper: {
    display: "flex",
    alignItems: "center",
    margin: "10px 0",
  },
  description: {
    color: colors.primary,
  },
  activityTagWrapper: {
    padding: "5px 10px;",
    color: "#fff",
    bordeRadius: "4px",
    background: colors.categoryTag,
    justifyContent: "flex-end",
    borderRadius: "15px",
  },
  fixedBottomRight: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  premiumMarkWrapper: {
    position: "absolute",
    top: 0,
    left: 0,
    marginRight: "20px",
    marginLeft: "-65px",
    backgroundColor: "#CDB537",
    color: "white",
    padding: "5px 60px",
    width: "200px",
    fontSize: "14px",
    transform: "rotate(320deg)",
  },
  instagramIcon: {
    color: "#DD2A7B",
    transition: "color 0.3s ease-in-out, transform 0.3s ease-in-out",
    cursor: "pointer",
    fontSize: 30,
    "&:hover": {
      color: "#DD2A7B",
      transform: "rotate(360deg)",
    },
  },
  facebookIcon: {
    color: "#4267B2",
    marginLeft: "5px",
    transition: "color 0.3s ease-in-out, transform 0.3s ease-in-out",
    cursor: "pointer",
    fontSize: 30,
    "&:hover": {
      color: "#587ac1",
      transform: "rotate(360deg)",
    },
  },
  linkedInIcon: {
    color: "#0077B5",
    transition: "color 0.3s ease-in-out, transform 0.3s ease-in-out",
    cursor: "pointer",
    fontSize: 30,
    "&:hover": {
      color: "#0077B5",
      transform: "rotate(360deg)",
    },
  },
  twitterIcon: {
    color: "#1DA1F2",
    transition: "color 0.3s ease-in-out, transform 0.3s ease-in-out",
    cursor: "pointer",
    fontSize: 30,
    "&:hover": {
      color: "#56b9f5",
      transform: "rotate(360deg)",
    },
  },
  youtubeIcon: {
    color: "#FF0000",
    marginLeft: "5px",
    transition: "color 0.3s ease-in-out, transform 0.3s ease-in-out",
    cursor: "pointer",
    fontSize: 30,
    "&:hover": {
      color: "#ff4d4d",
      transform: "rotate(360deg)",
    },
  },
  tiktokIcon: {
    color: "#000000",
    marginLeft: "5px",
    transition: "color 0.3s ease-in-out, transform 0.3s ease-in-out",
    cursor: "pointer",
    fontSize: 25,
    marginBlock: "4px",
    marginRight: "2px",
    "&:hover": {
      color: "#404040",
      transform: "rotate(360deg)",
    },
  },
  avatar: {
    maxWidth: 200,
    maxHeight: 200,
    minWidth: 200,
    minHeight: 200,
    objectFit: "contain",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    fontSize: "50px",
    background: colors.avatar,
    [theme.breakpoints.down(1600)]: {
      maxWidth: 200,
      maxHeight: 200,
      minWidth: 200,
      minHeight: 200,
      alignItems: "center",
      objectFit: "contain",
    },
    [theme.breakpoints.down(1800)]: {
      maxWidth: 250,
      maxHeight: 250,
      minWidth: 200,
      minHeight: 200,
      alignItems: "center",
      objectFit: "contain",
    },
    [theme.breakpoints.down(1800)]: {
      maxWidth: 250,
      maxHeight: 250,
      minWidth: 200,
      minHeight: 200,
      alignItems: "center",
      objectFit: "contain",
    },
  },
  positionRelative: {
    position: "relative",
  },
  shortDescWrapper: {},
  contactContent: {
    marginLeft: "5px",
    textDecoration: "none",
    cursor: "pointer",
    color: colors.inputLabel,
    "&:hover": {
      color: colors.primary,
    },
  },
}));
