import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";
import { colors } from "../../app/theme/colors";

export const useFooterStyles = makeStyles()((theme: Theme) => ({
  logo: {
    width: "200px",
    cursor: "pointer",
  },
  logoShort: {
    width: "55px",
    height: "55px",
  },
  fastLinksTitle: {
    color: "white",
    cursor: "pointer",
    position: "relative",
    overflow: "hidden",
    transition: "all 0.3s ease-out",
    display: "inline-block",
  },

  gridItem: {
    position: "relative",
    height: "2px",
    "&:hover $fastLinksTitle::before": {
      height: "100%",
    },
  },
  description: {
    color: "white",
    fontSize: 12,
  },
  sectionTitle: {
    color: "white",
  },
  secondSectionContent: {
    color: "white",
    marginLeft: "5px",
  },
  icon: {
    color: "white",
  },
  iconWrapper: {
    display: "flex",
  },
  sectionThreeContent: {
    color: "white",
    marginTop: "5px",
  },
  copyrightSection: {
    backgroundColor: "#212121",
  },
  copyRightSectionContent: {
    color: "white",
    fontWeight: 12,
  },
  instagramIcon: {
    color: "white",
    transition: "color 0.3s ease-in-out, transform 0.3s ease-in-out",
    cursor: "pointer",
    fontSize: 20,
    "&:hover": {
      color: "#DD2A7B",
      transform: "rotate(360deg)",
    },
  },
  facebookIcon: {
    color: "white",
    marginLeft: "5px",
    transition: "color 0.3s ease-in-out, transform 0.3s ease-in-out",
    cursor: "pointer",
    fontSize: 20,
    "&:hover": {
      color: "#4267B2",
      transform: "rotate(360deg)",
    },
  },
  label: {
    color: "#F9F9F9",
    marginLeft: "5px",
    textDecoration: "none",
  },
}));
