import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";
import { colors } from "../../app/theme/colors";

export const useDashboardSectionStyles = makeStyles()((theme: Theme) => ({
  title: {
    color: "#635F5FBA",
  },
  subtitle: {
    color: "#635F5FBA",
    marginLeft: "5px",
  },
  activitiesChipContainer: {
    overflowY: "auto",
    maxHeight: "200px",
  },
  sectionTitle: {
    color: "white",
    fontWeight: "bold",
  },
  iconWrapper: {
    display: "flex",
  },
  icon: {
    color: "white",
    fontSize: "50px",
  },
  label: {
    color: "#F9F9F9",
    marginLeft: "5px",
    textDecoration: "none",
  },
  closeIcon: {
    color: "white",
  },
  chip: {
    background: "white",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "#C8C7C7",
  },
  closeIconChip: {
    fontSize: "12px!important",
    color: "red!important",
  },
  dashboardCardWrapper: {
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
    textAlign: "center",
    maxWidth: "100%",
    width: "100%",
    margin: "auto",
    cursor: "pointer",
  },
  dashboardCardTitleWrapper: {
    textAlign: "center",
  },
  dashboardCardShowMoreWrapper: {
    textAlign: "center",
    paddingBottom: "5px",
  },
  dashboardCardBox: {
    width: "200px",
    textAlign: "center",
    margin: "auto",
    borderRadius: "20px",
  },
  dashboardCardBottomLine: {
    bottom: 0,
    left: 0,
    width: "100%",
    height: "5px",
  },
  ratingCardWrapper: {
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
    textAlign: "center",
    maxWidth: "100%",
    width: "100%",
    margin: "auto",
    cursor: "pointer",
    minHeight: "300px",
  },
  ratingCardTitleWrapper: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  ratingCardTitleDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  ratingCardContentWrapper: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  ratingCardValue: {
    color: "#635F5FBA",
    fontWeight: "bold",
  },
  ratingCardValueWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  activitiesCardWrapper: {
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
    // textAlign: "center",
    maxWidth: "100%",
    width: "100%",
    // margin: "auto",
    cursor: "pointer",
    minHeight: "300px",
  },
  activitiesCardTitleWrapper: {
    textAlign: "center",
  },
  ratingChartContainer: {
    maxWidth: "100%",
    margin: "auto",
  },
  mySiteChartWrapper: {
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
    textAlign: "center",
    maxWidth: "100%",
    width: "100%",
    margin: "auto",
    cursor: "pointer",
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  mySiteChartContainer: {
    maxWidth: "100%",
    margin: "auto",
  },
  dashboardCardWrapperDisabled: {
    pointerEvents: "none",
    opacity: 0.5,
  },
  autocompleteTextField: {
    "& .MuiInputBase-root": {
      borderRadius: "5px",
      background: "white",
    },
  },
  autocomplete: {
    "& .MuiOutlinedInput-root.Mui-focused": {
      "& > fieldset": {
        borderColor: colors.sectionTitle,
      },
    },
  },
}));
