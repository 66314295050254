import almira1 from "../../images/hotelAlmira/almira1.jpg";
import almira2 from "../../images/hotelAlmira/almira2.jpg";
import almira3 from "../../images/hotelAlmira/almira3.jpg";
import almira4 from "../../images/hotelAlmira/almira4.jpg";
import almira5 from "../../images/hotelAlmira/almira5.jpg";

import asha1 from "../../images/autoAsha/asha1.jpg";
import asha2 from "../../images/autoAsha/asha2.jpg";
import asha3 from "../../images/autoAsha/asha3.jpg";
import asha4 from "../../images/autoAsha/asha4.jpg";
import asha5 from "../../images/autoAsha/asha5.jpg";

import print1 from "../../images/print/print1.jpg";
import print2 from "../../images/print/print2.jpg";
import print3 from "../../images/print/print3.jpg";
import print4 from "../../images/print/print4.jpg";
import print5 from "../../images/print/print5.jpg";

import bmb1 from "../../images/bmb/bmb1.jpg";
import bmb2 from "../../images/bmb/bmb2.jpg";
import bmb3 from "../../images/bmb/bmb3.jpg";
import bmb4 from "../../images/bmb/bmb4.jpg";
import bmb5 from "../../images/bmb/bmb5.jpg";

export const hotelAlmiraImages = [
  {
    src: almira1,
    thumbnail: almira1,
    originalAlt: "Image 1",
    thumbnailAlt: "Image 1",
  },
  {
    src: almira2,
    thumbnail: almira2,
    originalAlt: "Image 2",
    thumbnailAlt: "Image 2",
  },
  {
    src: almira3,
    thumbnail: almira3,
    originalAlt: "Image 3",
    thumbnailAlt: "Image 3",
  },
  {
    src: almira4,
    thumbnail: almira4,
    originalAlt: "Image 4",
    thumbnailAlt: "Image 4",
  },
  {
    src: almira5,
    thumbnail: almira5,
    originalAlt: "Image 5",
    thumbnailAlt: "Image 5",
  },
];

export const autoAshaImages = [
  {
    src: asha1,
    thumbnail: asha1,
    originalAlt: "Image 1",
    thumbnailAlt: "Image 1",
  },
  {
    src: asha2,
    thumbnail: asha2,
    originalAlt: "Image 2",
    thumbnailAlt: "Image 2",
  },
  {
    src: asha3,
    thumbnail: asha3,
    originalAlt: "Image 3",
    thumbnailAlt: "Image 3",
  },
  {
    src: asha4,
    thumbnail: asha4,
    originalAlt: "Image 4",
    thumbnailAlt: "Image 4",
  },
  {
    src: asha5,
    thumbnail: asha5,
    originalAlt: "Image 5",
    thumbnailAlt: "Image 5",
  },
];

export const printImages = [
    {
      src: print1,
      thumbnail: print1,
      originalAlt: "Image 1",
      thumbnailAlt: "Image 1",
    },
    {
      src: print2,
      thumbnail: print2,
      originalAlt: "Image 2",
      thumbnailAlt: "Image 2",
    },
    {
      src: print3,
      thumbnail: print3,
      originalAlt: "Image 3",
      thumbnailAlt: "Image 3",
    },
    {
      src: print4,
      thumbnail: print4,
      originalAlt: "Image 4",
      thumbnailAlt: "Image 4",
    },
    {
      src: print5,
      thumbnail: print5,
      originalAlt: "Image 5",
      thumbnailAlt: "Image 5",
    },
  ];

  export const bmbImages = [
    {
      src: bmb1,
      thumbnail: bmb1,
      originalAlt: "Image 1",
      thumbnailAlt: "Image 1",
    },
    {
      src: bmb2,
      thumbnail: bmb2,
      originalAlt: "Image 2",
      thumbnailAlt: "Image 2",
    },
    {
      src: bmb3,
      thumbnail: bmb3,
      originalAlt: "Image 3",
      thumbnailAlt: "Image 3",
    },
    {
      src: bmb4,
      thumbnail: bmb4,
      originalAlt: "Image 4",
      thumbnailAlt: "Image 4",
    },
    {
      src: bmb5,
      thumbnail: bmb5,
      originalAlt: "Image 5",
      thumbnailAlt: "Image 5",
    },
  ];