import {
  Avatar,
  CardMedia,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { colors } from "../../../app/theme/colors";
import AppButton from "../../../components/appButton/appButton";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PublicIcon from "@mui/icons-material/Public";
import Gallery from "../../imageGallery/imageGallery";
import StarIcon from "@mui/icons-material/Star";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import {
  getCompanyFullDetailsById,
  selectCompanyById,
  selectLoadingFullDetailsStatus,
} from "../../../slices/companySlice";
import { useTranslation } from "react-i18next";
import { selectDisplayLanguage } from "../../../slices/displayLanguageSlice";
import { useFullDetailsModalStyles } from "../fullDetailsModal/fullDetailsModalStyles";
import TranslateDay from "../fullDetailsModal/translateDay";
import { formatTimeTo24Hours } from "../fullDetailsModal/fullDetailsModalUtilis";
import { Env_UM } from "../../../app/redux/api_um";

interface Props {
  open: any;
  handleClose: any;
  company: any;
}

export default function FullDetailsModalTextualAds({
  handleClose,
  open,
  company,
}: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { classes } = useFullDetailsModalStyles();
  const theme = useTheme();

  const displayLanugage = useAppSelector(selectDisplayLanguage);
  const companyById = useAppSelector(selectCompanyById);
  const fullDetailsStatus = useAppSelector(selectLoadingFullDetailsStatus);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const stars = Array(5)
    .fill(null)
    .map((_, i) => {
      if (i < Math.floor(companyById?.generalData?.numberOfStars ?? 0)) {
        return (
          <StarIcon
            fontSize="large"
            key={i}
            sx={{
              color: "#F0E700",
            }}
          />
        );
      } else {
        return null;
      }
    });
  useEffect(() => {
    if (company && company.companyId) {
      dispatch(getCompanyFullDetailsById(company.companyId));
    }
  }, [company?.companyId]);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
      id="fullDetailsModal_dialog"
    >
      {fullDetailsStatus === "Pending" ? (
        <DialogContent>
          <Grid
            container
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <CircularProgress />
          </Grid>
        </DialogContent>
      ) : (
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
              {companyById?.generalData?.numberOfStars != null && (
                <Grid
                  id="fullDetailsModal_stars"
                  container
                  md={12}
                  my={2}
                  xs={12}
                  display={"flex"}
                  justifyContent={"center"}
                  sx={{ background: "white" }}
                >
                  {stars}
                </Grid>
              )}
              {companyById?.logo === null ? (
                <Grid
                  container
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Avatar className={classes.avatar}>
                    {companyById?.generalData.name
                      ? companyById.generalData.name
                          .split(" ")
                          .slice(0, 1)
                          .map((word) => word[0])
                          .join("")
                          .toUpperCase()
                      : ""}
                  </Avatar>
                </Grid>
              ) : (
                <Grid
                  container
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <CardMedia
                    id="fullDetailsModal_img_logo"
                    component="img"
                    className={classes.cardMedia}
                    image={Env_UM.Prod + "/" + companyById?.logo?.logoBase64}
                  />
                </Grid>
              )}
            </Grid>
            <Grid item md={4} xs={12}>
              <Grid item md={12} xs={12} mb={1}>
                <Typography variant="h5" className={classes.title}>
                  {companyById?.generalData.name}
                </Typography>
              </Grid>
              {companyById?.locations && companyById.locations.length > 0 ? (
                <Grid item md={12} display="flex" mb={1}>
                  <PublicIcon className={classes.icon} />
                  <Typography
                    variant="body1"
                    className={classes.label}
                    component="a"
                  >
                    {t("searchResultLayout.cardAddress")}
                  </Typography>
                  <Typography
                    variant="body1"
                    className={classes.contactContentAddress}
                    id="fullDetailsModal_address"
                    component={"a"}
                    href={companyById?.locations[0]?.urlCoordinates}
                    target="_blank"
                  >
                    {companyById?.generalData.placeName},
                    {companyById?.generalData.streetName},
                    {companyById?.generalData.homeNumber}
                  </Typography>
                </Grid>
              ) : (
                <Grid item md={12} display="flex" mb={1}>
                  <PublicIcon className={classes.icon} />
                  <Typography
                    variant="body1"
                    className={classes.label}
                    component="a"
                  >
                    {t("searchResultLayout.cardAddress")}
                  </Typography>
                  <Typography
                    variant="body1"
                    className={classes.contactContentAddress}
                    id="fullDetailsModal_address"
                    component={"a"}
                    href={`https://maps.google.com/maps?q=${encodeURIComponent(
                      `${companyById?.generalData.placeName}, ${companyById?.generalData.streetName}, ${companyById?.generalData.homeNumber}`
                    )}`}
                    target="_blank"
                  >
                    {companyById?.generalData.placeName},
                    {companyById?.generalData.streetName},
                    {companyById?.generalData.homeNumber}
                  </Typography>
                </Grid>
              )}
              {companyById?.phones?.map((phone: any, index: number) => (
                <Grid
                  item
                  md={12}
                  xs={12}
                  display="flex"
                  alignItems={"center"}
                  sx={{ marginBottom: "5px" }}
                >
                  <Grid
                    container
                    key={index}
                    alignItems="center"
                    sx={{ marginBottom: "5px" }}
                  >
                    <LocalPhoneOutlinedIcon className={classes.icon} />
                    <Typography variant="body1" className={classes.label}>
                      {phone.typeName.charAt(0).toUpperCase() +
                        phone.typeName.slice(1)}
                      :
                    </Typography>
                    <Typography
                      id="fullDetailsModal_value_phone-number"
                      component="a"
                      href={`tel:${phone.phoneNumber}`}
                      variant="body1"
                      className={classes.contactContent}
                    >
                      {phone.phoneNumber}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
              {companyById?.websites?.map((website: any, index: any) => {
                let url = website.url;

                if (
                  !website.url.includes("http://") &&
                  !website.url.includes("https://")
                ) {
                  url = `http://${website.url}`;
                }

                return (
                  <Grid
                    item
                    md={12}
                    xs={12}
                    display="flex"
                    alignItems="center"
                    sx={{ marginBottom: "5px" }}
                  >
                    <Grid
                      container
                      key={index}
                      alignItems="center"
                      sx={{ marginBottom: "5px" }}
                    >
                      <OpenInNewOutlinedIcon className={classes.icon} />
                      <Typography variant="body1" className={classes.label}>
                        {t("fullDetailsModal.cardWeb")}:
                      </Typography>
                      <Typography
                        id="fullDetailsModal_value_website"
                        component="a"
                        href={url}
                        target="_blank"
                        variant="body1"
                        className={classes.contactContent}
                      >
                        {website.url}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}

              {companyById?.emails?.map((email: any, index: number) => (
                <Grid
                  item
                  md={12}
                  xs={12}
                  display="flex"
                  alignItems={"center"}
                  sx={{ marginBottom: "5px" }}
                  key={index}
                >
                  <Grid
                    container
                    alignItems="center"
                    sx={{ marginBottom: "5px" }}
                  >
                    <EmailOutlinedIcon className={classes.icon} />
                    <Typography variant="body1" className={classes.label}>
                      {email.typeName.charAt(0).toUpperCase() +
                        email.typeName.slice(1)}
                      :
                    </Typography>
                    <Link
                      href={`mailto:${email.emailAddress}`}
                      underline="none"
                    >
                      <Typography
                        id="fullDetailsModal_value_email"
                        variant="body1"
                        className={classes.contactContent}
                        component="a"
                        href={`mailto:${email.emailAddress}`}
                      >
                        {email.emailAddress}
                      </Typography>
                    </Link>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid item md={4} xs={12}>
              {isMobile ? (
                companyById?.workingTimes &&
                [...companyById?.workingTimes]
                  .sort((a: any, b: any) => a.dayOrderNumber - b.dayOrderNumber)
                  .map((working: any, index: number) => (
                    <Grid
                      item
                      md={12}
                      xs={12}
                      display="flex"
                      alignItems={"center"}
                      mb={2}
                    >
                      <Grid
                        container
                        key={index}
                        alignItems="center"
                        justifyContent={"center"}
                      >
                        <Grid item xs={12}>
                          <Typography variant="body1" className={classes.label}>
                            <TranslateDay day={working.dayName} />
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            variant="body1"
                            style={{
                              display: "block",
                              marginBottom: "5px",
                            }}
                          >
                            {displayLanugage === "English"
                              ? working.isClosed
                                ? t("fullDetailsModal.cardWorkingTimesClosed")
                                : working.isOpen24Hours
                                ? t("fullDetailsModal.cardWorkingTimesOpen")
                                : `${working.fromTime} - ${working.toTime}`
                              : working.isClosed
                              ? t("fullDetailsModal.cardWorkingTimesClosed")
                              : working.isOpen24Hours
                              ? t("fullDetailsModal.cardWorkingTimesOpen")
                              : `${formatTimeTo24Hours(working.fromTime)} ${t(
                                  "weekDays.hours"
                                )} - ${formatTimeTo24Hours(working.toTime)} ${t(
                                  "weekDays.hours"
                                )}`}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))
              ) : (
                <Grid container>
                  {companyById?.workingTimes &&
                    [...companyById?.workingTimes]
                      .sort(
                        (a: any, b: any) => a.dayOrderNumber - b.dayOrderNumber
                      )
                      .map((working: any, index: number) => (
                        <>
                          {working.dayName && (
                            <Grid item md={6} key={index}>
                              <Typography
                                variant="body1"
                                className={classes.label}
                                style={{
                                  display: "block",
                                  marginBottom: "5px",
                                }}
                              >
                                <TranslateDay day={working.dayName} />
                              </Typography>
                            </Grid>
                          )}

                          {(working.fromTime ||
                            working.toTime ||
                            working.isClosed ||
                            working.isOpen24Hours) && (
                            <Grid item md={6} key={index}>
                              <Typography
                                variant="body1"
                                style={{
                                  display: "block",
                                  marginBottom: "5px",
                                }}
                              >
                                <Typography
                                  variant="body1"
                                  style={{
                                    display: "block",
                                    marginBottom: "5px",
                                  }}
                                >
                                  {displayLanugage === "English"
                                    ? working.isClosed
                                      ? t(
                                          "fullDetailsModal.cardWorkingTimesClosed"
                                        )
                                      : working.isOpen24Hours
                                      ? t(
                                          "fullDetailsModal.cardWorkingTimesOpen"
                                        )
                                      : `${working.fromTime} - ${working.toTime}`
                                    : working.isClosed
                                    ? t(
                                        "fullDetailsModal.cardWorkingTimesClosed"
                                      )
                                    : working.isOpen24Hours
                                    ? t("fullDetailsModal.cardWorkingTimesOpen")
                                    : `${formatTimeTo24Hours(
                                        working.fromTime
                                      )}  ${t(
                                        "weekDays.hours"
                                      )} - ${formatTimeTo24Hours(
                                        working.toTime
                                      )}  ${t("weekDays.hours")}`}
                                </Typography>
                              </Typography>
                            </Grid>
                          )}
                        </>
                      ))}
                </Grid>
              )}
            </Grid>
            <Grid item md={12} display="flex">
              <Typography>
                {companyById?.description?.longDescription ||
                  companyById?.description?.shortDescription}
              </Typography>
            </Grid>
            <Grid item md={12} display="flex">
              <Gallery images={companyById?.photos} />
            </Grid>
            <Grid item md={12} display="flex">
              <div
                dangerouslySetInnerHTML={{
                  __html: companyById?.altText?.text || "",
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
      )}
      <DialogActions>
        <AppButton
          id="fullDetailsModal_button_close"
          onClick={handleClose}
          color={colors.primary}
          hover={colors.primary}
          label={t("buttons.close")}
        />
      </DialogActions>
    </Dialog>
  );
}
