import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/redux/store";
import { ApiStatus } from "../models/app/apiStatus";
import OverallRatingRequest from "../models/overallRating/overallRatingRequest";
import {
  saveOverallRating as saveOverallRatingApi,
  getCompanyRating as getCompanyRatingApi,
} from "../app/redux/overallRatingApi";
import OverallRatingResponse from "../models/overallRating/overallRatingResponse";

type OnboardingState = {
  companyRating: OverallRatingResponse | null;
  statuses: {
    saveOverallRating: ApiStatus;
  };
};

const initialState: OnboardingState = {
  companyRating: null,
  statuses: {
    saveOverallRating: ApiStatus.Idle,
  },
};

export const selectOverallRatingStatus = (state: RootState) =>
  state.overallRating.statuses.saveOverallRating;

export const selectOverallRatingById = (state: RootState) =>
  state.overallRating.companyRating;

export const saveOverallRating = createAsyncThunk(
  "searchResultsActionsSlice/save_overall_rating",
  async (request: OverallRatingRequest) => {
    const response = await saveOverallRatingApi(request);
    return response.data;
  }
);

export const getCompanyRating = createAsyncThunk(
  "searchResultsActionsSlice/get_overall_rating",
  async (companyId: string) => {
    const response = await getCompanyRatingApi(companyId);
    return response.data;
  }
);

export const resetOverallRatingStatus = createAsyncThunk(
  "searchResultsActionsSlice/reset_overall_rating_status",
  async () => {
    return initialState.statuses.saveOverallRating;
  }
);

const overallRatingSlice = createSlice({
  name: "overallRating",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(saveOverallRating.pending, (state, action) => {
        state.statuses.saveOverallRating = ApiStatus.Pending;
      })
      .addCase(saveOverallRating.fulfilled, (state, action) => {
        state.statuses.saveOverallRating = ApiStatus.Fulfilled;
      })
      .addCase(getCompanyRating.fulfilled, (state, action) => {
        state.companyRating = action.payload;
      })
      .addCase(getCompanyRating.pending, (state, action) => {})
      .addCase(resetOverallRatingStatus.fulfilled, (state, action) => {
        state.statuses.saveOverallRating =
          initialState.statuses.saveOverallRating;
      });
  },
});

export default overallRatingSlice.reducer;
