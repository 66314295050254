import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";

export const useBannerSectionStyles = makeStyles()((theme: Theme) => ({
  logoContainer: {
    position: "relative",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 15px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 2px",
    },
    "&:hover": {
      transform: "scale(1.01)",
      transition: "transform 0.3s ease-in-out",
      cursor: "pointer",
    },
  },
  logo: {
    width: "auto",
    height: "60%",
    maxWidth: 150,
    maxHeight: 150,
    objectFit: "contain",
    [theme.breakpoints.down("sm")]: {
      maxWidth: 100,
      maxHeight: 100,
      minWidth: "10%",
      minHeight: "100%",
    },
  },
  sliderWrapper: {
    margin: theme.spacing(-2),
    [theme.breakpoints.up("md")]: {
      margin: 0,
    },
  },
}));
