import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Grid,
  styled,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  CircularProgress,
} from "@mui/material";
import update from "../../images/update.png";
import { colors } from "../../app/theme/colors";
import { useUpdateDataSectionStyles } from "./updateDataSectionStyles";
import AppButton from "../../components/appButton/appButton";
import SendIcon from "@mui/icons-material/Send";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { UpdateDataModel } from "../../models/editions/updatedDataModel";
import { useAppDispatch, useAppSelector } from "../../app/redux/hooks";
import {
  getAllPlaces,
  getStreetsByPlaceId,
  selectAllPlaces,
  selectStreetsByPlaceId,
} from "../../slices/addressSlice";
import CustomOutlinedInput from "../../components/outlinedInput/customOutlinedInput";
import {
  selectUpdateDataStatus,
  sendUpdateDataRequest,
} from "../../slices/companySlice";
import {
  IsCompanyNameValid,
  IsEmailValid,
  IsMessageValid,
} from "../../common/validations/validations";

const GradientBackground = styled(Grid)(({ theme }) => ({
  alignItems: "center",
  backgroundRepeat: "no-repeat",
  backgroundColor: colors.primary,
  backgroundPosition: "center",
  padding: "40px",
  minHeight: "70vh",
  [theme.breakpoints.down("md")]: {
    padding: "20px",
  },
}));

export default function UpdateDataSection() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { classes } = useUpdateDataSectionStyles();
  const theme = useTheme();

  const places = useAppSelector(selectAllPlaces);
  const streetsByPlaceId = useAppSelector(selectStreetsByPlaceId);
  const updateDataStatus = useAppSelector(selectUpdateDataStatus);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const INIT_UPDATE_DATA: UpdateDataModel = {
    companyName: "",
    email: "",
    homeNumber: "",
    phoneNumber: "",
    place: "",
    street: "",
    taxId: "",
    message: "",
  };

  const [selectedPlace, setSelectedPlace] = useState(null);
  const [selectedStreet, setSelectedStreet] = useState(null);

  const [updateData, setUpdateData] =
    useState<UpdateDataModel>(INIT_UPDATE_DATA);
    
  const [isChecked, setIsChecked] = useState(false);
  const [validate, setValidate] = useState<boolean>(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const isValid = () => {
    setValidate(true);
    if (
      !IsCompanyNameValid(updateData.companyName) ||
      !IsEmailValid(updateData.email)
    ) {
      return false;
    }

    if (
      !updateData.phoneNumber &&
      (updateData.companyName || updateData.email)
    ) {
      return false;
    }

    return true;
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (isValid()) {
      if (updateData.phoneNumber !== "") {
        dispatch(sendUpdateDataRequest(updateData));
      }
      setValidate(false);
    }
  };
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setUpdateData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleResetFields = () => {
    setUpdateData(INIT_UPDATE_DATA);
    setSelectedPlace(null);
    setSelectedStreet(null);
    setIsChecked(false);
  };

  useEffect(() => {
    dispatch(getAllPlaces());
  }, []);

  useEffect(() => {
    if (updateDataStatus === "Fulfilled") {
      handleResetFields();
    }
  }, [updateDataStatus]);

  useEffect(() => {
    if (selectedPlace === null) {
      setSelectedStreet(null);
    }
  }, [selectedPlace]);

  return (
    <GradientBackground container>
      <Grid item xs={12} md={6} container justifyContent="flex-start">
        <Typography variant="h4" className={classes.title}>
          {t("updatingDataPage.updatingDataTitle")}
        </Typography>
        <Typography variant="body1" className={classes.text}>
          {t("updatingDataPage.updatingDataText")}
        </Typography>
        <Grid container spacing={3} mt={1}>
          <Grid item md={6} xs={12}>
            <CustomOutlinedInput
              onChange={handleInputChange}
              name="companyName"
              value={updateData.companyName || ""}
              id="updateDataSection_input_companyName"
              width="100%"
              label={t("updatingDataPage.updatingDataFormCompanyName") ?? ""}
              error={validate && !IsCompanyNameValid(updateData.companyName)}
              errorMessage={t("validation.validationCompanyName")}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <CustomOutlinedInput
              onChange={handleInputChange}
              name="taxId"
              value={updateData.taxId}
              id="updateDataSection_input_taxId"
              width="100%"
              label={t("updatingDataPage.updatingDataFormPIB") ?? ""}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <CustomOutlinedInput
              onChange={handleInputChange}
              name="email"
              value={updateData.email}
              id="updateDataSection_input_email"
              width="100%"
              label={t("updatingDataPage.updatingDataFormEmail") ?? ""}
              error={validate && !IsCompanyNameValid(updateData.email)}
              errorMessage={t("validation.validationEmail")}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <CustomOutlinedInput
              onChange={handleInputChange}
              name="phoneNumber"
              value={updateData.phoneNumber}
              id="updateDataSection_input_phoneNumber"
              width="100%"
              label={t("updatingDataPage.updatingDataFormPhoneNumber")}
              error={validate && !IsCompanyNameValid(updateData.phoneNumber)}
              errorMessage={t("validation.validationPhoneNumber")}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <CustomOutlinedInput
              width="100%"
              label={t("orderRequestPage.orderFormPlace") ?? ""}
              value={updateData.place}
              onChange={(e) => handleInputChange(e)}
              name={"place"}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <CustomOutlinedInput
              width="100%"
              label={t("orderRequestPage.orderFormStreet") ?? ""}
              value={updateData.street}
              onChange={(e) => handleInputChange(e)}
              name={"street"}
              disabled={updateData.place === ""}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <CustomOutlinedInput
              onChange={handleInputChange}
              name="homeNumber"
              value={updateData.homeNumber}
              id="updateDataSection_input_homeNumber"
              width="100%"
              label={t("updatingDataPage.updatingDataFormHomeNumber") ?? ""}
              disabled={updateData.street === ""}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <CustomOutlinedInput
              label={t("orderRequestPage.orderFormMessage")}
              value={updateData.message}
              onChange={(e) => handleInputChange(e)}
              name={"message"}
              multiline
              rows={5}
              width="100%"
              error={validate && !IsMessageValid(updateData.message)}
              errorMessage={t("validation.validationMessage")}
            />
          </Grid>
          <Grid item md={12} textAlign="left">
            <FormControlLabel
              sx={{ color: "white" }}
              control={
                <Checkbox
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  sx={{
                    color: "white",
                    "&.Mui-checked": {
                      color: "white",
                    },
                  }}
                />
              }
              label={t("termsAndContitions.error")}
            />
            <Typography variant="body1" sx={{ color: "white" }}>
              {t("termsAndContitions.description")}
            </Typography>
          </Grid>
          <Grid item md={12} xs={12}>
            <AppButton
              label={t("buttons.send")}
              color={colors.primary}
              hover={colors.primary}
              width={isMobile ? "100%" : "25%"}
              disabled={updateDataStatus === "Pending" || isChecked !== true}
              startIcon={
                updateDataStatus === "Pending" ? (
                  <CircularProgress size={"1em"} />
                ) : (
                  <SendIcon />
                )
              }
              onClick={handleSubmit}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        container
        justifyContent="center"
        alignItems="center"
      >
        <img src={update} alt="about_us" className={classes.img} />
      </Grid>
    </GradientBackground>
  );
}
