import { useEffect, useState } from "react";
import { AppBar, Toolbar, Grid, useMediaQuery, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { colors } from "../../../app/theme/colors";
import { useSubHeaderStyles } from "./subHeaderStyles";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import AppButton from "../../../components/appButton/appButton";
import { routes } from "../../../app/router/routes";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import EastIcon from "@mui/icons-material/East";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LoginModal from "../../modals/loginModal/loginModal";
import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import {
  logout,
  resetCurrentAccount,
  resetErrorMessage,
  resetLoginStatus,
  selectCurrentAccount,
  selectLoginStatus,
} from "../../../slices/authSlice";
import { toast } from "react-toastify";

const GradientAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: colors.subHeader,
  display: "flex",
}));

const SubHeader = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const { classes } = useSubHeaderStyles();
  const loginStatus = useAppSelector(selectLoginStatus);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const currentAccount = useAppSelector(selectCurrentAccount);

  const [buttonText, setButtonText] = useState(t("subheader.callNow"));
  const [phoneLinkMobile, setPhoneLinkMobile] = useState("tel:+38751255310");

  const [isOpenLogin, setIsOpenLogin] = useState<boolean>(false);

  const closeModal = () => {
    setIsOpenLogin(false);
  };

  const handleClick = () => {
    setButtonText("+387 51 255 310");
    setTimeout(() => {
      setButtonText(t("subheader.callNow"));
    }, 5000);
  };

  useEffect(() => {
    setButtonText(t("subheader.callNow"));
  }, [t]);

  const handleHomePage = () => {
    navigate(routes.home);
  };

  const handleAdvertisementPage = () => {
    navigate(routes.advertisementRequest);
  };

  useEffect(() => {
    if (loginStatus === "Fulfilled") {
      handleHomePage();
      dispatch(resetLoginStatus());
    }
  }, [loginStatus, currentAccount]);


  return (
    <GradientAppBar position="static">
      <Toolbar id="subHeader_toolbar" sx={{ display: "flex" }}>
        <Grid container spacing={2} display={"flex"}>
          <Grid item xs={12} justifyContent={"flex-start"} md={6}>
            {!currentAccount?.isActive  ? (
              <AppButton
                id="subHeader_button_login"
                startIcon={<AccountCircleIcon />}
                label="Prijavi se"
                color={colors.primary}
                hover={colors.primary}
                width={isMobile ? "100%" : "40%"}
                onClick={() => setIsOpenLogin(true)}
              />
            ) : null}
          </Grid>
          <Grid
            item
            display={"flex"}
            justifyContent={"flex-end"}
            md={6}
            xs={12}
          >
            <Grid item md={6} xs={12} textAlign={"right"}>
              <AppButton
                id="subHeader_button_call"
                startIcon={<PhoneInTalkIcon />}
                label={buttonText}
                color={colors.primary}
                hover={colors.primary}
                href={phoneLinkMobile}
                onClick={handleClick}
                width={isMobile ? "100%" : "100%"}
              />
            </Grid>
            <Grid item ml={1} md={6} xs={12} textAlign={"right"}>
              <AppButton
                id="subHeader_button_order"
                endIcon={<EastIcon />}
                label={t("buttons.order")}
                color={colors.primary}
                hover={colors.primary}
                width={isMobile ? "100%" : "100%"}
                onClick={handleAdvertisementPage}
              />
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
      <LoginModal
        handleClose={closeModal}
        open={isOpenLogin}
        closeLoginModal={closeModal}
        setIsOpenLogin={setIsOpenLogin}
      />
    </GradientAppBar>
  );
};

export default SubHeader;
