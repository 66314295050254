import * as React from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { colors } from "../../app/theme/colors";
import { Avatar, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSidebarStyles } from "./sidebarStyles";
import { useAppDispatch, useAppSelector } from "../../app/redux/hooks";
import {
  logout,
  resetLoginStatus,
  selectCurrentAccount,
  selectLoginStatus,
} from "../../slices/authSlice";
import { useNavigate } from "react-router-dom";
import { routes } from "../../app/router/routes";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LanguageIcon from "@mui/icons-material/Language";
import SettingsIcon from "@mui/icons-material/Settings";
import dashboardBackground from "../../images/sidebarBackground.jpg";
import LoginModal from "../modals/loginModal/loginModal";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LogoutIcon from "@mui/icons-material/Logout";
import { AnimatePresence, motion } from "framer-motion";
import ActivitySelectionModal from "../modals/activitySelectionModal/activitySelectionModal";
import SettingsActivitiesSelectionModal from "../modals/settingsActivitiesSelectionModal/settingsActivitiesSelectionModal";
import {
  getMainActivities,
  resetSavedActivitiesSettingsStatus,
  selectSavedActivitiesSettingsStatus,
} from "../../slices/onboardingSlice";
import {
  getAccountCategories,
  getAccountSubcategories,
} from "../../slices/categorySlice";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CategoryIcon from "@mui/icons-material/Category";
import LockIcon from "@mui/icons-material/Lock";
import QuizIcon from "@mui/icons-material/Quiz";
import FaqModal from "../modals/faqModal/faqModal";
import ChangePasswordModal from "../modals/changePasswordModal/changePasswordModal";
import { toast } from "react-toastify";
import {
  faqMessage,
  resetCompany,
  resetFaqMessageStatus,
  selectFaqMessageStatus,
} from "../../slices/companySlice";
import PersonIcon from "@mui/icons-material/Person";
import ManageClientsAccountsModal from "../modals/clientAccountModal/manageClientsAccountsModal";
import FaqRequest from "../../models/faq/faqRequest";
import { INIT_FAQ } from "../../models/init_data/initData";
import { getCompanyWebsite } from "../../slices/mySiteSlice";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

type SubmenuItem = { text: string; icon: React.ReactElement };

export default function MiniDrawer() {
  const dispatch = useAppDispatch();
  const { classes } = useSidebarStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  const faqMessageStatus = useAppSelector(selectFaqMessageStatus);
  const currentAccount = useAppSelector(selectCurrentAccount);
  const loginStatus = useAppSelector(selectLoginStatus);
  const savedActivitiesStatus = useAppSelector(
    selectSavedActivitiesSettingsStatus
  );
  const [open, setOpen] = React.useState(false);

  const [faqMessageState, setFaqMessageState] = useState<FaqRequest>(INIT_FAQ);

  const [isOpenLogin, setIsOpenLogin] = useState<boolean>(false);
  const [settingsOpen, setSettingsOpen] = useState<boolean>(false);
  const [isOpenActivity, setIsOpenActivity] = useState<boolean>(false);
  const [isOpenFaq, setIsOpenFaq] = useState<boolean>(false);
  const [isOpenChangePasswordModal, setIsOpenChangePasswordModal] =
    useState<boolean>(false);
  const [isOpenAddClientAccountModal, setIsOpenAddClientAccountModal] =
    useState<boolean>(false);

  const handleButtonClick = (route: string) => {
    dispatch(getCompanyWebsite());
    navigate(route);
  };

  const handleHomePage = () => {
    navigate(routes.home);
  };

  const closeModal = () => {
    setIsOpenLogin(false);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate(routes.home);
    dispatch(resetCompany());
  };

  const handleSettingsClick = () => {
    setSettingsOpen(!settingsOpen);
  };

  const handleSupportClick = () => {
    setIsOpenFaq(true);
  };

  const handleChangePasswordClick = () => {
    setIsOpenChangePasswordModal(true);
  };

  const handleFaqChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFaqMessageState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSaveFaq = () => {
    dispatch(faqMessage(faqMessageState));
  };

  useEffect(() => {
    if (loginStatus === "Fulfilled") {
      setIsOpenLogin(false);
      handleHomePage();
      dispatch(resetLoginStatus());
    }
  }, [loginStatus, currentAccount]);

  useEffect(() => {
    if (savedActivitiesStatus === "Fulfilled") {
      dispatch(resetSavedActivitiesSettingsStatus());
    }
  }, [savedActivitiesStatus]);

  useEffect(() => {
    if (faqMessageStatus === "Fulfilled") {
      setFaqMessageState(INIT_FAQ);
      dispatch(resetFaqMessageStatus());
    }
  }, [faqMessageStatus]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <CssBaseline />
      <Drawer id="sidebar_drawer" variant="permanent" open={open}>
        <DrawerHeader
          id="sidebar_drawer_header"
          sx={{
            position: "relative",
            backgroundImage: open ? `url(${dashboardBackground})` : "",
            backgroundSize: "cover",
            border: 0,
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: open ? colors.primary : colors.subHeader,
              opacity: 0.7,
            }}
          />
          <IconButton
            id="sidebar_button_arrowBackIcon"
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerClose}
          >
            {open && <ArrowBackIcon sx={{ color: colors.white }} />}
          </IconButton>
          {open && (
            <Avatar
              id="sidebar_avatar"
              alt="User Avatar"
              src="/path/to/avatar.jpg"
              sx={{
                position: "absolute",
                bottom: -30,
                left: 120,
                transform: "translateX(-50%)",
                zIndex: 1,
                width: 72,
                height: 72,
                border: "2px solid white",
              }}
            >
              {currentAccount &&
              currentAccount.firstName &&
              currentAccount.lastName
                ? `${currentAccount.firstName.charAt(
                    0
                  )}${currentAccount.lastName.charAt(0)}`
                : null}
            </Avatar>
          )}

          {!open && (
            <IconButton
              id="sidebar_button_menuIcon"
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerOpen}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MenuIcon sx={{ color: colors.primary }} />
            </IconButton>
          )}
        </DrawerHeader>

        <List sx={{ flexGrow: 1 }}>
          <List sx={{ marginTop: "30px" }}>
            {[
              {
                text: t("sidebar.dashboard"),
                icon: <DashboardIcon />,
                route: routes.dashboard,
              },
              {
                text: t("sidebar.mySite"),
                icon: <LanguageIcon />,
                route: routes.mySite,
                isVisible: currentAccount?.isWebsite,
              },
              {
                text: t("sidebar.settings"),
                icon: <SettingsIcon />,
                submenu: [
                  { text: t("sidebar.activity"), icon: <CategoryIcon /> },
                  { text: t("sidebar.password"), icon: <LockIcon /> },
                  { text: t("sidebar.support"), icon: <QuizIcon /> },
                  currentAccount?.roleName !== "User" && {
                    text: t("sidebar.user"),
                    icon: <PersonIcon />,
                  },
                ].filter((item): item is SubmenuItem => Boolean(item)), // Filter out false values and type check
              },
            ].map(({ text, icon, route, submenu, isVisible }, index) => (
              <React.Fragment key={text}>
                {isVisible !== false && (
                  <Tooltip title={text} placement="top">
                    <ListItem
                      id={text}
                      disablePadding
                      sx={{ display: "block" }}
                    >
                      <ListItemButton
                        onClick={() => {
                          if (index === 2) {
                            handleSettingsClick();
                          } else if (route) {
                            handleButtonClick(route);
                          }
                        }}
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          {icon}
                        </ListItemIcon>
                        <ListItemText
                          primary={text}
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </ListItem>
                  </Tooltip>
                )}

                {index === 2 && settingsOpen && submenu && (
                  <AnimatePresence>
                    {submenu.map((subitem, subindex) => (
                      <motion.div
                        key={subindex}
                        initial={{ x: -50, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        exit={{ x: -50, opacity: 0 }}
                        transition={{ duration: 0.3, delay: subindex * 0.1 }}
                      >
                        <List component="div" disablePadding>
                          <Tooltip title={subitem.text} placement="top">
                            <ListItem
                              key={subindex}
                              disablePadding
                              sx={{ display: "block" }}
                            >
                              <ListItemButton
                                onClick={() => {
                                  if (subitem.text === t("sidebar.activity")) {
                                    setIsOpenActivity(true);
                                    dispatch(getMainActivities());
                                    dispatch(getAccountCategories());
                                    dispatch(getAccountSubcategories());
                                  } else if (
                                    subitem.text === t("sidebar.support")
                                  ) {
                                    handleSupportClick();
                                  } else if (
                                    subitem.text === t("sidebar.password")
                                  ) {
                                    handleChangePasswordClick();
                                  } else if (
                                    subitem.text === t("sidebar.user")
                                  ) {
                                    setIsOpenAddClientAccountModal(true);
                                  }
                                }}
                                sx={{
                                  minHeight: 48,
                                  justifyContent: open ? "initial" : "center",
                                  px: !open ? 2.9 : 4.5,
                                }}
                              >
                                {!open ? (
                                  <ListItemIcon
                                    sx={{
                                      minWidth: 0,
                                      mr: open ? 3 : "auto",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {subitem.icon}
                                  </ListItemIcon>
                                ) : (
                                  <ListItemIcon
                                    sx={{
                                      minWidth: 0,
                                      mr: open ? 3 : "auto",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {subitem.icon}
                                  </ListItemIcon>
                                )}

                                <ListItemText
                                  primary={subitem.text}
                                  sx={{ opacity: open ? 1 : 0 }}
                                />
                              </ListItemButton>
                            </ListItem>
                          </Tooltip>
                        </List>
                      </motion.div>
                    ))}
                  </AnimatePresence>
                )}
              </React.Fragment>
            ))}
          </List>
        </List>
        {/* Logout item */}
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemButton
              onClick={handleLogout}
              id="sidebar_button_listItem"
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText
                primary={t("sidebar.logout")}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <LoginModal
        handleClose={() => setIsOpenLogin(false)}
        open={isOpenLogin}
        closeLoginModal={closeModal}
        setIsOpenLogin={setIsOpenLogin}
      />
      <SettingsActivitiesSelectionModal
        handleClose={() => setIsOpenActivity(false)}
        open={isOpenActivity}
      />
      <FaqModal
        open={isOpenFaq}
        handleClose={() => setIsOpenFaq(false)}
        handleFaqChange={handleFaqChange}
        faqMessageState={faqMessageState}
        handleSaveFaq={handleSaveFaq}
        faqMessageStatus={faqMessageStatus}
      />
      <ChangePasswordModal
        open={isOpenChangePasswordModal}
        handleClose={() => setIsOpenChangePasswordModal(false)}
      />
      <ManageClientsAccountsModal
        open={isOpenAddClientAccountModal}
        handleClose={() => setIsOpenAddClientAccountModal(false)}
      />
    </Box>
  );
}
