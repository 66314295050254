import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";
import { colors } from "../../../app/theme/colors";

export const useOverallRatingModalStyles = makeStyles()((theme: Theme) => ({
  dialog: {
    borderRadius: "15px",
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(2),
  },
  dialogContent: {
    background: `linear-gradient(to bottom, ${colors.primary} , white 60%)`,
  },
  gridContainer: {
    justifyContent: "center",
    alignItems: "center",
    padding: "5px",
    textAlign: "center",
  },
  logoImage: {
    width: "25%",
    height: "auto",
    margin: "auto",
    marginTop: "-20px",

  },
  customOutlinedInput: {
    label: "test",
    width: "100%",
    labelColor: colors.primary,
  },
  forgotPasswordText: {
    textAlign: "right",
    marginTop: "5px",
    color: "#635F5F",
    cursor:"pointer"
  },
  dialogActions: {
    textAlign: "center",
  },
  closeIcon: {
    color: "white",
  },
  visibilityIcon: {
    cursor: "pointer",
    color: colors.primary,
  },
}));
