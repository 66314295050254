import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import { useTextualAdvertisingSectionStyles } from "./textualAdvertisingSectionStyles";
import StarIcon from "@mui/icons-material/Star";
import { colors } from "../../app/theme/colors";
import GetImageByCategory from "./textualAdvertisingUtilis";
import { TextualAdvertisementModel } from "../../models/company/companyTextualAdvertisementModel";

interface Props {
  advertisement: TextualAdvertisementModel | null;
  count: number;
  onClick: any;
  style?: React.CSSProperties;
}

export default function TextualAdvertisingSection({
  advertisement,
  count,
  onClick,
  style,
}: Props) {
  const { classes } = useTextualAdvertisingSectionStyles();
  const index = count - 1;
  const categoryName = advertisement?.categories[0]?.parentCategoryName;

  return (
    <div>
      <Grid container>
        <Grid item xs={12} key={index}>
          <Card
            sx={{
              marginBottom: "10px",
              backgroundColor:
                advertisement?.premium === true
                  ? colors.gold
                  : index % 2 === 0
                  ? "#528FCC"
                  : "white",
              position: "relative",
              cursor: "pointer",
              ...style,
            }}
            onClick={onClick}
          >
            <CardMedia
              component="img"
              src={GetImageByCategory(categoryName)}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                maxWidth: "42%",
                opacity: 0.1,
                marginLeft: "20%",
              }}
            />

            {advertisement?.premium === true && (
              <Box className={classes.iconWrapper}>
                <span role="img" aria-label="star">
                  <StarIcon fontSize="large" className={classes.starIcon} />
                </span>
              </Box>
            )}
            <Grid container mt={4}>
              <Grid item xs={12} className={classes.titleWrapper}>
                <Typography
                  variant="h5"
                  sx={{
                    color:
                      advertisement?.premium === true
                        ? "#404040"
                        : index % 2 === 0
                        ? "white"
                        : "#528FCC",
                    fontWeight: "bold",
                  }}
                >
                  {advertisement?.title}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <CardContent>
                  <Typography
                    variant="body1"
                    sx={{
                      color:
                        advertisement?.premium === true
                          ? "#404040"
                          : index % 2 === 0
                          ? "white"
                          : "#528FCC",
                    }}
                  >
                    {advertisement?.description}
                  </Typography>
                </CardContent>
              </Grid>
              <Grid item xs={12} className={classes.companyTitleWrapper}>
                <CardContent>
                  <Typography
                    variant="body1"
                    className={classes.companyTitleLabel}
                    sx={{
                      color:
                        advertisement?.premium === true
                          ? "#404040"
                          : index % 2 === 0
                          ? "white"
                          : "#528FCC",
                    }}
                  >
                    {advertisement?.companyTitle}
                  </Typography>
                </CardContent>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
