import { ApiResponse } from "../../models/app/apiResponse";
import ActivitiesResponseModel from "../../models/onboarding/activitesResponseModel";
import OverallRatingRequest from "../../models/overallRating/overallRatingRequest";
import { api_poslovni_imenik } from "./api_poslovni_imenik";

export const saveOverallRating: any = async (request: OverallRatingRequest) => {
  return api_poslovni_imenik.post("/company-rating", request) as Promise<
    ApiResponse<ActivitiesResponseModel | null>
  >;
};

export const getCompanyRating: any = async (companyId: string) => {
  return api_poslovni_imenik.get(
    "/company-rating/user-company-rating?companyId=" + companyId
  ) as Promise<ApiResponse<ActivitiesResponseModel | null>>;
};

export const removeFavorite: any = async (companyId: string) => {
  return api_poslovni_imenik.delete(
    "/account-favorite?companyId=" + companyId
  ) as Promise<ApiResponse<ActivitiesResponseModel | null>>;
};
