import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { CssBaseline, Grid, ThemeProvider } from "@mui/material";
import Footer from "../../features/footer/footer";
import { useLayoutStyles } from "./layoutStyles";
import { theme } from "./theme";
import AppNotification from "../../components/appNotification/appNotification";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import CookieBanner from "../../components/cookieBanner/cookieBanner";

interface Props {
  children?: React.ReactNode;
}

export default function Layout({ children }: Props) {
  const { classes } = useLayoutStyles();

  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <CssBaseline />
        <Grid item md={12} xs={12}>
          <AppNotification />
        </Grid>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <div className={classes.container}>
          <div id="row-layout">{children}</div>
        </div>
        <div className={classes.footerContainer}>
          <Footer />
        </div>
        <CookieBanner />
      </React.Fragment>
    </ThemeProvider>
  );
}
