import { ApiResponse } from "../../models/app/apiResponse";
import DashboardRatingResponse from "../../models/dashboard/dashboardRatingResponse";
import DashboardWebsiteVisitsStatisticResponse from "../../models/dashboard/dashboardWebsiteVisitsStatisticResponse";
import { api_poslovni_imenik } from "./api_poslovni_imenik";

export const getCompanyRatingForDashboard: any = async () => {
  return api_poslovni_imenik.get(
    "/company-rating/get-company-rating-for-dashboard"
  ) as Promise<ApiResponse<DashboardRatingResponse | null>>;
};

export const getMySiteVisitsStatistic: any = async (request: number) => {
  return api_poslovni_imenik.get(
    "/company-website-partition/website-visits-statistic?type=" + request
  ) as Promise<ApiResponse<DashboardWebsiteVisitsStatisticResponse[] | null>>;
};
