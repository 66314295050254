import { ApiResponse } from "../../models/app/apiResponse";
import ForgotPasswordRequest from "../../models/auth/forgotPasswordRequest";
import { LoginRequest } from "../../models/auth/loginRequest";
import { LoginResponse } from "../../models/auth/loginResponse";
import RefreshTokenRequest from "../../models/auth/refreshTokenRequest";
import SetForgotPasswordModel from "../../models/auth/setForgotPasswordModel";
import SetPasswordModel from "../../models/auth/setPasswordModel";
import SetPasswordResponse from "../../models/auth/setPasswordResponse";
import { api_um } from "./api_um";

export const Auth = {
  Login: async (request: LoginRequest) => {
    return api_um.post("/auth/client-login", request) as Promise<
      ApiResponse<LoginResponse>
    >;
  },
  ResetPassword: async (request: SetPasswordModel) => {
    return api_um.put("/client-accounts/reset-password", request) as Promise<
      ApiResponse<SetPasswordResponse>
    >;
  },
  ChangePassword: async (request: SetPasswordModel) => {
    return api_um.put("/client-accounts/reset-password", request) as Promise<
      ApiResponse<SetPasswordResponse>
    >;
  },
  RefreshTokenClientAccount: async (request: RefreshTokenRequest) => {
    return api_um.put("/client-account-refresh-token", request) as Promise<
      ApiResponse<SetPasswordResponse>
    >;
  },
  ForgotPasswordClientAccount: async (request: ForgotPasswordRequest) => {
    return api_um.post("/client-accounts/request-forgot-password", request) as Promise<
      ApiResponse<ApiResponse<null>>
    >;
  },
  SetForgotPasswordClientAccount: async (request: SetForgotPasswordModel) => {
    return api_um.post("/client-accounts/forgot-password", request) as Promise<
      ApiResponse<ApiResponse<null>>
    >;
  }
};
