import { useTranslation } from "react-i18next";

export const useDayOfWeekTranslation = () => {
  const { t } = useTranslation();

  const dayOfWeekTranslation = (day: string) => {
    switch (day) {
      case "Monday":
        return t("mySiteStatisticChart.monday");
      case "Tuesday":
        return t("mySiteStatisticChart.tuesday");
      case "Wednesday":
        return t("mySiteStatisticChart.wednesday");
      case "Thursday":
        return t("mySiteStatisticChart.thursday");
      case "Friday":
        return t("mySiteStatisticChart.friday");
      case "Saturday":
        return t("mySiteStatisticChart.saturday");
      case "Sunday":
        return t("mySiteStatisticChart.sunday");
      default:
        return day;
    }
  };

  return { dayOfWeekTranslation };
};

export const useMonthTranslation = () => {
  const { t } = useTranslation();

  const monthTranslation = (monthNumber: number) => {
    switch (monthNumber) {
      case 1:
        return t("mySiteStatisticChart.january");
      case 2:
        return t("mySiteStatisticChart.february");
      case 3:
        return t("mySiteStatisticChart.march");
      case 4:
        return t("mySiteStatisticChart.april");
      case 5:
        return t("mySiteStatisticChart.may");
      case 6:
        return t("mySiteStatisticChart.june");
      case 7:
        return t("mySiteStatisticChart.july");
      case 8:
        return t("mySiteStatisticChart.august");
      case 9:
        return t("mySiteStatisticChart.september");
      case 10:
        return t("mySiteStatisticChart.october");
      case 11:
        return t("mySiteStatisticChart.november");
      case 12:
        return t("mySiteStatisticChart.december");
      default:
        return "";
    }
  };

  return { monthTranslation };
};