import {
  AppBar,
  Toolbar,
  Grid,
  Grow,
  Autocomplete,
  TextField,
  List,
  ListItem,
  ListItemText,
  Divider,
  Drawer,
  Collapse,
  Checkbox,
  CircularProgress,
  ListItemIcon,
  ListItemButton,
  Avatar,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import Typography from "@mui/material/Typography";
import backgroundImg from "../../../images/ba-03.png";
import { useEffect, useState } from "react";
import logoFull from "../../../images/logoFull.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../app/router/routes";
import AppButton from "../../../components/appButton/appButton";
import { colors } from "../../../app/theme/colors";
import MenuIcon from "@mui/icons-material/Menu";
import React from "react";
import { useMobileHeaderStyles } from "./mobileHeaderStyles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import {
  faqMessage,
  getAllCategories,
  getCompaniesAuthorized,
  getCompaniesUnauthorized,
  resetCompany,
  resetSearch,
  selectSearchStatus,
} from "../../../slices/companySlice";
import { getAllPlaces, selectAllPlaces } from "../../../slices/addressSlice";
import useGetAllCategoryShortDetails from "../../../hook/categories/useGetAllCategoryShortDetails";
import { AdvanceSearchFilterModel } from "../../../models/advanceSearchFilterModel";
import { CategoryShortDetail } from "../../../models/company/categoryShortDetail";
import SelectCategories from "../../../components/selectCategories/selectCategories";
import LanguageSwitcher from "../../../components/languageSwitcher/languageSwitcher";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomOutlinedInput from "../../../components/outlinedInput/customOutlinedInput";
import ChangePasswordModal from "../../modals/changePasswordModal/changePasswordModal";
import FaqModal from "../../modals/faqModal/faqModal";
import SettingsActivitiesSelectionModal from "../../modals/settingsActivitiesSelectionModal/settingsActivitiesSelectionModal";
import { getMainActivities } from "../../../slices/onboardingSlice";
import {
  getAccountCategories,
  getAccountSubcategories,
} from "../../../slices/categorySlice";
import { logout, selectCurrentAccount } from "../../../slices/authSlice";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import ImportContactsOutlinedIcon from "@mui/icons-material/ImportContactsOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import { LogoutOutlined } from "@mui/icons-material";
import dashboardBackground from "../../../images/sidebarBackground.jpg";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import ManageClientsAccountsModal from "../../modals/clientAccountModal/manageClientsAccountsModal";
import FaqRequest from "../../../models/faq/faqRequest";
import { INIT_FAQ } from "../../../models/init_data/initData";

interface Props {
  advanceSearchGlobal: any;
  setAdvanceSearchGlobal: any;
  advanceSearch: any;
  setAdvanceSearch: any;
  handleSearch: any;
  setShowAds: any;
}

type Anchor = "left";
const GradientAppBar = styled(AppBar)(({ theme }) => ({
  alignItems: "center",
  backgroundImage: `url(${backgroundImg})`,
  backgroundRepeat: "no-repeat",
  backgroundColor:
    "linear-gradient(180deg, #4285F4 0%, rgba(66, 133, 244, 0.73) 99.99%, rgba(66, 133, 244, 0) 100%)",
  backgroundSize: "35%",
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const MobileHeader = ({
  advanceSearchGlobal,
  setAdvanceSearchGlobal,
  advanceSearch,
  setAdvanceSearch,
  handleSearch,
  setShowAds,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { classes } = useMobileHeaderStyles();

  const searchStatus = useAppSelector(selectSearchStatus);
  const currentAccount = useAppSelector(selectCurrentAccount);
  const { categoryShortDetails } = useGetAllCategoryShortDetails();

  const places = useAppSelector(selectAllPlaces);

  const [category, setCategory] = useState<AdvanceSearchFilterModel>();

  const [categories, setCategories] = useState<CategoryShortDetail[]>(
    categoryShortDetails?.filter((x, i) => {
      const find = category?.categoryIds.find((y) => x.id === y);
      if (!find) {
        return;
      }

      return find;
    }) ?? []
  );

  const [isOpenChangePasswordModal, setIsOpenChangePasswordModal] =
    useState<boolean>(false);
  const [isOpenActivitySelectionModal, setIsOpenActivitySelectionModal] =
    useState<boolean>(false);
  const [isOpenFaqModal, setIsOpenFaqModal] = useState<boolean>(false);

  const [faqMessageState, setFaqMessageState] = useState<FaqRequest>(INIT_FAQ);

  const [showAdvanceSearch, setShowAdvanceSearch] = useState(false);
  const [servicesMenuOpen, setServicesMenuOpen] = useState(false);
  const [settingsMenuOpen, setSettingsMenuOpen] = useState(false);
  const [isOpenManageClientsModal, setIsOpenManageClientsModal] =
    useState<boolean>(false);
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [settingsState, setSettingsState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const handleFaqChange = ( e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFaqMessageState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSaveFaq = () => {
    dispatch(faqMessage(faqMessageState))
  };

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open || servicesMenuOpen });
      setSettingsState({
        ...settingsState,
        [anchor]: open || settingsMenuOpen,
      });
    };

  const handleClickAdvanceSearch = () => {
    dispatch(getAllPlaces());
    dispatch(getAllCategories());
    setShowAdvanceSearch(true);
  };

  const handleButtonClick = (route: string) => {
    navigate(route);
    dispatch(resetCompany());
  };

  const handleRemoveResult = () => {
    dispatch(resetSearch());
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate(routes.home);
    dispatch(resetCompany());
  };

  useEffect(() => {
    if (servicesMenuOpen) {
      setState({
        ...state,
        left: true,
      });
    }
  }, [servicesMenuOpen]);

  useEffect(() => {
    if (settingsMenuOpen) {
      setSettingsState({
        ...settingsState,
        left: true,
      });
    }
  }, [settingsMenuOpen]);

  useEffect(() => {
    if (advanceSearchGlobal.companyName.length >= 3) {
      if (currentAccount) {
        dispatch(getCompaniesAuthorized(advanceSearchGlobal));
      } else {
        dispatch(getCompaniesUnauthorized(advanceSearchGlobal));
      }
    }
  }, [advanceSearchGlobal]);

  const list = (anchor: Anchor) => (
    <Grid
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      className={classes.list}
    >
      <DrawerHeader
        id="sidebar_drawer_header"
        sx={{
          position: "relative",
          backgroundImage: `url(${dashboardBackground})`,
          backgroundSize: "cover",
          border: 0,
          marginBottom: "30px",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            opacity: 0.7,
            backgroundColor: colors.primary,
          }}
        />
        <Avatar
          id="sidebar_avatar"
          alt="User Avatar"
          src="/path/to/avatar.jpg"
          sx={{
            position: "absolute",
            bottom: -30,
            left: 86,
            transform: "translateX(-50%)",
            zIndex: 1,
            width: 72,
            height: 72,
            border: "2px solid white",
          }}
        >
          {currentAccount && currentAccount.firstName && currentAccount.lastName
            ? `${currentAccount.firstName.charAt(
                0
              )}${currentAccount.lastName.charAt(0)}`
            : null}
        </Avatar>
      </DrawerHeader>
      <List>
        <React.Fragment>
          <ListItem
            disablePadding
            sx={{ display: "flex", alignItems: "center" }}
            id="mobileHeader_home"
            onClick={() => handleButtonClick(routes.home)}
          >
            <ListItemButton>
              <ListItemIcon
                className={classes.listItemText}
                sx={{
                  minWidth: 0,
                  justifyContent: "center",
                }}
              >
                <HomeOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                id="mobileHeader_homeText"
                className={classes.listItemText}
                sx={{ marginLeft: "5px" }}
              >
                {t("header.homePage")}
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <Divider className={classes.divider} variant="middle" />
          <ListItem
            onClick={() => setServicesMenuOpen(!servicesMenuOpen)}
            disablePadding
            sx={{ display: "flex", alignItems: "center" }}
            id="mobileHeader_services"
          >
            <ListItemButton>
              <ListItemIcon
                className={classes.listItemText}
                sx={{
                  minWidth: 0,
                  justifyContent: "center",
                }}
              >
                <MiscellaneousServicesIcon />
              </ListItemIcon>
              <ListItemText
                id="mobileHeader_servicesText"
                className={classes.listItemText}
                sx={{ marginLeft: "5px" }}
              >
                {t("header.services")}
              </ListItemText>
              {servicesMenuOpen ? (
                <ExpandLessIcon className={classes.expandIcon} />
              ) : (
                <ExpandMoreIcon className={classes.expandIcon} />
              )}
            </ListItemButton>
          </ListItem>
          <Collapse in={servicesMenuOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                disablePadding
                sx={{ display: "flex", alignItems: "center" }}
                onClick={() => handleButtonClick(routes.updateData)}
                id="mobileHeader_updateData"
              >
                <ListItemButton>
                  <ListItemText
                    id="mobileHeader_servicesText"
                    className={classes.listItemText}
                    sx={{ marginLeft: "5px" }}
                  >
                    {t("header.updateData")}
                  </ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem
                disablePadding
                sx={{ display: "flex", alignItems: "center" }}
                onClick={() => handleButtonClick(routes.advertisement)}
                id="mobileHeader_advertisement"
              >
                <ListItemButton>
                  <ListItemText
                    id="mobileHeader_servicesText"
                    className={classes.listItemText}
                    sx={{ marginLeft: "5px" }}
                  >
                    {t("header.advertisement")}
                  </ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem
                disablePadding
                sx={{ display: "flex", alignItems: "center" }}
                id="mobileHeader_digitalMarketing"
                onClick={() => handleButtonClick(routes.digitalMarketing)}
              >
                <ListItemButton>
                  <ListItemText
                    id="mobileHeader_servicesText"
                    className={classes.listItemText}
                    sx={{ marginLeft: "5px" }}
                  >
                    {t("header.digitalMarketing")}
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            </List>
          </Collapse>
          <Divider className={classes.divider} variant="middle" />
          <ListItem
            disablePadding
            sx={{ display: "flex", alignItems: "center" }}
            id="mobileHeader_editions"
            onClick={() => handleButtonClick(routes.editions)}
          >
            <ListItemButton>
              <ListItemIcon
                className={classes.listItemText}
                sx={{
                  minWidth: 0,
                  justifyContent: "center",
                }}
              >
                <ImportContactsOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                id="mobileHeader_editionsText"
                className={classes.listItemText}
                sx={{ marginLeft: "5px" }}
              >
                {t("header.editions")}
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <Divider className={classes.divider} variant="middle" />

          <ListItem
            disablePadding
            sx={{ display: "flex", alignItems: "center" }}
            id="mobileHeader_about"
            onClick={() => handleButtonClick(routes.about)}
          >
            <ListItemButton>
              <ListItemIcon
                className={classes.listItemText}
                sx={{
                  minWidth: 0,
                  justifyContent: "center",
                }}
              >
                <InfoOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                id="mobileHeader_aboutText"
                className={classes.listItemText}
                sx={{ marginLeft: "5px" }}
              >
                {t("header.aboutUs")}
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <Divider className={classes.divider} variant="middle" />

          <ListItem
            disablePadding
            sx={{ display: "flex", alignItems: "center" }}
            id="mobileHeader_contact"
            onClick={() => handleButtonClick(routes.contact)}
          >
            <ListItemButton>
              <ListItemIcon
                className={classes.listItemText}
                sx={{
                  minWidth: 0,
                  justifyContent: "center",
                }}
              >
                <LocalPhoneOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                id="mobileHeader_contactText"
                className={classes.listItemText}
                sx={{ marginLeft: "5px" }}
              >
                {t("header.contact")}
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <Divider className={classes.divider} variant="middle" />

          <ListItem
            disablePadding
            sx={{ display: "flex", alignItems: "center" }}
            id="mobileHeader_dashboard"
            onClick={() => handleButtonClick(routes.dashboard)}
          >
            <ListItemButton>
              <ListItemIcon
                className={classes.listItemText}
                sx={{
                  minWidth: 0,
                  justifyContent: "center",
                }}
              >
                <DashboardOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                id="mobileHeader_dashboard"
                className={classes.listItemText}
                sx={{ marginLeft: "5px" }}
              >
                {t("sidebar.dashboard")}
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <Divider className={classes.divider} variant="middle" />
          {currentAccount?.isWebsite && (
            <ListItem
              disablePadding
              sx={{ display: "flex", alignItems: "center" }}
              id="mobileHeader_mySite"
              onClick={() => handleButtonClick(routes.mySite)}
            >
              <ListItemButton>
                <ListItemIcon
                  className={classes.listItemText}
                  sx={{
                    minWidth: 0,
                    justifyContent: "center",
                  }}
                >
                  <LanguageOutlinedIcon />
                </ListItemIcon>
                <ListItemText
                  id="mobileHeader_mySite"
                  className={classes.listItemText}
                  sx={{ marginLeft: "5px" }}
                >
                  {t("sidebar.mySite")}
                </ListItemText>
              </ListItemButton>
            </ListItem>
          )}

          <Divider className={classes.divider} variant="middle" />
          <ListItem
            onClick={() => setSettingsMenuOpen(!settingsMenuOpen)}
            disablePadding
            sx={{ display: "flex", alignItems: "center" }}
            id="mobileHeader_settings"
          >
            <ListItemButton>
              <ListItemIcon
                className={classes.listItemText}
                sx={{
                  minWidth: 0,
                  justifyContent: "center",
                }}
              >
                <MiscellaneousServicesIcon />
              </ListItemIcon>
              <ListItemText
                id="mobileHeader_settings"
                className={classes.listItemText}
                sx={{ marginLeft: "5px" }}
              >
                {t("sidebar.settings")}
              </ListItemText>
              {settingsMenuOpen ? (
                <ExpandLessIcon className={classes.expandIcon} />
              ) : (
                <ExpandMoreIcon className={classes.expandIcon} />
              )}
            </ListItemButton>
          </ListItem>
          <Collapse in={settingsMenuOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button
                onClick={() => {
                  setIsOpenActivitySelectionModal(true);
                  dispatch(getMainActivities());
                  dispatch(getAccountCategories());
                  dispatch(getAccountSubcategories());
                }}
                id="mobileHeader_activity"
              >
                <ListItemText
                  id="mobileHeader_activityText"
                  className={classes.listItemText}
                >
                  {t("sidebar.activity")}
                </ListItemText>
              </ListItem>
              <ListItem
                button
                onClick={() => setIsOpenChangePasswordModal(true)}
                id="mobileHeader_password"
              >
                <ListItemText
                  id="mobileHeader_passwordText"
                  className={classes.listItemText}
                >
                  {t("sidebar.password")}
                </ListItemText>
              </ListItem>
              <ListItem
                button
                id="mobileHeader_faq"
                onClick={() => setIsOpenFaqModal(true)}
              >
                <ListItemText
                  id="mobileHeader_faqText"
                  className={classes.listItemText}
                >
                  {t("sidebar.support")}
                </ListItemText>
              </ListItem>
              <ListItem
                button
                id="mobileHeader_user"
                onClick={() => setIsOpenManageClientsModal(true)}
              >
                <ListItemText
                  id="mobileHeader_user"
                  className={classes.listItemText}
                >
                  {t("sidebar.user")}
                </ListItemText>
              </ListItem>
            </List>
          </Collapse>
          <Divider className={classes.divider} variant="middle" />

          <ListItem
            disablePadding
            sx={{ display: "flex", alignItems: "center" }}
            id="mobileHeader_logout"
            onClick={() => handleLogout()}
          >
            <ListItemButton>
              <ListItemIcon
                className={classes.listItemText}
                sx={{
                  minWidth: 0,
                  justifyContent: "center",
                }}
              >
                <LogoutOutlined />
              </ListItemIcon>
              <ListItemText
                id="mobileHeader_logout"
                className={classes.listItemText}
                sx={{ marginLeft: "5px" }}
              >
                {t("sidebar.logout")}
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </React.Fragment>
      </List>
    </Grid>
  );

  return (
    <GradientAppBar position="static" sx={{ minHeight: 150 }}>
      <Toolbar>
        <Grid container>
          <Grid item md={6} xs={6}>
            {(["left"] as Anchor[]).map((anchor) => (
              <React.Fragment key={anchor}>
                <MenuIcon
                  id="header_menu-icon"
                  style={{ marginTop: "12px" }}
                  onClick={toggleDrawer(anchor, true)}
                />
                <Drawer
                  anchor={anchor}
                  open={state[anchor] || settingsState[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                >
                  {list(anchor)}
                </Drawer>
              </React.Fragment>
            ))}
          </Grid>
          <Grid
            item
            md={6}
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
            mt={1}
          >
            <img
              src={logoFull}
              className={classes.logo}
              onClick={() => handleButtonClick(routes.home)}
            />
          </Grid>
          <Grid item xs={12} justifyContent={"center"} display="flex">
            <LanguageSwitcher />
          </Grid>
          {showAdvanceSearch !== true && (
            <Grid item md={12} xs={12}>
              <CustomOutlinedInput
                id="mobileHeader_input_company-name"
                name="global_search"
                label={t("header.companyNameInput") ?? ""}
                startAdornment={<SearchIcon className={classes.searchIcon} />}
                height="40px"
                width="100%"
                onChange={(event) => {
                  const inputCompanyName = event.target.value;
                  if (event.target.name === "global_search") {
                    if (inputCompanyName.length >= 3) {
                      setAdvanceSearchGlobal((prevState: any) => ({
                        ...prevState,
                        companyName: inputCompanyName,
                        shortDescription: inputCompanyName
                      }));
                      setShowAds(true);
                    } else {
                      setAdvanceSearchGlobal((prevState: any) => ({
                        ...prevState,
                        companyName: "",
                      }));
                      setShowAds(false);
                      dispatch(resetSearch());
                    }
                  }
                }}
              />
            </Grid>
          )}
          {showAdvanceSearch ? (
            <Grid container spacing={3}>
              <Grid item md={12} xs={12} justifyContent="center">
                <Typography variant="body1" style={{ textAlign: "center" }}>
                  {t("header.advanceSearchLabel")}
                </Typography>
              </Grid>
              <Grow in={showAdvanceSearch} timeout={400}>
                <Grid item md={6} xs={12}>
                  <CustomOutlinedInput
                    label={t("header.companyNameInput") ?? ""}
                    name="global_search"
                    width="100%"
                    startAdornment={
                      <SearchIcon className={classes.searchIcon} />
                    }
                    onChange={(event) => {
                      const inputCompanyName = event.target.value;
                      if (event.target.name === "global_search") {
                        if (inputCompanyName.length >= 3) {
                          setAdvanceSearchGlobal((prevState: any) => ({
                            ...prevState,
                            companyName: inputCompanyName,
                            shortDescription: inputCompanyName
                          }));
                          setShowAds(true);
                        } else {
                          setAdvanceSearchGlobal((prevState: any) => ({
                            ...prevState,
                            companyName: "",
                          }));
                          setShowAds(false);
                          dispatch(resetSearch());
                        }
                      }
                      setAdvanceSearch((prevState: any) => ({
                        ...prevState,
                        companyName: inputCompanyName,
                      }));
                    }}
                  />
                </Grid>
              </Grow>
              <Grow in={showAdvanceSearch} timeout={600}>
                <Grid item md={6} xs={12}>
                  <SelectCategories
                    categories={categories}
                    categoryShortDetails={categoryShortDetails}
                    advanceSearch={advanceSearch}
                    setAdvanceSearch={setAdvanceSearch}
                    setCategories={setCategories}
                  />
                </Grid>
              </Grow>
              <Grow in={showAdvanceSearch} timeout={1000}>
                <Grid item md={6} xs={12}>
                  <Autocomplete
                    id="mainHeader_autocomplete_places-2"
                    size="small"
                    limitTags={1}
                    options={places ? places : []}
                    multiple
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <TextField
                        className={classes.autocompleteTextField}
                        {...params}
                        variant="outlined"
                        size="small"
                        fullWidth
                        label={t("header.place") ?? ""}
                        sx={{
                          fontSize: "5px",
                          "& label.Mui-focused": {
                            color: "white",
                          },
                          "& .MuiInputLabel-outlined": {
                            transition: "all 0.2s",
                          },
                          "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
                            transform: "translate(14px, -13px) scale(0.75)",
                          },
                        }}
                      />
                    )}
                    className={classes.autocomplete}
                    onChange={(event, selectedOptions) => {
                      setAdvanceSearch((prevState: any) => ({
                        ...prevState,
                        placeIds: selectedOptions.map((option) => option.id),
                      }));
                    }}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.name}
                      </li>
                    )}
                    disablePortal
                    disableCloseOnSelect
                  />
                </Grid>
              </Grow>
              <Grid container item md={12} xs={12} alignItems="center">
                <Grid item xs={3}>
                  <ArrowBackIcon
                    onClick={() => setShowAdvanceSearch(false)}
                    className={classes.arrowBackIcon}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  display="flex"
                  textAlign="center"
                  justifyContent="center"
                >
                  <Grid item>
                    <AppButton
                      id="mainHeader_button_search"
                      label={t("buttons.clearSearch")}
                      color={colors.red}
                      hover={colors.red}
                      onClick={handleRemoveResult}
                      startIcon={<DeleteIcon />}
                      minWidth={"150px"}
                    />
                  </Grid>
                  <Grid item ml={1}>
                    <AppButton
                      label="Pretraga"
                      color={colors.secondary}
                      hover={colors.secondary}
                      onClick={handleSearch}
                      minWidth={"150px"}
                      startIcon={
                        searchStatus !== "Pending" ? (
                          <SearchIcon />
                        ) : (
                          <CircularProgress size={"16px"} />
                        )
                      }
                      disabled={searchStatus !== "Pending" ? false : true}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grow in={showAdvanceSearch === false}>
              <Grid
                item
                md={12}
                xs={12}
                justifyContent="center"
                textAlign="center"
                className={classes.advanceSearchWrapper}
              >
                <Typography
                  className={classes.typography}
                  variant="body2"
                  gutterBottom
                  onClick={() => handleClickAdvanceSearch()}
                >
                  <SearchIcon className={classes.searchIconFilters} />
                  {t("header.advanceSearchLabel")}
                </Typography>
              </Grid>
            </Grow>
          )}
        </Grid>
      </Toolbar>
      <ChangePasswordModal
        open={isOpenChangePasswordModal}
        handleClose={() => setIsOpenChangePasswordModal(false)}
      />
      <SettingsActivitiesSelectionModal
        open={isOpenActivitySelectionModal}
        handleClose={() => setIsOpenActivitySelectionModal(false)}
      />
      <FaqModal
        open={isOpenFaqModal}
        handleClose={() => setIsOpenFaqModal(false)}
        handleFaqChange={handleFaqChange}
        faqMessageState={faqMessageState}
        handleSaveFaq={handleSaveFaq}
      />
      <ManageClientsAccountsModal
        open={isOpenManageClientsModal}
        handleClose={() => setIsOpenManageClientsModal(false)}
      />
    </GradientAppBar>
  );
};

export default MobileHeader;
