import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import UploadImgModal from "./uploadImgModal";
import MySiteImgSection from "./mySiteImgSection";
import MySiteTextSection from "./mySiteTextSection";
import MySiteSectionCube from "./mySiteSectionCube";
import MySitePhoneSection from "./mySitePhoneSection";
import MySiteSocialMediaSection from "./mySiteSocialMediaSection";
import MySiteVideoSection from "./mySiteVideoSection";
import { colors } from "../../app/theme/colors";
import CustomOutlinedInput from "../../components/outlinedInput/customOutlinedInput";
import MySiteGallerySection from "./mySiteGallerySection";
import UploadGalleryModal from "./uploadGalleryModal";
import MySiteSliderSection from "./mySiteSliderSection";
import UploadSliderModal from "./uploadSliderModal";
import DisplayDataMobile from "./displayDataMobile";
import LanguageIcon from "@mui/icons-material/Language";
import TextSection, {
  EmptyGuid,
  INIT_REMOWE_WEBSITE_PARTITION,
} from "../../models/init_data/initData";
import { useAppDispatch, useAppSelector } from "../../app/redux/hooks";
import {
  getCompanyWebsite,
  removeWebsitePartition,
  selectCompanyWebsiteStatus,
  selectMySiteDetails,
  selectMySiteGallery,
  selectMySiteGalleryStatus,
  selectMySitePhone,
  selectMySitePhoneStatus,
  selectMySitePhoto,
  selectMySitePhotoStatus,
  selectMySiteSlider,
  selectMySiteSliderStatus,
  selectMySiteSocialMedia,
  selectMySiteSocialMediaStatus,
  selectMySiteText,
  selectMySiteTextStatus,
  selectMySiteVideo,
  selectMySiteVideoStatus,
  selectRemovedWebsitePartitionStatus,
} from "../../slices/mySiteSlice";
import MySitePhotoModel from "../../models/mySite/mySitePhotoModel";
import MySiteTextModel from "../../models/mySite/mySiteTextModel";
import MySitePhoneModel from "../../models/mySite/mySitePhoneModel";
import MySiteVideoModel from "../../models/mySite/mySiteVideoModel";
import MySiteSocialMediaModel from "../../models/mySite/mySiteSocialMediaModel";
import MySiteGalleryModel from "../../models/mySite/mySiteGalleryModel";
import MySiteSliderModel from "../../models/mySite/mySiteSliderModel";
import RemoveWebsitePartition from "../../models/mySite/removeWebsitePartition";
import usePartitionUpdaterEffect from "./hooks/usePartitionUptaderEffect";
import useNotificationMessagessEffect from "./hooks/useNotificationMessagessEffect";
import { PartitionTypeText } from "../../models/enums/partitionTypeText";
import {
  handleClearItem,
  handleRemovePartition,
  handleSelectItem,
  toKey,
} from "./utilis";
import { useTranslation } from "react-i18next";
import { PartitionType } from "../../models/enums/partitionType";
import { MySiteDetailsResponse } from "../../models/mySite/mySiteDetalisResponse";
import { selectCurrentAccount } from "../../slices/authSlice";
import {
  getCompanyFullDetailsById,
  selectCompanyById,
} from "../../slices/companySlice";
import AppButton from "../../components/appButton/appButton";

const LineWithIndicator = styled(Box)(({ theme }) => ({
  width: "100%",
  position: "relative",
  "&::before": {
    content: '""',
    position: "absolute",
    bottom: -5,
    left: "50%",
    transform: "translateX(-50%)",
    width: "100%",
    height: 2,
    backgroundColor: theme.palette.primary.main,
  },
}));

const GradientBackground = styled(Grid)(({ theme }) => ({
  backgroundPosition: "center",
  [theme.breakpoints.down("md")]: {
    backgroundSize: "100%",
    backgroundPosition: "bottom",
  },
}));
export default function MySiteSection() {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  const companyWebsiteStatus = useAppSelector(selectCompanyWebsiteStatus);
  const currentAccount = useAppSelector(selectCurrentAccount);
  const companyById = useAppSelector(selectCompanyById);
  const websiteName = companyById?.generalData.websiteName ?? undefined;
  const companyWebsiteName = companyById?.generalData.websiteName;
  const [options, setOptions] = useState([
    { label: t("mySite.mySitePopoverPhoto"), value: "Photo" },
    { label: t("mySite.mySitePopoverVideo"), value: "Video" },
    { label: t("mySite.mySitePopoverText"), value: "Text" },
    { label: t("mySite.mySitePopoverPhone"), value: "Phone" },
    { label: t("mySite.mySitePopoverSocialMedia"), value: "SocialMedia" },
    { label: t("mySite.mySitePopoverGallery"), value: "Gallery" },
    { label: t("mySite.mySitePopoverSlider"), value: "Slider" },
  ]);

  const mySitePartitions = useAppSelector((state) => ({
    photo: selectMySitePhoto(state),
    phone: selectMySitePhone(state),
    text: selectMySiteText(state),
    video: selectMySiteVideo(state),
    socialMedia: selectMySiteSocialMedia(state),
    gallery: selectMySiteGallery(state),
    slider: selectMySiteSlider(state),
    websiteDetails: selectMySiteDetails(state),
  }));

  const mySitePartitionsStatus = {
    photo: useAppSelector(selectMySitePhotoStatus),
    phone: useAppSelector(selectMySitePhoneStatus),
    text: useAppSelector(selectMySiteTextStatus),
    video: useAppSelector(selectMySiteVideoStatus),
    socialMedia: useAppSelector(selectMySiteSocialMediaStatus),
    gallery: useAppSelector(selectMySiteGalleryStatus),
    slider: useAppSelector(selectMySiteSliderStatus),
    removePartition: useAppSelector(selectRemovedWebsitePartitionStatus),
  };

  const [clickedCube, setClickedCube] = useState<string | null>(null);

  const [selectedItems, setSelectedItems] = useState<{
    [key: string]: {
      value: string | null;
      orderNumber: number;
      id: string | null;
    };
  }>({
    first: { value: null, orderNumber: 1, id: null },
    second: { value: null, orderNumber: 2, id: null },
    third: { value: null, orderNumber: 3, id: null },
    fourth: { value: null, orderNumber: 4, id: null },
    fifth: { value: null, orderNumber: 5, id: null },
    sixth: { value: null, orderNumber: 6, id: null },
    seventh: { value: null, orderNumber: 7, id: null },
    eight: { value: null, orderNumber: 8, id: null },
  });
  const [anchorEl, setAnchorEl] = useState<{
    [key: string]: HTMLElement | null;
  }>({
    first: null,
    second: null,
    third: null,
    fourth: null,
    fifth: null,
    sixth: null,
    seventh: null,
    eight: null,
  });
  const [selectedGalleryFiles, setSelectedGalleryFiles] = useState<File[]>([]);
  const [selectedSliderFiles, setSelectedSliderFiles] = useState<File[]>([]);

  const [mySitePhotoSection, setMySitePhotoSection] = useState<
    MySitePhotoModel[]
  >([]);
  const [mySiteTextSection, setMySiteTextSection] = useState<MySiteTextModel[]>(
    []
  );

  const [mySitePhoneSection, setMySitePhoneSection] = useState<
    MySitePhoneModel[]
  >([]);
  const [mySiteVideoSection, setMySiteVideoSection] = useState<
    MySiteVideoModel[]
  >([]);
  const [mySiteSocialMediaSection, setMySiteSocialMediaSection] = useState<
    MySiteSocialMediaModel[]
  >([]);
  const [mySiteGallerySection, setMySiteGallerySection] = useState<
    MySiteGalleryModel[]
  >([]);
  const [mySiteSliderSection, setMySiteSliderSection] = useState<
    MySiteSliderModel[]
  >([]);
  const [newSocialMediaSections, setNewSocialMediaSections] = useState<
    { icon: string | null; name: string; link: string }[]
  >([]);

  const [removeWebsitePartitionId, setRemoveWebsitePartitionId] =
    useState<RemoveWebsitePartition>(INIT_REMOWE_WEBSITE_PARTITION);

  const [isOpenUploadGalleryModal, setIsOpenUploadGalleryModal] =
    useState<boolean>(false);
  const [isOpenUploadSliderModal, setIsOpenUploadSliderModal] =
    useState<boolean>(false);
  const [isOpenUploadImgModal, setIsOpenUploadImgModal] =
    useState<boolean>(false);

  useEffect(() => {
    if (removeWebsitePartitionId.websitePartitionId !== EmptyGuid) {
      dispatch(removeWebsitePartition(removeWebsitePartitionId));
    }
  }, [removeWebsitePartitionId]);

  const handleClose = (cube: string) => {
    setAnchorEl((prevState) => ({ ...prevState, [cube]: null }));
  };
  const handleSetSelectedFile = (file: File | null, cube: string) => {};

  const handleOpenMenu =
    (cube: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
      setClickedCube(cube);
      setAnchorEl((prevState) => ({
        ...prevState,
        [cube]: event.currentTarget,
      }));
    };

  const [websiteDetailsLoaded, setWebsiteDetailsLoaded] = useState(false);

  const isItemExist = (section: any[], itemId: string) =>
    section.some((item) => item.id === itemId);

  const handleMySiteViewPage = () => {
    const newTab = window.open();
    if (newTab) {
      newTab.location.href = websiteName as string;
    } else {
      console.error("Unable to open a new tab");
    }
  };

  useEffect(() => {
    if (mySitePartitions.websiteDetails && !websiteDetailsLoaded) {
      setWebsiteDetailsLoaded(true);

      const updatedSelectedItems: any = {};
      const updatedPhotoSection: MySitePhotoModel[] = [];
      const updatedTextSection: MySiteTextModel[] = [];
      const updatedPhoneSection: MySitePhoneModel[] = [];
      const updatedVideoSection: MySiteVideoModel[] = [];
      const updatedSocialMediaSection: MySiteSocialMediaModel[] = [];
      const updatedGallerySection: MySiteGalleryModel[] = [];
      const updatedSliderSection: MySiteSliderModel[] = [];

      mySitePartitions.websiteDetails.forEach(
        (detail: MySiteDetailsResponse) => {
          const {
            orderNumber,
            id,
            partitionType,
            photoPath,
            text,
            phones,
            socialMedias,
            photoPaths,
          } = detail;
          const key = toKey(orderNumber);

          updatedSelectedItems[key] = {
            value: PartitionType[partitionType],
            orderNumber,
            id,
          };

          switch (partitionType as PartitionType) {
            case PartitionType.Photo:
              if (!isItemExist(updatedPhotoSection, id)) {
                updatedPhotoSection.push({
                  id,
                  orderNumber,
                  base64Photo: photoPath ? photoPath : "",
                });
              }
              break;
            case PartitionType.Text:
              if (!isItemExist(updatedTextSection, id)) {
                updatedTextSection.push({
                  id,
                  orderNumber,
                  value: text ? text : "",
                });
              }
              break;
            case PartitionType.Phone:
              if (!isItemExist(updatedPhoneSection, id)) {
                updatedPhoneSection.push({
                  id,
                  orderNumber,
                  phones: phones ? phones : [],
                });
              }
              break;
            case PartitionType.Video:
              if (!isItemExist(updatedVideoSection, id)) {
                updatedVideoSection.push({
                  id,
                  orderNumber,
                  link: text ? text : "",
                });
              }
              break;
            case PartitionType.SocialMedia:
              if (!isItemExist(updatedSocialMediaSection, id)) {
                updatedSocialMediaSection.push({
                  id,
                  orderNumber,
                  socialMedias: socialMedias ? socialMedias : [],
                });
              }
              break;
            case PartitionType.Gallery:
              if (!isItemExist(updatedGallerySection, id)) {
                updatedGallerySection.push({
                  id,
                  orderNumber,
                  base64Photos: photoPaths ? photoPaths : [],
                  isGallery: true,
                });
              }
              break;
            case PartitionType.Slider:
              if (!isItemExist(updatedSliderSection, id)) {
                updatedSliderSection.push({
                  id,
                  orderNumber,
                  base64Photos: photoPaths ? photoPaths : [],
                  isSlider: true,
                });
              }
              break;
            default:
              break;
          }
        }
      );

      setSelectedItems((prevItems: any) => ({
        ...prevItems,
        ...updatedSelectedItems,
      }));
      setMySitePhotoSection([...updatedPhotoSection]);
      setMySiteTextSection([...updatedTextSection]);
      setMySitePhoneSection([...updatedPhoneSection]);
      setMySiteVideoSection([...updatedVideoSection]);
      setMySiteSocialMediaSection([...updatedSocialMediaSection]);
      setMySiteGallerySection([...updatedGallerySection]);
      setMySiteSliderSection([...updatedSliderSection]);
    }
  }, [
    mySitePartitions,
    websiteDetailsLoaded,
    mySiteTextSection,
    mySitePhotoSection,
  ]);

  usePartitionUpdaterEffect(
    setSelectedItems,
    setMySitePhotoSection,
    setMySiteTextSection,
    setMySitePhoneSection,
    setMySiteVideoSection,
    setMySiteSocialMediaSection,
    setMySiteGallerySection,
    setMySiteSliderSection
  );

  // useNotificationMessagessEffect();

  useEffect(() => {
    dispatch(getCompanyWebsite());
    if (currentAccount) {
      dispatch(getCompanyFullDetailsById(currentAccount?.companyId || ""));
    }
  }, []);

  useEffect(() => {
    const updateLanguage = () => {
      const newOptions = [
        { label: t("mySite.mySitePopoverPhoto"), value: "Photo" },
        { label: t("mySite.mySitePopoverVideo"), value: "Video" },
        { label: t("mySite.mySitePopoverText"), value: "Text" },
        { label: t("mySite.mySitePopoverPhone"), value: "Phone" },
        { label: t("mySite.mySitePopoverSocialMedia"), value: "SocialMedia" },
        { label: t("mySite.mySitePopoverGallery"), value: "Gallery" },
        { label: t("mySite.mySitePopoverSlider"), value: "Slider" },
      ];
      setOptions(newOptions);
    };
    updateLanguage();
    i18n.on("languageChanged", updateLanguage);

    return () => {
      i18n.off("languageChanged", updateLanguage);
    };
  }, [i18n, t]);

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Container maxWidth="xl">
      <GradientBackground
        container
        spacing={5}
        justifyContent={"center"}
        alignItems={"flex-start"}
      >
        <Grid item md={6} xs={12}>
          <Grid
            item
            display={"flex"}
            mt={2}
            md={12}
            xs={12}
            mb={4}
            ml={!isMobile ? 3 : 5}
          >
            <Typography sx={{ color: colors.primary }} variant="h6">
              {t("mySite.mySiteTitle")} - {websiteName}
            </Typography>
          </Grid>
          <Grid item md={12} mt={2} ml={!isMobile ? 3 : 0}>
            <AppButton
              label={t("fullDetailsModal.cardShowWebsite")}
              color={colors.primary}
              hover={colors.primary}
              onClick={handleMySiteViewPage}
              startIcon={<LanguageIcon />}
            />
          </Grid>
          <Grid
            item
            // md={12}
            display={"flex"}
            justifyContent={"flex-start"}
            mt={2}
            md={12}
            xs={12}
            mb={4}
            ml={!isMobile ? 3 : 0}
          >
            <Typography
              variant="body1"
              sx={{ color: "rgba(140, 140, 140, 1)"}}
            >
              {t("mySite.mySiteSubtitle")}
            </Typography>
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
            ml={!isMobile ? 2 : 0}
            display={"flex"}
            sx={{
              overflowX: { xs: "scroll", md: "auto" },
              whiteSpace: "nowrap",
              overflowY: "hidden",
              "::-webkit-scrollbar": {
                width: 0,
              },
              scrollbarWidth: "none",
            }}
          >
            {Object.keys(selectedItems).map((cubeType) => (
              <MySiteSectionCube
                companyWebsiteStatus={companyWebsiteStatus}
                key={cubeType}
                cubeType={cubeType}
                handleClickCube={handleOpenMenu}
                handleOpenMenu={handleOpenMenu}
                anchorEl={anchorEl[cubeType]}
                clickedCube={clickedCube}
                handleClose={() => handleClose(cubeType)}
                handleSelectItem={(item: string) =>
                  handleSelectItem(
                    item,
                    cubeType,
                    selectedItems,
                    setSelectedItems,
                    setMySitePhotoSection,
                    setMySiteTextSection,
                    setMySitePhoneSection,
                    setMySiteVideoSection,
                    setMySiteSocialMediaSection,
                    setMySiteGallerySection,
                    setMySiteSliderSection,
                    setIsOpenUploadImgModal,
                    handleClose,
                    EmptyGuid
                  )
                }
                options={options}
                selectedItem={selectedItems[cubeType]}
                isActive={clickedCube === cubeType}
                handleClearItem={(orderNumber: number, type: string) =>
                  handleClearItem(
                    orderNumber,
                    type,
                    selectedItems[cubeType]?.id || "",
                    selectedItems,
                    setSelectedItems,
                    setMySitePhotoSection,
                    setMySiteTextSection,
                    setMySitePhoneSection,
                    setMySiteVideoSection,
                    setMySiteSocialMediaSection,
                    setMySiteGallerySection,
                    setMySiteSliderSection,
                    dispatch
                  )
                }
              />
            ))}
          </Grid>

          {clickedCube && <LineWithIndicator />}
          {Object.keys(selectedItems).map(
            (cubeType) =>
              clickedCube === cubeType &&
              selectedItems[cubeType] && (
                <Grid
                  item
                  md={8.5}
                  xs={12}
                  mb={4}
                  ml={currentAccount && !isMobile ? 6.5 : 0}
                  // md={12}
                  display={"flex"}
                  key={`${cubeType}-${selectedItems[cubeType]}`}
                >
                  {selectedItems[cubeType]?.value ===
                    PartitionTypeText.Photo && (
                    <MySiteImgSection
                      setIsOpenUploadImgModal={setIsOpenUploadImgModal}
                      status={mySitePartitionsStatus.photo}
                      cubeType={cubeType}
                      selectedPhoto={mySitePhotoSection}
                      isOpenUploadImgModal={isOpenUploadImgModal}
                      selectedItems={selectedItems}
                      clickedCube={clickedCube}
                      selectMySiteImg={mySitePartitions.photo}
                      handleRemoveImg={() =>
                        handleRemovePartition(
                          dispatch,
                          selectedItems,
                          setSelectedItems,
                          cubeType,
                          mySitePhotoSection,
                          PartitionTypeText.Photo,
                          setMySitePhotoSection,
                          setMySiteTextSection,
                          setMySitePhoneSection,
                          setMySiteVideoSection,
                          setMySiteSocialMediaSection,
                          setMySiteGallerySection,
                          setMySiteSliderSection
                        )
                      }
                    />
                  )}
                  {selectedItems[cubeType]?.value ===
                    PartitionTypeText.Text && (
                    <MySiteTextSection
                      status={mySitePartitionsStatus.text}
                      mySiteTextSections={mySiteTextSection}
                      setMySiteTextSections={setMySiteTextSection}
                      selectedItems={selectedItems}
                      cubeType={cubeType}
                      handleRemoveText={() =>
                        handleRemovePartition(
                          dispatch,
                          selectedItems,
                          setSelectedItems,
                          cubeType,
                          mySiteTextSection,
                          PartitionTypeText.Text,
                          setMySitePhotoSection,
                          setMySiteTextSection,
                          setMySitePhoneSection,
                          setMySiteVideoSection,
                          setMySiteSocialMediaSection,
                          setMySiteGallerySection,
                          setMySiteSliderSection
                        )
                      }
                      selectMySiteText={mySitePartitions.text}
                      clickedCube={clickedCube}
                    />
                  )}

                  {selectedItems[cubeType]?.value ===
                    PartitionTypeText.Phone && (
                    <MySitePhoneSection
                      status={mySitePartitionsStatus.phone}
                      phoneSections={mySitePhoneSection}
                      setPhoneSections={setMySitePhoneSection}
                      handleRemovePhone={() =>
                        handleRemovePartition(
                          dispatch,
                          selectedItems,
                          setSelectedItems,
                          cubeType,
                          mySitePhoneSection,
                          PartitionTypeText.Phone,
                          setMySitePhotoSection,
                          setMySiteTextSection,
                          setMySitePhoneSection,
                          setMySiteVideoSection,
                          setMySiteSocialMediaSection,
                          setMySiteGallerySection,
                          setMySiteSliderSection
                        )
                      }
                      clickedCube={clickedCube}
                      selectMySitePhone={mySitePartitions.phone}
                      cubeType={cubeType}
                      selectedItems={selectedItems}
                      setMySitePhoneSection={setMySitePhoneSection}
                    />
                  )}
                  {selectedItems[cubeType]?.value ===
                    PartitionTypeText.SocialMedia && (
                    <MySiteSocialMediaSection
                      status={mySitePartitionsStatus.socialMedia}
                      mySiteSocialMediaSection={mySiteSocialMediaSection}
                      setMySiteSocialMediaSection={setMySiteSocialMediaSection}
                      cubeType={cubeType}
                      selectedItems={selectedItems}
                      newSocialMediaSections={newSocialMediaSections}
                      setNewSocialMediaSections={setNewSocialMediaSections}
                      handleRemoveSocialMedia={() =>
                        handleRemovePartition(
                          dispatch,
                          selectedItems,
                          setSelectedItems,
                          cubeType,
                          mySiteSocialMediaSection,
                          PartitionTypeText.SocialMedia,
                          setMySitePhotoSection,
                          setMySiteTextSection,
                          setMySitePhoneSection,
                          setMySiteVideoSection,
                          setMySiteSocialMediaSection,
                          setMySiteGallerySection,
                          setMySiteSliderSection
                        )
                      }
                      selectMySitePhone={mySitePartitions.socialMedia}
                      clickedCube={clickedCube}
                    />
                  )}
                  {selectedItems[cubeType]?.value ===
                    PartitionTypeText.Video && (
                    <MySiteVideoSection
                      status={mySitePartitionsStatus.video}
                      cubeType={cubeType}
                      mySiteVideo={mySiteVideoSection}
                      selectedItems={selectedItems}
                      setMySiteVideo={setMySiteVideoSection}
                      handleRemoveVideo={() =>
                        handleRemovePartition(
                          dispatch,
                          selectedItems,
                          setSelectedItems,
                          cubeType,
                          mySiteVideoSection,
                          PartitionTypeText.Video,
                          setMySitePhotoSection,
                          setMySiteTextSection,
                          setMySitePhoneSection,
                          setMySiteVideoSection,
                          setMySiteSocialMediaSection,
                          setMySiteGallerySection,
                          setMySiteSliderSection
                        )
                      }
                      clickedCube={clickedCube}
                      selectMySiteVideo={mySitePartitions.video}
                    />
                  )}
                  {selectedItems[cubeType]?.value ===
                    PartitionTypeText.Gallery && (
                    <MySiteGallerySection
                      status={mySitePartitionsStatus.gallery}
                      setSelectedGalleryFiles={setSelectedGalleryFiles}
                      selectedGalleryFiles={selectedGalleryFiles}
                      setIsOpenModal={setIsOpenUploadGalleryModal}
                      mySiteGallerySection={mySiteGallerySection}
                      selectedItems={selectedItems}
                      clickedCube={clickedCube}
                      setMySiteGallerySection={setMySiteGallerySection}
                      handleRemoveGallery={() =>
                        handleRemovePartition(
                          dispatch,
                          selectedItems,
                          setSelectedItems,
                          cubeType,
                          mySiteGallerySection,
                          PartitionTypeText.Gallery,
                          setMySitePhotoSection,
                          setMySiteTextSection,
                          setMySitePhoneSection,
                          setMySiteVideoSection,
                          setMySiteSocialMediaSection,
                          setMySiteGallerySection,
                          setMySiteSliderSection
                        )
                      }
                      selectMySiteGallery={mySitePartitions.gallery}
                      cubeType={cubeType}
                    />
                  )}
                  {selectedItems[cubeType]?.value ===
                    PartitionTypeText.Slider && (
                    <MySiteSliderSection
                      status={mySitePartitionsStatus.slider}
                      setSelectedGalleryFiles={setSelectedSliderFiles}
                      selectedGalleryFiles={selectedSliderFiles}
                      setIsOpenModal={setIsOpenUploadSliderModal}
                      mySiteSliderSection={mySiteSliderSection}
                      selectedItems={selectedItems}
                      clickedCube={clickedCube}
                      setMySiteSliderSection={setMySiteSliderSection}
                      handleRemoveSlider={() =>
                        handleRemovePartition(
                          dispatch,
                          selectedItems,
                          setSelectedItems,
                          cubeType,
                          mySiteSliderSection,
                          PartitionTypeText.Slider,
                          setMySitePhotoSection,
                          setMySiteTextSection,
                          setMySitePhoneSection,
                          setMySiteVideoSection,
                          setMySiteSocialMediaSection,
                          setMySiteGallerySection,
                          setMySiteSliderSection
                        )
                      }
                      selectMySiteSlider={mySitePartitions.slider}
                      cubeType={cubeType}
                    />
                  )}
                </Grid>
              )
          )}
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
          justifyContent={"center"}
          textAlign={"center"}
        >
          <DisplayDataMobile
            mySiteSocialMedia={mySiteSocialMediaSection}
            phoneSection={mySitePhoneSection}
            mySitePhoto={mySitePhotoSection}
            mySiteText={mySiteTextSection}
            mySiteVideo={mySiteVideoSection || []}
            selectedItems={selectedItems}
            mySiteGallery={mySiteGallerySection || []}
            mySiteSlider={mySiteSliderSection}
            mySiteNewSocialMediaSection={newSocialMediaSections}
          />
        </Grid>
      </GradientBackground>
      <UploadImgModal
        mySitePhotoSection={mySitePhotoSection}
        setMySitePhotoSection={setMySitePhotoSection}
        handleClose={() => setIsOpenUploadImgModal(false)}
        open={isOpenUploadImgModal}
        setSelectedFile={(file: File | null) =>
          handleSetSelectedFile(file, clickedCube || "")
        }
        clickedCube={clickedCube}
        selectedItems={selectedItems}
      />

      <UploadGalleryModal
        handleClose={() => setIsOpenUploadGalleryModal(false)}
        open={isOpenUploadGalleryModal}
        setSelectedFiles={setSelectedGalleryFiles}
        selectedFiles={selectedGalleryFiles}
        mySiteGallerySection={mySiteGallerySection}
        setMySiteGallerySection={setMySiteGallerySection}
        clickedCube={clickedCube}
        selectedItems={selectedItems}
      />
      <UploadSliderModal
        handleClose={() => {
          setIsOpenUploadSliderModal(false);
        }}
        open={isOpenUploadSliderModal}
        selectedFiles={selectedSliderFiles}
        setSelectedFiles={setSelectedSliderFiles}
        clickedCube={clickedCube}
        mySiteSliderSection={mySiteSliderSection}
        selectedItems={selectedItems}
        setMySiteSliderSection={setMySiteSliderSection}
      />
    </Container>
  );
}
