import { ApiResponse } from "../../models/app/apiResponse";
import AccountCategoryModel from "../../models/categories/accountCategoryModel";
import GetAllSubcategoriesModel from "../../models/categories/accountSubcategoriesRequest";
import AccountSubcategoryModel from "../../models/categories/accountSubcategoryModel";
import ActivitiesResponseModel from "../../models/onboarding/activitesResponseModel";
import { api_poslovni_imenik } from "./api_poslovni_imenik";

export const savePartner: any = async (companyId: string) => {
  return api_poslovni_imenik.post(
    "/account-partner-company?companyId=" + companyId
  ) as Promise<ApiResponse<ActivitiesResponseModel | null>>;
};

export const savePotentialPartner: any = async (companyId: string) => {
  return api_poslovni_imenik.post(
    "/account-potential-partner-company?companyId=" + companyId
  ) as Promise<ApiResponse<ActivitiesResponseModel | null>>;
};

export const saveClient: any = async (companyId: string) => {
  return api_poslovni_imenik.post(
    "/account-company-client?companyId=" + companyId
  ) as Promise<ApiResponse<ActivitiesResponseModel | null>>;
};

export const saveActivity: any = async (companyId: string) => {
  return api_poslovni_imenik.post(
    "/account-category?companyId=" + companyId
  ) as Promise<ApiResponse<ActivitiesResponseModel | null>>;
};

export const saveCategory: any = async (request: string[]) => {
  return api_poslovni_imenik.post(
    "/account-category/add-account-categories",
    request
  ) as Promise<ApiResponse<ActivitiesResponseModel | null>>;
};

export const saveFavorite: any = async (companyId: string) => {
  return api_poslovni_imenik.post(
    "/account-favorite?companyId=" + companyId
  ) as Promise<ApiResponse<ActivitiesResponseModel | null>>;
};

export const removePartner: any = async (companyId: string) => {
  return api_poslovni_imenik.delete(
    "/account-partner-company?companyId=" + companyId
  ) as Promise<ApiResponse<ActivitiesResponseModel | null>>;
};

export const removePotentialPartner: any = async (companyId: string) => {
  return api_poslovni_imenik.delete(
    "/account-potential-partner-company?companyId=" + companyId
  ) as Promise<ApiResponse<ActivitiesResponseModel | null>>;
};

export const removeClient: any = async (companyId: string) => {
  return api_poslovni_imenik.delete(
    "/account-company-client?companyId=" + companyId
  ) as Promise<ApiResponse<ActivitiesResponseModel | null>>;
};

export const removeCategory: any = async (companyId: string) => {
  return api_poslovni_imenik.delete(
    "/account-favorite?companyId=" + companyId
  ) as Promise<ApiResponse<ActivitiesResponseModel | null>>;
};

export const removeFavorite: any = async (companyId: string) => {
  return api_poslovni_imenik.delete(
    "/account-favorite?companyId=" + companyId
  ) as Promise<ApiResponse<ActivitiesResponseModel | null>>;
};

export const getAccountCategories: any = async () => {
  return api_poslovni_imenik.get(
    "/account-category"
  ) as Promise<ApiResponse<AccountCategoryModel | null>>;
};

export const getAllSubcategories: any = async (request: GetAllSubcategoriesModel) => {
  return api_poslovni_imenik.post(
    "/category/get-all-sub-categories", request
  ) as Promise<ApiResponse<AccountCategoryModel | null>>;
};

export const getAccountSubcategories: any = async () => {
  return api_poslovni_imenik.get(
    "/account-category/sub-categories"
  ) as Promise<ApiResponse<AccountSubcategoryModel | null>>;
};