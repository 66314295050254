import { useEffect } from "react";
import MySitePhotoModel from "../../../models/mySite/mySitePhotoModel";
import MySitePhoneModel from "../../../models/mySite/mySitePhoneModel";
import MySiteTextModel from "../../../models/mySite/mySiteTextModel";
import MySiteVideoModel from "../../../models/mySite/mySiteVideoModel";
import MySiteSocialMediaModel from "../../../models/mySite/mySiteSocialMediaModel";
import MySiteGalleryModel from "../../../models/mySite/mySiteGalleryModel";
import MySiteSliderModel from "../../../models/mySite/mySiteSliderModel";
import { toKey } from "../utilis";
import { useAppSelector } from "../../../app/redux/hooks";
import {
  selectMySiteDetails,
  selectMySiteGallery,
  selectMySitePhone,
  selectMySitePhoto,
  selectMySiteSlider,
  selectMySiteSocialMedia,
  selectMySiteText,
  selectMySiteVideo,
} from "../../../slices/mySiteSlice";

const usePartitionUpdaterEffect = (
  setSelectedItems: (prevItems: any) => void,
  setMySitePhotoSection: (
    prevSections: (prevSections: MySitePhotoModel[]) => MySitePhotoModel[]
  ) => void,
  setMySiteTextSection: (
    prevSections: (prevSections: MySiteTextModel[]) => MySiteTextModel[]
  ) => void,
  setMySitePhoneSection: (
    prevSections: (prevSections: MySitePhoneModel[]) => MySitePhoneModel[]
  ) => void,
  setMySiteVideoSection: (
    prevSections: (prevSections: MySiteVideoModel[]) => MySiteVideoModel[]
  ) => void,
  setMySiteSocialMediaSection: (
    prevSections: (
      prevSections: MySiteSocialMediaModel[]
    ) => MySiteSocialMediaModel[]
  ) => void,
  setMySiteGallerySection: (
    prevSections: (prevSections: MySiteGalleryModel[]) => MySiteGalleryModel[]
  ) => void,
  setMySiteSliderSection: (
    prevSections: (prevSections: MySiteSliderModel[]) => MySiteSliderModel[]
  ) => void
) => {
  const mySitePartitions = useAppSelector((state) => ({
    photo: selectMySitePhoto(state),
    phone: selectMySitePhone(state),
    text: selectMySiteText(state),
    video: selectMySiteVideo(state),
    socialMedia: selectMySiteSocialMedia(state),
    gallery: selectMySiteGallery(state),
    slider: selectMySiteSlider(state),
    websiteDetails: selectMySiteDetails(state),
  }));
  useEffect(() => {
    if (mySitePartitions.photo) {
      setMySitePhotoSection((prevSections) => {
        return prevSections.map((section) => {
          const index = mySitePartitions.photo?.findIndex(
            (item) => item.orderNumber === section.orderNumber
          );
          if (
            index !== undefined &&
            index !== -1 &&
            mySitePartitions.photo &&
            mySitePartitions.photo[index]
          ) {
            const photoItem = mySitePartitions.photo[index];
            setSelectedItems((prevItems: any) => {
              if (photoItem) {
                return {
                  ...prevItems,
                  [toKey(section.orderNumber)]: {
                    ...prevItems[toKey(section.orderNumber)],
                    id: photoItem.id,
                  },
                };
              }
              return prevItems;
            });
            return {
              ...section,
              id: photoItem.id,
              base64Photo: photoItem.base64Photo,
              orderNumber: photoItem.orderNumber,
            };
          } else {
            return section;
          }
        });
      });
    }

    if (mySitePartitions.text) {
      setMySiteTextSection((prevSections) => {
        return prevSections.map((section) => {
          const index = mySitePartitions.text?.findIndex(
            (item) => item.orderNumber === section.orderNumber
          );
          if (
            index !== undefined &&
            index !== -1 &&
            mySitePartitions.text &&
            mySitePartitions.text[index]
          ) {
            const textItem = mySitePartitions.text[index];
            setSelectedItems((prevItems: any) => {
              if (textItem) {
                return {
                  ...prevItems,
                  [toKey(section.orderNumber)]: {
                    ...prevItems[toKey(section.orderNumber)],
                    id: textItem.id,
                  },
                };
              }
              return prevItems;
            });
            return {
              ...section,
              id: textItem.id,
              orderNumber: textItem.orderNumber,
              value: textItem.value,
            };
          } else {
            return section;
          }
        });
      });
    }

    if (mySitePartitions.phone) {
      setMySitePhoneSection((prevSections) => {
        return prevSections.map((section) => {
          const index = mySitePartitions.phone?.findIndex(
            (item) => item.orderNumber === section.orderNumber
          );
          if (
            index !== undefined &&
            index !== -1 &&
            mySitePartitions.phone &&
            mySitePartitions.phone[index]
          ) {
            const phoneItem = mySitePartitions.phone[index];
            setSelectedItems((prevItems: any) => {
              if (phoneItem) {
                return {
                  ...prevItems,
                  [toKey(section.orderNumber)]: {
                    ...prevItems[toKey(section.orderNumber)],
                    id: phoneItem.id,
                  },
                };
              }
              return prevItems;
            });
            return {
              ...section,
              id: phoneItem.id,
              orderNumber: phoneItem.orderNumber,
              phones: phoneItem.phones,
            };
          } else {
            return section;
          }
        });
      });
    }

    if (mySitePartitions.video) {
      setMySiteVideoSection((prevSections) => {
        return prevSections.map((section) => {
          const index = mySitePartitions.video?.findIndex(
            (item) => item.orderNumber === section.orderNumber
          );
          if (
            index !== undefined &&
            index !== -1 &&
            mySitePartitions.video &&
            mySitePartitions.video[index]
          ) {
            const videoItem = mySitePartitions.video[index];
            setSelectedItems((prevItems: any) => {
              if (videoItem) {
                return {
                  ...prevItems,
                  [toKey(section.orderNumber)]: {
                    ...prevItems[toKey(section.orderNumber)],
                    id: videoItem.id,
                  },
                };
              }
              return prevItems;
            });
            return {
              ...section,
              id: videoItem.id,
              orderNumber: videoItem.orderNumber,
              link: videoItem.link,
            };
          } else {
            return section;
          }
        });
      });
    }

    if (mySitePartitions.socialMedia) {
      setMySiteSocialMediaSection((prevSections) => {
        return prevSections.map((section) => {
          const index = mySitePartitions.socialMedia?.findIndex(
            (item) => item.orderNumber === section.orderNumber
          );
          if (
            index !== undefined &&
            index !== -1 &&
            mySitePartitions.socialMedia &&
            mySitePartitions.socialMedia[index]
          ) {
            const socialMediaItem = mySitePartitions.socialMedia[index];
            setSelectedItems((prevItems: any) => {
              if (socialMediaItem) {
                return {
                  ...prevItems,
                  [toKey(section.orderNumber)]: {
                    ...prevItems[toKey(section.orderNumber)],
                    id: socialMediaItem.id,
                  },
                };
              }
              return prevItems;
            });
            return {
              ...section,
              id: socialMediaItem.id,
              orderNumber: socialMediaItem.orderNumber,
              socialMedias: socialMediaItem.socialMedias,
            };
          } else {
            return section;
          }
        });
      });
    }

    if (mySitePartitions.gallery) {
      setMySiteGallerySection((prevSections) => {
        return prevSections.map((section) => {
          const index = mySitePartitions.gallery?.findIndex(
            (item) => item.orderNumber === section.orderNumber
          );
          if (
            index !== undefined &&
            index !== -1 &&
            mySitePartitions.gallery &&
            mySitePartitions.gallery[index]
          ) {
            const galleryItem = mySitePartitions.gallery[index];
            setSelectedItems((prevItems: any) => {
              if (galleryItem) {
                return {
                  ...prevItems,
                  [toKey(section.orderNumber)]: {
                    ...prevItems[toKey(section.orderNumber)],
                    id: galleryItem.id,
                  },
                };
              }
              return prevItems;
            });
            return {
              ...section,
              id: galleryItem.id,
              orderNumber: galleryItem.orderNumber,
              base64Photos: galleryItem.base64Photos,
            };
          } else {
            return section;
          }
        });
      });
    }

    if (mySitePartitions.slider) {
      setMySiteSliderSection((prevSections) => {
        return prevSections.map((section) => {
          const index = mySitePartitions.slider?.findIndex(
            (item) => item.orderNumber === section.orderNumber
          );
          if (
            index !== undefined &&
            index !== -1 &&
            mySitePartitions.slider &&
            mySitePartitions.slider[index] !== undefined
          ) {
            const sliderItem = mySitePartitions.slider[index];
            setSelectedItems((prevItems: any) => {
              if (sliderItem !== undefined) {
                return {
                  ...prevItems,
                  [toKey(section.orderNumber)]: {
                    ...prevItems[toKey(section.orderNumber)],
                    id: sliderItem.id,
                  },
                };
              }
              return prevItems;
            });
            return {
              ...section,
              id: sliderItem.id,
              orderNumber: sliderItem.orderNumber,
              base64Photos: sliderItem.base64Photos,
            };
          } else {
            return section;
          }
        });
      });
    }
  }, [
    mySitePartitions.photo,
    mySitePartitions.text,
    mySitePartitions.phone,
    mySitePartitions.video,
    mySitePartitions.socialMedia,
    mySitePartitions.gallery,
    mySitePartitions.slider,
  ]);
};

export default usePartitionUpdaterEffect;
