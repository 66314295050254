import { FormControl, MenuItem, Select } from "@mui/material";
import { RS, US, BA } from "country-flag-icons/react/3x2";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useAppDispatch, useAppSelector } from "../../app/redux/hooks";
import { DisplayLanguage } from "../../models/enums/displayLanguage";
import {
  selectDisplayLanguage,
  setDisplayLanguage,
} from "../../slices/displayLanguageSlice";
import { useLanguageSwithcherStyles } from "./languageSwitcherStyles";

export default function LanguageSwitcher() {
  const dispatch = useAppDispatch();
  const currentLanguage = useAppSelector(selectDisplayLanguage);
  const { t } = useTranslation();

  const { classes } = useLanguageSwithcherStyles();

  const onChangeLanguage = (value: string | null) => {
    const newLanguage = value as DisplayLanguage;

    if (!newLanguage) {
      return;
    }

    i18n.changeLanguage(
      newLanguage === DisplayLanguage.English
        ? "en-US"
        : newLanguage === DisplayLanguage.SerbianLatin
        ? "rs"
        : "rsCyrl"
    );

    dispatch(setDisplayLanguage(newLanguage));
  };

  return (
    <FormControl
      sx={{
        flexDirection: "row",
        alignItems: "center",
        width: 160,
        display: "flex",
        justifyContent: "center",
        color: "white",
      }}
      fullWidth
    >
      <Select
        value={currentLanguage}
        onChange={(e) => onChangeLanguage(e.target.value)}
        className={classes.select}
        size="small"
        fullWidth
        sx={{
          overflow: "hidden",
          color: "white",
          ".MuiSelect-icon": {
            color: "white",
          },
        }}
      >
        <MenuItem value={DisplayLanguage.English} className={classes.menuItem}>
          <US className={classes.countryIcon} />
          {t("languageSwitcher.englishLagnuage")}
        </MenuItem>
        <MenuItem
          value={DisplayLanguage.SerbianLatin}
          className={classes.menuItem}
        >
          <BA className={classes.countryIcon} />
          {t("languageSwitcher.serbianLatinLanguage")}
        </MenuItem>
        <MenuItem
          value={DisplayLanguage.SerbianCyrillic}
          className={classes.menuItem}
        >
          <RS className={classes.countryIcon} />
          {t("languageSwitcher.serbianCyrlLanguage")}
        </MenuItem>
      </Select>
    </FormControl>
  );
}
