import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";
import { colors } from "../../../app/theme/colors";

export const useManageClientsAccountsModalStyles = makeStyles()(
  (theme: Theme) => ({
    dialog: {
      borderRadius: "15px",
    },
    closeButton: {
      position: "absolute",
      top: theme.spacing(1),
      right: theme.spacing(2),
    },
    dialogContent: {},
    gridContainer: {
      justifyContent: "center",
      alignItems: "center",
      padding: "5px",
      textAlign: "center",
    },
    logoImage: {
      width: "25%",
      height: "auto",
      margin: "auto",
      marginTop: "-20px",
    },
    customOutlinedInput: {
      label: "test",
      width: "100%",
      labelColor: colors.primary,
    },
    forgotPasswordText: {
      textAlign: "right",
      marginTop: "5px",
      color: "#635F5F",
      cursor: "pointer",
    },
    dialogActions: {
      paddingLeft: "25px",
      paddingRight: "25px",
      paddingBottom: "25px",
      textAlign: "center",
    },
    closeIcon: {
      color: colors.primary,
    },
    visibilityIcon: {
      cursor: "pointer",
      color: colors.primary,
    },

    title: {
      color: "#4D8CF5",
    },

    deleteIcon: {
      color: colors.red,
    },
    table: {
      minWidth: 650,
      border: "none",
      boxShadow: "none",
    },
    tableCell: {
      border: "none",
    },
    headTableCell: {
      fontWeight: "bold",
      border: "none",
    },
  })
);
