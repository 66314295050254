import {
  Grid,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import digitalMarketingSection from "../../images/digitalMarketingSection.png";
import { colors } from "../../app/theme/colors";
import { useDigitalMarketingSectionStyles } from "./digitalMarketingSectionStyles";
import { useTranslation } from "react-i18next";
import { useDigitalMarketingData } from "../../mockData/mockData";

const GradientBackground = styled(Grid)(({ theme }) => ({
  alignItems: "center",
  backgroundRepeat: "no-repeat",
  backgroundColor: colors.primary,
  backgroundPosition: "center",
  padding: "40px",
  minHeight: "70vh",
  [theme.breakpoints.down("md")]: {
    padding: "20px",
  },
}));

export default function DigitalMarketingSection() {
  const { t } = useTranslation();
  const { classes } = useDigitalMarketingSectionStyles();
  const theme = useTheme();
  const digitalMarketingData = useDigitalMarketingData();

  return (
    <GradientBackground container>
      <Grid item xs={12} md={6} container justifyContent="flex-start">
        <Typography variant="h4" className={classes.title}>
          {t("digitalMarketingPage.digitalMarketingTitle")}
        </Typography>
        {digitalMarketingData.map((data: any, index: number) => (
          <Typography variant="body1" className={classes.text} key={index}>
            {data.text}
          </Typography>
        ))}
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        container
        justifyContent="center"
        alignItems="center"
      >
        <img
          src={digitalMarketingSection}
          alt="about_us"
          className={classes.img}
        />
      </Grid>
    </GradientBackground>
  );
}
