import React, { useState } from "react";
import CookieConsent from "react-cookie-consent";
import { Grid, Typography } from "@mui/material";
import { CustomizeCookies } from "./customizeCookies";
import AppButton from "../appButton/appButton";
import { colors } from "../../app/theme/colors";
import { useTranslation } from "react-i18next";

const CookieBanner: React.FC = () => {
  const { t } = useTranslation();
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText={t("buttons.accept")}
        declineButtonText={t("buttons.reject")}
        cookieName="myWebsiteCookies"
        style={{
          background: "#fff",
          color: "#000",
          fontSize: "14px",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
          padding: "20px",
        }}
        buttonStyle={{
          color: "#fff",
          backgroundColor: "#4285F4",
          fontSize: "14px",
          padding: "10px 20px",
          borderRadius: "5px",
          margin: "5px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        }}
        declineButtonStyle={{
          color: "#000",
          backgroundColor: "#fff",
          fontSize: "14px",
          padding: "10px 20px",
          borderRadius: "5px",
          margin: "5px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          border: "1px solid #000",
        }}
        enableDeclineButton
      >
        <Grid item md={12}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            {t("cookieConsent.title")}
          </Typography>
        </Grid>
        <Grid item md={12} my={1}>
          <Typography>{t("cookieConsent.description")}</Typography>
        </Grid>
        <Grid item md={12}>
          <AppButton
            label={t("cookieConsent.showDetails")}
            color={colors.primary}
            hover={colors.primary}
            onClick={openModal}
          />
        </Grid>
      </CookieConsent>
      <CustomizeCookies isOpen={isModalOpen} onRequestClose={closeModal} />
    </>
  );
};

export default CookieBanner;
