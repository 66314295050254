import {
  Grid,
  IconButton,
  styled,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ReplyIcon from "@mui/icons-material/Reply";
import { useNavigate } from "react-router-dom";
import { routes } from "../../app/router/routes";
import { t } from "i18next";

const GradientBackground = styled(Grid)(({ theme }) => ({
  alignItems: "center",
  backgroundRepeat: "no-repeat",
  backgroundColor: "white",
  backgroundPosition: "center",
  padding: "40px",
  [theme.breakpoints.down("md")]: {
    padding: "20px",
  },
}));

const IframeContainer = styled("iframe")(({ theme }) => ({
  border: "1px solid lightgray",
  width: "100%",
  height: "800px",
  marginTop: theme.breakpoints.down("md") ? "0" : "0",
  [theme.breakpoints.down("md")]: {
    height: "500px",
  },
}));

export default function PdfViewSection() {
  const navigate = useNavigate();

  const handleButtonClick = (route: string) => () => {
    navigate(route);
  };

  return (
    <GradientBackground container>
      <Grid item xs={12}>
        <Tooltip title={t("editionsPage.backIcon")}>
               <IconButton onClick={handleButtonClick(routes.editions)}>
          <ReplyIcon fontSize="large" />
        </IconButton>
        </Tooltip>
   
      </Grid>
      <Grid item xs={12}>
        <IframeContainer
          allowFullScreen
          scrolling="no"
          className="fp-iframe"
          src="https://heyzine.com/flip-book/e4429d3a7f.html"
        />
      </Grid>
    </GradientBackground>
  );
}
