import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Addresses } from "../app/redux/poslovniImenikApi";
import { RootState } from "../app/redux/store";
import { StreetModel } from "../models/address/streetModel";
import { PlaceModel } from "../models/address/placeModel";

type AddressState = {
  cities: PlaceModel[] | null;
  streets: StreetModel[] | null;

  statuses: {};
};

const initialState: AddressState = {
  cities: null,
  streets: null,

  statuses: {},
};

export const selectAllPlaces = (state: RootState) => state.address.cities;
export const selectStreetsByPlaceId = (state: RootState) =>
  state.address.streets;

export const getAllPlaces = createAsyncThunk(
  "address/get_all_places",
  async () => {
    const response = await Addresses.GetAllPlaces();
    return response.data;
  }
);

export const getStreetsByPlaceId = createAsyncThunk(
  "address/get_streets_by_place_id",
  async (placeId: string) => {
    const response = await Addresses.GetStreetsByPlaceId(placeId);
    return response.data;
  }
);

const addressSlice = createSlice({
  name: "address",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllPlaces.fulfilled, (state, action) => {
        state.cities = action.payload;
      })
      .addCase(getStreetsByPlaceId.fulfilled, (state, action) => {
        state.streets = action.payload;
      });
  },
});

export default addressSlice.reducer;
