import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import { colors } from "../../app/theme/colors";
import { TextualAdvertisementModel } from "../../models/company/companyTextualAdvertisementModel";
import { usePromoteBusinessSectionStyles } from "./promoteBusinessSectionStlyes";
import logo from "../../images/logoT_black.png";
import { useNavigate } from "react-router-dom";
import { routes } from "../../app/router/routes";
import { useAppDispatch } from "../../app/redux/hooks";
import { resetCompany } from "../../slices/companySlice";
import { useTranslation } from "react-i18next";

interface Props {
  onClick?: any;
  style?: React.CSSProperties;
}

export default function PromoteBusinessSection({ onClick, style }: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { classes } = usePromoteBusinessSectionStyles();

  const INIT_PROMOTE_BUSINESS = [
    {
      id: "6095dea5-ebae-4b55-8753-96c230b3670a",
      premium: true,
      title: t("promoteBusinessSection.titlePremium"),
      description: t("promoteBusinessSection.description"),
      companyTitle: t("promoteBusinessSection.companyTitle"),
    },
    {
      id: "0dae5853-4203-4062-a34b-b6919b0e0ebc",
      premium: false,
      title: t("promoteBusinessSection.title"),
      description: t("promoteBusinessSection.description"),
      companyTitle: t("promoteBusinessSection.companyTitle"),
    },
    {
      id: "06bd2fdf-c2d3-4e74-8685-a97c0052c9b6",
      premium: false,
      title: t("promoteBusinessSection.title"),
      description: t("promoteBusinessSection.description"),
      companyTitle: t("promoteBusinessSection.companyTitle"),
    },
  ];

  const advertisement = INIT_PROMOTE_BUSINESS;

  const handleButtonClick = (route: string) => {
    navigate(route);
    dispatch(resetCompany());
  };

  return (
    <div onClick={() => handleButtonClick(routes.advertisement)}>
      <Grid container>
        {advertisement.map((ad, index) => (
          <Grid item xs={12} key={index}>
            <Card
              sx={{
                marginBottom: "10px",
                backgroundColor:
                  ad?.premium === true
                    ? colors.gold
                    : index % 2 === 0
                    ? "#528FCC"
                    : "white",
                position: "relative",
                cursor: "pointer",
                ...style,
              }}
              onClick={onClick}
            >
              <CardMedia
                component="img"
                src={logo}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  maxWidth: "35%",
                  opacity: 0.1,
                  marginLeft: "20%",
                }}
              />
              {ad?.premium === true && (
                <Box className={classes.iconWrapper}>
                  <span role="img" aria-label="star">
                    <StarIcon fontSize="large" className={classes.starIcon} />
                  </span>
                </Box>
              )}
              <Grid container mt={4}>
                <Grid item xs={12} className={classes.titleWrapper}>
                  <Typography
                    variant="h5"
                    sx={{
                      color:
                        ad?.premium === true
                          ? "#404040"
                          : index % 2 === 0
                          ? "white"
                          : "#528FCC",
                      fontWeight: "bold",
                    }}
                  >
                    {ad?.title}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <CardContent>
                    <Typography
                      variant="body1"
                      sx={{
                        color:
                          ad?.premium === true
                            ? "#404040"
                            : index % 2 === 0
                            ? "white"
                            : "#528FCC",
                      }}
                    >
                      {ad?.description}
                    </Typography>
                  </CardContent>
                </Grid>
                <Grid item xs={12} className={classes.companyTitleWrapper}>
                  <CardContent>
                    <Typography
                      variant="body1"
                      className={classes.companyTitleLabel}
                      sx={{
                        color:
                          ad?.premium === true
                            ? "#404040"
                            : index % 2 === 0
                            ? "white"
                            : "#528FCC",
                      }}
                    >
                      {ad?.companyTitle}
                    </Typography>
                  </CardContent>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
