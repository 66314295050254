import {
  Fade,
  Grid,
  Grow,
  Typography,
  Zoom,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import logo from "../../images/logo_ba.png";
import logoShort from "../../images/redTLogo.png";
import PhoneIcon from "@mui/icons-material/Phone";
import logoFull from "../../images/logoFull.png";
import PinDropIcon from "@mui/icons-material/PinDrop";
import EmailIcon from "@mui/icons-material/Email";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import { useFooterStyles } from "./footerStyles";
import { colors } from "../../app/theme/colors";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../app/router/routes";
import { useAppDispatch } from "../../app/redux/hooks";
import { resetCompany } from "../../slices/companySlice";

const Footer = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { classes } = useFooterStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const [showServicesSubmenu, setShowServicesSubmenu] = useState(false);

  const handlePhoneClick = (phoneNumber: string) => {
    window.location.href = `tel:${phoneNumber}`;
  };

  const handleButtonClick = (route: string) => {
    navigate(route);
    dispatch(resetCompany());
  };

  return (
    <>
      <Grid
        container
        sx={{
          backgroundColor: "#404040",
          py: isMobile ? 2 : 3,
          px: isMobile ? 1 : 5,
        }}
      >
        <Grid item xs={12} sm={4} sx={{ px: isMobile ? 1 : 6 }}>
          <Grid item md={12} sx={{ display: "flex", alignItems: "center" }}>
            <img
              src={logoFull}
              className={classes.logo}
              onClick={() => handleButtonClick(routes.home)}
            />
            <Typography variant="h5" sx={{ marginLeft: "5px", color: "white" }}>
              {t("header.telekomBl")}
            </Typography>
          </Grid>
          <Grid item md={12}>
            <Typography variant="body1" className={classes.description}>
              {t("footer.footerDescription")}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          sx={{ px: isMobile ? 1 : 6, py: isMobile ? 2 : null }}
        >
          <Grid item md={12} xs={12}>
            <Typography variant="body1" className={classes.sectionTitle}>
              {t("footer.footerFastLinksTitle")}
            </Typography>
          </Grid>
          <Grid container mt={3} spacing={1}>
            <Grid item md={12} xs={12}>
              <Typography
                variant="body1"
                className={classes.fastLinksTitle}
                onClick={() => handleButtonClick(routes.home)}
              >
                {t("footer.footerFastLinkHome")}
              </Typography>
            </Grid>

            <Grid item md={12} xs={12}>
              <Typography
                variant="body1"
                className={classes.fastLinksTitle}
                onClick={() => setShowServicesSubmenu(!showServicesSubmenu)}
                sx={{ cursor: "pointer" }}
              >
                {t("footer.footerFastLinkServices")}
              </Typography>
            </Grid>
            {showServicesSubmenu && (
              <Grid container spacing={1} mt={0.5} ml={2}>
                <Fade in={showServicesSubmenu} timeout={500}>
                  <Grid item md={12} xs={12}>
                    <Typography
                      variant="body1"
                      className={classes.fastLinksTitle}
                      onClick={() => handleButtonClick(routes.updateData)}
                    >
                      {t("footer.footerFastLinkUpdatingData")}
                    </Typography>
                  </Grid>
                </Fade>
                <Fade in={showServicesSubmenu} timeout={700}>
                  <Grid item md={12} xs={12}>
                    <Typography
                      variant="body1"
                      className={classes.fastLinksTitle}
                      onClick={() => handleButtonClick(routes.advertisement)}
                    >
                      {t("footer.footerFastLinkAdvertisement")}
                    </Typography>
                  </Grid>
                </Fade>
                <Fade in={showServicesSubmenu} timeout={900}>
                  <Grid item md={12} xs={12}>
                    <Typography
                      variant="body1"
                      className={classes.fastLinksTitle}
                      onClick={() => handleButtonClick(routes.digitalMarketing)}
                    >
                      {t("footer.footerFastLinkDigitalMarketing")}
                    </Typography>
                  </Grid>
                </Fade>
              </Grid>
            )}
            <Grid item md={12} xs={12}>
              <Typography
                variant="body1"
                className={classes.fastLinksTitle}
                onClick={() => handleButtonClick(routes.editions)}
              >
                {t("footer.footerFastLinkEditions")}
              </Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <Typography
                variant="body1"
                className={classes.fastLinksTitle}
                onClick={() => handleButtonClick(routes.about)}
              >
                {t("footer.footerFastLinkAbout")}
              </Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <Typography
                variant="body1"
                className={classes.fastLinksTitle}
                onClick={() => handleButtonClick(routes.contact)}
              >
                {t("footer.footerFastLinkContact")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          sx={{ px: isMobile ? 1 : 6, py: isMobile ? 2 : null }}
        >
          <Grid item md={12}>
            <Typography variant="body1" className={classes.sectionTitle}>
              {t("contactUsPage.contactTitle")}
            </Typography>
          </Grid>
          <Grid
            container
            item
            md={12}
            xs={12}
            mt={2}
            alignItems="center"
            className={classes.iconWrapper}
          >
            <PinDropIcon className={classes.icon} />
            <Typography
              variant="body1"
              className={classes.label}
              component={"a"}
              href={
                "https://www.google.com/maps/place/Rade+Vranje%C5%A1evi%C4%87+12,+Banja+Luka+78000,+Bosnia+and+Herzegovina/data=!4m2!3m1!1s0x475e036cc4391a63:0x45b15858a5c27ddf?sa=X&ved=2ahUKEwiz0aHY_bX_AhVI9LsIHQJ2ATsQ8gF6BAgSEAI"
              }
              target="_blank"
            >
              Bosna i Hercegovina <br /> Rade Vranješević 12 <br />
              Banja Luka 78000
            </Typography>
          </Grid>
          <Grid
            container
            item
            md={12}
            xs={12}
            alignItems="center"
            className={classes.iconWrapper}
            my={3}
          >
            <EmailIcon className={classes.icon} />
            <Typography variant="body1" className={classes.label}>
              <a
                href="mailto:info@poslovniimenik.net?subject=Kontakt"
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  cursor: "pointer",
                }}
              >
                info@poslovniimenik.net
              </a>
              <br />
              <a
                href="mailto:telekombluka@gmail.com?subject=Kontakt"
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  cursor: "pointer",
                }}
              >
                telekombluka@gmail.com
              </a>
            </Typography>
          </Grid>
          <Grid
            container
            item
            md={12}
            xs={12}
            alignItems="center"
            className={classes.iconWrapper}
          >
            <PhoneIcon className={classes.icon} />
            <Typography variant="body1" className={classes.label}>
              <span
                onClick={() => handlePhoneClick("+38751255310")}
                style={{ cursor: "pointer" }}
              >
                +387 51 255 310
              </span>
              <br />
              <span
                onClick={() => handlePhoneClick("+38751255311")}
                style={{ cursor: "pointer" }}
              >
                +387 51 255 311
              </span>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{ py: 1, px: 5 }}
        className={classes.copyrightSection}
      >
        <Grid item md={6} xs={6} sx={{ px: isMobile ? null : 6 }}>
          <Typography
            variant="body1"
            className={classes.copyRightSectionContent}
          >
            © Copyright 2023. poslovniimenik.net
          </Typography>
        </Grid>
        <Grid
          item
          md={6}
          xs={6}
          sx={{ px: 1 }}
          container
          justifyContent={"flex-end"}
        >
          <InstagramIcon className={classes.instagramIcon} />
          <FacebookOutlinedIcon className={classes.facebookIcon} />
        </Grid>
      </Grid>
    </>
  );
};

export default Footer;
