import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  IconButton,
  Autocomplete,
  TextField,
  Button,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomOutlinedInput from "../../components/outlinedInput/customOutlinedInput";
import { colors } from "../../app/theme/colors";
import PublicIcon from "@mui/icons-material/Public";
import {
  FaTiktok,
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaLinkedin,
} from "react-icons/fa";
import { TbBrandBooking } from "react-icons/tb";
import MySiteSocialMediaModel from "../../models/mySite/mySiteSocialMediaModel";
import { findSocialMediaType } from "./utilis";
import { useAppDispatch } from "../../app/redux/hooks";
import { saveMySiteSocialMedia } from "../../slices/mySiteSlice";
import { EmptyGuid } from "../../models/init_data/initData";
import AppButton from "../../components/appButton/appButton";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useMySectionStyles } from "./mySiteSectionStyles";
import SocialMediaModel from "../../models/mySite/socialMediaModel";
import { ApiStatus } from "../../models/app/apiStatus";

interface Props {
  status: string;
  setMySiteSocialMediaSection: React.Dispatch<
    React.SetStateAction<MySiteSocialMediaModel[]>
  >;
  mySiteSocialMediaSection: MySiteSocialMediaModel[];
  selectedItems: any;
  cubeType: string;
  selectMySitePhone: MySiteSocialMediaModel[] | null;
  handleRemoveSocialMedia: any;
  clickedCube: string;
  setNewSocialMediaSections: React.Dispatch<
    React.SetStateAction<{ icon: string | null; name: string; link: string }[]>
  >;
  newSocialMediaSections: { icon: string | null; name: string; link: string }[];
}

interface SocialMedia {
  icon: string | JSX.Element;
  name: string;
  link: string;
}

const socialMediaOptions: SocialMedia[] = [
  {
    name: "Facebook",
    icon: <FaFacebook size={25} style={{ color: "rgba(24, 119, 242, 1)" }} />,
    link: "",
  },
  {
    name: "Instagram",
    icon: <FaInstagram size={25} style={{ color: "rgba(193, 53, 132, 1)" }} />,
    link: "",
  },
  {
    name: "YouTube",
    icon: <FaYoutube size={25} style={{ color: "rgba(255, 0, 0, 1)" }} />,
    link: "",
  },
  {
    name: "LinkedIn",
    icon: <FaLinkedin size={25} style={{ color: "rgba(0, 119, 181, 1)" }} />,
    link: "",
  },
  {
    name: "TikTok",
    icon: <FaTiktok size={25} style={{ color: "rgba(0, 0, 0, 1)" }} />,
    link: "",
  },
  {
    name: "Booking.com",
    icon: <TbBrandBooking size={25} style={{ color: "rgba(0, 53, 128, 1)" }} />,
    link: "",
  },
];

const MySiteSocialMediaSection: React.FC<Props> = ({
  mySiteSocialMediaSection,
  setMySiteSocialMediaSection,
  selectedItems,
  cubeType,
  handleRemoveSocialMedia,
  selectMySitePhone,
  clickedCube,
  newSocialMediaSections,
  setNewSocialMediaSections,
  status,
}) => {
  const dispatch = useAppDispatch();
  const { classes } = useMySectionStyles();
  const { t } = useTranslation();
  const [selectedSocialMedia, setSelectedSocialMedia] =
    useState<SocialMedia | null>(null);
  const [buttonText, setButtonText] = useState<string>("");
  const [buttonIcon, setButtonIcon] = useState<any>(null);
  const [addSectionCount, setAddSectionCount] = useState<number>(0);

  useEffect(() => {
    setSelectedSocialMedia(null);
  }, [selectedItems]);

  useEffect(() => {
    if (mySiteSocialMediaSection) {
      const sectionToUpdate = mySiteSocialMediaSection.find(
        (section) =>
          section.orderNumber === selectedItems[cubeType]?.orderNumber
      );
      if (sectionToUpdate) {
        const hasId = sectionToUpdate.id !== EmptyGuid;
        if (hasId) {
          setButtonText(t("buttons.edit") as string);
          setButtonIcon(<EditIcon />);
        } else {
          setButtonText(t("buttons.save") as string);
          setButtonIcon(<CheckCircleOutlineIcon />);
        }
      }
    }
  }, [mySiteSocialMediaSection, cubeType, clickedCube, selectMySitePhone, t]);
  const handleAddSection = () => {
    setAddSectionCount((prev) => prev + 1);

    setNewSocialMediaSections((prev) => [
      ...prev,
      { icon: null, name: "", link: "" },
    ]);

    const newSocialMedia: SocialMediaModel = {
      name: "",
      link: "",
      base64Photo: "",
      socialMediaType: 6,
    };

    const sectionIndex = mySiteSocialMediaSection.findIndex(
      (section) => section.orderNumber === selectedItems[cubeType]?.orderNumber
    );

    if (sectionIndex !== -1) {
      setMySiteSocialMediaSection((prevSections) =>
        prevSections.map((section, index) =>
          index === sectionIndex
            ? {
                ...section,
                socialMedias: [...section.socialMedias, newSocialMedia],
              }
            : section
        )
      );
    }
  };

  const handleNewSocialMediaSection = (
    name: string,
    link: string,
    index: number
  ) => {
    setNewSocialMediaSections((prevSections) =>
      prevSections.map((section, i) =>
        i === index ? { ...section, name, link } : section
      )
    );

    setMySiteSocialMediaSection((prevSections) =>
      prevSections.map((section) =>
        section.orderNumber === selectedItems[cubeType]?.orderNumber
          ? {
              ...section,
              socialMedias: section.socialMedias.map((socialMedia, i) =>
                i === index ? { ...socialMedia, name, link } : socialMedia
              ),
            }
          : section
      )
    );
  };

  const handleAddSocialMedia = (selectedOption: SocialMedia) => {
    const isCustomSocialMedia = selectedOption === null;
    const socialMediaType = isCustomSocialMedia
      ? 6
      : findSocialMediaType(selectedOption.name);

    if (socialMediaType !== null) {
      const newSocialMedia = {
        name: isCustomSocialMedia
          ? selectedSocialMedia?.name || ""
          : selectedOption.name,
        link: "",
        socialMediaType,
      };

      setMySiteSocialMediaSection((prevSections) =>
        prevSections.map((section) =>
          section.orderNumber === selectedItems[cubeType]?.orderNumber
            ? {
                ...section,
                socialMedias: [...section.socialMedias, newSocialMedia],
              }
            : section
        )
      );
    }
  };

  const handleDeleteSocialMedia = (sectionIndex: number, index: number) => {
    setMySiteSocialMediaSection((prevSections) =>
      prevSections.map((section, sIndex) =>
        sIndex === sectionIndex
          ? {
              ...section,
              socialMedias: section.socialMedias.filter((_, i) => i !== index),
            }
          : section
      )
    );
  };

  const handleInputChange = (
    sectionIndex: number,
    index: number,
    value: string
  ) => {
    setMySiteSocialMediaSection((prevSections) =>
      prevSections.map((section, sIndex) =>
        sIndex === sectionIndex
          ? {
              ...section,
              socialMedias: section.socialMedias.map((social, i) =>
                i === index ? { ...social, link: value } : social
              ),
            }
          : section
      )
    );
  };

  const handleSave = () => {
    dispatch(saveMySiteSocialMedia(mySiteSocialMediaSection));
  };

  const handleIconUpload = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const files = event.target.files;
    if (files && files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const iconUrl = e.target?.result as string;
        setNewSocialMediaSections((prevSections) =>
          prevSections.map((section, i) =>
            i === index ? { ...section, icon: iconUrl } : section
          )
        );
        setMySiteSocialMediaSection((prevSections) =>
          prevSections.map((section) =>
            section.orderNumber === selectedItems[cubeType]?.orderNumber
              ? {
                  ...section,
                  socialMedias: section.socialMedias.map((socialMedia, i) =>
                    i === index
                      ? { ...socialMedia, base64Photo: iconUrl }
                      : socialMedia
                  ),
                }
              : section
          )
        );
      };
      reader.readAsDataURL(files[0]);
    }
  };

  return (
    <Grid container spacing={2} mt={1} padding="10px">
      <Grid item md={12} display="flex" alignItems="center">
        <PublicIcon sx={{ color: "rgba(75, 138, 244, 0.8)" }} />
        <Typography variant="body2" sx={{ marginLeft: "5px" }}>
          {t("mySite.mySiteSocialMediaTitle")}
        </Typography>
      </Grid>
      <Grid item lg={6} xs={12}>
        <Autocomplete
          size="small"
          options={socialMediaOptions}
          getOptionLabel={(option) => option.name}
          onChange={(event, value) => {
            if (value !== null) {
              setSelectedSocialMedia(value);
              handleAddSocialMedia(value);
            }
          }}
          value={selectedSocialMedia}
          renderOption={(props, option) => (
            <Box component="li" {...props} display="flex" alignItems="center">
              <Box sx={{ mr: 1 }}>
                {typeof option.icon === "string" ? (
                  <img
                    src={option.icon}
                    alt={option.name}
                    style={{ width: 25, height: 25 }}
                  />
                ) : (
                  option.icon
                )}
              </Box>
              {option.name}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("mySite.mySiteSocialMediaSelect")}
              InputProps={{
                ...params.InputProps,
              }}
            />
          )}
        />
      </Grid>
      {mySiteSocialMediaSection
        .filter(
          (section) =>
            section.orderNumber === selectedItems[cubeType]?.orderNumber
        )
        .flatMap((section) =>
          section.socialMedias.map((socialMedia, index) => (
            <Grid
              key={index}
              item
              md={12}
              display="flex"
              alignItems="center"
              mt={2}
            >
              {socialMedia.socialMediaType === 6 ? (
                <>
                  <Grid item mr={1}>
                    {socialMedia.base64Photo ? (
                      <img
                        src={socialMedia.base64Photo}
                        alt="Uploaded Icon"
                        style={{ width: 50, height: 50 }}
                      />
                    ) : (
                      <Button
                        variant="outlined"
                        component="label"
                        startIcon={<CloudUploadIcon />}
                        style={{ whiteSpace: "nowrap" }}
                      >
                        <Typography variant="body2">
                          {" "}
                          {t("mySite.mySiteSocialMediaUploadIcon")}
                        </Typography>
                        <input
                          type="file"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={(e) => handleIconUpload(e, index)}
                        />
                      </Button>
                    )}
                  </Grid>
                  <Grid item mr={1}>
                    <CustomOutlinedInput
                      width="100%"
                      labelColor={colors.primary}
                      label={t("mySite.mySiteSocialMediaName")}
                      value={socialMedia.name}
                      onChange={(e) =>
                        handleNewSocialMediaSection(
                          e.target.value,
                          socialMedia.link,
                          index
                        )
                      }
                    />
                  </Grid>
                </>
              ) : (
                <Box mr={1}>
                  {
                    socialMediaOptions.find(
                      (item) => item.name === socialMedia.name
                    )?.icon
                  }
                </Box>
              )}
              <CustomOutlinedInput
                width="100%"
                labelColor={colors.primary}
                label={
                  socialMedia.socialMediaType === 6
                    ? `${t("mySite.mySiteSocialMediaLink")}`
                    : `${t("mySite.mySiteSocialMediaLink")} ${socialMedia.name}`
                }
                value={socialMedia.link}
                onChange={(e) =>
                  handleInputChange(
                    mySiteSocialMediaSection.indexOf(section),
                    index,
                    e.target.value
                  )
                }
              />
              <Tooltip title={t("mySite.mySiteRemoveSocialMedia")}>
              <IconButton
                onClick={() =>
                  handleDeleteSocialMedia(
                    mySiteSocialMediaSection.indexOf(section),
                    index
                  )
                }
              >
                <DeleteIcon className={classes.deleteIcon} />
              </IconButton>
              </Tooltip>
            
            </Grid>
          ))
        )}
      <Grid item md={12}>
        <Tooltip title={t("mySite.mySiteTooltip")}>
          <IconButton onClick={handleAddSection}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid
        container
        justifyContent="flex-end"
        alignItems="center"
        spacing={1}
        mt={5}
      >
        <Grid item>
          <Tooltip title={"mySite.mySiteRemoveSocialMediaTooltip"}>
            <AppButton
              startIcon={<DeleteIcon />}
              label={t("buttons.delete")}
              color={colors.red}
              hover={colors.red}
              onClick={handleRemoveSocialMedia}
            />
          </Tooltip>
        </Grid>
        <Grid item>
          <AppButton
            startIcon={
              status === ApiStatus.Pending ? (
                <CircularProgress size={15} sx={{ color: colors.primary }} />
              ) : (
                buttonIcon
              )
            }
            label={buttonText}
            color={colors.green}
            hover={colors.green}
            onClick={handleSave}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MySiteSocialMediaSection;
