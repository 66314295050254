import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";
import { colors } from "../../app/theme/colors";

export const useAdvertisementRequestPageStyles = makeStyles()(
  (theme: Theme) => ({
    container: {
      width: "100%",
      overflowX: "hidden",
    },
    header: {
      width: "100%",
    },
    footerContainer: {
      bottom: 0,
      left: 0,
      width: "100%",
      backgroundColor: theme.palette.background.paper,
    },
  })
);
