import React, { useEffect, useRef, useState } from "react";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { colors } from "../../../app/theme/colors";
import AppButton from "../../../components/appButton/appButton";
import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { useLoginModalStyles } from "./loginModalStyles";
import logo from "../../../images/logoFull.png";
import CustomOutlinedInput from "../../../components/outlinedInput/customOutlinedInput";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ForgotPasswordModal from "../forgotPasswordModal/forgotPasswordModal";
import { LoginRequest } from "../../../models/auth/loginRequest";
import {
  INIT_FORGOT_PASSWORD,
  INIT_LOGIN_REQUEST,
  INIT_SET_FORGOT_PASSWORD_MODEL,
  INIT_SET_PASSWORD,
} from "../../../models/init_data/initData";
import {
  forgotPasswordClientAccount,
  login as loginSlice,
  resetErrorMessage,
  resetForgotPasswordStatus,
  selectCurrentAccount,
  selectErrorMessage,
  selectForgotPasswordStatus,
  selectLoginResponse,
  selectLoginStatus,
} from "../../../slices/authSlice";
import SetPasswordModal from "../setPasswordModal/setPasswordModal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { routes } from "../../../app/router/routes";
import ForgotPasswordRequest from "../../../models/auth/forgotPasswordRequest";
import SetPasswordModel from "../../../models/auth/setPasswordModel";
import SetForgotPasswordModel from "../../../models/auth/setForgotPasswordModel";

interface Props {
  open: any;
  handleClose: any;
  closeLoginModal: any;
  setIsOpenLogin: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function LoginModal({
  handleClose,
  open,
  closeLoginModal,
  setIsOpenLogin,
}: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { classes } = useLoginModalStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const loginResponse = useAppSelector(selectLoginResponse);
  const currentAccount = useAppSelector(selectCurrentAccount);
  const errorMessage = useAppSelector(selectErrorMessage);
  const loginStatus = useAppSelector(selectLoginStatus);
  const forgotPasswordStatus = useAppSelector(selectForgotPasswordStatus);

  const [isPasswordVisible, setIsPasswordVisible] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [isOpenForgot, setIsOpenForgot] = useState<boolean>(false);

  const [login, setLogin] = useState<LoginRequest>(INIT_LOGIN_REQUEST);
  const [forgotPassword, setForgotPassword] =
    useState<ForgotPasswordRequest>(INIT_FORGOT_PASSWORD);
  const [setForgotPasswordModal, setSetForgotPasswordModal] =
    useState<SetPasswordModel>({
      ...INIT_SET_PASSWORD,
      email: "",
      newPassword: "",
      confirmNewPassword: "",
      code: "",
    });
  const [forgotPasswordAccount, setForgotPasswordAccount] =
    useState<SetForgotPasswordModel>(INIT_SET_FORGOT_PASSWORD_MODEL);

  const [isOpenForgotSetPassword, setIsOpenForgotSetPassword] =
    useState<boolean>(false);
  const [isOpenSetPasswordModal, setIsOpenSetPasswordModal] =
    useState<boolean>(false);
  const [notificationShown, setNotificationShown] = useState<boolean>(true);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);
    setLogin((prevState) => ({
      ...prevState,
      email: value,
    }));
    setEmailError(false);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPassword(e.target.value);
    setLogin((prevState) => ({
      ...prevState,
      password: value,
    }));
    setPasswordError(false);
  };

  // const handleForgotPasswordChange = (
  //   e: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   const value = e.target.value;
  //   setForgotPassword((prevState: ForgotPasswordRequest) => ({
  //     ...prevState,
  //     email: value,
  //   }));
  //   setForgotPasswordAccount((prevState: SetForgotPasswordModel) => ({
  //     ...prevState,
  //     email: value,
  //   }));
  // };

  const handleSaveForgotPassword = () => {
    dispatch(forgotPasswordClientAccount(forgotPassword));
  };

  const handleDashboardPage = (route: string) => {
    navigate(route);
  };

  const handleLogin = () => {
    if (email === "" || password === "") {
      setEmailError(email === "");
      setPasswordError(password === "");
      return;
    }

    const loginRequest: LoginRequest = {
      email: email,
      password: password,
    };
    dispatch(loginSlice(loginRequest));
  };

  const handleDialogClose = () => {
    setEmailError(false);
    setPasswordError(false);

    handleClose();
  };

  const resetLoginState = () => {
    setLogin(INIT_LOGIN_REQUEST);
    setPassword("");
    setEmail("");
  };

  useEffect(() => {
    if (loginResponse?.isFirstLogin) {
      setIsOpenSetPasswordModal(true);
    } else {
      setIsOpenSetPasswordModal(false);
    }
  }, [loginResponse]);

  useEffect(() => {
    if (loginStatus === "Fulfilled" && loginResponse?.isFirstLogin !== true) {
      handleDashboardPage(routes.dashboard);
      dispatch(resetErrorMessage());
      handleClose();
      resetLoginState();
    } else if (loginStatus === "Rejected" && !notificationShown) {
      setNotificationShown(true);
    }
  }, [loginStatus]);

  useEffect(() => {
    if (forgotPasswordStatus === "Fulfilled") {
      setIsOpenForgotSetPassword(true);
    }
  }, [forgotPasswordStatus]);

  useEffect(() => {
    dispatch(resetForgotPasswordStatus());
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      fullWidth
      maxWidth="xs"
      id="fullDetailsModal_dialog"
      PaperProps={{ sx: { borderRadius: "15px", overflow: "hidden" } }}
    >
      <IconButton
        edge="end"
        color="inherit"
        onClick={handleDialogClose}
        aria-label="close"
        sx={{
          position: "absolute",
          top: theme.spacing(1),
          right: theme.spacing(2),
        }}
      >
        <CloseIcon sx={{ color: "white" }} />
      </IconButton>
      <DialogContent className={classes.dialogContent}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          padding={"5px"}
          sx={{ textAlign: "center" }}
        >
          <Grid item md={12} xs={12}>
            <img src={logo} alt="Logo" className={classes.logoImage} />
          </Grid>
          <Grid item md={12} xs={12} mt={5}>
            <CustomOutlinedInput
              id="loginModal_input_email"
              type="email"
              label={t("loginModal.loginEmail")}
              width="100%"
              labelColor={colors.primary}
              onChange={handleEmailChange}
              value={email}
              error={emailError}
              errorMessage={t("loginModal.loginErrorEmail")}
            />
          </Grid>
          <Grid item md={12} xs={12} mt={3}>
            <CustomOutlinedInput
              id="loginModal_input_password"
              label={t("loginModal.loginPassword")}
              type={isPasswordVisible ? "password" : "text"}
              width="100%"
              labelColor={colors.primary}
              onChange={handlePasswordChange}
              value={password}
              error={passwordError}
              endAdornment={
                <IconButton
                  onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                  edge="end"
                  color="inherit"
                  aria-label={
                    isPasswordVisible ? "Hide Password" : "Show Password"
                  }
                >
                  {isPasswordVisible ? (
                    <VisibilityOffIcon className={classes.visibilityIcon} />
                  ) : (
                    <VisibilityIcon className={classes.visibilityIcon} />
                  )}
                </IconButton>
              }
              errorMessage={t("loginModal.loginErrorPassword")}
            />
            <Typography
              id="loginModal_label_forgotPassword"
              variant="body1"
              className={classes.forgotPasswordText}
              onClick={() => setIsOpenForgot(true)}
            >
              {t("loginModal.loginForgotPassword")}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <AppButton
          id="loginModal_button_close"
          onClick={handleLogin}
          color={colors.primary}
          hover={colors.primary}
          label={t("buttons.login")}
          width="100%"
          disabled={loginStatus === "Pending" ? true : false}
          startIcon={
            loginStatus === "Pending" ? (
              <CircularProgress size={"16px"} sx={{ color: colors.primary }} />
            ) : null
          }
        />
      </DialogActions>
      <ForgotPasswordModal
        handleClose={() => setIsOpenForgot(false)}
        open={isOpenForgot}
        // handleForgotPasswordChange={handleForgotPasswordChange}
        forgotPassword={forgotPassword}
        // handleSaveForgotPassword={handleSaveForgotPassword}
        isOpenForgotSetPassword={isOpenForgotSetPassword}
        setIsOpenForgotSetPassword={setIsOpenForgotSetPassword}
        forgotPasswordStatus={forgotPasswordStatus}
        setForgotPassword={setForgotPasswordModal}
        setSetForgotPassword={setSetForgotPasswordModal}
        forgotPasswordAccount={forgotPasswordAccount}
        setForgotPasswordAccount={setForgotPasswordAccount}
        setIsOpenForgotPasswordModal={setIsOpenForgot}
        setForgotPasswordModal={setForgotPassword}
      />
      <SetPasswordModal
        handleClose={() => setIsOpenSetPasswordModal(false)}
        open={isOpenSetPasswordModal}
        currentAccount={currentAccount}
        login={login}
        setIsOpenSetPasswordModal={setIsOpenSetPasswordModal}
        closeLoginModal={closeLoginModal}
      />
    </Dialog>
  );
}
