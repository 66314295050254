import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";

export const useDigitalMarketingSectionStyles = makeStyles()(
  (theme: Theme) => ({
    text: {
      color: "white",
      marginBottom: theme.spacing(3),
    },
    img: {
      width: "60%",
      marginTop: theme.spacing(2),
      [theme.breakpoints.down("md")]: {
        width: "80%",
      },
    },
    title: {
      color: "white",
      fontWeight: "bold",
      marginBottom: "5px",
    },
    description: {
      color: "white",
      fontWeight: "normal",
    },
    imageSectionWrapper: {
      textAlign: "center",
    },
  })
);
