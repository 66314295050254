import { colors } from "../../../app/theme/colors";

export const frequentlyAskedQuestions = [
  {
    content: "faqPage.title",
    isBold: true,
    color: colors.primary,
    textAlign: false,
    isTitle: true,
    isSubtitle: false,
  },
  {
    content: "faqPage.subtitle",
    isBold: false,
    color: colors.primary,
    textAlign: false,
    isTitle: false,
    isSubtitle: true,
  },
  {
    content: "faqPage.content",
    isBold: false,
    color: "default",
    textAlign: true,
    isTitle: false,
    isSubtitle: false,
  },
  {
    content: "faqPage.faq",
    isBold: true,
    color: colors.primary,
    textAlign: false,
    isTitle: false,
    isSubtitle: false,
  },
];

export const accordionData = [
  {
    title: "faqPage.firstAccordionTitle",
    content: "faqPage.firstAccordionContent",
    color: colors.primary,
    isDefaultExpanded: true,
  },
  {
    title: "faqPage.secondAccordionTitle",
    content: "faqPage.secondAccordionContent",
    color: colors.primary,
    isDefaultExpanded: false,
  },
  {
    title: "faqPage.thirdAccordionTitle",
    content: "faqPage.thirdAccordionContent",
    color: colors.primary,
    isDefaultExpanded: false,
  },
];
