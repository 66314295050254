import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  typography: {
    fontFamily: "Open Sans, sans-serif",
    fontSize: 16,
    body1: {
      fontSize: "0.8rem",
    },
    body2: {
      fontSize: "0.875rem",
    },
    h4: {
      fontSize: "1.5rem",
    },
    h5: {
      fontSize: "1rem",
    },
  },
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: "20px",
        },
      },
    },
  },
});
