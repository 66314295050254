import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Rating,
  Typography,
} from "@mui/material";
import { colors } from "../../../app/theme/colors";
import AppButton from "../../../components/appButton/appButton";
import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import { useTranslation } from "react-i18next";
import starsImg from "../../../images/stars.png";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import { motion } from "framer-motion";
import { useOverallRatingModalStyles } from "./overallRatingModalStyles";
import { CompanyModel } from "../../../models/company/companyModel";
import { INIT_OVERALL_RATING } from "../../../models/init_data/initData";
import OverallRatingRequest from "../../../models/overallRating/overallRatingRequest";
import {
  getCompanyRating,
  saveOverallRating,
  selectOverallRatingById,
  selectOverallRatingStatus,
} from "../../../slices/overallRatingSlice";
import { getCompanyFullDetailsById } from "../../../slices/companySlice";
import { ApiStatus } from "../../../models/app/apiStatus";

interface Props {
  open: any;
  handleClose: any;
  company: CompanyModel | null;
  setSelectedCompany: any;
  selectedCompany: CompanyModel | null;
}

export default function OverallRatingModal({
  handleClose,
  open,
  company,
  setSelectedCompany,
  selectedCompany,
}: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { classes } = useOverallRatingModalStyles();
  const selectOverallRating = useAppSelector(selectOverallRatingById);
  const overallRatingStatus = useAppSelector(selectOverallRatingStatus);

  const [value, setValue] = useState<number>(0);
  const [overallRating, setOverallRating] =
    useState<OverallRatingRequest>(INIT_OVERALL_RATING);

  useEffect(() => {
    if (open && company) {
      setOverallRating((prevRating: OverallRatingRequest) => ({
        ...prevRating,
        companyId: company.generalData.id,
      }));
      dispatch(getCompanyRating(company.generalData.id));
      setSelectedCompany(company);
      dispatch(getCompanyFullDetailsById(company.generalData.id));
    }
  }, [open, company]);

  const handleChange = (event: any, newValue: number | null) => {
    if (newValue !== null) {
      setValue(newValue);
      setOverallRating((prevRating: OverallRatingRequest) => ({
        ...prevRating,
        rating: newValue,
      }));
    }
  };
  const generateGuid = () => {
    const chunk = () => Math.random().toString(16).substr(2, 4);

    return (
      chunk() +
      chunk() +
      "-" +
      chunk() +
      "-" +
      chunk() +
      "-" +
      chunk() +
      "-" +
      chunk() +
      chunk() +
      chunk()
    ).toLowerCase();
  };

  const guid = generateGuid();

  const handleSaveOverallRating = () => {
    const temporaryId = localStorage.getItem("temporaryId");

    if (temporaryId) {
      localStorage.removeItem("temporaryId");
      dispatch(saveOverallRating(overallRating));
    } else {
      const newTemporaryId = guid;
      localStorage.setItem("temporaryId", newTemporaryId);

      dispatch(saveOverallRating(overallRating));
    }
  };

  const handleDialogClose = () => {
    handleClose();
  };

  useEffect(() => {
    if (selectOverallRating) {
      const newRatingValue = selectOverallRating.ratingValue;
      setValue(newRatingValue);
      setOverallRating((prevState: OverallRatingRequest) => ({
        ...prevState,
        rating: newRatingValue,
      }));
    }
  }, [selectOverallRating]);

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      fullWidth
      maxWidth="xs"
      id="overallRatingModal_dialog"
      PaperProps={{ sx: { borderRadius: "15px", overflow: "hidden" } }}
    >
      <DialogContent className={classes.dialogContent}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ textAlign: "center" }}
        >
          <Grid item md={12} xs={12} sx={{ padding: 0 }}>
            <img src={starsImg} alt="Logo" className={classes.logoImage} />
          </Grid>
          <Grid item md={12} xs={12}>
            <Typography
              variant="h4"
              sx={{ fontWeight: "bold", color: "#404040" }}
            >
              {t("overallRatingModal.overallRatingTitle")}
            </Typography>
          </Grid>
          <Grid item md={12} xs={12} mt={1}>
            <Typography variant="body2">
              {t("overallRatingModal.overallRatingShareOpinion")}{" "}
              {company?.generalData.name}
            </Typography>
          </Grid>
          <Grid item md={12} xs={12} mt={1}>
            <Typography variant="body1">
              {t("overallRatingModal.overallRatingImportant")}
            </Typography>
          </Grid>
          <Grid md={12} xs={12} mt={2}>
            <Rating
              id="overallRatingModal_rating"
              name="rating"
              value={value}
              size="large"
              onChange={handleChange}
              icon={
                <motion.div whileTap={{ scale: 1.2 }}>
                  <StarIcon style={{ width: "36px", height: "36px" }} />
                </motion.div>
              }
              emptyIcon={
                <motion.div whileTap={{ scale: 1.2 }}>
                  <StarOutlineIcon style={{ width: "36px", height: "36px" }} />
                </motion.div>
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        className={classes.dialogActions}
        sx={{ justifyContent: "center" }}
      >
        <Grid container>
          <Grid item md={12}>
            <AppButton
              id="overallRatingModal_button_close"
              color={colors.green}
              hover={colors.green}
              label={t("buttons.send")}
              width="60%"
              onClick={handleSaveOverallRating}
              disabled={overallRatingStatus === ApiStatus.Pending}
              startIcon={
                overallRatingStatus === ApiStatus.Pending ? (
                  <CircularProgress size={15} sx={{ color: colors.primary }} />
                ) : null
              }
            />
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
