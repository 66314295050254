import React from "react";
import { Box, Grid, Typography, IconButton } from "@mui/material";
import { colors } from "../../app/theme/colors";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import MySitePhotoModel from "../../models/mySite/mySitePhotoModel";
import MySiteTextModel from "../../models/mySite/mySiteTextModel";
import MySitePhoneModel from "../../models/mySite/mySitePhoneModel";
import MySiteVideoModel from "../../models/mySite/mySiteVideoModel";
import YouTube from "react-youtube";
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaLinkedin,
  FaTiktok,
} from "react-icons/fa";
import MySiteSocialMediaModel from "../../models/mySite/mySiteSocialMediaModel";
import MySiteGalleryModel from "../../models/mySite/mySiteGalleryModel";
import MySiteSliderModel from "../../models/mySite/mySiteSliderModel";
import { useMySectionStyles } from "./mySiteSectionStyles";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

interface Props {
  mySitePhoto: MySitePhotoModel[] | null;
  mySiteText: MySiteTextModel[] | null;
  selectedItems: any;
  phoneSection: MySitePhoneModel[] | null;
  mySiteVideo: MySiteVideoModel[] | null;
  mySiteSocialMedia: MySiteSocialMediaModel[] | null;
  mySiteSlider: MySiteSliderModel[] | null;
  mySiteGallery: MySiteGalleryModel[] | null;
  mySiteNewSocialMediaSection: {
    icon: string | null;
    name: string;
    link: string;
  }[];
}

export default function DisplayDataMobile({
  mySitePhoto,
  mySiteText,
  selectedItems,
  phoneSection,
  mySiteVideo,
  mySiteSocialMedia,
  mySiteGallery,
  mySiteSlider,
  mySiteNewSocialMediaSection,
}: Props) {
  const { classes } = useMySectionStyles();
  const allSections: (
    | MySiteTextModel
    | MySitePhoneModel
    | MySitePhotoModel
    | MySiteVideoModel
    | MySiteSocialMediaModel
    | MySiteGalleryModel
    | MySiteSliderModel
  )[] = [];
  if (mySiteText) {
    allSections.push(...mySiteText);
  }
  if (phoneSection) {
    allSections.push(...phoneSection);
  }
  if (mySitePhoto) {
    allSections.push(...mySitePhoto);
  }
  if (mySiteVideo) {
    allSections.push(...mySiteVideo);
  }
  if (mySiteSocialMedia) {
    allSections.push(...mySiteSocialMedia);
  }
  if (mySiteGallery) {
    allSections.push(...mySiteGallery);
  }
  if (mySiteSlider) {
    allSections.push(...mySiteSlider);
  }
  const sortedSections = allSections.sort(
    (a, b) => a.orderNumber - b.orderNumber
  );

  const handleSocialMediaClick = (link: string) => {
    window.open(link, "_blank");
  };

  return (
    <>
      <Box className={classes.displayDataMobileWrapper}>
        <Box className={classes.displayDateMobileContentWrapper}>
          {sortedSections.map(
            (
              section:
                | MySiteTextModel
                | MySitePhoneModel
                | MySitePhotoModel
                | MySiteVideoModel
                | MySiteSocialMediaModel
                | MySiteGalleryModel
                | MySiteSliderModel
            ) => (
              <React.Fragment key={section.id}>
                {"value" in section && section.value !== null ? (
                  <Grid item md={12}>
                    <Typography
                      dangerouslySetInnerHTML={{
                        __html: (section as MySiteTextModel).value,
                      }}
                      mb={2}
                      sx={{ textAlign: "justify" }}
                    />
                  </Grid>
                ) : "phones" in section &&
                  Array.isArray((section as MySitePhoneModel).phones) ? (
                  (section as MySitePhoneModel).phones.map((phone, index) => (
                    <Grid item md={12} key={`${phone.name}-${index}`}>
                      <Typography
                        mb={1}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <LocalPhoneIcon
                          sx={{ color: colors.primary, marginRight: 1 }}
                        />
                        <span
                          style={{ color: colors.primary, marginRight: "5px" }}
                        >
                          {phone.name}:
                        </span>{" "}
                        {phone.phoneNumber}
                      </Typography>
                    </Grid>
                  ))
                ) : "base64Photo" in section && section.base64Photo ? (
                  <img
                    src={section.base64Photo}
                    alt="uploaded"
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                  />
                ) : "isSlider" in section && section.isSlider ? (
                  <Carousel showThumbs={false} autoPlay>
                    {section.base64Photos.map((base64, index) => (
                      <div key={index}>
                        <img src={base64} style={{ width: "100%" }} />
                      </div>
                    ))}
                  </Carousel>
                ) : "isGallery" in section && section.isGallery ? (
                  <Grid container spacing={1}>
                    {section.base64Photos.map((base64, index) => (
                      <Grid item md={6} key={index}>
                        <img src={base64} style={{ width: "100%" }} />
                      </Grid>
                    ))}
                  </Grid>
                ) : "link" in section && section.link ? (
                  <div style={{ position: "relative" }}>
                    <YouTube
                      videoId={(section as MySiteVideoModel).link.split("=")[1]}
                      opts={{ width: "100%", height: "auto" }}
                    />
                  </div>
                ) : "socialMedias" in section ? (
                  <Grid container spacing={1} justifyContent="center">
                    {section.socialMedias.map((socialMedia, index) => (
                      <Grid item key={index}>
                        <IconButton
                          onClick={() =>
                            handleSocialMediaClick(socialMedia.link)
                          }
                          size="large"
                        >
                          {socialMedia.name === "Facebook" && (
                            <FaFacebook
                              size={20}
                              style={{ color: "rgba(24, 119, 242, 1)" }}
                            />
                          )}
                          {socialMedia.name === "Instagram" && (
                            <FaInstagram
                              size={20}
                              style={{ color: "rgba(193, 53, 132, 1)" }}
                            />
                          )}
                          {socialMedia.name === "YouTube" && (
                            <FaYoutube
                              size={20}
                              style={{ color: "rgba(255, 0, 0, 1)" }}
                            />
                          )}
                          {socialMedia.name === "LinkedIn" && (
                            <FaLinkedin
                              size={20}
                              style={{ color: "rgba(0, 119, 181, 1)" }}
                            />
                          )}
                          {socialMedia.name === "TikTok" && (
                            <FaTiktok
                              size={20}
                              style={{ color: "rgba(0, 0, 0, 1)" }}
                            />
                          )}
                          {socialMedia.socialMediaType === 6 &&
                            socialMedia.base64Photo && (
                              <img
                                src={socialMedia.base64Photo}
                                alt={socialMedia.name}
                                style={{ height: 25, width: 25 }}
                              />
                            )}
                        </IconButton>
                      </Grid>
                    ))}
                    {mySiteNewSocialMediaSection &&
                      mySiteNewSocialMediaSection.map((socialMedia, index) => (
                        <Grid item key={index}>
                          <IconButton
                            onClick={() =>
                              handleSocialMediaClick(socialMedia.link)
                            }
                            size="large"
                          >
                            {socialMedia.icon && (
                              <img
                                src={socialMedia.icon}
                                alt={socialMedia.name}
                                style={{ height: 25, width: 25 }}
                              />
                            )}
                          </IconButton>
                        </Grid>
                      ))}
                  </Grid>
                ) : null}
              </React.Fragment>
            )
          )}
        </Box>
        <Box className={classes.displayDataMobileBottomWrapper}>
          <Box className={classes.displayDataMobileDots} />
          <Box className={classes.displayDataMobileDots} />
          <Box className={classes.displayDataMobileDots} />
        </Box>
      </Box>
    </>
  );
}
