import { FC } from "react";
import Slider from "react-slick";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { useBannerSectionStyles } from "./bannerSectionStyles";
import { BannerModel } from "../../models/bannerModel";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { Env_UM } from "../../app/redux/api_um";

interface Props {
  banners: BannerModel[] | null;
}

const BannerSection: FC<Props> = ({ banners }) => {
  const theme = useTheme();
  const { classes } = useBannerSectionStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const quarter = Math.ceil((banners?.length || 0) / 4);
  const half = Math.ceil((banners?.length || 0) / 2);

  const firstGridLogos = banners
    ?.slice(0, isDesktop ? half : quarter)
    .map((l) => ({
      ...l,
      className: classes.logo,
    }));

  const secondGridLogos = banners
    ?.slice(isDesktop ? half : quarter, isDesktop ? half * 2 : quarter * 3)
    .map((l) => ({
      ...l,
      className: classes.logo,
    }));

  const thirdGridLogos = banners?.slice(quarter * 2, quarter * 3).map((l) => ({
    ...l,
    className: classes.logo,
  }));

  const fourthGridLogos = banners?.slice(quarter * 3).map((l) => ({
    ...l,
    className: classes.logo,
  }));

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: isMobile ? 2 : 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    initialSlide: 0,
    autoplayHoverPause: true,
    pauseOnHover: true,
  };

  const rtlSettings = {
    ...settings,
    rtl: true,
    pauseOnHover: true,
  };

  return (
    <div>
      {isDesktop ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Slider {...rtlSettings}>
              {firstGridLogos?.map((banner, i) => (
                <div className={classes.logoContainer} key={i}>
                  <div>
                    <a
                      href={
                        banner.url.startsWith("https://poslovniimenik.net/")
                          ? "/"
                          : !banner.url.includes("http://") &&
                            !banner.url.includes("https://")
                          ? `http://${banner.url}`
                          : banner.url
                      }
                      target="_blank"
                    >
                      <img
                        src={Env_UM.Prod + "/" + banner.photoBase64}
                        alt="logo"
                        className={banner.className}
                        style={{}}
                      />
                    </a>
                  </div>
                </div>
              ))}
            </Slider>
          </Grid>
          <Grid item xs={12} my={8}>
            <Slider {...settings}>
              {secondGridLogos?.map((banner, i) => (
                <div className={classes.logoContainer} key={i}>
                  <a
                    href={
                      banner.url.startsWith("https://poslovniimenik.net/")
                        ? "/"
                        : !banner.url.includes("http://") &&
                          !banner.url.includes("https://")
                        ? `http://${banner.url}`
                        : banner.url
                    }
                    target="_blank"
                  >
                    <img
                      src={Env_UM.Prod + "/" + banner.photoBase64}
                      alt="logo"
                      className={banner.className}
                      style={{}}
                    />
                  </a>
                </div>
              ))}
            </Slider>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Slider {...rtlSettings}>
              {firstGridLogos?.map((banner, i) => (
                <div
                  className={`${classes.logoContainer} ${
                    i % 2 === 0 ? "slide-left" : "slide-right"
                  }`}
                  key={i}
                >
                  <div>
                    <a
                      href={
                        banner.url.startsWith("https://poslovniimenik.net/")
                          ? "/"
                          : !banner.url.includes("http://") &&
                            !banner.url.includes("https://")
                          ? `http://${banner.url}`
                          : banner.url
                      }
                      target="_blank"
                    >
                      <img
                        src={Env_UM.Prod + "/" + banner.photoBase64}
                        alt="logo"
                        className={banner.className}
                        style={{}}
                      />
                    </a>
                  </div>
                </div>
              ))}
            </Slider>
          </Grid>
          <Grid item xs={12}>
            <Slider {...settings}>
              {secondGridLogos?.map((banner, i) => (
                <div
                  className={`${classes.logoContainer} ${
                    i % 2 === 0 ? "slide-right" : "slide-left"
                  }`}
                  key={i}
                >
                  <a
                    href={
                      banner.url.startsWith("https://poslovniimenik.net/")
                        ? "/"
                        : !banner.url.includes("http://") &&
                          !banner.url.includes("https://")
                        ? `http://${banner.url}`
                        : banner.url
                    }
                    target="_blank"
                  >
                    <img
                      src={Env_UM.Prod + "/" + banner.photoBase64}
                      alt="logo"
                      className={banner.className}
                      style={{}}
                    />
                  </a>
                </div>
              ))}
            </Slider>
          </Grid>
          <Grid item xs={12}>
            <Slider {...rtlSettings}>
              {thirdGridLogos?.map((banner, i) => (
                <div
                  className={`${classes.logoContainer} ${
                    i % 2 === 0 ? "slide-left" : "slide-right"
                  }`}
                  key={i}
                >
                  <a
                    href={
                      banner.url.startsWith("https://poslovniimenik.net/")
                        ? "/"
                        : !banner.url.includes("http://") &&
                          !banner.url.includes("https://")
                        ? `http://${banner.url}`
                        : banner.url
                    }
                    target="_blank"
                  >
                    <img
                      src={Env_UM.Prod + "/" + banner.photoBase64}
                      alt="logo"
                      className={banner.className}
                      style={{}}
                    />
                  </a>
                </div>
              ))}
            </Slider>
          </Grid>
          <Grid item xs={12}>
            <Slider {...settings}>
              {fourthGridLogos?.map((banner, i) => (
                <div
                  className={`${classes.logoContainer} ${
                    i % 2 === 0 ? "slide-right" : "slide-left"
                  }`}
                  key={i}
                >
                  <a
                    href={
                      banner.url.startsWith("https://poslovniimenik.net/")
                        ? "/"
                        : !banner.url.includes("http://") &&
                          !banner.url.includes("https://")
                        ? `http://${banner.url}`
                        : banner.url
                    }
                    target="_blank"
                  >
                    <img
                      src={Env_UM.Prod + "/" + banner.photoBase64}
                      alt="logo"
                      className={banner.className}
                      style={{}}
                    />
                  </a>
                </div>
              ))}
            </Slider>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default BannerSection;
