import {
  Button,
  CircularProgress,
  Grid,
} from "@mui/material";
import { motion } from "framer-motion";
import { colors } from "../../app/theme/colors";
import CheckIcon from "@mui/icons-material/Check";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import { useSearchResultLayoutStyles } from "./searchResultLayoutStyles";
import { CompanyModel } from "../../models/company/companyModel";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Category } from "../../models/company/category";
import { useAppDispatch } from "../../app/redux/hooks";
import { saveCategory } from "../../slices/searchResultsActionsSlice";

interface Props {
  savePartnerStatus: string;
  clickedButtons: any;
  handleButtonClickRemove: any;
  buttonTitle: string;
  handleButtonClick: any;
  displayTitle: string;
  isCategory: boolean;
  company?: CompanyModel | null;
}

export default function SearchResultLayoutPopoverMenu({
  buttonTitle,
  clickedButtons,
  handleButtonClickRemove,
  savePartnerStatus,
  handleButtonClick,
  displayTitle,
  isCategory,
  company,
}: Props) {
  const dispatch = useAppDispatch();
  const { classes } = useSearchResultLayoutStyles();
  const [showAutocomplete, setShowAutocomplete] = useState<boolean>(false);
  const [selectedCategories, setSelectedCategories] = useState<Category[]>([]);


  const handleButtonClickWithAutocomplete = () => {
    if (showAutocomplete) {
      setShowAutocomplete(false);
    } else {
      setShowAutocomplete(true);
    }
    handleButtonClick(buttonTitle);
  };

  const handleCategoryButtonClick = (category: Category) => {
    const parentCategoryName = category.parentCategoryName;
    dispatch(saveCategory([parentCategoryName]));

    const index = selectedCategories.findIndex((c) => c.parentCategoryName === category.parentCategoryName);

    if (index !== -1) {
      const newSelectedCategories = [...selectedCategories];
      newSelectedCategories.splice(index, 1);
      setSelectedCategories(newSelectedCategories);
    } else {
      setSelectedCategories([...selectedCategories, category]);
    }
  };
  return (
    <>
      <Grid item md={12} mt={1} sx={{ width: "100%" }}>
        {isCategory ? (
          <>
            <motion.div whileTap={{ scale: 0.9 }}>
              <Button
                sx={{
                  width: "100%",
                  justifyContent: "flex-start",
                  textTransform: "none",
                  borderRadius: "10px",
                  backgroundColor: clickedButtons
                    ? colors.primary
                    : "transparent",
                  color: clickedButtons ? "#ffffff" : "inherit",
                  "&:hover": {
                    color: clickedButtons ? "#ffffff" : "#ffffff",
                    backgroundColor: colors.primary,
                  },
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={handleButtonClickWithAutocomplete}
              >
                <motion.span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "8px",
                  }}
                  animate={{
                    rotate: showAutocomplete ? 360 : 0,
                  }}
                >
                  {showAutocomplete ? <ArrowDownwardIcon /> : <AddIcon />}
                </motion.span>
                {displayTitle}
              </Button>
            </motion.div>
            {showAutocomplete && (
              <>
                <Grid item md={12} mt={1}>
                  {company?.categories.map((category: Category, i: number) => (
                    <Grid item md={12} mt={1}>
                      <Button
                        sx={{
                          width: "100%",
                          justifyContent: "flex-start",
                          textTransform: "none",
                          borderRadius: "10px",
                          backgroundColor: selectedCategories.includes(category)
                            ? colors.primary
                            : "transparent",
                          color: selectedCategories.includes(category)
                            ? "#ffffff"
                            : "inherit",
                          "&:hover": {
                            color: selectedCategories.includes(category)
                              ? "#ffffff"
                              : "#ffffff",
                            backgroundColor: colors.primary,
                          },
                          display: "flex",
                          alignItems: "center",
                        }}
                        onClick={() => handleCategoryButtonClick(category)}
                      >
                        <motion.span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginRight: "8px",
                          }}
                          animate={{
                            rotate: selectedCategories.includes(category)
                              ? 360
                              : 0,
                          }}
                        >
                          {selectedCategories.includes(category) ? (
                            <CheckIcon />
                          ) : (
                            <AddIcon />
                          )}
                        </motion.span>
                        {category.parentCategoryName}
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              </>
            )}
          </>
        ) : (
          <motion.div whileTap={{ scale: 0.9 }}>
            <Button
              disabled={savePartnerStatus === "Pending"}
              sx={{
                width: "100%",
                justifyContent: "flex-start",
                textTransform: "none",
                borderRadius: "10px",
                backgroundColor: clickedButtons
                  ? colors.primary
                  : "transparent",
                color: clickedButtons ? "#ffffff" : "inherit",
                "&:hover": {
                  color: clickedButtons ? "#ffffff" : "#ffffff",
                  backgroundColor: colors.primary,
                },
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => {
                if (clickedButtons) {
                  handleButtonClickRemove(buttonTitle);
                } else {
                  handleButtonClick(buttonTitle);
                }
              }}
            >
              {savePartnerStatus === "Pending" ? (
                <CircularProgress
                  size={"1em"}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "8px",
                  }}
                />
              ) : (
                <motion.span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "8px",
                  }}
                  animate={{
                    rotate: clickedButtons ? 360 : 0,
                  }}
                >
                  {clickedButtons ? <CheckIcon /> : <AddIcon />}
                </motion.span>
              )}

              {displayTitle}
            </Button>
          </motion.div>
        )}
      </Grid>
    </>
  );
}
