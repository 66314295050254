import { Box, Grid, Typography, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import { useDashboardSectionStyles } from "./dashboardSectionStyles";
import CountUp from "react-countup";

interface Props {
  title: string;
  count: number;
  backgroundColor: string;
  borderColor: string;
  bottomBorderColor: string;
  showMore: string;
  id: string;
  onClick: any;
  disabled: boolean;
}

export const DashboardCard = ({
  title,
  count,
  backgroundColor,
  borderColor,
  bottomBorderColor,
  showMore,
  id,
  onClick,
  disabled,
}: Props) => {
  const { classes } = useDashboardSectionStyles();
  return (
    <motion.div
      id={id}
      whileHover={{ scale: 1.04 }}
      whileTap={{ scale: 0.95 }}
      onClick={onClick}
      initial={{ scale: 1 }}
      transition={{ duration: 0.2 }}
      className={`${classes.dashboardCardWrapper} ${
        disabled ? classes.dashboardCardWrapperDisabled : ""
      }`}
      style={{
        borderColor: borderColor,
      }}
    >
      <Grid item md={12} xs={12} className={classes.dashboardCardTitleWrapper}>
        <Typography variant="h4" className={classes.title}>
          {title}
        </Typography>
      </Grid>
      <Grid item md={12} xs={12} className={classes.dashboardCardTitleWrapper}>
        <Typography variant="h3" className={classes.title}>
          <CountUp end={count} duration={2} separator="," />
        </Typography>
      </Grid>
      <Grid item md={12} xs={12} className={classes.dashboardCardShowMoreWrapper}>
        <Box
          className={classes.dashboardCardBox}
          sx={{
            backgroundColor: backgroundColor,
          }}
        >
          <Typography>{showMore}</Typography>
        </Box>
      </Grid>
      <Grid item md={12} xs={12}>
        <Box
          className={classes.dashboardCardBottomLine}
          sx={{
            backgroundColor: bottomBorderColor,
          }}
        />
      </Grid>
    </motion.div>
  );
};
