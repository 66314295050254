import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";
import { colors } from "../../app/theme/colors";

export const useSearchResultLayoutMobileStyles = makeStyles()(
  (theme: Theme) => ({
    card: {
      background: colors.searchLayout,
      borderRadius: "15px",
    },
    box: {
      display: "flex",
      flexDirection: "column",
      background: colors.searchLayout,
    },
    cardMedia: {
      borderBottomRightRadius: "15px",
      // width: 120,
      width: "80%",
      textAlign: "left",
      justifyContent: "flex-start"
      // marginRight: "5px",
    },
    label: {
      marginLeft: "5px",
      color: colors.primary,
    },
    content: {
      marginLeft: "5px",
    },
    icons: {
      color: colors.primary,
    },
    contentWrapper: {
      display: "flex",
      alignItems: "center",
      margin: "10px 0",
    },
    description: {
      color: colors.primary,
    },
    paidCard: {
      backgroundColor: colors.gold,
    },
    activityTagWrapper: {
      background: colors.primary,
      color: "white",
      padding: "2px",
      borderRadius: "10px",
      minWidth: "100px",
      textAlign: "center",
    },
    premiumMarkWrapper: {
      position: "absolute",
      top: -50,
      left: -7,
      marginRight: "20px",
      marginLeft: "-55px",
      backgroundColor: "#CDB537",
      color: "white",
      padding: "1px 55px",
      width: "200px",
      fontSize: "10px",
      transform: "rotate(320deg)",
    },
    instagramIcon: {
      color: "#DD2A7B",
      transition: "color 0.3s ease-in-out, transform 0.3s ease-in-out",
      cursor: "pointer",
      fontSize: 20,
      "&:hover": {
        color: "#DD2A7B",
        transform: "rotate(360deg)",
      },
    },
    facebookIcon: {
      color: "#4267B2",
      marginLeft: "5px",
      transition: "color 0.3s ease-in-out, transform 0.3s ease-in-out",
      cursor: "pointer",
      fontSize: 20,
      "&:hover": {
        color: "#587ac1",
        transform: "rotate(360deg)",
      },
    },
    linkedInIcon: {
      color: "#0077B5",
      transition: "color 0.3s ease-in-out, transform 0.3s ease-in-out",
      cursor: "pointer",
      fontSize: 20,
      "&:hover": {
        color: "#0077B5",
        transform: "rotate(360deg)",
      },
    },
    twitterIcon: {
      color: "#1DA1F2",
      transition: "color 0.3s ease-in-out, transform 0.3s ease-in-out",
      cursor: "pointer",
      fontSize: 20,
      "&:hover": {
        color: "#56b9f5",
        transform: "rotate(360deg)",
      },
    },
    youtubeIcon: {
      color: "#FF0000",
      marginLeft: "5px",
      transition: "color 0.3s ease-in-out, transform 0.3s ease-in-out",
      cursor: "pointer",
      fontSize: 20,
      "&:hover": {
        color: "#ff4d4d",
        transform: "rotate(360deg)",
      },
    },
    tiktokIcon: {
      color: "#000000",
      marginLeft: "5px",
      transition: "color 0.3s ease-in-out, transform 0.3s ease-in-out",
      cursor: "pointer",
      fontSize: 18,
      marginBlock: "4px",
      marginRight: "2px",
      "&:hover": {
        color: "#404040",
        transform: "rotate(360deg)",
      },
    },
    avatar:{
      width: 150,
      height: 150,
      marginRight: "5px",
      background: colors.avatar,
    },
    contactContent: {
      marginLeft: "5px",
      textDecoration: "none",
      cursor: "pointer",
      color: "black",
      // "&:hover": {
      //   color: colors.primary,
      // },
    },
  })
);
