import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";
import { colors } from "../../../app/theme/colors";

export const useMainHeaderStyles = makeStyles()((theme: Theme) => ({
  buttonsWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    flexGrow: 1,
  },
  logo: {
    width: "250px",
    cursor: "pointer",
  },
  inputWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  searchIcon: {
    color: "#929292",
    marginRight: "5px",
  },
  typography: {
    background: "rgba(249, 249, 249, 0.2)",
    borderRadius: "5px",
    textAlign: "center",

    marginTop: "5px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  searchIconFilters: {
    color: "white",
    marginRight: "5px",
  },
  arrowBackIcon: {
    cursor: "pointer",
  },
  inputGrid: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    width: "100%",
  },
  autocompleteTextField: {
    "& .MuiInputBase-root": {
      borderRadius: "5px",
      background: "white",
    },
  },
  autocomplete: {
    "& .MuiOutlinedInput-root.Mui-focused": {
      "& > fieldset": {
        borderColor: colors.sectionTitle,
      },
    },
  },
  advanceSearchWrapper: {
    display: "flex",
  },
  button: {
    position: "relative",
    overflow: "hidden",
    transition: "all 0.3s ease-out",
    "&:hover": {
      "&::before": {
        width: "100%",
      },
      backgroundColor: "transparent",
    },
    "&::before": {
      content: '""',
      position: "absolute",
      bottom: "4px",
      left: "50%",
      transform: "translateX(-50%)",
      width: 0,
      height: "2px",
      backgroundColor: theme.palette.common.white,
      transition: "all 0.3s ease-out",
    },
  },
  closeIcon: {
    cursor: "pointer",
    color: colors.sectionTitle,
  },
}));
