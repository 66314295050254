import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../app/redux/hooks";
import { useMySectionStyles } from "./mySiteSectionStyles";
import { colors } from "../../app/theme/colors";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import MySitePhotoRequest from "../../models/mySite/mySitePhotoModel";
import { EmptyGuid } from "../../models/init_data/initData";

interface Props {
  open: any;
  handleClose: any;
  setSelectedFile: any;
  mySitePhotoSection: MySitePhotoRequest[] | null;
  setMySitePhotoSection: any;
  clickedCube: string | null;
  selectedItems: {
    [key: string]: { value: string | null; orderNumber: number };
  };
}

export default function UploadImgModal({
  handleClose,
  open,
  setSelectedFile,
  mySitePhotoSection,
  setMySitePhotoSection,
  clickedCube,
  selectedItems,
}: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { classes } = useMySectionStyles();
  const handleDialogClose = () => {
    handleClose();
  };
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target) {
          const base64 = event.target.result as string;
          setMySitePhotoSection((prevPhotos: MySitePhotoRequest[]) => {
            return prevPhotos.map((photo) => {
              if (
                clickedCube &&
                selectedItems[clickedCube]?.orderNumber === photo.orderNumber
              ) {
                return {
                  ...photo,
                  base64Photo: base64,
                };
              }
              return photo;
            });
          });
        }
      };
      reader.readAsDataURL(file);
      handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      fullWidth
      maxWidth="xs"
      id="rateUsModal_dialog"
      PaperProps={{
        sx: {
          borderRadius: "15px",
          overflow: "hidden",
          background: "rgba(210, 226, 252, 1)",
        },
      }}
    >
      <DialogContent>
        <Grid container justifyContent="center" alignItems="center">
          <Grid
            item
            md={12}
            display={"flex"}
            alignItems="center"
            sx={{ textAlign: "left" }}
          >
            <FileUploadIcon
              sx={{ fontSize: "50px", color: "rgba(133, 136, 144, 1)" }}
            />
            <Typography variant="body2" sx={{ ml: 1 }}>
              <b>{t("mySite.mySiteUploadImgModalTitle")}</b> <br />
              <Typography
                variant="body1"
                component="span"
                sx={{ color: "rgba(133, 136, 144, 0.7)" }}
              >
                {t("mySite.mySiteUploadImgModalSubtitle")}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        className={classes.dialogActions}
        sx={{ justifyContent: "center" }}
      >
        <Grid container>
          <Grid item md={12}>
            <input
              type="file"
              accept="image/*"
              id="fileInput"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <label htmlFor="fileInput">
              <button
                style={{
                  backgroundColor: colors.primary,
                  color: "#fff",
                  padding: "10px 20px",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                onClick={() => document.getElementById("fileInput")?.click()}
              >
                {t("mySite.mySiteUploadImgModalChoose")}
              </button>
            </label>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
