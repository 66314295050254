import { motion } from "framer-motion";
import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Autocomplete, Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { colors } from "../../app/theme/colors";
import { useAppDispatch } from "../../app/redux/hooks";
import { getMySiteVisitsStatistic } from "../../slices/dashboardSlice";
import DashboardWebsiteVisitsStatisticResponse from "../../models/dashboard/dashboardWebsiteVisitsStatisticResponse";
import {
  useDayOfWeekTranslation,
  useMonthTranslation,
} from "./dashboardChartTranslation";
import CustomTooltip from "./customTooltip";

interface Props {
  websiteStatistic: DashboardWebsiteVisitsStatisticResponse[] | null;
  selectedStatistic: number | null;
  setSelectedStatistic: any;
}

const DashboardMySiteChart = ({
  websiteStatistic,
  selectedStatistic,
  setSelectedStatistic,
}: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { dayOfWeekTranslation } = useDayOfWeekTranslation();
  const { monthTranslation } = useMonthTranslation();

  const handleChange = (event: React.SyntheticEvent, value: string | null) => {
    let statisticValue: number | null = null;

    switch (value) {
      case t("mySiteStatisticChart.day"):
        statisticValue = 0;
        break;
      case t("mySiteStatisticChart.month"):
        statisticValue = 1;
        break;
      case t("mySiteStatisticChart.year"):
        statisticValue = 2;
        break;
      default:
        statisticValue = null;
        break;
    }

    setSelectedStatistic(statisticValue);

    if (statisticValue !== null) {
      dispatch(getMySiteVisitsStatistic(statisticValue));
    }
  };

  const getTickFormatter = () => {
    if (selectedStatistic === 1) {
      return (tick: number) => monthTranslation(tick);
    } else if (selectedStatistic === 0) {
      return (tick: string) => dayOfWeekTranslation(tick);
    }
    return (tick: any) => tick;
  };

  return (
    <motion.div>
      <Grid
        item
        md={12}
        sx={{ padding: "5px" }}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"flex-end"}
      >
        <Autocomplete
          onChange={handleChange}
          options={[
            t("mySiteStatisticChart.day"),
            t("mySiteStatisticChart.month"),
            t("mySiteStatisticChart.year"),
          ]}
          limitTags={1}
          size="small"
          sx={{ width: "30%" }}
          defaultValue={t("mySiteStatisticChart.day")}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("mySiteStatisticChart.chooseDayMonthYear")}
              variant="outlined"
              size="small"
              sx={{
                fontSize: "5px",
                "& label.Mui-focused": {
                  color: colors.primary,
                },
                "& .MuiInputLabel-outlined": {
                  transition: "all 0.2s",
                },
                "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
                  transform: "translate(14px, -13px) scale(0.75)",
                },
              }}
              fullWidth
            />
          )}
        />
      </Grid>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          data={websiteStatistic || []}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey={
              selectedStatistic === 2
                ? "year"
                : selectedStatistic === 1
                ? "month"
                : "dayOfTheWeek"
            }
            tickFormatter={getTickFormatter()}
          />
          <YAxis />
          <Tooltip
            content={
              <CustomTooltip selectedStatistic={selectedStatistic ?? null} />
            }
          />
          <Legend />
          <Line
            type="monotone"
            dataKey="numberOfVisits"
            stroke="#8884d8"
            activeDot={{ r: 8 }}
            name={t("mySiteStatisticChart.numberOfVisits") ?? ""}
          />
        </LineChart>
      </ResponsiveContainer>
    </motion.div>
  );
};

export default DashboardMySiteChart;
