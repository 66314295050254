import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";

export const useAboutUsSectionStyles = makeStyles()((theme: Theme) => ({
  aboutText: {
    color: "white",
    marginBottom: theme.spacing(3), 
  },
  aboutUsImg: {
    width: "40%",
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
  },
  title: {
    color: "white",
    fontWeight: "bold",
    marginBottom: "5px"
  },
}));
