import { AxiosResponse } from "axios";
import { ApiResponse } from "../../models/app/apiResponse";
import { MySiteDetailsResponse } from "../../models/mySite/mySiteDetalisResponse";
import MySiteGalleryModel from "../../models/mySite/mySiteGalleryModel";
import MySitePhoneModel from "../../models/mySite/mySitePhoneModel";
import MySitePhotoModel from "../../models/mySite/mySitePhotoModel";
import MySiteSliderModel from "../../models/mySite/mySiteSliderModel";
import MySiteSocialMediaModel from "../../models/mySite/mySiteSocialMediaModel";
import MySiteTextModel from "../../models/mySite/mySiteTextModel";
import MySiteVideoModel from "../../models/mySite/mySiteVideoModel";
import RemoveWebsitePartition from "../../models/mySite/removeWebsitePartition";
import { api_poslovni_imenik } from "./api_poslovni_imenik";

export const saveMySiteText: any = async (request: MySiteTextModel[]) => {
  return api_poslovni_imenik.post(
    "/company-website-partition/add-website-text-partition",
    request
  ) as Promise<ApiResponse<MySiteTextModel | null>>;
};

export const saveMySitePhone: any = async (request: MySitePhoneModel[]) => {
  return api_poslovni_imenik.post(
    "/company-website-partition/add-website-phone-partition",
    request
  ) as Promise<ApiResponse<MySitePhoneModel | null>>;
};

export const saveMySitePhoto: any = async (request: MySitePhotoModel[]) => {
  return api_poslovni_imenik.post(
    "/company-website-partition/add-website-photo-partition",
    request
  ) as Promise<ApiResponse<MySitePhotoModel | null>>;
};

export const saveMySiteVideo: any = async (request: MySiteVideoModel[]) => {
  return api_poslovni_imenik.post(
    "/company-website-partition/add-website-video-partition",
    request
  ) as Promise<ApiResponse<MySiteVideoModel | null>>;
};

export const saveMySiteSocialMedia: any = async (
  request: MySiteSocialMediaModel[]
) => {
  return api_poslovni_imenik.post(
    "/company-website-partition/add-website-social-media-partition",
    request
  ) as Promise<ApiResponse<MySiteSocialMediaModel | null>>;
};

export const saveMySiteGallery: any = async (request: MySiteGalleryModel[]) => {
  return api_poslovni_imenik.post(
    "/company-website-partition/add-website-gallery-partition",
    request
  ) as Promise<ApiResponse<MySiteGalleryModel | null>>;
};

export const saveMySiteSlider: any = async (request: MySiteSliderModel[]) => {
  return api_poslovni_imenik.post(
    "/company-website-partition/add-website-slider-partition",
    request
  ) as Promise<ApiResponse<MySiteSliderModel | null>>;
};

export const getCompanyWebsite: any = async () => {
  return api_poslovni_imenik.get(
    "/company-website-partition/get-website"
  ) as Promise<ApiResponse<MySiteDetailsResponse | null>>;
};

export const getMySiteView = async (websiteName: string) => {
  return api_poslovni_imenik.get(
    "/company-website-partition/get-website/" + websiteName
  ) as Promise<ApiResponse<MySiteDetailsResponse[]>>;
};

export const removeWebsitePartition = async (
  request: RemoveWebsitePartition
) => {
  return api_poslovni_imenik.delete<AxiosResponse<ApiResponse<MySiteDetailsResponse | null>>>(
    "/company-website-partition/remove-website-partition",
    {
      data: request 
    }
  );
};