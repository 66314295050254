import { Grid, styled } from "@mui/material";
import EditionsSectionCard from "./editionsSectionCard";

const GradientBackground = styled(Grid)(({ theme }) => ({
  alignItems: "center",
  backgroundRepeat: "no-repeat",
  backgroundColor: "white",
  backgroundPosition: "center",
  padding: "40px",
  [theme.breakpoints.down("md")]: {
    padding: "20px",
  },
}));

export default function EditionsPageSection() {
  return (
    <GradientBackground container>
      <EditionsSectionCard />
    </GradientBackground>
  );
}
