import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { colors } from "../../../app/theme/colors";
import AppButton from "../../../components/appButton/appButton";
import { useAppDispatch } from "../../../app/redux/hooks";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import logo from "../../../images/logoFull.png";
import CustomOutlinedInput from "../../../components/outlinedInput/customOutlinedInput";
import { useForgotPasswordModalStyles } from "./forgotPasswordModalStyles";
import ForgotSetPasswordModal from "./forgotSetPasswordModal";
import ForgotPasswordRequest from "../../../models/auth/forgotPasswordRequest";
import { ApiStatus } from "../../../models/app/apiStatus";
import SetPasswordModel from "../../../models/auth/setPasswordModel";
import SetForgotPasswordModel from "../../../models/auth/setForgotPasswordModel";
import {
  forgotPasswordClientAccount,
  resetForgotPasswordStatus,
} from "../../../slices/authSlice";

interface Props {
  open: boolean;
  handleClose: any;
  forgotPassword: ForgotPasswordRequest;
  isOpenForgotSetPassword: boolean;
  setIsOpenForgotSetPassword: React.Dispatch<React.SetStateAction<boolean>>;
  forgotPasswordStatus: string;
  setForgotPassword: SetPasswordModel;
  setSetForgotPassword: React.Dispatch<React.SetStateAction<SetPasswordModel>>;
  forgotPasswordAccount: SetForgotPasswordModel;
  setForgotPasswordAccount: React.Dispatch<
    React.SetStateAction<SetForgotPasswordModel>
  >;
  setIsOpenForgotPasswordModal: React.Dispatch<React.SetStateAction<boolean>>;
  setForgotPasswordModal: React.Dispatch<
    React.SetStateAction<ForgotPasswordRequest>
  >;
}

export default function ForgotPasswordModal({
  handleClose,
  open,
  forgotPassword,
  isOpenForgotSetPassword,
  setIsOpenForgotSetPassword,
  forgotPasswordStatus,
  setForgotPassword,
  setSetForgotPassword,
  forgotPasswordAccount,
  setForgotPasswordAccount,
  setIsOpenForgotPasswordModal,
  setForgotPasswordModal,
}: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { classes } = useForgotPasswordModalStyles();
  const theme = useTheme();

  const [emailError, setEmailError] = useState(false);

  const handleDialogClose = () => {
    setEmailError(false);
    handleClose();
  };

  const handleForgotPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    setForgotPasswordModal((prevState: ForgotPasswordRequest) => ({
      ...prevState,
      email: value,
    }));
    setForgotPasswordAccount((prevState: SetForgotPasswordModel) => ({
      ...prevState,
      email: value,
    }));

    if (value.trim() !== "") {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  };

  useEffect(() => {
    dispatch(resetForgotPasswordStatus());
    setEmailError(false);
  }, [open]);

  const handleSaveForgotPassword = () => {
    if (!forgotPassword.email) {
      setEmailError(true);
    } else {
      setEmailError(false);
      dispatch(forgotPasswordClientAccount(forgotPassword));
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      fullWidth
      maxWidth="xs"
      id="forgotPassword_dialog"
      PaperProps={{ sx: { borderRadius: "15px", overflow: "hidden" } }}
    >
      <IconButton
        edge="end"
        color="inherit"
        onClick={handleDialogClose}
        aria-label="close"
        sx={{
          position: "absolute",
          top: theme.spacing(1),
          right: theme.spacing(2),
        }}
      >
        <CloseIcon className={classes.closeIcon} />
      </IconButton>
      <DialogContent className={classes.dialogContent}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          padding={"5px"}
          sx={{ textAlign: "center" }}
        >
          <Grid item md={12} xs={12}>
            <img
              src={logo}
              alt="Logo"
              className={classes.logoImage}
              id="forgotPassword_img_logo"
            />
          </Grid>
          <Grid item md={12} xs={12} mt={5}>
            <Typography variant="h4" className={classes.title}>
              {t("forgotPasswordModal.forgotPasswordTitle")}
            </Typography>
          </Grid>
          <Grid item md={12} xs={12} mt={5}>
            <CustomOutlinedInput
              id="forgotPassword_input_email"
              type="email"
              label={t("loginModal.loginEmail")}
              width="100%"
              labelColor={colors.primary}
              onChange={handleForgotPasswordChange}
              value={forgotPassword.email}
              error={emailError}
              errorMessage={emailError ? t("loginModal.loginErrorEmail") : ""}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <AppButton
          id="forgotPassword_button_save"
          onClick={handleSaveForgotPassword}
          color={colors.primary}
          hover={colors.primary}
          label={t("buttons.send")}
          width="100%"
          disabled={forgotPasswordStatus === ApiStatus.Pending}
          startIcon={
            forgotPasswordStatus === ApiStatus.Pending ? (
              <CircularProgress size={15} sx={{ color: colors.primary }} />
            ) : null
          }
        />
      </DialogActions>
      <ForgotSetPasswordModal
        handleClose={() => setIsOpenForgotSetPassword(false)}
        open={isOpenForgotSetPassword}
        setForgotPassword={setForgotPassword}
        setSetForgotPassword={setSetForgotPassword}
        forgotPasswordAccount={forgotPasswordAccount}
        setForgotPasswordAccount={setForgotPasswordAccount}
        setIsOpenForgotPasswordModal={setIsOpenForgotPasswordModal}
        setForgotPasswordModal={setForgotPasswordModal}
      />
    </Dialog>
  );
}
