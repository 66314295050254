import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: "rs",
    lng: "rs",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {
          cookieConsent: {
            title: "We use cookies",
            description:
              "This site uses cookies to improve your experience. Please select your cookie settings:",
            understand: "I understand",
            showDetails: "Show details",
            modalTitle: "Cookie Information",
            modalDescFirst:
              "We use cookies to collect information about you. We use this information:",
            modalDescSecond: "to provide you with",
            modalDescThird: "a better experience",
            modalDescFourth: "of our website (functional)",
            modalDescFifth: "to",
            modalDescSixth: "count the pages",
            modalDescSeventh: "you visit (statistics)",
            modalDescEighth: "to serve you",
            modalDescNinth: "relevant promotions",
            modalDescTenth: "(marketing)",
            modalDesc:
              "Click “OK” to give us your consent to use cookies for all these purposes. You can also use the checkboxes to consent to specific purposes. Select purposes and “Save settings”. Withdraw or change your consent at any time by clicking the icon in the bottom left corner of the screen. Change your settings.",
            switchNecessary: "Strictly necessary",
            switchFunctional: "Functional",
            switchStatistical: "Statistical",
            swtichMarketing: "Marketing",
            saveSettings: "Save settings",
          },

          validation: {
            validationCompanyName: "The company name field is required",
            validationTaxId: "The tax ID field is required",
            validationEmail: "The email field is required",
            validationPhoneNumber: "The phone number field is required",
            validationMessage: "The message field is required",
            validationPackage: "Please select a package",
            validationFirstName: "The first name field is required",
            validationLastName: "The last name field is required",
          },
          messageKey: {
            orderRequest_Pending: "Sending order request...",
            orderRequest_Success:
              "The order request has been successfully sent!",
            orderRequest_Error:
              "Unfortunately, the request could not be sent. Please check your internet connection and try again.",

            advertisementRequest_Pending: "Sending advertising request...",
            advertisementRequest_Success:
              "The advertising request has been successfully sent!",
            advertisementRequest_Error:
              "Unfortunately, the request could not be sent. Please check your internet connection and try again.",

            updateDataRequest_Pending: "Slanje zahtjeva za ažuriranje...",
            updateDataRequest_Success:
              "Zahtjev za ažuriranje je uspješno poslan!",
            updateDataRequest_Error:
              "Unfortunately, the request could not be sent. Please check your internet connection and try again.",

            contactRequest_Pending: "Vaša poruka se šalje... ",
            contactRequest_Success:
              "Vaš zahtjev je uspješno poslan! Hvala vam što ste nas kontaktirali. Potrudit ćemo se da vam odgovorimo u najkraćem mogućem roku.",
            contactRequest_Error:
              "Unfortunately, sending the message failed. Please check your internet connection and try again.",
            Login_Pending: "Logging in...",
            GetByEmail_AccountByEmailNotFound:
              "Account with this email address does not exist.",
            Login_AccountByEmailNotFound:
              "Account with this email address does not exist.",
            Login_TooManyAttempts:
              "Too many failed login attempts. Please contact the administrator.",
            Login_NeedToChangePassword: "You need to reset your password.",
            Login_WrongPassword: "Incorrect password.",
            Login_AccountInactive:
              "Account is inactive. Please contact the administrator.",
            Login_NeedResetPasswordOnFirstLogin:
              "You need to change your password.",
            Login_Success: "You have successfully logged in.",

            Logout_Success: "You have been logged out.",
            RequestForgotPassword_EmailSent:
              "Your request to change the password has been successfully sent. A code to reset your password has been sent to your email address. Enter this code to proceed with the password change process.",
            RequestForgotPassword_AccountByEmailNotFound:
              "No account found with the provided email address.",
            ForgotPassword_Pending: "Processing password reset request...",
            ClientAccount_ForgotPasswordCodeNotValid:
              "Password reset code is not valid. Please try again.",
            ForgotPassword_Success: "Password reset successful.",
            ResetPassword_AccountByIdNotFound:
              "Account not found with the provided ID.",
            ResetPassword_AccountByEmailNotFound:
              "Account not found with the provided email address.",
            ResetPassword_IncorrectOldPassword: "Incorrect old password.",
            ResetPassword_Success: "Password successfully reset.",
            SendContactRequest_Success:
              "You have successfully sent a message. Our team will contact you as soon as possible.",
            SendContactRequest_Pending:
              "Your message is being sent. Please wait...",
            CreateAccount_AccountWithThisEmailAlreadyExists:
              "An account with this email already exists.",
            CreateAccount_Pending: "Creating account...",
            CreateAccount_Success: "Account successfully created!",
            DeleteAccount_Success: "Account successfully deleted!",
            DeleteClient_Pending: "Deleting account...",
            UpdateAccount_Success: "Account successfully updated!",
            ActivitySelection_Success:
              "You have successfully saved categories!",
            MySiteText_Pending: "Saving text in progress...",
            MySiteSuccessMessageText_Success: "Text successfully saved!",
            MySiteErrorMessageText_Error:
              "Error saving text. Please try again.",

            MySitePhone_Pending: "Saving phone in progress...",
            MySiteSuccessMessagePhone_Success: "Phone successfully saved!",
            MySiteErrorMessagePhone_Error:
              "Error saving phone. Please try again.",

            MySitePhoto_Pending: "Saving photo in progress...",
            MySiteSuccessMessagePhoto_Success: "Photo successfully saved!",
            MySiteErrorMessagePhoto_Error:
              "Error saving photo. Please try again.",

            MySiteVideo_Pending: "Saving video in progress...",
            MySiteSuccessMessageVideo_Success: "Video successfully saved!",
            MySiteErrorMessageVideo_Error:
              "Error saving video. Please try again.",

            MySiteSocialMedia_Pending: "Saving social media in progress...",
            MySiteSuccessMessageSocialMedia_Success:
              "Social media successfully saved!",
            MySiteErrorMessageSocialMedia_Error:
              "Error saving social media. Please try again.",

            MySiteGallery_Pending: "Saving photo gallery in progress...",
            MySiteSuccessMessageGallery_Success:
              "Photo gallery successfully saved!",
            MySiteErrorMessageGallery_Error:
              "Error saving photo gallery. Please try again.",

            MySiteSlider_Pending: "Saving slider in progress...",
            MySiteSuccessMessageSlider_Success: "Slider successfully saved!",
            MySiteErrorMessageSlider_Error:
              "Error saving slider. Please try again.",

            MySitePartition_Pending: "Deleting partition in progress...",
            MySiteSuccessMessagePartition_Success:
              "Partition successfully deleted!",
            MySiteErrorMessagePartition_Error:
              "Error deleting partition. Please try again.",

            WidgetDeleteMessagePartners_Pending:
              "Deleting company from partners in progress...",
            WidgetDeleteMessagePartners_Success:
              "Successfully deleted company from partners!",
            WidgetDeleteMessagePartners_Error:
              "Error deleting company from partners. Please try again.",

            WidgetDeleteMessagePotentialPartners_Pending:
              "Deleting company from potential partners in progress...",
            WidgetDeleteMessagePotentialPartners_Success:
              "Successfully deleted company from potential partners!",
            WidgetDeleteMessagePotentialPartners_Error:
              "Error deleting company from potential partners. Please try again.",

            WidgetDeleteMessageClients_Pending:
              "Deleting company from clients in progress...",
            WidgetDeleteMessageClients_Success:
              "Successfully deleted company from clients!",
            WidgetDeleteMessageClients_Error:
              "Error deleting company from clients. Please try again.",

            WidgetDeleteMessageFavorites_Pending:
              "Deleting company from favorites in progress...",
            WidgetDeleteMessageFavorites_Success:
              "Successfully deleted company from favorites!",
            WidgetDeleteMessageFavorites_Error:
              "Error deleting company from favorites. Please try again.",
            Logout_ExpiredToken: "Your session has expired.",
            NetworkError: "Error connecting to server.",
            InternalServerError: "Error connecting to server.",
            EditionsSectionCard_PdfDownload_Success: "You have successfully downloaded katalog_2024.pdf.",
            EditionsSectionCard_PdfDownload_Error: "Error downloading PDF: Unable to download the catalog. Please try again."
          },
          header: {
            homePage: "Home",
            services: "Services",
            updateData: "Updating Data",
            advertisement: "Advertising",
            digitalMarketing: "Digital Marketing",
            editions: "Editions",
            aboutUs: "About Us",
            contact: "Contact",
            companyNameInput:
              "Enter company name, service/product, location...",
            advanceSearchLabel: "Advanced search of Business Directory BiH",
            categoryInput: "Category",
            subCategoryInput: "Subcategory",
            place: "Place",
            telekomBl: "Telekom-BL",
          },
          footer: {
            footerDescription:
              "The primary activity of our company is business publishing in the territory of Bosnia and Herzegovina. Our editorial team has successfully launched numerous highly circulated specialized business publications, including the Telephone-telefax Directory of BiH, Telefonoteka, Poslovni imenik BiH, 300 Most Successful Companies in BiH, Catalog of Poslovni imenik BiH, as well as corresponding electronic publications on CD, and accompanying online editions for these titles.",
            footerFastLinksTitle: "Fast links",
            footerFastLinkHome: "Home",
            footerFastLinkServices: "Services",
            footerFastLinkUpdatingData: "Updating Data",
            footerFastLinkAdvertisement: "Advertising",
            footerFastLinkDigitalMarketing: "Digital Marketing",
            footerFastLinkEditions: "Editions",
            footerFastLinkAbout: "About Us",
            footerFastLinkContact: "Contact us",
          },
          homePage: {
            resultsPerPage: "Reuslts per page",
          },
          updatingDataPage: {
            updatingDataTitle: "Updating Data",
            updatingDataText:
              "The process of updating data in the Business Directory of Bosnia and Herzegovina involves modifying existing information, adding new entries, and removing outdated or invalid data from the database. This task is performed by the publisher, who gathers information through fieldwork, telephone surveys, and online submissions via the website's form.",
            updatingDataFormCompanyName: "Company name",
            updatingDataFormPIB: "TAX-ID",
            updatingDataFormEmail: "E-mail",
            updatingDataFormPhoneNumber: "Phone number",
            updatingDataFormPlace: "Place",
            updatingDataFormStreet: "Street",
            updatingDataFormHomeNumber: "Home number",
          },
          advertisementPage: {
            advertisementTitle: "Advertising",
            advertisementText:
              "We provide various types of advertising and create a set of services tailored to the needs of our clients. For a complete range of our services and personalized creation of an advertising services set, it is necessary to contact our sales agents. You can also order some of our most popular service packages via the following contact form.",
            advertisementFormCompanyName: "Company name",
            advertisementFormPIB: "TAX-ID",
            advertisementFormEmail: "E-mail",
            advertisementFormPhoneNumber: "Phone number",
            advertisementFormPlace: "Place",
            advertisementFormStreet: "Street",
            advertisementFormHomeNumber: "Home number",
          },
          digitalMarketingPage: {
            digitalMarketingTitle: "Digital Marketing",
            digitalMarketingText:
              "We provide comprehensive solutions for a company's business information, advertising, and digital transformation. Leveraging intelligent software technologies, we offer high-quality, efficient, and affordable solutions to enhance digital business and drive increased success.",
            digitalMarketingTextSecond:
              "Our approach begins with analyzing your business processes and information systems to propose optimal solutions for digital transformation, integration, automation, and improvement across all business segments. Our solutions are unique, dynamic, and scalable, fully customized to meet your specific needs and goals.",
            digitalMarketingTextThird:
              "We stay up-to-date with the latest technological trends and implement our services in accordance with best practices. Whether youre embarking on a new project, expanding existing operations, or seeking to enhance your online presence, we are here to assist you. Get in touch with us to request a free assessment of your digital business improvement possibilities, and we will provide you with tailored suggestions for digital business development aligned with your needs and goals.",
          },
          editionsPage: {
            catalogTitle: "Poslovni imenik BiH - Catalog",
            catalogTextFirst:
              "The Business Directory of Bosnia and Herzegovina (printed edition) held the distinction of being the most widely read and best-selling business information publication in the country until 2016. Its long- standing presence in the business landscape has made it well-known to companies, organizations, and institutions that have directly or indirectly benefited from the information provided in its annual editions. Business Directory BiH can be searched on the website www.poslovniimenik.net.",
            catalogText:
              "The Business Directory of Bosnia and Herzegovina (printed edition) held the distinction of being the most widely read and best-selling business information publication in the country until 2016. Its long- standing presence in the business landscape has made it well-known to companies, organizations, and institutions that have directly or indirectly benefited from the information provided in its annual editions of the Business Directory BiH can be searched on the website www.poslovniimenik.net.",
            catalogTextSecond:
              "The Business Directory BiH - Catalog primarily focuses on companies, organizations, and institutions operating within the territory of Bosnia and Herzegovina. These entities vividly showcase themselves through colorful advertisements and descriptive text within this publication.",
            catalogTextThird:
              "Like previous editions of the Business Directory of Bosnia and Herzegovina - Catalog, this publication adheres to the established concept of direct marketing, emphasizing active legal entities from Bosnia and Herzegovina. In the Catalog, advertisements of legal entities are categorized based on related activities, while advertisers with logos from the same industry are listed alphabetically within each ad section. More comprehensive information about companies and institutions can be found in the Catalog (in PDF format), and the entire database of the Business Directory BiH can be searched on the website www.poslovniimenik.net.",
            cdTitle:
              "Business Directory of Bosnia and Herzegovina - digital edition",
            cdText:
              "Compared to the online and print editions, the electronic edition of the Business Directory of Bosnia and Herzegovina offers numerous advantages. These advantages are particularly evident in the search and sorting functionalities, allowing users to search by various individual parameters, conduct combined searches using multiple criteria, and save and store searches.",
            cdTextSecond:
              "Another significant advantage is the ability to utilize the data for digital marketing purposes, such as social media or direct marketing campaigns. The database of the Electronic Business Directory of Bosnia and Herzegovina is updated daily and enriched with new information concerning both existing and newly established companies and institutions.",
            cdTextThird:
              "For each company or institution listed in the database, the following basic information is provided: - Company or institution name - Details about the company or institutions activities - Address, including street, number, city, postal code, entity, and telephone number with country code - Telephone numbers, fax numbers, and email addresses - Website addresses of the company or institution",
            cdTextFourth:
              "By purchasing the electronic edition of the Business Directory of Bosnia and Herzegovina and adding your company to the database, you gain the privilege of having your information listed and published in both the online and electronic editions of the Business Directory. Furthermore, you are entitled to free regular updates, changes, or additions to your data via the internet throughout a one-year subscription period.",
            cdTextFifth:
              "We firmly believe that this electronic edition of the Business Directory of Bosnia and Herzegovina will provide you with convenient access to all the necessary data for seamless communication, facilitating the establishment of business contacts with existing legal entities in Bosnia and Herzegovina.",
            onlineTitle: "Online Business Directory of Bosnia and Herzegovina",
            onlineText:
              "The online edition of the Business Directory of Bosnia and Herzegovina serves as a central hub for various business activities. The website, www.poslovniimenik.net, provides its content to all internet users, allowing easy browsing and quick data searches using a variety of parameters. Our website attracts a substantial number of daily visitors, including a diverse range of individuals and business users, providing substantial benefits to both our visitors and service users.",
            onlineTextSecond:
              "By showcasing your company, institution, or organization on our website, you join a community of advertisers who have already highlighted their information through advertisements.",
            onlineTextThird:
              "This exposure allows hundreds of thousands of interested visitors to gain a comprehensive overview of your company and its activities. The online edition of the Business Directory of Bosnia and Herzegovina, in conjunction with its printed and electronic counterparts, aims to provide essential business information to users across all categories and actively influence the flow of information through its dynamic nature.",
            onlineTextFourth:
              "The publisher diligently collects and regularly updates all information obtained through field and phone surveys, as well as from other reliable sources. This ensures that the information about your company will be swiftly available on the online presentation of the Business Directory of Bosnia and Herzegovina.",
            onlineTextFifth: "All information collected by the publisher through field or telephone surveys, as well as information from other sources, is regularly updated in the BiH Business Directory database. This means that information about your company will be quickly available on the BiH Business Directory's online presentation.",
            tooltip: "Clicking on the image will open the online catalog view.",
            backIcon: 'Return to the "Services" page.'
          },
          aboutUsPage: {
            aboutTitle: "About Us",
            aboutText:
              "The primary activity of our company is business publishing in the territory of Bosnia and Herzegovina. Our editorial team has successfully launched numerous highly circulated specialized business publications, including the Telephone-telefax Directory of BiH, Telefonoteka, Poslovni imenik BiH, 300 Most Successful Companies in BiH, Catalog of Poslovni imenik BiH, as well as corresponding electronic publications on CD and accompanying online editions for these titles.",
            aboutTextSecond:
              "Our business strategy revolves around direct marketing and direct sales. Through these strategies, the publisher of the Business Directory of Bosnia and Herzegovina independently organizes all commercial and marketing activities, as well as the preparation, editing, and publishing of our business publications.",
            aboutTextThird:
              "This business concept is the foundation of our mission, which is to actively and continuously update the database of economic entities from across the entire territory of Bosnia and Herzegovina. Our goal is to consolidate all relevant business information in one place, making it easily accessible to all business entities and individuals in Bosnia and Herzegovina, the region, and beyond.",
            aboutTextFourth:
              "Our vision is to maintain a reputable position in the business publishing industry in Bosnia and Herzegovina, recognized primarily for the quality, timeliness, and pricing of our services. To continuously achieve this, we have oriented ourselves to operate in step with the development of modern technologies, aiming for year-on-year growth in the quality of our business publications and expanding our network of users and advertisers. We are prepared to launch new business publications according to market demands, such as specialized brochures, special professional supplements, and periodic and monthly bulletins and magazines.",
            aboutLandmark: "Telekom – BL, Banja Luka",
          },
          contactUsPage: {
            contactTitle: "Contact Us",
            contactFormCompanyName: "Company name",
            contactFormContactPerson: "Contact person",
            contcatFormEmail: "E-mail",
            contactFormMessage: "Message",
            contactSuccessMessage:
              "Your message has been successfully sent. We will respond to you as soon as possible.",
            contactSuccessTitle: "Thanks!",
          },
          orderRequestPage: {
            orderTitle: "Order request",
            orderPackageOne: "Package 1",
            orderPackageTwo: "Package 2",
            orderPackageThree: "Package 3",
            orderPackageOneText:
              "Updating and publishing the customers information in the Business Directory of Bosnia and Herzegovina (electronic edition); advertisement on the website poslovniimenik.net (logo, information, and link); publishing the same advertisement in the Catalog of the Business Directory of Bosnia and Herzegovina",
            orderPackageTwoText:
              "Updating and publishing the customer's information in the Business Directory of Bosnia and Herzegovina (electronic edition); advertisement on the website poslovniimenik.net (logo, information, and link); publishing the same advertisement in the Catalog of the Business Directory of Bosnia and Herzegovina; banner advertisement.",
            orderPackageThreeText:
              "Business Directory of Bosnia and Herzegovina digital edition.",
            orderNoteTitle: "Note:",
            orderNoteText:
              "The services of the package last for one year from the day of publishing. VAT is not included in the price. For other services and products, please contact our customer service. The ordered services and products from the mentioned packages will be realized and sent no later than five days from the payment date.",
            orderNoteSub:
              "Please note that the grammar and formatting have been adjusted for clarity and readability.",
            orderFormCompanyName: "Company name",
            orderFormTaxId: "TAX-ID",
            orderFormEmail: "E-mail",
            orderFormPhoneNumber: "Phone number",
            orderFormPlace: "Place",
            orderFormStreet: "Street",
            orderFormHomeNumber: "Street number",
            orderFormMessage: "Message",
          },
          buttons: {
            search: "Search",
            send: "Send",
            call: "Call",
            order: "Order Now",
            download: "Download",
            downloading: "Downloading...",
            orderOnline: "Order Online",
            orderByPhone: "Order by Phone",
            orderByEmail: "Order by Email",
            close: "Close",
            clearSearch: "Clear Search",
            login: "Log In",
            save: "Save",
            manageActivities: "Manage Activities",
            edit: "Edit",
            delete: "Delete",
            update: "Update",
            forgotPass: "Change password",
            reject: "Reject",
            accept: "Accept",
          },

          searchResultLayout: {
            cardPremium: "Premium",
            cardPlace: "Place:",
            cardStreet: "Street:",
            cardWeb: "Web:",
            cardPhone: "Phone:",
            cardEmail: "Е-mail:",
            cardAddress: "Address:",
            cardShowMore: "Show more...",
            saveActivity: "Save activity",
            cardShareTitle: "Copy URL from the website",
            cardShareMessage: "You have successfully copied the URL!",
            removeFavoriteTooltip: "Remove company from favorites.",
            addFavoriteTooltip: "Add company to favorites.",
            addPartnerTooltip: "Add the company to partners",
            addPotentialPartnerTooltip: "Add the company to potential partners",
            addClientTooltip: "Add the company to clients",
          },
          fullDetailsModal: {
            cardPremium: "Premium",
            cardPlace: "Place:",
            cardStreet: "Street:",
            cardNumber: "Number:",
            cardAddress: "Address:",
            cardWeb: "Web",
            cardPhone: "Phone:",
            cardEmail: "E-mail:",
            cardWorkingTimes: "Radno vrjeme:",
            cardWorkingTimesClosed: "Closed",
            cardWorkingTimesOpen: "Open 24h",
            cardPostCode: "Post code",
            cardShowWebsite: "Show website",
          },
          subheader: {
            callNow: "Call Now",
            order: "Order",
          },
          languageSwitcher: {
            serbianLatinLanguage: "Bosnia",
            englishLagnuage: "English",
            serbianCyrlLanguage: "Serbian (cyrillic)",
          },
          weekDays: {
            monday: "Monday",
            tuesday: "Tuesday",
            wednesday: "Wednesday",
            thursday: "Thursday",
            friday: "Friday",
            saturday: "Saturday",
            sunday: "Sunday",
            hours: "h",
          },
          notificationMessages: {
            searchErrorMessage:
              "You need to enter a minimum of 3 characters in the field to display the results!",
            fullDetailsModalError:
              "An error occurred while collecting data. Please try again!",
          },
          termsAndContitions: {
            accept: "I accept the terms and conditions.",
            description:
              'By clicking the "Send" button, I confirm that you may inform me about current offers in the future and that I have read and understood the privacy statement.',
            error: "Please accept the terms and conditions.",
          },
          promoteBusinessSection: {
            title: "Promote your business!",
            titlePremium: "Promote your business(premium)!",
            description: "Your business description!",
            companyTitle: "Your company title",
          },
          loginModal: {
            loginEmail: "Email",
            loginPassword: "Password",
            loginForgotPassword: "Forgot password?",
            loginErrorEmail: "Email is a required field",
            loginErrorPassword: "Password is a required field",
          },
          setPasswordModal: {
            setPasswordMinimumEightCharacter:
              "Password must be at least 8 characters long.",
            setPasswordCapitalLetter:
              "Password must contain at least one uppercase letter.",
            setPasswordNumber: "Password must contain at least one number.",
            setPasswordSpecialCharacter:
              "Password must contain at least one special character.",
            setPasswordConfirmPassword: "Passwords must match.",
          },
          forgotPasswordModal: {
            forgotPasswordTitle: "Forgot password?",
            inputCode: "Code",
            codeMessage: "Enter the code from the email",
            inputNewPassword: "New password",
            inputConfirmNewPassword: "Confirm password",
            forgotPasswordErrorMessage:
              "The entered data is incorrect. Please check and try again.",
            forgotPasswordSuccessMessage:
              "You have successfully changed your password!",
          },
          activitySelectionModal: {
            activitySelectionTitle: "Welcome",
            activitySelectionSubtitle: "To the portal poslovni imenik BiH",
            activitySelectionSubtitle2: "Categories you are interested in?",
            activitySelectionCategories: "Choose <b>3</b> industries",
            activitySelectionAutocompleteLabel: "Categories",
            activitySelectionSkip: "Skip",
          },
          settingsActivitiesSelectionModal: {
            title: "Categories",
            subtitle: "Manage categories and subcategories",
            activityTitle: "Your chosen categories:",
            subcategoriesTitle: "Your chosen subcategories:",
          },
          dashboardCard: {
            dashboardCardAssociateTitle: "Partners",
            dashboardCardAssociateShowMore: "View existing partners",
            dashboardCardPotentialAssociateTitle: "Potential partners",
            dashboardCardPotentialAssociateShowMore: "View potential partners",
            dashboardCardClientTitle: "Clients",
            dashboardCardClientShowMore: "View existing clients",
            dashboardCardFavoritesTitle: "Favorites",
            dashboardCardFavoriteshowMore: "View existing favorites",
            dashboardActivitiesCardTitle: "Your favorite activities",
            dashboardRatingCardTitle: "Your rating",
            dashboardRemove: "Remove",
            dashboardRemovePartner: "from partners.",
            dashboardRemovePotentialPartner: "from potential partners.",
            dashboardRemoveClient: "from clients.",
            dashboardRemoveFavorite: "from favorites.",
          },
          dashboardRatingCard: {
            ratingOne: "rating",
            ratingTwo: "ratings",
            ratingThree: "ratings",
            ratingContentFirstPart: "You have",
            ratingContentSecondPart: 'of your "My Site" page.',
          },
          partnersModal: {
            partnersModalTitle: "Partners",
          },
          potentialPartnersModal: {
            potentialPartnersModalTitle: "Potential partners",
          },
          clientsModal: {
            clientsModalTitle: "Clients",
          },
          favoritesModal: {
            favoritesModalTitle: "Favorites",
          },
          widgetTable: {
            widgetTableName: "Company Name",
            widgetTableAddress: "Address",
            widgetTableCity: "City",
            widgetTableCategory: "Category",
            widgetTableAction: "Action",
            widgetSuccessDeleteMessagePartners:
              "You have successfully deleted the company from partners!",
            widgetSuccessDeleteMessagePotentialPartners:
              "You have successfully deleted the company from potential partners!",
            widgetSuccessDeleteMessageClients:
              "You have successfully deleted the company from clients!",
            widgetSuccessDeleteMessageFavorites:
              "You have successfully deleted the company from favorites!",
          },
          rateUsModal: {
            rateUsModalTitle: "Rate Business Directory BiH",
            rateUsModalContent:
              "With a quick rating, you can help us improve our services. Please select the appropriate rating below. <br />Your opinion is important to us! <br /> Thank you for your support.",
            skip: "I don't want to rate now",
            commentPlaceholder: "Your comment/suggestion...",
          },
          overallRatingModal: {
            overallRatingTitle: "Rate",
            overallRatingShareOpinion: "Share your opinion about the",
            overallRatingImportant:
              "Your rating is important, thank you for taking the time!",
            overallRatingSuccessMessage: "You have successfully rated the",
          },
          dashbordRatingCard: {
            numberOfRatings: "Number of ratings",
          },
          mySite: {
            mySiteTitle: "My website",
            mySiteSubtitle: "Create my website",
            mySiteImgTitle: "Photo",
            mySiteTextTitle: "Text",
            mySitePhoneTitle: "Contact",
            mySiteSocialMediaTitle: "Social media",
            mySiteSocialMediaLink: "Paste link",
            mySiteSocialMediaSelect: "Select social media",
            mySiteVideoTitle: "Video",
            mySiteGalleryTitle: "Photo gallery",
            mySiteGalleryRemovePhotos: "Remove photos",
            mySiteGalleryAdd: "Add photos",
            mySiteGalleryFrom: "out of",
            mySiteGalleryPhotography: "photos",
            mySiteSliderTitle: "Slider",
            mySitePopoverPhoto: "Photo",
            mySitePopoverVideo: "Video",
            mySitePopoverText: "Text",
            mySitePopoverPhone: "Phone",
            mySitePopoverSocialMedia: "Social media",
            mySitePopoverGallery: "Gallery",
            mySitePopoverSlider: "Slider",
            mySiteSuccessMessageText: "Text successfully saved!",
            mySiteSuccessMessagePhone: "Phone successfully saved!",
            mySiteSuccessMessagePhoto: "Photo successfully saved!",
            mySiteSuccessMessageVideo: "Video successfully saved!",
            mySiteSuccessMessageSocialMedia: "Social media successfully saved!",
            mySiteSuccessMessageGallery: "Photo gallery successfully saved!",
            mySiteSuccessMessageSlider: "Slider successfully saved!",
            mySiteSuccessMessagePartition: "Partition successfully deleted!",
            mySiteUploadImgModalTitle: "Upload photo",
            mySiteUploadImgModalSubtitle:
              "Choose a photo to upload from your computer or device",
            mySiteUploadImgModalChoose: "Choose photo",
            mySiteSocialMediaName: "Name",
            mySiteSocialMediaUploadIcon: "Upload icon",
            mySiteMaxNumber: "Maximum number of photos 5",
            mySitePhoneTooltipAdd: "Add new phone number",
            mySitePhoteTooltipDelete: "Delete phone number",
            mySiteTooltip:
              "Click here to add a new social media platform that is not available among the provided options.",
            mySiteAddTooltip: `Select one of the offered options to create the "My Site" page!`,
            mySiteEditPhotoTooltip: "Edit photo section.",
            mySiteEditTextTooltip: "Edit text section.",
            mySiteEditPhoneTooltip: "Edit phone section.",
            mySiteEditSocialMediaTooltip: "Edit social media section.",
            mySiteEditVideoTooltip: "Edit video section.",
            mySiteEditGalleryTooltip: "Edit photo gallery section.",
            mySiteEditSliderTooltip: "Edit slider section.",
            mySiteRemovePhotoTooltip: "Remove photo section.",
            mySiteRemoveTextTooltip: "Remove text section.",
            mySiteRemovePhoneTooltip: "Remove phone section.",
            mySiteRemoveSocialMediaTooltip: "Remove social media section.",
            mySiteRemoveVideoTooltip: "Remove video section.",
            mySiteRemoveGalleryTooltip: "Remove photo gallery section.",
            mySiteRemoveSliderTooltip: "Remove slider section.",
            mySiteRemoveDefaultTooltip: "Remove section.",
            mySiteRemoveSocialMedia: "Remove social media.",
          },
          faqPage: {
            title: "Support",
            subtitle:
              "Answers to frequently asked questions about our company, services, and products",
            content:
              "Our FAQ page contains answers to frequently asked questions about our company, services, and products. Find all the necessary information about our business strategy, basic products and services, updating information, advantages of the electronic edition of the Business Directory of Bosnia and Herzegovina, and much more.",
            faq: "Frequently Asked Questions:",
            firstAccordionTitle:
              "What are the basic products and services of your company?",
            secondAccordionTitle:
              "How is information updated in the online Business Directory of Bosnia and Herzegovina?",
            thirdAccordionTitle:
              "What are the advantages of the electronic edition of the Business Directory of Bosnia and Herzegovina compared to the printed edition?",
            firstAccordionContent:
              "The main activity of our company is business publishing in the territory of Bosnia and Herzegovina. We have launched many high-circulation specialized business editions such as the Telephone - telefax directory of BiH, Telefonoteka, Poslovni imenik BiH, 300 most successful companies in BiH, Catalog of the Poslovni Imenik BiH, as well as corresponding electronic publications on CD, and accompanying internet editions for the mentioned titles.",
            secondAccordionContent:
              "All information we gather through field or telephone surveys, as well as information from other sources, is regularly updated in the database of the Poslovni imenik BiH. This means that the data about your company will be quickly available on the internet presentation of the Poslovni imenik BiH.",
            thirdAccordionContent:
              "The electronic edition of the Poslovni Imenik BiH offers many advantages, including the ability to search and sort content by various parameters, use of data for digital marketing, as well as regular updating of the database. It also enables easy communication and establishment of business contacts with existing legal entities in Bosnia and Herzegovina.",
            didntFindAnswer: "Did not find an answer?",
            askUs: "Ask us!",
            faqMessage:
              "You have successfully sent a message. Our team will contact you as soon as possible.",
          },
          changePassword: {
            title: "Password",
            subtitle: "Change Password",
            oldPassword: "Current Password",
            newPassword: "New Password",
            confirmPassword: "Confirm Password",
            successMessage: "You have successfully changed your password!",
            errorMessage:
              "The entered data is incorrect. Please check the information and try again!",
          },
          sidebar: {
            dashboard: "Dashboard",
            mySite: "My site",
            settings: "Settings",
            activity: "Categories",
            password: "Password",
            support: "Support",
            logout: "Logout",
            user: "Manage employees",
          },
          mySiteStatisticChart: {
            january: "January",
            february: "February",
            march: "March",
            april: "April",
            may: "May",
            june: "June",
            july: "July",
            august: "August",
            september: "September",
            october: "October",
            november: "November",
            december: "December",
            monday: "Monday",
            tuesday: "Tuesday",
            wednesday: "Wednesday",
            thursday: "Thursday",
            friday: "Friday",
            saturday: "Saturday",
            sunday: "Sunday",
            day: "Day",
            month: "Month",
            year: "Year",
            numberOfVisits: "Number of visits",
            chooseDayMonthYear: "Choose a day, month or year",
          },
          manageClientsTable: {
            firstName: "First name",
            lastName: "Last name",
            email: "E-mail",
            phone: "Phone",
            manageClient: "Manage employees",
            addClient: "Add employee",
            updateClient: "Update employee",
            tooltip:
              'As the owner, you can add employees who will have the ability to log into the system, create websites, and use all the functionalities provided by the application. Employees will not have access to the "Manage Employees" feature.',
            deleteTooltip: "Delete employee",
          },
        },
      },
      rs: {
        translation: {
          cookieConsent: {
            title: "Koristimo kolačiće",
            description:
              "Ovaj sajt koristi kolačiće za poboljšanje vašeg iskustva. Molimo vas da odaberete vaše postavke kolačića:",
            understand: "Razumijem",
            showDetails: "Prikaži detalje",
            modalTitle: "Informacije o kolačićima",
            modalDescFirst:
              "Koristimo kolačiće za prikupljanje informacija o vama. Koristimo ove informacije:",
            modalDescSecond: "da vam pružimo",
            modalDescThird: "bolje iskustvo",
            modalDescFourth: "naše web stranice (funkcionalno)",
            modalDescFifth: "za",
            modalDescSixth: "brojanje stranica",
            modalDescSeventh: "koje posjećujete (statistika)",
            modalDescEighth: "da vam pružimo",
            modalDescNinth: "relevantne promocije",
            modalDescTenth: "(marketing)",
            modalDesc:
              "Kliknite “OK” da nam date svoju saglasnost za korištenje kolačića u sve ove svrhe. Također možete koristiti polja za potvrdu da date saglasnost za određene svrhe. Odaberite svrhe i “Sačuvajte postavke”. Povucite ili promijenite svoju saglasnost u bilo kojem trenutku klikom na ikonu u donjem lijevom uglu ekrana. Promijenite svoje postavke.",
            switchNecessary: "Neophondi",
            switchFunctional: "Funkcionalni",
            switchStatistical: "Statistički",
            swtichMarketing: "Marketing",
            saveSettings: "Sačuvaj postavke",
          },
          validation: {
            validationCompanyName: "Polje za naziv kompanije je obavezno",
            validationTaxId: "Polje za PIB je obavezno",
            validationEmail: "Polje za e-mail je obavezno",
            validationPhoneNumber: "Polje za broj telefona je obavezno",
            validationMessage: "Polje za poruku je obavezno",
            validationPackage: "Izaberite paket",
            validationFirstName: "Polje za ime je obavezno",
            validationLastName: "Polje za prezime je obavezno",
          },
          messageKey: {
            orderRequest_Pending: "Slanje zahtjeva za porudžbinu...",
            orderRequest_Success: "Zahtjev za porudžbinu je uspješno poslan!",
            orderRequest_Error:
              "Nažalost, slanje zahtjeva nije uspjelo. Molimo provjerite vašu internet vezu i pokušajte ponovo.",

            advertisementRequest_Pending: "Slanje zahtjeva za oglašavanje...",
            advertisementRequest_Success:
              "Zahtjev za oglašavanje je uspješno poslan!",
            advertisementRequest_Error:
              "Nažalost, slanje zahtjeva nije uspjelo. Molimo provjerite vašu internet vezu i pokušajte ponovo.",

            updateDataRequest_Pending: "Slanje zahtjeva za ažuriranje...",
            updateDataRequest_Success:
              "Zahtjev za ažuriranje je uspješno poslan!",
            updateDataRequest_Error:
              "Nažalost, slanje zahtjeva nije uspjelo. Molimo provjerite vašu internet vezu i pokušajte ponovo.",

            contactRequest_Pending: "Vaša poruka se šalje... ",
            contactRequest_Success:
              "Vaš zahtjev je uspješno poslan! Hvala vam što ste nas kontaktirali. Potrudit ćemo se da vam odgovorimo u najkraćem mogućem roku.",
            contactRequest_Error:
              "Nažalost, slanje poruke nije uspjelo. Molimo provjerite vašu internet vezu i pokušajte ponovo.",
            Login_Pending: "Prijavljivanje u toku...",
            GetByEmail_AccountByEmailNotFound:
              "Nalog sa ovom e-mail adresom ne postoji.",
            Login_AccountByEmailNotFound:
              "Nalog sa ovom e-mail adresom ne postoji.",
            Login_TooManyAttempts:
              "Previše neuspjelih pokušaja prijavljivanja. Kontaktirajte administratora.",
            Login_NeedToChangePassword: "Potrebno je da resetujete lozinku.",
            Login_WrongPassword: "Pogrešna lozinka.",
            Login_AccountInactive:
              "Nalog nije aktivan. Kontaktirajte administratora.",
            Login_NeedResetPasswordOnFirstLogin:
              "Potrebno je da promijenite lozinku.",
            Login_Success: "Prijavili ste se.",

            Logout_Success: "Odjavili ste se.",
            RequestForgotPassword_EmailSent:
              "Vaš zahtjev za promjenu lozinke je uspješno poslan. Na vašu e-mail adresu stigao je kod za resetovanje lozinke. Unesite kod da biste nastavili sa procesom promjene lozinke.",
            RequestForgotPassword_AccountByEmailNotFound:
              "Nije pronađen nalog sa unešenom email adresom.",
            ForgotPassword_Pending:
              "Obrada zahtjeva za resetovanje lozinke u toku...",
            ClientAccount_ForgotPasswordCodeNotValid:
              "Kod za resetovanje lozinke nije validan. Molimo pokušajte ponovo.",
            ForgotPassword_Success: "Uspješno ste resetovali lozinku.",
            ResetPassword_AccountByIdNotFound:
              "Nalog nije pronađen sa datim ID-om.",
            ResetPassword_AccountByEmailNotFound:
              "Nalog nije pronađen sa datom adresom e-pošte.",
            ResetPassword_IncorrectOldPassword: "Pogrešna stara lozinka.",
            ResetPassword_Success: "Lozinka uspješno resetovana.",
            SendContactRequest_Success:
              "Uspješno ste poslali poruku. Naš tim će Vas kontaktirati u što kraćem vrjemenskom periodu.",
            SendContactRequest_Pending:
              "Vaša poruka se šalje. Molimo sačekajte...",
            CreateAccount_AccountWithThisEmailAlreadyExists:
              "Nalog s ovom e-poštom već postoji.",
            CreateAccount_Pending: "Kreiranje naloga...",
            CreateAccount_Success: "Nalog uspješno kreiran!",
            DeleteAccount_Success: "Nalog uspješno obrisan!",
            DeleteClient_Pending: "Brisanje naloga...",
            UpdateAccount_Success: "Nalog uspješno ažuriran!",
            ActivitySelection_Success: "Uspješno ste sačuvali djelatnosti!",
            MySiteText_Pending: "Čuvanje teksta u toku...",
            MySiteSuccessMessageText_Success: "Tekst je uspješno sačuvan!",
            MySiteErrorMessageText_Error:
              "Greška pri čuvanju teksta. Molimo pokušajte ponovo.",

            MySitePhone_Pending: "Čuvanje telefona u toku...",
            MySiteSuccessMessagePhone_Success: "Telefon je uspješno sačuvan!",
            MySiteErrorMessagePhone_Error:
              "Greška pri čuvanju telefona. Molimo pokušajte ponovo.",

            MySitePhoto_Pending: "Čuvanje fotografije u toku...",
            MySiteSuccessMessagePhoto_Success:
              "Fotografija je uspješno sačuvana!",
            MySiteErrorMessagePhoto_Error:
              "Greška pri čuvanju fotografije. Molimo pokušajte ponovo.",

            MySiteVideo_Pending: "Čuvanje videa u toku...",
            MySiteSuccessMessageVideo_Success: "Video je uspješno sačuvan!",
            MySiteErrorMessageVideo_Error:
              "Greška pri čuvanju videa. Molimo pokušajte ponovo.",

            MySiteSocialMedia_Pending: "Čuvanje društvene mreže u toku...",
            MySiteSuccessMessageSocialMedia_Success:
              "Društvena mreža je uspješno sačuvana!",
            MySiteErrorMessageSocialMedia_Error:
              "Greška pri čuvanju društvene mreže. Molimo pokušajte ponovo.",

            MySiteGallery_Pending: "Čuvanje galerije fotografija u toku...",
            MySiteSuccessMessageGallery_Success:
              "Galerija fotografija je uspješno sačuvana!",
            MySiteErrorMessageGallery_Error:
              "Greška pri čuvanju galerije fotografija. Molimo pokušajte ponovo.",

            MySiteSlider_Pending: "Čuvanje slajdera u toku...",
            MySiteSuccessMessageSlider_Success: "Slajder je uspješno sačuvan!",
            MySiteErrorMessageSlider_Error:
              "Greška pri čuvanju slajdera. Molimo pokušajte ponovo.",

            MySitePartition_Pending: "Brisanje particije u toku...",

            MySiteSuccessMessagePartition_Success:
              "Particija je uspješno obrisana!",
            MySiteErrorMessagePartition_Error:
              "Greška pri brisanju particije. Molimo pokušajte ponovo.",

            WidgetDeleteMessagePartners_Pending:
              "Brisanje kompanije iz partnera u toku...",
            WidgetDeleteMessagePartners_Success:
              "Uspješno ste obrisali kompaniju iz partnera!",
            WidgetDeleteMessagePartners_Error:
              "Greška pri brisanju kompanije iz partnera. Molimo pokušajte ponovo.",

            WidgetDeleteMessagePotentialPartners_Pending:
              "Brisanje kompanije iz potencijalnih partnera u toku...",
            WidgetDeleteMessagePotentialPartners_Success:
              "Uspješno ste obrisali kompaniju iz potencijalnih partnera!",
            WidgetDeleteMessagePotentialPartners_Error:
              "Greška pri brisanju kompanije iz potencijalnih partnera. Molimo pokušajte ponovo.",

            WidgetDeleteMessageClients_Pending:
              "Brisanje kompanije iz klijenata u toku...",
            WidgetDeleteMessageClients_Success:
              "Uspješno ste obrisali kompaniju iz klijenata!",
            WidgetDeleteMessageClients_Error:
              "Greška pri brisanju kompanije iz klijenata. Molimo pokušajte ponovo.",

            WidgetDeleteMessageFavorites_Pending:
              "Brisanje kompanije iz favorita u toku...",
            WidgetDeleteMessageFavorites_Success:
              "Uspješno ste obrisali kompaniju iz favorita!",
            WidgetDeleteMessageFavorites_Error:
              "Greška pri brisanju kompanije iz favorita. Molimo pokušajte ponovo.",
            Logout_ExpiredToken: "Vaša sesija je istekla.",
            NetworkError: "Greška pri povjezivanju sa serverom.",
            InternalServerError: "Greška pri povjezivanju sa serverom.",
            EditionsSectionCard_PdfDownload_Success: "Uspješno ste preuzeli katalog_2024.pdf.",
            EditionsSectionCard_PdfDownload_Error: "Greška pri preuzimanju PDF-a: Nije moguće preuzeti katalog. Molimo pokušajte ponovo."
          },
          header: {
            homePage: "Početna",
            services: "Usluge",
            updateData: "Ažurirajte podatke",
            advertisement: "Oglašavanje",
            digitalMarketing: "Digitalni marketing",
            editions: "Izdanja",
            aboutUs: "O nama",
            contact: "Kontakt",
            companyNameInput: "Unesite naziv firme, uslugu/proizvod, mjesto...",
            advanceSearchLabel: "Napredno pretraživanje Poslovnog imenika BiH",
            categoryInput: "Djelatnost",
            subCategoryInput: "Poddjelatnost",
            place: "Mjesto",
            telekomBl: "Telekom-BL",
          },
          footer: {
            footerDescription:
              "Osnovna djelatnost naše firme je poslovno izdavaštvo na teritoriji Bosne i Hercegovine. Naša redakcija je pokrenula mnoga visokotiražna specijalizovana poslovna izdanja kao što su: Telefonski - telefax imenik BiH, Telefonoteka, Poslovni imenik BiH, 300 najuspješnijih firmi u BiH, Katalog Poslovnog imenika BiH, kao i odgovarajuće elektronske publikacije na CD-u, te prateća internet izdanja za navedene naslove.",
            footerFastLinksTitle: "Brzi linkovi",
            footerFastLinkHome: "Početna",
            footerFastLinkServices: "Usluge",
            footerFastLinkUpdatingData: "-Ažurirajte podatke",
            footerFastLinkAdvertisement: "-Oglašavanje",
            footerFastLinkDigitalMarketing: "-Digitalni marketing",
            footerFastLinkEditions: "Izdanja",
            footerFastLinkAbout: "O nama",
            footerFastLinkContact: "Kontakt",
          },
          homePage: {
            resultsPerPage: "Rezultata po stranici",
          },
          updatingDataPage: {
            updatingDataTitle: "Ažurirajte podatke",
            updatingDataText:
              "Upis novih podataka podrazumijeva ažuriranje postojećih, unos novih i brisanje starih nevažećih podataka u bazi Poslovnog imenika BiH. Ovu aktivnost sprovodi izdavač na osnovu informacija pribavljenih terenskim radom, telefonskim anketama, kao i podatke pribavljene preko online forme na ovom vebsajtu.",
            updatingDataFormCompanyName: "Naziv firme",
            updatingDataFormPIB: "PIB",
            updatingDataFormEmail: "E-mail",
            updatingDataFormPhoneNumber: "Broj telefona",
            updatingDataFormPlace: "Mjesto",
            updatingDataFormStreet: "Ulica",
            updatingDataFormHomeNumber: "Broj",
          },
          advertisementPage: {
            advertisementTitle: "Oglašavanje",
            advertisementText:
              "Obezbjeđujemo razne vrste oglašavanja i kreiramo set usluga prema potrebama naših klijenata. Za kompletan asortiman naših usluga i personalizovanu izradu seta usluga za oglašavanje neophodno je da stupite u kontantakt s našim agentima prodaje, a preko sledeće kontakt forme možete da naručite neke od naših najpopularnijih paketa usluga.",
            advertisementFormCompanyName: "Naziv firme",
            advertisementFormPIB: "PIB",
            advertisementFormEmail: "E-mail",
            advertisementFormPhoneNumber: "Broj telefona",
            advertisementFormPlace: "Mjesto",
            advertisementFormStreet: "Ulica",
            advertisementFormHomeNumber: "Broj",
          },
          digitalMarketingPage: {
            digitalMarketingTitle: "Digitalni marketing",
            digitalMarketingText:
              "Obezbjeđujemo sve što je neophodno privrednim subjektima za poslovno informisanje, oglašavanje i digitalnu transformaciju. Posredstvom inteligentnih softverskih tehnologija pružamo kvalitetna, efikasna i pristupačna rješenja za unapređivanje digitalnog poslovanja i porast poslovnog uspjeha.",
            digitalMarketingTextSecond:
              "Analiziramo Vaše poslovne procese i informacione sisteme, te predlažemo optimalna rješenja za digitalnu transformaciju, integraciju, automatizaciju i unapređivanje svih segmenata poslovanja. Naša rješenja su jedinstvena, dinamična i skalabilna, te potpuno prilagođena Vašim potrebama i ciljevima. Sve naše usluge prate najnovije tehnološke trendove i primjenjuju se u skladu s najboljim praksama. Bez obzira da li se radi o novom projektu, proširenju postojećeg poslovanja ili povećanju vidljivosti Vaše online prisutnosti, mi smo tu da Vam pomognemo.",
            digitalMarketingTextThird:
              "Kontaktirajte nas i zatražite besplatnu procjenu mogućnosti Vašeg digitalnog poslovnog unaprijeđivanja, a mi ćemo Vam dati prijedloge za digitalni poslovni razvoj prilagođen Vašim potrebama i ciljevima.",
          },
          editionsPage: {
            catalogTitle: "Poslovni imenik Bosne i Hercegovine - Katalog",
            catalogTextFirst:
              "Poslovni imenik Bosne i Hercegovine (štampano izdanje) je do 2016. god. bio najčitanija i najprodavanija publikacija poslovnih informacija na području Bosne i Hercegovine. Duga tradicija našeg poslovanja poznata je svim firmama, organizacijama i institucijama koje su posredno ili neposredno koristili naša godišnja izdanja.",
            catalogText:
              "Poslovni imenik Bosne i Hercegovine (štampano izdanje) je do 2016. god. bio najčitanija i najprodavanija publikacija poslovnih informacija na području Bosne i Hercegovine. Duga tradicija našeg poslovanja poznata je svim firmama, organizacijama i institucijama koje su posredno ili neposredno koristili naša godišnja izdanja.",
            catalogTextSecond:
              "Savremena elektronska i tehnološka dostignuća upravljala su promjenama u mnogim poslovnim sistemima, što je slučaj i kod Poslovnog imenika Bosne i Hercegovine. Do sada poznati Poslovni imenik BiH (knjižno izdanje) koji se od 2016. god. više ne štampa, redizajniran je i modernizovan u novu publikaciju Poslovni imenik BiH – Katalog.",
            catalogTextThird:
              "Osnovu Poslovnog imenika BiH – Kataloga čine firme, organizacije i institucije sa teritorije Bosne i Hercegovine, koje se ovim putem kolorno i tekstualno oglašavaju. Kao i za sva ostala izdanja Poslovnog imenika Bosne i Hercegovine – Katalog i za ovo slijedi raniji ustanovljeni koncept direktnog marketinga, gdje se u prvi plan stavljaju informacije o aktivnim pravnim subjektima iz Bosne i Hercegovine. U Katalogu svi oglasi pravnih subjekata sortirani su po srodnim djelatnostima, a oglašivači sa logotipima u nastavku oglasa iste djelatnosti, sortirani su po abecedi. Više detalja o firmama i institucijama možete pogledati u Katalogu (PDF formatu), a cijelu bazu podataka Poslovnog imenika BiH potražite na sajtu www.poslovniimenik.net.",
            cdTitle: "Poslovni imenik BiH - elektronsko izdanje",
            cdText:
              "U odnosu na online, a posebno na štampano izdanje, Poslovni imenik Bosne i Hercegovine - elektronsko izdanje, nudi mnoge prednosti. One su naročito izražene kod pretraživanja i sortiranja sadržaja jer je omogućena pretraga po svim parametrima pojedinačno, kombinovane pretrage na dva ili više načina te snimanje i čuvanje pretraga.",
            cdTextSecond:
              "Druga značajna prednost je mogućnost korišćenja podataka za digitalni marketing, bilo da su u pitanju društvene mreže ili direktni marketing. Baza podataka elektronskog Poslovnog imenika BiH se osvježava svakodnevno i dopunjava novim informacijama vezanim za postojeće i nove firme ili institucije.",
            cdTextThird:
              "Za svaku firmu ili instituciju u bazi postoje osnovni podaci: - Naziv firme ili institucije; - Podaci o djelatnosti firme ili institucije; - Adresa firme ili institucije: ulica, broj, mjesto, poštanski broj, entitet i tel. i pozivni broj; - Brojevi telefona, telefaxa, e-mail adrese; - Web adrese firme ili institucije.",
            cdTextFourth:
              "Kupovinom elektronskog izdanja Poslovnog imenika BiH i unosom Vaše firme u bazu, ostvarujete pravo na upis i objavu podataka u izdanjima Poslovnog imenika Bosne i Hercegovine (internet i elektronsko izdanje), kao i pravo na besplatno redovno ažuriranje, izmjene ili dopune Vaših podataka preko interneta u trajanju jednogodišnje pretplate.",
            cdTextFifth:
              "Vjerujemo da će Vam ovo elektronsko izdanje Poslovnog imenika BiH omogućiti da sve podatke neophodne za svakodnevnu komunikaciju lako pronalazite i na taj način stupite u poslovni kontakt postojećih pravnih subjekata u Bosni i Hercegovini.",
            onlineTitle: "Online Poslovni imenik Bosne i Hercegovine",
            onlineText:
              "Od svih naših poslovnih izdanja najvažnije mjesto zauzima internet prezentacija Poslovnog imenika Bosne i Hercegovine. Elektronska prezentacija predstavlja ogledalo Vaše firme i veoma je pristupačan, povoljan i moderan oblik reklamiranja koji može mnogo da doprinese Vašem uspješnijem poslovanju.",
            onlineTextSecond:
              "Online izdanje Poslovnog imenika BiH je koncipirano kao centar raznovrsnih poslovnih djelatnosti. Sadržaj internet prezentacije www.poslovniimenik.net dostupan je svim korisnicima internet usluge na pregled, te je omogućena brza pretraga podataka posredstvom većeg broja različitih parametara. Ogroman broj ljudi i poslovnih korisnika svakodnevno posjećuje našu internet prezentaciju, što je od velike koristi kako našim posjetiocima tako i  korisnicima  naših usluga.",
            onlineTextThird:
              "Prezentovanjem Vaše firme, institucije ili organizacije na našoj internet prezentaciji pridružujete se svim oglašivačima koji su već putem oglasa istakli svoje podatke i na taj način omogućili stotinama hiljada zainteresovanih posjetilaca da imaju detaljan pregled Vaše firme i Vaše djelatnosti.",
            onlineTextFourth:
              "Online izdanje Poslovnog imenika BiH u paketu sa štampanim i elektronskim izdanjem ima zadatak da osnovne poslovne podatke prenese do svih kategorija korisnika i da svojom dinamikom utiče na protok informacija.",
            onlineTextFifth:
              "Sve informacije koje izdavač prikupi terenskim ili telefonskim anketiranjem kao i informacije iz drugih izvora redovno se ažuriraju u bazi podataka Poslovnog imenika BiH što podrazumijeva da će podaci o Vašoj firmi biti vrlo brzo i na internet prezentaciji Poslovnog imenika BiH.",
            tooltip: "Klikom na sliku otvoriće se prikaz online kataloga.",
            backIcon: 'Vrati se na stranicu "Usluge".'
          },
          aboutUsPage: {
            aboutTitle: "O nama",
            aboutText:
              "Osnovna djelatnost naše firme je poslovno izdavaštvo na teritoriji Bosne i Hercegovine. Naša redakcija je pokrenula mnoga visokotiražna specijalizovana poslovna izdanja kao što su: Telefonski - telefax imenik BiH, Telefonoteka, Poslovni imenik BiH, 300 najuspješnijih firmi u BiH, Katalog Poslovnog imenika BiH, kao i odgovarajuće elektronske publikacije na CD-u, te prateća internet izdanja za navedene naslove.",
            aboutTextSecond:
              "Poslovna strategija koju koristimo je direktni marketing i direktna prodaja. Posredstvom tih poslovnih strategija izdavač Poslovnog imenika Bosne i Hercegovine potpuno samostalno organizuje svoje komercijalne i marketinške aktivnosti, kao i pripremu, te uređivanje i publikovanje svih svojih poslovnih izdanja.",
            aboutTextThird:
              "Navedeni poslovni koncept formirao je i našu misiju kao aktivno i permanentno ažuriranje baze podataka privrednih subjekata sa cjelokupne teritorije Bosne i Hercegovine, čineći sve te poslovne podatke objedinjene na jednom mjestu, te brzo i lako dostupne svim poslovnim subjektima kao i civilnim licima u BiH, regionu i šire.",
            aboutTextFourth:
              "Naša vizija je da zauvijek očuvamo renomirano mjesto u poslovnom izdavaštvu na teritoriji Bosne i Hercegovine, prepoznatljivo prvenstveno po kvalitetu, rokovima i cijenama usluga. Da bi to kontinuelno postizali, orijentisali smo se da poslujemo u korak s razvojem savremenih tehnologija kako bi iz godine u godinu postizali rast kvaliteta naših poslovnih izdanja, te iz dana u dan širimo mrežu naših korisnika i oglašivača. Prema zahtjevima tržišta spremni smo pokretati nova poslovna izdanja kao što su specijalizove brošure, posebni stručni dodaci, te periodični i mjesečni bilteni i časopisi.",
            aboutLandmark: "Telekom – BL, Banja Luka",
          },
          contactUsPage: {
            contactTitle: "Kontaktirajte nas",
            contactFormCompanyName: "Naziv firme",
            contactFormContactPerson: "Kontakt osoba",
            contactFormEmail: "E-mail",
            contactFormMessage: "Poruka",
            contactSuccessMessage:
              "Vaša poruka je uspješno poslata. Odgovorićemo Vam u najkraćem mogućem roku.",
            contactSuccessTitle: "Hvala!",
          },
          orderRequestPage: {
            orderTitle: "Zahtjev za naručivanje",
            orderPackageOne: "Paket 1",
            orderPackageTwo: "Paket 2",
            orderPackageThree: "Paket 3",
            orderPackageOneText:
              "Ažuriranje i objavljivanje informacija o klijentu u Poslovnom imeniku Bosne i Hercegovine (elektronsko izdanje); Oglas na web stranici poslovniimenik.net (logo, informacije i link); Objavljivanje istog oglasa u Katalogu Poslovnog imenika Bosne i Hercegovine.",
            orderPackageTwoText:
              "Ažuriranje i objavljivanje informacija o kupcu u Poslovnom imeniku Bosne i Hercegovine (elektronsko izdanje); Oglas na web stranici poslovniimenik.net (logo, informacije i link); Objavljivanje istog oglasa u Katalogu Poslovnog imenika Bosne i Hercegovine; Oglas na baneru.",
            orderPackageThreeText: "Poslovni imenik BiH digitalno izdanje.",
            orderNoteTitle: "Napomena:",
            orderNoteText:
              "Usluge paketa traju jednu godinu od dana objavljivanja. U cijenu nije uračunat PDV. Za ostale naše usluge i proizvode kontaktirajte našu službu za korisnike. Naručene usluge i proizvodi iz navedenih paketa realizuju se i šalju najkasnije pet dana od dana uplate.",
            orderNoteSub:
              "Molim vas imajte na umu da su gramatika i formatiranje prilagođeni radi jasnoće i čitljivosti.",
            orderFormCompanyName: "Naziv firme",
            orderFormTaxId: "PIB",
            orderFormEmail: "E-mail",
            orderFormPhoneNumber: "Broj telefona",
            orderFormPlace: "Mjesto",
            orderFormStreet: "Ulica",
            orderFormHomeNumber: "Broj",
            orderFormMessage: "Poruka",
          },
          buttons: {
            search: "Pretraga",
            send: "Pošaljite",
            call: "Pozovite",
            order: "Naručite",
            download: "Preuzmite",
            downloading: "Preuzimam...",
            orderOnline: "Naručite online",
            orderByPhone: "Naručite telefonom",
            orderByEmail: "Naručite preko e-maila",
            close: "Zatvori",
            clearSearch: "Očisti pretragu",
            login: "Prijavi se",
            save: "Sačuvaj",
            manageActivities: "Upravljaj djelatnostima",
            edit: "Izmeni",
            delete: "Obriši",
            update: "Ažuriraj",
            forgotPass: "Promeni lozinku",
            reject: "Otkaži",
            accept: "Prihvati",
          },
          searchResultLayout: {
            cardPremium: "Premium",
            cardPlace: "Grad-mjesto:",
            cardStreet: "Ulica:",
            cardWeb: "Web",
            cardPhone: "Telefon:",
            cardEmail: "E-mail:",
            cardAddress: "Adresa:",
            cardShowMore: "Prikaži više...",
            saveActivity: "Sačuvaj djelatnost",
            cardShareTitle: "Kopiraj URL od web-sajta",
            cardShareMessage: "Uspješno ste iskopirali URL!",
            removeFavoriteTooltip: "Ukloni kompaniju iz favorita.",
            addFavoriteTooltip: "Dodajte kompaniju u favorite.",
            addPartnerTooltip: "Dodajte kompaniju u saradnike.",
            addPotentialPartnerTooltip:
              "Dodaj kompaniju u potencijalne saradnike",
            addClientTooltip: "Dodaj kompaniju u klijente",
          },
          fullDetailsModal: {
            cardPremium: "Premium",
            cardPlace: "Grad-mjesto:",
            cardStreet: "Ulica:",
            cardNumber: "Broj:",
            cardAddress: "Adresa:",
            cardWeb: "Web",
            cardPhone: "Telefon:",
            cardEmail: "E-mail:",
            cardWorkingTimes: "Radno vrjeme:",
            cardWorkingTimesClosed: "Zatvoreno",
            cardWorkingTimesOpen: "Otvoreno 24h",
            cardPostCode: "Poštanski broj:",
            cardShowWebsite: "Prikaži vebsajt",
          },
          subheader: {
            callNow: "Pozovite",
            order: "Naručite",
          },
          languageSwitcher: {
            serbianLatinLanguage: "Bosanski",
            englishLagnuage: "Engleski",
            serbianCyrlLanguage: "Srpski (ćirilica)",
          },
          weekDays: {
            monday: "Ponedeljak",
            tuesday: "Utorak",
            wednesday: "Sreda",
            thursday: "Četvrtak",
            friday: "Petak",
            saturday: "Subota",
            sunday: "Nedelja",
            hours: "čas",
          },
          notificationMessages: {
            searchErrorMessage:
              "Potrebno je uneti minimalno 3 karaktera u polje da bi se prikazali rezultati!",
            fullDetailsModalError:
              "Došlo je do greške prilikom prikupljanja podataka. Molimo Vas pokušajte ponovo!",
          },
          termsAndContitions: {
            accept: "Prihvatam uslove",
            description:
              'Klikom na dugme "Pošaljite" potvrđujem da me ubuduće obavještavate o trenutnim ponudama i potvrđujem da sam pročitao/la i razumio/la izjavu o privatnosti.',
            error: "Molimo Vas da prihvatite uslove i odredbe.",
          },
          promoteBusinessSection: {
            title: "Promovišite svoj posao!",
            titlePremium: "Promovišite svoj posao (premium)!",
            description: "Opis vašeg posla!",
            companyTitle: "Naziv vaše kompanije",
          },
          loginModal: {
            loginEmail: "Email",
            loginPassword: "Lozinka",
            loginForgotPassword: "Zaboravili ste lozinku?",
            loginErrorEmail: "E-mail je obavezno polje",
            loginErrorPassword: "Lozinka je obavezno polje",
          },
          setPasswordModal: {
            setPasswordMinimumEightCharacter:
              "Lozinka mora imati minimum 8 karaktera.",
            setPasswordCapitalLetter:
              "Lozinka mora imati barem jedno veliko slovo.",
            setPasswordNumber: "Lozinka mora imati barem jedan broj.",
            setPasswordSpecialCharacter:
              "Lozinka mora imati barem jedan specijalan karakter.",
            setPasswordConfirmPassword: "Lozinke se moraju poklapati.",
          },
          forgotPasswordModal: {
            forgotPasswordTitle: "Zaboravljena lozinka?",
            inputCode: "Kod",
            codeMessage: "Unesite kod iz e-pošte",
            inputNewPassword: "Nova lozinka",
            inputConfirmNewPassword: "Ponovite novu lozinku",
            forgotPasswordErrorMessage:
              "Unešeni podaci nisu ispravni. Provjerite i pokušajte ponovo.",
            forgotPasswordSuccessMessage: "Uspiješno ste promenili lozinku!",
          },
          activitySelectionModal: {
            activitySelectionTitle: "Dobrodošli",
            activitySelectionSubtitle: "Na portal Poslovni imenik BiH",
            activitySelectionSubtitle2: "Dijelatnosti koje vas zanimaju?",
            activitySelectionCategories: "Izaberite <b>3</b> industrije",
            activitySelectionAutocompleteLabel: "Kategorije",
            activitySelectionSkip: "Preskoči",
          },
          settingsActivitiesSelectionModal: {
            title: "Djelatnosti",
            subtitle: "Upravljaj djelatnostima i poddjelatnostima",
            activityTitle: "Vaše izabrane dijelatnosti:",
            subcategoriesTitle: "Vaše izabrane poddjelatnosti:",
          },
          dashboardCard: {
            dashboardCardAssociateTitle: "Saradnici",
            dashboardCardAssociateShowMore: "Pogledaj postojeće saradnike",
            dashboardCardPotentialAssociateTitle: "Potencijalni saradnici",
            dashboardCardPotentialAssociateShowMore:
              "Pogledaj potencijalne saradnike",
            dashboardCardClientTitle: "Klijenti",
            dashboardCardClientShowMore: "Pogledaj postojeće klijente",
            dashboardCardFavoritesTitle: "Favoriti",
            dashboardCardFavoriteshowMore: "Pogledaj postojeće favorite",
            dashboardActivitiesCardTitle: "Vaše omiljene djelatnosti",
            dashboardRatingCardTitle: "Vaš rejting",
            dashboardRemove: "Ukloni",
            dashboardRemovePartner: "iz partnera.",
            dashboardRemovePotentialPartner: "iz potencijalnih partnera.",
            dashboardRemoveClient: "iz klijenata.",
            dashboardRemoveFavorite: "iz favorita.",
          },
          dashboardRatingCard: {
            ratingOne: "ocjenu",
            ratingTwo: "ocjene",
            ratingThree: "ocjena",
            ratingContentFirstPart: "Imate",
            ratingContentSecondPart: 'vaše stranice "Moj Sajt".',
          },
          partnersModal: {
            partnersModalTitle: "Saradnici",
          },
          potentialPartnersModal: {
            potentialPartnersModalTitle: "Potencijalni saradnici",
          },
          clientsModal: {
            clientsModalTitle: "Klijenti",
          },
          favoritesModal: {
            favoritesModalTitle: "Favoriti",
          },
          widgetTable: {
            widgetTableName: "Naziv firme",
            widgetTableAddress: "Adresa",
            widgetTableCity: "Grad",
            widgetTableCategory: "Djelatnost",
            widgetTableAction: "Akcija",
            widgetSuccessDeleteMessagePartners:
              "Uspješno ste obrisali kompaniju iz partnera!",
            widgetSuccessDeleteMessagePotentialPartners:
              "Uspješno ste obrisali kompaniju iz potencijalnih partnera!",
            widgetSuccessDeleteMessageClients:
              "Uspješno ste obrisali kompaniju iz klijenata!",
            widgetSuccessDeleteMessageFavorites:
              "Uspješno ste obrisali kompaniju iz favorita!",
          },
          rateUsModal: {
            rateUsModalTitle: "Ocjenite Poslovni imenik BiH",
            rateUsModalContent:
              "Pomoću kratke ocjene možete nam pomoći da unaprijedimo naše usluge. Molimo vas da izaberete odgovarajuću ocjenu ispod. <br />Vaše mišljenje nam je važno! <br /> Hvala vam na podršci.",
            skip: "Ne želim ocjenivati sada",
            commentPlaceholder: "Vaš komentar/sugestija...",
          },
          overallRatingModal: {
            overallRatingTitle: "Ocijenite",
            overallRatingShareOpinion: "Podijelite vaše mišljenje o",
            overallRatingImportant:
              "Vaša ocjena je važna, hvala vam na izdvojenom vremenu!",
            overallRatingSuccessMessage: "Uspješno ste ocijenili",
          },
          dashbordRatingCard: {
            numberOfRatings: "Broj ocjena",
          },
          mySite: {
            mySiteTitle: "Moj sajt",
            mySiteSubtitle: "Kreiraj moj sajt",
            mySiteImgTitle: "Fotografija",
            mySiteTextTitle: "Tekst",
            mySitePhoneTitle: "Kontakt",
            mySiteSocialMediaTitle: "Društvene mreže",
            mySiteSocialMediaLink: "Nalepite link",
            mySiteSocialMediaSelect: "Izaberite društvenu mrežu",
            mySiteVideoTitle: "Video",
            mySiteGalleryTitle: "Galerija fotografija",
            mySiteGalleryRemovePhotos: "Uklonite fotografije",
            mySiteGalleryAdd: "Dodajte fotografije",
            mySiteGalleryFrom: "od",
            mySiteGalleryPhotography: "fotografija",
            mySiteSliderTitle: "Slajder",
            mySitePopoverPhoto: "Fotografija",
            mySitePopoverVideo: "Video zapis",
            mySitePopoverText: "Tekst",
            mySitePopoverPhone: "Telefon",
            mySitePopoverSocialMedia: "Drustvene mreže",
            mySitePopoverGallery: "Galerija",
            mySitePopoverSlider: "Slajder",
            mySiteSuccessMessageText: "Uspješno ste sačuvali tekst!",
            mySiteSuccessMessagePhone: "Uspješno ste sačuvali telefon!",
            mySiteSuccessMessagePhoto: "Uspješno ste sačuvali fotografiju!",
            mySiteSuccessMessageVideo: "Uspješno ste sačuvali video!",
            mySiteErrorMessageText:
              "Došlo je do greške prilikom čuvanja teksta. Proverite podatke i pokušajte ponovo!",
            mySiteErrorMessagePhone: "Uspješno ste sačuvali telefon!",
            mySiteErrorMessagePhoto: "Uspješno ste sačuvali fotografiju!",
            mySiteErrorMessageVideo: "Uspješno ste sačuvali video!",
            mySiteSuccessMessageSocialMedia:
              "Uspješno ste sačuvali društvene mreže!",
            mySiteSuccessMessageGallery:
              "Uspješno ste sačuvali galeriju fotografija!",
            mySiteSuccessMessageSlider: "Uspješno ste sačuvali slajder!",
            mySiteSuccessMessagePartition: "Uspješno ste obrisali particiju!",
            mySiteUploadImgModalTitle: "Otpremite fotografiju",
            mySiteUploadImgModalSubtitle:
              "Izaberite fotografiju za otpremanje sa svog računara ili uređaja",
            mySiteUploadImgModalChoose: "Izaberite fotografiju",
            mySiteSocialMediaName: "Naziv",
            mySiteSocialMediaUploadIcon: "Otpremite ikonicu",
            mySiteMaxNumber: "Maksimalan broj fotografija 5",
            mySitePhoneTooltipAdd: "Dodajte novi telefon",
            mySitePhoteTooltipDelete: "Uklonite telefon",
            mySiteTooltip:
              "Kliknite ovde ako želite dodati novu društvenu mrežu koja nije dostupna među već ponuđenim opcijama.",
            mySiteAddTooltip: `Odaberite jednu od ponuđenih opcija za kreiranje stranice "Moj sajt"!`,
            mySiteEditPhotoTooltip: "Uredi sekciju fotografije.",
            mySiteEditTextTooltip: "Uredi sekciju teksta.",
            mySiteEditPhoneTooltip: "Uredi sekciju telefona.",
            mySiteEditSocialMediaTooltip: "Uredi sekciju društvenih mreža.",
            mySiteEditVideoTooltip: "Uredi sekciju videa.",
            mySiteEditGalleryTooltip: "Uredi sekciju galerije fotografija.",
            mySiteEditSliderTooltip: "Uredi slajder sekciju.",
            mySiteRemovePhotoTooltip: "Ukloni sekciju fotografije.",
            mySiteRemoveTextTooltip: "Ukloni sekciju teksta.",
            mySiteRemovePhoneTooltip: "Ukloni sekciju telefona.",
            mySiteRemoveSocialMediaTooltip: "Ukloni sekciju društvenih mreža.",
            mySiteRemoveVideoTooltip: "Ukloni sekciju videa.",
            mySiteRemoveGalleryTooltip: "Ukloni sekciju galerije fotografija.",
            mySiteRemoveSliderTooltip: "Ukloni slajder sekciju.",
            mySiteRemoveDefaultTooltip: "Ukloni sekciju.",
            mySiteRemoveSocialMedia: "Ukloni društvenu mrežu.",
          },
          faqPage: {
            title: "Podrška",
            subtitle:
              "Odgovori na često postavljena pitanja o našoj firmi, uslugama i proizvodima",
            content:
              "Naša FAQ stranica sadrži odgovore na često postavljena pitanja o našoj firmi, uslugama i proizvodima. Pronađite sve potrebne informacije o našoj poslovnoj strategiji, osnovnim proizvodima i uslugama, ažuriranju informacija, prednostima elektronskog izdanja Poslovnog imenika Bosne i Hercegovine i još mnogo toga.",
            faq: "Često postavljana pitanja:",
            firstAccordionTitle:
              "Koji su osnovni proizvodi i usluge vaše firme?",
            secondAccordionTitle:
              "Kako se vrši ažuriranje informacija u online Poslovnom imeniku Bosne i Hercegovine?",
            thirdAccordionTitle:
              "Koje su prednosti elektronskog izdanja Poslovnog imenika Bosne i Hercegovine u odnosu na štampano izdanje?",
            firstAccordionContent:
              "Osnovna djelatnost naše firme je poslovno izdavaštvo na teritoriji Bosne i Hercegovine. Pokrenuli smo mnoga visokotiražna specijalizovana poslovna izdanja kao što su Telefonski - telefax imenik BiH, Telefonoteka, Poslovni imenik BiH, 300 najuspješnijih firmi u BiH, Katalog Poslovnog imenika BiH, kao i odgovarajuće elektronske publikacije na CD-u, te prateća internet izdanja za navedene naslove.",
            secondAccordionContent:
              "Sve informacije koje prikupimo terenskim ili telefonskim anketiranjem, kao i informacije iz drugih izvora, redovno se ažuriraju u bazi podataka Poslovnog imenika BiH. To podrazumijeva da će podaci o vašoj firmi biti vrlo brzo dostupni na internet prezentaciji Poslovnog imenika BiH.",
            thirdAccordionContent:
              "Elektronsko izdanje Poslovnog imenika BiH nudi mnoge prednosti, uključujući mogućnost pretraživanja i sortiranja sadržaja po različitim parametrima, korišćenje podataka za digitalni marketing, kao i redovno ažuriranje baze podataka. Takođe, omogućava laku komunikaciju i stupanje u poslovni kontakt sa postojećim pravnim subjektima u Bosni i Hercegovini.",
            didntFindAnswer: "Niste pronašli odgovor?",
            askUs: "Pitajte nas!",
            faqMessage:
              "Uspješno ste poslali poruku. Naš tim će Vas kontaktirati u što kraćem vrjemenskom periodu.",
          },
          changePassword: {
            title: "Lozinka",
            subtitle: "Promeni lozinku",
            oldPassword: "Trenutna lozinka",
            newPassword: "Nova lozinka",
            confirmPassword: "Potvrdi lozinku",
            successMessage: "Uspješno ste promenili lozinku!",
            errorMessage:
              "Uneti podaci nisu ispravni. Proverite podatke i pokušajte ponovo!",
          },
          sidebar: {
            dashboard: "Kontrolna tabla",
            mySite: "Moj sajt",
            settings: "Podešavanja",
            activity: "Kategorije",
            password: "Lozinka",
            support: "Podrška",
            logout: "Odjavi se",
            user: "Upravljaj zaposlenima",
          },
          mySiteStatisticChart: {
            january: "Januar",
            february: "Februar",
            march: "Mart",
            april: "April",
            may: "Maj",
            june: "Jun",
            july: "Jul",
            august: "Avgust",
            september: "Septembar",
            october: "Oktobar",
            november: "Novembar",
            december: "Decembar",
            monday: "Ponedeljak",
            tuesday: "Utorak",
            wednesday: "Sreda",
            thursday: "Četvrtak",
            friday: "Petak",
            saturday: "Subota",
            sunday: "Nedelja",
            day: "Dan",
            month: "Mesec",
            year: "Godina",
            numberOfVisits: "Broj posjeta",
            chooseDayMonthYear: "Izaberite dan, mjesec ili godinu",
          },
          manageClientsTable: {
            firstName: "Ime",
            lastName: "Prezime",
            email: "E-mail",
            phone: "Telefon",
            manageClient: "Upravljaj zaposlenima",
            addClient: "Dodaj zaposlenog",
            updateClient: "Ažuriraj zaposlenog",
            tooltip:
              'Kao vlasnik, možete dodati zaposlene koji će imati mogućnost prijavljivanja u sistem, kreiranja sajtova i korišćenja svih funkcionalnosti koje aplikacija pruža. Zaposleni neće imati pristup funkciji "Upravljaj zaposlenima".',
            deleteTooltip: "Uklonite zaposlenog",
          },
        },
      },
      rsCyrl: {
        translation: {
          cookieConsent: {
            title: "Користимо колачиће",
            description:
              "Овај сајт користи колачиће за побољшање вашег искуства. Молимо вас да одаберете ваше поставке колачића:",
            understand: "Разумијем",
            showDetails: "Прикажи детаље",
            modalTitle: "Информације о колачићима",
            modalDescFirst:
              "Користимо колачиће да прикупимо информације о вама. Користимо ове информације:",
            modalDescSecond: "да вам пружимо",
            modalDescThird: "боље искуство",
            modalDescFourth: "наше веб странице (функционално)",
            modalDescFifth: "за",
            modalDescSixth: "бројање страница",
            modalDescSeventh: "које посјећујете (статистика)",
            modalDescEighth: "да вам пружимо",
            modalDescNinth: "релевантне промоције",
            modalDescTenth: "(маркетинг)",
            modalDesc:
              "Кликните “OK” да нам дате своју сагласност за коришћење колачића у све ове сврхе. Такође можете користити поља за потврду да дате сагласност за одређене сврхе. Одаберите сврхе и “Сачувајте поставке”. Повуците или промијените своју сагласност у било којем тренутку кликом на икону у доњем љевом углу екрана. Промијените своје поставке.",
            switchNecessary: "Строго неопходно",
            switchFunctional: "Функционални",
            switchStatistical: "Статистички",
            swtichMarketing: "Маркетинг",
            saveSettings: "Сачувај поставке",
          },

          validation: {
            validationCompanyName: "Поље за назив компаније је обавезно",
            validationTaxId: "Поље за ПИБ је обавезно",
            validationEmail: "Поље за имејл је обавезно",
            validationPhoneNumber: "Поље за број телефона је обавезно",
            validationMessage: "Поље за поруку је обавезно",
            validationPackage: "Изаберите пакет",
            validationFirstName: "Поље за име је обавезно",
            validationLastName: "Поље за презиме је обавезно",
          },

          messageKey: {
            orderRequest_Pending: "Слање захтјева за поруџбину...",
            orderRequest_Success: "Захтјев за поруџбину је успјешно послан!",
            orderRequest_Error:
              "Нажалост, слање захтјева није успјело. Молимо провјерите вашу интернет везу и покушајте поново.",

            advertisementRequest_Pending: "Слање захтјева за оглашавање...",
            advertisementRequest_Success:
              "Захтјев за оглашавање је успјешно послан!",
            advertisementRequest_Error:
              "Нажалост, слање захтјева није успјело. Молимо провјерите вашу интернет везу и покушајте поново.",

            updateDataRequest_Pending: "Слање захтјева за ажурирање...",
            updateDataRequest_Success:
              "Захтјев за ажурирање је успјешно послан!",
            updateDataRequest_Error:
              "Нажалост, слање захтјева није успјело. Молимо провјерите вашу интернет везу и покушајте поново.",

            contactRequest_Pending: "Ваша порука се шаље...",
            contactRequest_Success:
              "Ваш захтјев је успјешно послан! Хвала вам што сте нас контактирали. Потрудит ћемо се да вам одговоримо у најкраћем могућем року.",
            contactRequest_Error:
              "Нажалост, слање поруке није успјело. Молимо провјерите вашу интернет везу и покушајте поново.",
            Login_Pending: "Пријављивање у току...",
            GetByEmail_AccountByEmailNotFound:
              "Налог са овом е-маил адресом не постоји.",
            Login_AccountByEmailNotFound:
              "Налог са овом е-маил адресом не постоји.",
            Login_TooManyAttempts:
              "Превише неуспелих покушаја пријављивања. Контактирајте администратора.",
            Login_NeedToChangePassword: "Потребно је да ресетујете лозинку.",
            Login_WrongPassword: "Погрешна лозинка.",
            Login_AccountInactive:
              "Налог није активан. Контактирајте администратора.",
            Login_NeedResetPasswordOnFirstLogin:
              "Потребно је да промените лозинку.",
            Login_Success: "Пријавили сте се.",

            Logout_Success: "Одјавили сте се.",
            RequestForgotPassword_EmailSent:
              "Ваш захтјев за промјену лозинке је успјешно послан. На вашу е-маил адресу стигао је код за ресетовање лозинке. Унесите код да бисте наставили са процесом промјене лозинке.",
            RequestForgotPassword_AccountByEmailNotFound:
              "Није пронађен налог са унесеном имејл адресом.",
            ForgotPassword_Pending:
              "Обрада захтјева за ресетовање лозинке у току...",
            ClientAccount_ForgotPasswordCodeNotValid:
              "Код за ресетовање лозинке није валидан. Молимо покушајте поново.",
            ForgotPassword_Success: "Успјешно сте ресетовали лозинку.",
            ResetPassword_AccountByIdNotFound:
              "Налог није пронађен са датим ИД-ом.",
            ResetPassword_AccountByEmailNotFound:
              "Налог није пронађен са датом адресом е-поште.",
            ResetPassword_IncorrectOldPassword: "Погрешна стара лозинка.",
            ResetPassword_Success: "Лозинка успјешно ресетована.",
            SendContactRequest_Success:
              "Успјешно сте послали поруку. Наш тим ће Вас контактирати у што краћем врјеменском периоду.",
            SendContactRequest_Pending:
              "Ваша порука се шаље. Молимо сачекајте...",
            CreateAccount_AccountWithThisEmailAlreadyExists:
              "Налог с овом е-поштом већ постоји.",
            CreateAccount_Pending: "Креирање налога...",
            CreateAccount_Success: "Налог успјешно креиран!",
            DeleteAccount_Success: "Налог успјешно обрисан!",
            DeleteClient_Pending: "Брисање налога...",
            UpdateAccount_Success: "Налог успјешно ажуриран!",
            ActivitySelection_Success: "Успјешно сте сачували дјелатности!",
            MySiteText_Pending: "Чување текста у току...",
            MySiteSuccessMessageText_Success: "Текст је успјешно сачуван!",
            MySiteErrorMessageText_Error:
              "Грешка при чувању текста. Молимо покушајте поново.",

            MySitePhone_Pending: "Чување телефона у току...",
            MySiteSuccessMessagePhone_Success: "Телефон је успјешно сачуван!",
            MySiteErrorMessagePhone_Error:
              "Грешка при чувању телефона. Молимо покушајте поново.",

            MySitePhoto_Pending: "Чување фотографије у току...",
            MySiteSuccessMessagePhoto_Success:
              "Фотографија је успјешно сачувана!",
            MySiteErrorMessagePhoto_Error:
              "Грешка при чувању фотографије. Молимо покушајте поново.",

            MySiteVideo_Pending: "Чување видеа у току...",
            MySiteSuccessMessageVideo_Success: "Видео је успјешно сачуван!",
            MySiteErrorMessageVideo_Error:
              "Грешка при чувању видеа. Молимо покушајте поново.",

            MySiteSocialMedia_Pending: "Чување друштвене мреже у току...",
            MySiteSuccessMessageSocialMedia_Success:
              "Друштвена мрежа је успјешно сачувана!",
            MySiteErrorMessageSocialMedia_Error:
              "Грешка при чувању друштвене мреже. Молимо покушајте поново.",

            MySiteGallery_Pending: "Чување галерије фотографија у току...",
            MySiteSuccessMessageGallery_Success:
              "Галерија фотографија је успјешно сачувана!",
            MySiteErrorMessageGallery_Error:
              "Грешка при чувању галерије фотографија. Молимо покушајте поново.",

            MySiteSlider_Pending: "Чување слајдера у току...",
            MySiteSuccessMessageSlider_Success: "Слајдер је успјешно сачуван!",
            MySiteErrorMessageSlider_Error:
              "Грешка при чувању слајдера. Молимо покушајте поново.",

            MySitePartition_Pending: "Брисање партиције у току...",
            MySiteSuccessMessagePartition_Success:
              "Партиција је успјешно обрисана!",
            MySiteErrorMessagePartition_Error:
              "Грешка при брисању партиције. Молимо покушајте поново.",

            idgetDeleteMessagePartners_Pending:
              "Брисање компаније из партнера у току...",
            WidgetDeleteMessagePartners_Success:
              "Успјешно сте обрисали компанију из партнера!",
            WidgetDeleteMessagePartners_Error:
              "Грешка при брисању компаније из партнера. Молимо покушајте поново.",

            WidgetDeleteMessagePotentialPartners_Pending:
              "Брисање компаније из потенцијалних партнера у току...",
            WidgetDeleteMessagePotentialPartners_Success:
              "Успјешно сте обрисали компанију из потенцијалних партнера!",
            WidgetDeleteMessagePotentialPartners_Error:
              "Грешка при брисању компаније из потенцијалних партнера. Молимо покушајте поново.",

            WidgetDeleteMessageClients_Pending:
              "Брисање компаније из клијената у току...",
            WidgetDeleteMessageClients_Success:
              "Успјешно сте обрисали компанију из клијената!",
            WidgetDeleteMessageClients_Error:
              "Грешка при брисању компаније из клијената. Молимо покушајте поново.",

            WidgetDeleteMessageFavorites_Pending:
              "Брисање компаније из фаворита у току...",
            WidgetDeleteMessageFavorites_Success:
              "Успјешно сте обрисали компанију из фаворита!",
            WidgetDeleteMessageFavorites_Error:
              "Грешка при брисању компаније из фаворита. Молимо покушајте поново.",
            Logout_ExpiredToken: "Ваша сесија је истекла.",
            NetworkError: "Грешка при повезивању са сервером.",
            InternalServerError: "Грешка при повезивању са сервером.",
            EditionsSectionCard_PdfDownload_Success: "Успјешно сте преузели katalog_2024.pdf.",
            EditionsSectionCard_PdfDownload_Error: "Грешка при преузимању PDF-а: Није могуће преузети каталог. Молимо покушајте поново."
          },
          header: {
            homePage: "Почетна",
            services: "Услуге",
            updateData: "Ажурирајте податке",
            advertisement: "Оглашавање",
            digitalMarketing: "Дигитални маркетинг",
            editions: "Издања",
            aboutUs: "О нама",
            contact: "Контакт",
            companyNameInput: "Унесите назив фирме, услугу/производ, мјесто...",
            advanceSearchLabel: "Напредно претраживање Пословног именика БиХ",
            categoryInput: "Дјелатност",
            subCategoryInput: "Поддјелатност",
            place: "Мјесто",
            telekomBl: "Telekom-BL",
          },
          footer: {
            footerDescription:
              "Основна дјелатност наше фирме је пословно издаваштво на територији Босне и Херцеговине. Наша редакција је покренула многа високотиражна специјализована пословна издања као што су: Телефонски-телефаx именик БиХ, Телефонотека, Пословни именик БиХ, 300 најуспјешнијих фирми у БиХ, Каталог Пословног именика БиХ, као и одговарајуће електронске публикације на ЦД-у, те пратећа интернет издања за наведене наслове.",
            footerFastLinksTitle: "Брзи линкови",
            footerFastLinkHome: "Почетна",
            footerFastLinkServices: "Услуге",
            footerFastLinkUpdatingData: "-Ажурирајте податке",
            footerFastLinkAdvertisement: "-Оглашавање",
            footerFastLinkDigitalMarketing: "-Дигитални маркетинг",
            footerFastLinkEditions: "Издања",
            footerFastLinkAbout: "О нама",
            footerFastLinkContact: "Контакт",
          },
          homePage: {
            resultsPerPage: "Резултата по страници",
          },
          updatingDataPage: {
            updatingDataTitle: "Ажурирајте податке",
            updatingDataText:
              "Упис нових података подразумијева ажурирање постојећих, унос нових и брисање старих неважећих података у бази Пословног именика БиХ. Ову активност спроводи издавач на основу информација прибављених теренским радом, телефонским анкетама, као и податке прибављене преко онлајн форме на овом вебсајту.",
            updatingDataFormCompanyName: "Назив фирме",
            updatingDataFormPIB: "ПИБ",
            updatingDataFormEmail: "Е-маил",
            updatingDataFormPhoneNumber: "Број телефона",
            updatingDataFormPlace: "Мјесто",
            updatingDataFormStreet: "Улица",
            updatingDataFormHomeNumber: "Број",
          },
          advertisementPage: {
            advertisementTitle: "Оглашавање",
            advertisementText:
              "Обезбјеђујемо разне врсте оглашавања и креирамо сет услуга према потребама наших клијената. За комплетан асортиман наших услуга и персонализовану израду сета услуга за оглашавање неопходно је да ступите у контантакт с нашим агентима продаје, а преко следеће контакт форме можете да наручите неке од наших најпопуларнијих пакета услуга.",
            advertisementFormCompanyName: "Назив фирме",
            advertisementFormPIB: "ПИБ",
            advertisementFormEmail: "Е-маил",
            advertisementFormPhoneNumber: "Број телефона",
            advertisementFormPlace: "Мјесто",
            advertisementFormStreet: "Улица",
            advertisementFormHomeNumber: "Број",
          },
          digitalMarketingPage: {
            digitalMarketingTitle: "Дигитални маркетинг",
            digitalMarketingText:
              "Обезбјеђујемо све што је неопходно привредним субјектима за пословно информисање, оглашавање и дигиталну трансформацију. Посредством интелигентних софтверских технологија пружамо квалитетна, ефикасна и приступачна рјешења за унапређивање дигиталног пословања и пораст пословног успјеха.",
            digitalMarketingTextSecond:
              "Анализирамо Ваше пословне процесе и информационе системе, те предлажемо оптимална рјешења за дигиталну трансформацију, интеграцију, аутоматизацију и унапређивање свих сегмената пословања. Наша рјешења су јединствена, динамична и скалабилна, те потпуно прилагођена Вашим потребама и циљевима. Све наше услуге прате најновије технолошке трендове и примјењују се у складу с најбољим праксама. Без обзира да ли се ради о новом пројекту, проширењу постојећег пословања или повећању видљивости Ваше онлајн присутности, ми смо ту да Вам помогнемо.",
            digitalMarketingTextThird:
              "Контактирајте нас и затражите бесплатну проћену могућности Вашег дигиталног пословног унапријеђивања, а ми ћемо Вам дати приједлоге за дигитални пословни развој прилагођен Вашим потребама и циљевима.",
          },
          editionsPage: {
            catalogTitle: "Пословни именик Босне и Херцеговине - Каталог",
            catalogTextFirst:
              "Пословни именик Босне и Херцеговине (штампано издање) је до 2016. год. био најчитанија и најпродаванија публикација пословних информација на подручју Босне и Херцеговине. Дуга традиција нашег пословања позната је свим фирмама, организацијама и институцијама које су посредно или непосредно користили наша годишња издања.",
            catalogText:
              "Пословни именик Босне и Херцеговине (штампано издање) је до 2016. год. био најчитанија и најпродаванија публикација пословних информација на подручју Босне и Херцеговине. Дуга традиција нашег пословања позната је свим фирмама, организацијама и институцијама које су посредно или непосредно користили наша годишња издања.",
            catalogTextSecond:
              "Савремена електронска и технолошка достигнућа управљала су промјенама у многим пословним системима, што је случај и код Пословног именика Босне и Херцеговине. До сада познати Пословни именик БиХ (књижно издање) који се од 2016. год. више не штампа, редизајниран је и модернизован у нову публикацију Пословни именик БиХ – Каталог.",
            catalogTextThird:
              "Основу Пословног именика БиХ – Каталога чине фирме, организације и институције са територије Босне и Херцеговине, које се овим путем колорно и текстуално оглашавају. Као и за сва остала издања Пословног именика Босне и Херцеговине – Каталог и за ово слиједи ранији установљени концепт директног маркетинга, гдје се у први план стављају информације о активним правним субјектима из Босне и Херцеговине. У Каталогу сви огласи правних субјеката сортирани су по сродним дјелатностима, а оглашивачи са логотипима у наставку огласа исте дјелатности, сортирани су по абецеди. Више детаља о фирмама и институцијама можете погледати у Каталогу (ПДФ формату), а цијелу базу података Пословног именика БиХ потражите на сајту poslovniimenik.net.",
            cdTitle: "Пословни именик БиХ - електронско издање",
            cdText:
              "У односу на онлајн, а посебно на штампано издање, Пословни именик Босне и Херцеговине - електронско издање, нуди многе предности. Оне су нарочито изражене код претраживања и сортирања садржаја јер је омогућена претрага по свим параметрима појединачно, комбиноване претраге на два или више начина те снимање и чување претрага.",
            cdTextSecond:
              "Друга значајна предност је могућност коришћења података за дигитални маркетинг, било да су у питању друштвене мреже или директни маркетинг. База података електронског Пословног именика БиХ се освјежава свакодневно и допуњава новим информацијама везаним за постојеће и нове фирме или институције.",
            cdTextThird:
              "За сваку фирму или институцију у бази постоје основни подаци: - Назив фирме или институције; - Подаци о дјелатности фирме или институције; - Адреса фирме или институције: улица, број, мјесто, поштански број, ентитет и тел. и позивни број; - Бројеви телефона, телефаxа, е-маил адресе; - Веб адресе фирме или институције.",
            cdTextFourth:
              "Куповином електронског издања Пословног именика БиХ и уносом Ваше фирме у базу, остварујете право на упис и објаву података у издањима Пословног именика Босне и Херцеговине (интернет и електронско издање), као и право на бесплатно редовно ажурирање, измјене или допуне Ваших података преко интернета у трајању једногодишње претплате.",
            cdTextFifth:
              "Вјерујемо да ће Вам ово електронско издање Пословног именика БиХ омогућити да све податке неопходне за свакодневну комуникацију лако проналазите и на тај начин ступите у пословни контакт постојећих правних субјеката у Босни и Херцеговини.",
            onlineTitle: "Онлајн Пословни именик Босне и Херцеговине",
            onlineText:
              "Од свих наших пословних издања најважније мјесто заузима интернет презентација Пословног именика Босне и Херцеговине. Електронска презентација представља огледало Ваше фирме и веома је приступачан, повољан и модеран облик рекламирања који може много да допринесе Вашем успјешнијем пословању.",
            onlineTextSecond:
              "Онлајн издање Пословног именика БиХ је конципирано као центар разноврсних пословних дјелатности. Садржај интернет презентације poslovniimenik.net доступан је свим корисницима интернет услуге на преглед, те је омогућена брза претрага података посредством већег броја различитих параметара. Огроман број људи и пословних корисника свакодневно посјећује нашу интернет презентацију, што је од велике користи како нашим посјетиоцима тако и  корисницима  наших услуга.",
            onlineTextThird:
              "Презентовањем Ваше фирме, институције или организације на нашој интернет презентацији придружујете се свим оглашивачима који су већ путем огласа истакли своје податке и на тај начин омогућили стотинама хиљада заинтересованих посјетилаца да имају детаљан преглед Ваше фирме и Ваше дјелатности.",
            onlineTextFourth:
              "Онлајн издање Пословног именика БиХ у пакету са штампаним и електронским издањем има задатак да основне пословне податке пренесе до свих категорија корисника и да својом динамиком утиче на проток информација.",
            onlineTextFifth:
              "Све информације које издавач прикупи теренским или телефонским анкетирањем као и информације из других извора редовно се ажурирају у бази података Пословног именика БиХ што подразумијева да ће подаци о Вашој фирми бити врло брзо и на интернет презентацији Пословног именика БиХ.",
            tooltip: "Кликом на слику отвориће се приказ онлајн каталога.",
            backIcon: 'Врати се на страницу "Услуге".'
          },
          aboutUsPage: {
            aboutTitle: "О нама",
            aboutText:
              "Основна дјелатност наше фирме је пословно издаваштво на територији Босне и Херцеговине. Наша редакција је покренула многа високотиражна специјализована пословна издања као што су: Телефонски - телефаx именик БиХ, Телефонотека, Пословни именик БиХ, 300 најуспјешнијих фирми у БиХ, Каталог Пословног именика БиХ, као и одговарајуће електронске публикације на ЦД-у, те пратећа интернет издања за наведене наслове.",
            aboutTextSecond:
              "Пословна стратегија коју користимо је директни маркетинг и директна продаја. Посредством тих пословних стратегија издавач Пословног именика Босне и Херцеговине потпуно самостално организује своје комерцијалне и маркетиншке активности, као и припрему, те уређивање и публиковање свих својих пословних издања.",
            aboutTextThird:
              "Наведени пословни концепт формирао је и нашу мисију као активно и перманентно ажурирање базе података привредних субјеката са цјелокупне територије Босне и Херцеговине, чинећи све те пословне податке обједињене на једном мјесту, те брзо и лако доступне свим пословним субјектима као и цивилним лицима у БиХ, региону и шире.",
            aboutTextFourth:
              "Наша визија је да заувијек очувамо реномирано мјесто у пословном издаваштву на територији Босне и Херцеговине, препознатљиво првенствено по квалитету, роковима и цијенама услуга. Да би то континуелно постизали, оријентисали смо се да послујемо у корак с развојем савремених технологија како би из године у годину постизали раст квалитета наших пословних издања, те из дана у дан ширимо мрежу наших корисника и оглашивача. Према захтјевима тржишта спремни смо покретати нова пословна издања као што су специјализове брошуре, посебни стручни додаци, те периодични и мјесечни билтени и часописи.",
            aboutLandmark: "Телеком – БЛ, Бања Лука",
          },
          contactUsPage: {
            contactTitle: "Контактирајте нас",
            contactFormCompanyName: "Назив фирме",
            contactFormContactPerson: "Контакт особа",
            contactFormEmail: "Е-маил",
            contactFormMessage: "Порука",
            contactSuccessMessage:
              "Ваша порука је успјешно послата. Одговорићемо Вам у најкраћем могућем року.",
            contactSuccessTitle: "Хвала!",
          },
          orderRequestPage: {
            orderTitle: "Захтјев за наручивање",
            orderPackageOne: "Пакет 1",
            orderPackageTwo: "Пакет 2",
            orderPackageThree: "Пакет 3",
            orderPackageOneText:
              "Ажурирање и објављивање информација о клијенту у Пословном именику Босне и Херцеговине (електронско издање); Оглас на веб страници poslovniimenik.net (лого, информације и линк); Објављивање истог огласа у Каталогу Пословног именика Босне и Херцеговине.",
            orderPackageTwoText:
              "Ажурирање и објављивање информација о купцу у Пословном именику Босне и Херцеговине (електронско издање); Оглас на веб страници poslovniimenik.net (лого, информације и линк); Објављивање истог огласа у Каталогу Пословног именика Босне и Херцеговине; Оглас на банеру.",
            orderPackageThreeText: "Пословни именик БиХ дигитално издање.",
            orderNoteTitle: "Напомена:",
            orderNoteText:
              "Услуге пакета трају једну годину од дана објављивања. У цијену није урачунат ПДВ. За остале наше услуге и производе контактирајте нашу службу за кориснике. Наручене услуге и производи из наведених пакета реализују се и шаљу најкасније пет дана од дана уплате.",
            orderNoteSub:
              "Молим вас имајте на уму да су граматика и форматирање прилагођени ради јасноће и читљивости.",
            orderFormCompanyName: "Назив фирме",
            orderFormTaxId: "ПИБ",
            orderFormEmail: "Е-маил",
            orderFormPhoneNumber: "Број телефона",
            orderFormPlace: "Мјесто",
            orderFormStreet: "Улица",
            orderFormHomeNumber: "Број",
            orderFormMessage: "Порука",
          },
          buttons: {
            search: "Претрага",
            send: "Пошаљите",
            call: "Позовите",
            order: "Наручите одмах",
            download: "Преузмите",
            downloading: "Преузимам...",
            orderOnline: "Наручите онлајн",
            orderByPhone: "Наручите телефоном",
            orderByEmail: "Наручите преко е-поште",
            close: "Затвори",
            clearSearch: "Очисти претрагу",
            login: "Пријави се",
            save: "Сачувај",
            manageActivities: "Управљај дјелатностима",
            edit: "Измени",
            delete: "Обриши",
            update: "Ажурирај",
            forgotPass: "Промени лозинку",
            reject: "Откажи",
            accept: "Прихвати",
          },

          searchResultLayout: {
            cardPremium: "Премиум",
            cardPlace: "Град-мјесто:",
            cardStreet: "Улица:",
            cardWeb: "Веб",
            cardPhone: "Телефон:",
            cardEmail: "Е-маил:",
            cardAddress: "Adresa:",
            cardShowMore: "Прикажи више...",
            saveActivity: "Сачувај дјелатност",
            cardShareTitle: "Копирај URL од веб-сајта",
            cardShareMessage: "Успјешно сте копирали URL!",
            removeFavoriteTooltip: "Уклони компанију из фаворита",
            addFavoriteTooltip: "Додајте компанију у фаворите.",
            addPartnerTooltip: "Додајте компанију у сараднике",
            addPotentialPartnerTooltip:
              "Додај компанију у потенцијалне сараднике",
            addClientTooltip: "Додај компанију у клијенте",
          },
          fullDetailsModal: {
            cardPremium: "Премиум",
            cardPlace: "Град-мјесто:",
            cardStreet: "Улица:",
            cardNumber: "Број:",
            cardWeb: "Веб",
            cardAddress: "Адреса",
            cardPhone: "Телефон:",
            cardEmail: "Е-маил:",
            cardWorkingTimes: "Радно врјеме:",
            cardWorkingTimesClosed: "Затворено",
            cardWorkingTimesOpen: "Отворено 24h",
            cardPostCode: "Поштански број:",
            cardShowWebsite: "Прикажи вебсајт",
          },
          subheader: {
            callNow: "Позовите",
            order: "Наручите",
          },
          languageSwitcher: {
            serbianLatinLanguage: "Босански",
            englishLagnuage: "Енглески",
            serbianCyrlLanguage: "Српски (ћирилица)",
          },
          weekDays: {
            monday: "Понедељак",
            tuesday: "Уторак",
            wednesday: "Среда",
            thursday: "Четвртак",
            friday: "Петак",
            saturday: "Субота",
            sunday: "Недеља",
            hours: "час",
          },
          notificationMessages: {
            searchErrorMessage:
              "Потребно је унети минимално 3 карактера у поље да би се приказали резултати",
            fullDetailsModalError:
              "Дошло је до грешке приликом прикупљања података. Молимо Вас покушајте поново",
          },
          termsAndContitions: {
            accept: "Прихватам услове",
            description:
              'Кликом на дугме "Пошаљите" потврђујем да ме убудуће обавјештавате о тренутним понудама и потврђујем да сам прочитао/ла и разумио/ла изјаву о приватности.',
            error: "Молимо Вас да прихватите услове и одредбе.",
          },
          promoteBusinessSection: {
            title: "Промовишите свој посао!",
            titlePremium: "Промовишите свој посао (премиум)!",
            description: "Опис вашег посла!",
            companyTitle: "Назив ваше компаније",
          },
          loginModal: {
            loginEmail: "Е-маил",
            loginPassword: "Лозинка",
            loginForgotPassword: "Заборавили сте лозинку?",
            loginErrorEmail: "Е-маил је обавезно поље",
            loginErrorPassword: "Лозинка је обавезно поље",
          },
          setPasswordModal: {
            setPasswordMinimumEightCharacter:
              "Лозинка мора имати минимум 8 карактера.",
            setPasswordCapitalLetter:
              "Лозинка мора имати барем једно велико слово.",
            setPasswordNumber: "Лозинка мора имати барем један број.",
            setPasswordSpecialCharacter:
              "Лозинка мора имати барем један специјалан карактер.",
            setPasswordConfirmPassword: "Лозинке се морају поклапати.",
          },
          forgotPasswordModal: {
            forgotPasswordTitle: "Заборављена лозинка?",
            inputCode: "Код",
            codeMessage: "Унесите код из е-поште",
            inputNewPassword: "Нова лозинка",
            inputConfirmNewPassword: "Поновите нову лозинку",
            forgotPasswordErrorMessage:
              "Унешени подаци нису исправни. Провјерите и покушајте поново.",
            forgotPasswordSuccessMessage: "Успјешно сте променили лозинку!",
          },
          activitySelectionModal: {
            activitySelectionTitle: "Добродошли",
            activitySelectionSubtitle: "На портал пословни именик БиХ",
            activitySelectionSubtitle2: "Дјелатности које вас занимају?",
            activitySelectionCategories: "Изаберите <b>3</b> индустрије",
            activitySelectionAutocompleteLabel: "Категорије",
            activitySelectionSkip: "Прескочи",
          },
          settingsActivitiesSelectionModal: {
            title: "Дјелатности",
            subtitle: "Управљај дјелатностима и поддјелатностима",
            activityTitle: "Ваше изабране дјелатности:",
            subcategoriesTitle: "Ваше изабране поддјелатности:",
          },
          dashboardCard: {
            dashboardCardAssociateTitle: "Сарадници",
            dashboardCardAssociateShowMore: "Погледај постојеће сараднике",
            dashboardCardPotentialAssociateTitle: "Потенцијални сарадници",
            dashboardCardPotentialAssociateShowMore:
              "Погледај потенцијалне сараднике",
            dashboardCardClientTitle: "Клијенти",
            dashboardCardClientShowMore: "Погледај постојеће клијенте",
            dashboardCardFavoritesTitle: "Фаворити",
            dashboardCardFavoriteshowMore: "Погледај постојеће фаворите",
            dashboardActivitiesCardTitle: "Ваше омиљене активности",
            dashboardRatingCardTitle: "Ваш рејтинг",
            dashboardRemove: "Уклони",
            dashboardRemovePartner: "из партнера.",
            dashboardRemovePotentialPartner: "из потенцијалних партнера.",
            dashboardRemoveClient: "из клијената.",
            dashboardRemoveFavorite: "из фаворита.",
          },
          dashboardRatingCard: {
            ratingOne: "оцјену",
            ratingTwo: "оцјене",
            ratingThree: "оцјена",
            ratingContentFirstPart: "Имате",
            ratingContentSecondPart: 'ваше странице "Мој Сајт".',
          },
          partnersModal: {
            partnersModalTitle: "Сарадници",
          },
          potentialPartnersModal: {
            potentialPartnersModalTitle: "Потенцијални сарадници",
          },
          clientsModal: {
            clientsModalTitle: "Клијенти",
          },
          favoritesModal: {
            favoritesModalTitle: "Фаворити",
          },
          widgetTable: {
            widgetTableName: "Име фирме",
            widgetTableAddress: "Адреса",
            widgetTableCity: "Град",
            widgetTableCategory: "Дjeлатност",
            widgetTableAction: "Акција",
            widgetSuccessDeleteMessagePartners:
              "Успјешно сте обрисали компанију из партнера!",
            widgetSuccessDeleteMessagePotentialPartners:
              "Успјешно сте обрисали компанију из потенцијалних партнера!",
            widgetSuccessDeleteMessageClients:
              "Успјешно сте обрисали компанију из клијената!",
            widgetSuccessDeleteMessageFavorites:
              "Успјешно сте обрисали компанију из фаворита!",
          },

          rateUsModal: {
            rateUsModalTitle: "Оцјените Пословни Именик БиХ",
            rateUsModalContent:
              "Путем брзе оцјене можете нам помоћи да побољшамо наше услуге. Молимо вас да изаберете одговарајућу оцјену испод. <br /> Ваше мишљење је важно за нас! <br /> Хвала вам на подршци.",
            skip: "Не желим оцјењивати сада",
            commentPlaceholder: "Ваш коментар/сугестија...",
          },

          overallRatingModal: {
            overallRatingTitle: "Оцјените",
            overallRatingShareOpinion: "Подијелите ваше мишљење о",
            overallRatingImportant:
              "Ваша оцјена је важна, хвала вам на издвојеном времену!",
            overallRatingSuccessMessage: "Успјешно сте оцјенили",
          },
          dashbordRatingCard: {
            numberOfRatings: "Број оцјена",
          },
          mySite: {
            mySiteTitle: "Мој сајт",
            mySiteSubtitle: "Креирај мој сајт",
            mySiteImgTitle: "Фотографија",
            mySiteTextTitle: "Текст",
            mySitePhoneTitle: "Контакт",
            mySiteSocialMediaTitle: "Друштвене мреже",
            mySiteSocialMediaLink: "Налепите линк",
            mySiteSocialMediaSelect: "Изаберите друштвену мрежу",
            mySiteVideoTitle: "Видео",
            mySiteGalleryTitle: "Галерија фотографија",
            mySiteGalleryRemovePhotos: "Уклоните фотографије",
            mySiteGalleryAdd: "Додајте фотографије",
            mySiteGalleryFrom: "од",
            mySiteGalleryPhotography: "фотографија",
            mySiteSliderTitle: "Слајдер",
            mySitePopoverPhoto: "Фотографија",
            mySitePopoverVideo: "Видео запис",
            mySitePopoverText: "Текст",
            mySitePopoverPhone: "Телефон",
            mySitePopoverSocialMedia: "Друштвене мреже",
            mySitePopoverGallery: "Галерија",
            mySitePopoverSlider: "Слајдер",
            mySiteSuccessMessageText: "Успјешно сте сачували текст!",
            mySiteSuccessMessagePhone: "Успјешно сте сачували телефон!",
            mySiteSuccessMessagePhoto: "Успјешно сте сачували фотографију!",
            mySiteSuccessMessageVideo: "Успјешно сте сачували видео!",
            mySiteSuccessMessageSocialMedia:
              "Успјешно сте сачували друштвене мреже!",
            mySiteSuccessMessageGallery:
              "Успјешно сте сачували галерију фотографија!",
            mySiteSuccessMessageSlider: "Успјешно сте сачували слајдер!",
            mySiteSuccessMessagePartition: "Успјешно сте обрисали партицију!",
            mySiteUploadImgModalTitle: "Отпремите фотографију",
            mySiteUploadImgModalSubtitle:
              "Изаберите фотографију за отпремање са свог рачунара или уређаја",
            mySiteUploadImgModalChoose: "Изаберите фотографију",
            mySiteSocialMediaName: "Назив",
            mySiteSocialMediaUploadIcon: "Отпремите иконицу",
            mySiteMaxNumber: "Максималан број фотографија 5",
            mySitePhoneTooltipAdd: "Додајте нови телефон",
            mySitePhoteTooltipDelete: "Уклоните телефон",
            mySiteTooltip:
              "Кликните овде да бисте додали нову друштвену мрежу која није доступна међу понуђеним опцијама.",
            mySiteAddTooltip: `Одаберите једну од понуђених опција за креирање странице "Мој сајт"!`,
            mySiteEditPhotoTooltip: "Уреди секцију фотографије.",
            mySiteEditTextTooltip: "Уреди секцију текста.",
            mySiteEditPhoneTooltip: "Уреди секцију телефона.",
            mySiteEditSocialMediaTooltip: "Уреди секцију друштвених мрежа.",
            mySiteEditVideoTooltip: "Уреди секцију видеа.",
            mySiteEditGalleryTooltip: "Уреди секцију галерије фотографија.",
            mySiteEditSliderTooltip: "Уреди слајдер секцију.",
            mySiteRemovePhotoTooltip: "Уклони секцију фотографије.",
            mySiteRemoveTextTooltip: "Уклони секцију текста.",
            mySiteRemovePhoneTooltip: "Уклони секцију телефона.",
            mySiteRemoveSocialMediaTooltip: "Уклони секцију друштвених мрежа.",
            mySiteRemoveVideoTooltip: "Уклони секцију видеа.",
            mySiteRemoveGalleryTooltip: "Уклони секцију галерије фотографија.",
            mySiteRemoveSliderTooltip: "Уклони слајдер секцију.",
            mySiteRemoveDefaultTooltip: "Уклони секцију.",
            mySiteRemoveSocialMedia: "Уклони друштвену мрежу.",
          },
          faqPage: {
            title: "Подршка",
            subtitle:
              "Одговори на често постављена питања о нашем предузећу, услугама и производима",
            content:
              "На нашој страници Често постављена питања садржи одговоре на учестала питања о нашем предузећу, услугама и производима. Пронађите све потребне информације о нашој пословној стратегији, основним производима и услугама, ажурирању информација, предностима електронског издања Пословног именика Босне и Херцеговине и још много тога.",
            faq: "Често постављена питања:",
            firstAccordionTitle:
              "Који су основни производи и услуге вашег предузећа?",
            secondAccordionTitle:
              "Како се врши ажурирање информација у онлајн Пословном именику Босне и Херцеговине?",
            thirdAccordionTitle:
              "Које су предности електронског издања Пословног именика Босне и Херцеговине у односу на штампано издање?",
            firstAccordionContent:
              "Основна делатност нашег предузећа је пословно издаваштво на територији Босне и Херцеговине. Покренули смо многа високотиражна специјализована пословна издања као што су Телефонски - телефакс именик БиХ, Телефонотека, Пословни именик БиХ, 300 најуспјешнијих фирми у БиХ, Каталог Пословног именика БиХ, као и одговарајуће електронске публикације на ЦД-у, те пратећа интернет издања за наведене наслове.",
            secondAccordionContent:
              "Све информације које прикупимо теренским или телефонским анкетирањем, као и информације из других извора, редовно се ажурирају у бази података Пословног именика БиХ. То подразумијева да ће подаци о вашем предузећу бити врло брзо доступни на интернет презентацији Пословног именика БиХ.",
            thirdAccordionContent:
              "Електронско издање Пословног именика БиХ нуди многе предности, укључујући могућност претраживања и сортирања садржаја по различитим параметрима, коришћење података за дигитални маркетинг, као и редовно ажурирање базе података. Такође, омогућава лаку комуникацију и ступање у пословни контакт са постојећим правним субјектима у Босни и Херцеговини.",
            didntFindAnswer: "Нисте пронашли одговор?",
            askUs: "Питајте нас!",
            faqMessage:
              "Успјешно сте послали поруку. Наш тим ће Вас контактирати у што краћем врјеменском периоду.",
          },
          changePassword: {
            title: "Лозинка",
            subtitle: "Промени лозинку",
            oldPassword: "Тренутна лозинка",
            newPassword: "Нова лозинка",
            confirmPassword: "Потврди лозинку",
            successMessage: "Успјешно сте променили лозинку!",
            errorMessage:
              "Унети подаци нису исправни. Проверите податке и покушајте поново!",
          },
          sidebar: {
            dashboard: "Контролна табла",
            mySite: "Мој сајт",
            settings: "Подешавања",
            activity: "Категоорије",
            password: "Лозинка",
            support: "Подршка",
            logout: "Одјави се",
            user: "Управљај запосленима",
          },
          mySiteStatisticChart: {
            january: "Јануар",
            february: "Фебруар",
            march: "Март",
            april: "Април",
            may: "Мај",
            june: "Јун",
            july: "Јул",
            august: "Август",
            september: "Септембар",
            october: "Октобар",
            november: "Новембар",
            december: "Децембар",
            monday: "Понедељак",
            tuesday: "Уторак",
            wednesday: "Среда",
            thursday: "Четвртак",
            friday: "Петак",
            saturday: "Субота",
            sunday: "Недеља",
            day: "Дан",
            month: "Месец",
            year: "Година",
            numberOfVisits: "Број посјета",
            chooseDayMonthYear: "Изаберите дан, мјесец или годину",
          },
          manageClientsTable: {
            firstName: "Име",
            lastName: "Презиме",
            email: "Имејл",
            phone: "Телефон",
            manageClient: "Управљај запосленима",
            addClient: "Додај Запосленог",
            updateClient: "Ажурирај запосленог",
            tooltip:
              'Као власник, можете додати запослене који ће имати могућност пријављивања у систем, креирања сајтова и коришћења свих функционалности које апликација пружа. Запослени неће имати приступ функцији "Управљај запосленима".',
            deleteTooltip: "Уклоните запосленог",
          },
        },
      },
    },
  });

export default i18n;
