import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { colors } from "../../../app/theme/colors";
import AppButton from "../../../components/appButton/appButton";
import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import {
  login as loginSlice,
  resetErrorMessage,
  selectErrorMessage,
  selectLoginResponse,
} from "../../../slices/authSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  saveActivitiesSettings,
  selectMainActivities,
  selectSavedActivitiesSettingsStatus,
} from "../../../slices/onboardingSlice";
import { CategoryShortDetail } from "../../../models/company/categoryShortDetail";
import ActivitiesRequestModel from "../../../models/onboarding/activitiesRequestModel";
import { useSettingsActivitiesSelectionModalStyles } from "./settingsActivitiesSelectionModalStyles";
import {
  getAllSubcategories,
  selectAccountCategories,
  selectAccountSubcategories,
  selectSubcategories,
} from "../../../slices/categorySlice";
import AccountSubcategoryModel from "../../../models/categories/accountSubcategoryModel";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ApiStatus } from "../../../models/app/apiStatus";

interface Props {
  open: any;
  handleClose: any;
}
let renderCount = 0;

export default function SettingsActivitiesSelectionModal({
  handleClose,
  open,
}: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { classes } = useSettingsActivitiesSelectionModalStyles();
  const theme = useTheme();
  const navigate = useNavigate();

  const loginResponse = useAppSelector(selectLoginResponse);
  const errorMessage = useAppSelector(selectErrorMessage);
  const mainActivities = useAppSelector(selectMainActivities);
  const savedActivitiesStatus = useAppSelector(
    selectSavedActivitiesSettingsStatus
  );
  const accountCategories = useAppSelector(selectAccountCategories);
  const accountSubcategories = useAppSelector(selectAccountSubcategories);
  const subcategories = useAppSelector(selectSubcategories);
  const INIT_ACTIVTIES: ActivitiesRequestModel = {
    categoryIds: [],
  };

  const [savedActivities, setSavedActivities] =
    useState<ActivitiesRequestModel>(INIT_ACTIVTIES);

  const [selectedActivities, setSelectedActivities] = useState<
    CategoryShortDetail[]
  >([]);

  const [selectedSubcategories, setSelectedSubcategories] = useState<
    AccountSubcategoryModel[]
  >([]);

  const [subcategoriesDisplay, setSubcategoriesDisplay] = useState<
    AccountSubcategoryModel[]
  >([]);

  const handleDialogClose = () => {
    handleClose();
  };

  const handleActivitySelect = (
    event: React.ChangeEvent<{}>,
    value: CategoryShortDetail | CategoryShortDetail[]
  ) => {
    const selectedValueArray = Array.isArray(value) ? value : [value];

    setSelectedActivities(selectedValueArray);

    const selectedIds = selectedValueArray.map((activity) => activity.id);

    setSavedActivities((prevState) => ({
      ...prevState,
      categoryIds: selectedIds,
    }));

    const selectedIdsArray = selectedValueArray.map((activity) => activity.id);

    dispatch(getAllSubcategories({ parentCategoryIds: selectedIdsArray }));

    selectedValueArray.forEach((activity) => {
      const isCategorySelected = selectedSubcategories.some(
        (category) => category.id === activity.id
      );
      if (!isCategorySelected) {
        setSelectedSubcategories((prevSubcategories) => [
          ...prevSubcategories,
          {
            id: activity.id,
            name: activity.name,
            subCategories: [],
          },
        ]);
      }
    });
  };

  const handleSubcategorySelect = (
    event: React.ChangeEvent<{}>,
    value: AccountSubcategoryModel | AccountSubcategoryModel[]
  ) => {
    const selectedValueArray = Array.isArray(value) ? value : [value];
    const newSelectedSubcategories = selectedValueArray.map((subcategory) => ({
      id: subcategory.id,
      name: subcategory.name,
      subCategories: subcategory.subCategories || [],
    }));
    setSelectedSubcategories(newSelectedSubcategories);

    setSubcategoriesDisplay(newSelectedSubcategories);
  };
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const [selectedSubcategoriesIds, setSelectedSubcategoriesIds] = useState<
    string[]
  >([]);

  const handleCheckboxClick = (subcategory: any) => {
    const isSelected = selectedSubcategories.some((cat) =>
      cat.subCategories.some((sub) => sub.id === subcategory.id)
    );

    if (!isSelected) {
      const selectedIndex = selectedSubcategories.findIndex(
        (cat) => cat.id === subcategory.parentId
      );

      if (selectedIndex !== -1) {
        const updatedSubCategories = selectedSubcategories.map((cat, index) => {
          if (index === selectedIndex) {
            return {
              ...cat,
              subCategories: [...cat.subCategories, subcategory],
            };
          }
          return cat;
        });

        setSelectedSubcategories(updatedSubCategories);
        setSubcategoriesDisplay(updatedSubCategories);
      }
    } else {
      const updatedSelectedSubcategories = selectedSubcategories.map((cat) => ({
        ...cat,
        subCategories: cat.subCategories.filter(
          (sub) => sub.id !== subcategory.id
        ),
      }));
      setSelectedSubcategories(updatedSelectedSubcategories);
      setSubcategoriesDisplay(updatedSelectedSubcategories);
    }
  };

  const handleSaveActivities = () => {
    const updatedSavedActivities: ActivitiesRequestModel = {
      categoryIds: selectedIds,
    };
    dispatch(saveActivitiesSettings(updatedSavedActivities));
  };

  const handleSaveButtonClick = () => {
    handleSaveActivities();
  };

  const handleRemoveActivity = (activityIdToRemove: string) => {
    const updatedSelectedActivities = selectedActivities.filter(
      (activity) => activity.id !== activityIdToRemove
    );
    setSelectedActivities(updatedSelectedActivities);

    const updatedSelectedIds = selectedIds.filter(
      (id) => id !== activityIdToRemove
    );

    const updatedSelectedSubcategories = selectedSubcategories.filter(
      (subcategory) => subcategory.id !== activityIdToRemove
    );
    setSelectedSubcategories(updatedSelectedSubcategories);
    setSubcategoriesDisplay(updatedSelectedSubcategories);
    setSelectedIds(updatedSelectedIds);
  };

  const handleRemoveSubcategory = (subcategoryId: string) => {
    const updatedSubcategoriesDisplay = subcategoriesDisplay.map((category) => {
      const updatedSubCategories = category.subCategories.filter(
        (subcategory) => subcategory.id !== subcategoryId
      );
      return { ...category, subCategories: updatedSubCategories };
    });
    setSubcategoriesDisplay(updatedSubcategoriesDisplay);

    const updatedSelectedSubcategories = selectedSubcategories.filter(
      (subcategory) => subcategory.id !== subcategoryId
    );
    setSelectedSubcategories(updatedSelectedSubcategories);

    const updatedSelectedIds = selectedIds.filter((id) => id !== subcategoryId);
    setSelectedIds(updatedSelectedIds);
  };

  const resetActivitySelectionModalState = () => {
    setSelectedActivities([]);
    setSavedActivities(INIT_ACTIVTIES);
  };

  useEffect(() => {
    if (errorMessage) {
      if (errorMessage === "Failed to login.") {
        toast.error(t("forgotPasswordModal.forgotPasswordErrorMessage"), {
          position: "bottom-left",
          autoClose: 3000,
          hideProgressBar: true,
          onClose: () => {
            dispatch(resetErrorMessage());
          },
        });
      }
    }
  }, [errorMessage]);

  useEffect(() => {
    if (savedActivitiesStatus === "Fulfilled") {
      // handleClose();
    }
  }, [savedActivitiesStatus]);

  useEffect(() => {
    const selectedActivityIds = selectedActivities.map(
      (activity) => activity.id
    );
    const selectedSubcategoryIds = subcategoriesDisplay.flatMap((subcategory) =>
      subcategory.subCategories.map((sub) => sub.id)
    );
    const updatedSelectedIds = [
      ...selectedActivityIds,
      ...selectedSubcategoryIds,
    ];
    setSelectedIds(updatedSelectedIds);
  }, [selectedActivities, selectedSubcategories]);

  useEffect(() => {
    if (accountCategories !== null) {
      const selectedIds = selectedActivities.map((activity) => activity.id);
      setSelectedActivities(accountCategories);
      dispatch(getAllSubcategories({ parentCategoryIds: selectedIds }));
    }
  }, [accountCategories]);

  useEffect(() => {
    if (subcategories) {
      setSubcategoriesDisplay(subcategories);
      setSelectedSubcategories(subcategories);
    }
  }, [subcategories]);

  useEffect(() => {
    const selectedIds = selectedActivities.map((activity) => activity.id);
    dispatch(getAllSubcategories({ parentCategoryIds: selectedIds }));
  }, [selectedActivities]);

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      fullWidth
      maxWidth="md"
      id="activitySelectionModal_dialog"
      PaperProps={{ sx: { borderRadius: "15px", overflow: "hidden" } }}
    >
      <IconButton
        id="activitySelectionModal_button_close"
        edge="end"
        color="inherit"
        onClick={handleDialogClose}
        aria-label="close"
        sx={{
          position: "absolute",
          top: theme.spacing(1),
          right: theme.spacing(2),
        }}
      >
        <CloseIcon
          id="activitySelectionModal_icon_close"
          className={classes.closeIcon}
        />
      </IconButton>
      <DialogContent sx={{ height: "500px", overflowY: "auto" }}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          padding={"5px"}
          sx={{ textAlign: "center" }}
        >
          <Grid container spacing={1}>
            <Grid item md={12} xs={12}>
              <Box marginBottom={-2}>
                <Typography variant="h4" sx={{ color: colors.primary }}>
                  {t("settingsActivitiesSelectionModal.title")}
                </Typography>
              </Box>
            </Grid>
            <Grid item md={12} xs={12} mt={1}>
              <Typography variant="body2" sx={{ color: colors.primary }}>
                {t("settingsActivitiesSelectionModal.subtitle")}
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <Autocomplete
                size="small"
                disablePortal
                fullWidth
                multiple
                id="settingsActivitiesSelectionModal-autocomplete-categories"
                options={mainActivities ? mainActivities : []}
                getOptionLabel={(option) => option.name}
                onChange={handleActivitySelect}
                value={selectedActivities}
                autoHighlight={true}
                renderTags={() => null}
                disableCloseOnSelect
                renderInput={(params) => (
                  <TextField
                    className={classes.autocompleteTextField}
                    {...params}
                    variant="outlined"
                    fullWidth
                    label="Dijelatnosti"
                  />
                )}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={
                        selected ||
                        selectedActivities.some(
                          (activity) => activity.id === option.id
                        )
                      }
                    />
                    {option.name}
                  </li>
                )}
              />
              <Grid
                item
                md={12}
                mt={2}
                display={"flex"}
                justifyContent={"flex-start"}
              >
                <Typography variant="body2">
                  {t("settingsActivitiesSelectionModal.activityTitle")}
                </Typography>
              </Grid>
              {selectedActivities?.map((activity, index) => {
                if (activity.parentId === null) {
                  return (
                    <Grid item md={12} key={index} mt={index === 0 ? 1 : 1}>
                      <motion.div
                        key={activity.name}
                        initial={{ scale: 0, rotate: -5 }}
                        animate={{ scale: 1, rotate: 0 }}
                        transition={{ duration: 0.2 }}
                      >
                        <Chip
                          label={activity.name}
                          sx={{
                            width: "100%",
                            backgroundColor:
                              index % 2 === 0 ? "#4D8CF557" : "#EBEBEBCC",
                            color: index % 2 === 0 ? "#635F5FBA" : "#635F5FBA",
                            display: "flex",
                            justifyContent: "flex-start",
                            height: "25px",
                          }}
                          icon={
                            <Tooltip title={"Ukloni djelatnost."}>
                              <IconButton
                                onClick={() =>
                                  handleRemoveActivity(activity.id)
                                }
                              >
                                <CloseIcon className={classes.closeIconChip} />
                              </IconButton>
                            </Tooltip>
                          }
                        />
                      </motion.div>
                    </Grid>
                  );
                } else {
                  return null;
                }
              })}
            </Grid>
            <Grid item md={6} xs={12}>
              <Autocomplete
                size="small"
                disablePortal
                fullWidth
                multiple
                id="settingsActivitiesSelectionModal-autocomplete-subcategories"
                options={accountSubcategories ? accountSubcategories : []}
                getOptionLabel={(option) => option.name}
                value={selectedSubcategories}
                onChange={handleSubcategorySelect}
                autoHighlight={true}
                renderTags={() => null}
                disableCloseOnSelect
                renderInput={(params) => (
                  <TextField
                    className={classes.autocompleteTextField}
                    {...params}
                    variant="outlined"
                    fullWidth
                    label="Poddjelatnosti"
                  />
                )}
                renderOption={(props, option, { selected }) => {
                  const isParent =
                    option.subCategories && option.subCategories.length > 0;
                  const isSelected =
                    selected ||
                    (subcategories !== null &&
                      subcategories !== undefined &&
                      subcategories.some((category) =>
                        category.subCategories.some(
                          (sub) => sub.id === option.id
                        )
                      ));
                  return (
                    <>
                      {isParent && (
                        <li style={{ textDecoration: "none" }}>
                          <Typography
                            variant="body2"
                            sx={{ fontWeight: isParent ? "bold" : "normal" }}
                          >
                            {option.name}
                          </Typography>
                        </li>
                      )}
                      {isParent && (
                        <ul>
                          {option.subCategories.map((subcategory) => (
                            <li
                              key={subcategory.id}
                              style={{
                                textDecoration: "none",
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                              }}
                            >
                              <Checkbox
                                icon={
                                  <CheckBoxOutlineBlankIcon fontSize="small" />
                                }
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                style={{ marginRight: 8 }}
                                checked={
                                  selected ||
                                  selectedSubcategories.some((sub) =>
                                    sub.subCategories.some(
                                      (sub) => sub.id === subcategory.id
                                    )
                                  )
                                }
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleCheckboxClick(subcategory);
                                }}
                              />

                              <Typography variant="body1">
                                {subcategory.name}
                              </Typography>
                            </li>
                          ))}
                        </ul>
                      )}
                    </>
                  );
                }}
              />
              <Grid
                item
                md={12}
                mt={2}
                display={"flex"}
                justifyContent={"flex-start"}
              >
                <Typography variant="body2">
                  {t("settingsActivitiesSelectionModal.subcategoriesTitle")}
                </Typography>
              </Grid>
              {subcategoriesDisplay?.map((category, index) => (
                <Grid item md={12} key={index} mt={index === 0 ? 1 : 1}>
                  <motion.div
                    key={category.id}
                    initial={{ scale: 0, rotate: -5 }}
                    animate={{ scale: 1, rotate: 0 }}
                    transition={{ duration: 0.2 }}
                  >
                    {category.subCategories.length > 0 && (
                      <Accordion sx={{ boxShadow: "none" }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>{category.name}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={1}>
                            {category.subCategories.map(
                              (subcategory, subIndex) => (
                                <Grid item key={subcategory.id} md={12}>
                                  <motion.div
                                    initial={{ scale: 0, rotate: -5 }}
                                    animate={{ scale: 1, rotate: 0 }}
                                    transition={{ duration: 0.2 }}
                                  >
                                    <Chip
                                      label={subcategory.name}
                                      sx={{
                                        width: "100%",
                                        backgroundColor:
                                          subIndex % 2 === 0
                                            ? "#4D8CF557"
                                            : "#EBEBEBCC",
                                        color:
                                          subIndex % 2 === 0
                                            ? "#635F5FBA"
                                            : "#635F5FBA",
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        height: "25px",
                                      }}
                                      icon={
                                        <Tooltip title={"Ukloni poddjelatnost."}>
                                           <IconButton
                                          onClick={() =>
                                            handleRemoveSubcategory(
                                              subcategory.id
                                            )
                                          }
                                        >
                                          <CloseIcon
                                            className={classes.closeIconChip}
                                          />
                                        </IconButton>
                                        </Tooltip>
                                       
                                      }
                                    />
                                  </motion.div>
                                </Grid>
                              )
                            )}
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    )}
                  </motion.div>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Grid
          container
          spacing={1}
          display={"flex"}
          justifyContent={"flex-end"}
        >
          <Grid item>
            <AppButton
              id="activitySelectionModal_button_save"
              color={colors.green}
              hover={colors.green}
              label={t("buttons.save")}
              width="100%"
              onClick={handleSaveButtonClick}
              disabled={savedActivitiesStatus === ApiStatus.Pending}
              startIcon={
                savedActivitiesStatus === ApiStatus.Pending ? (
                  <CircularProgress size={15} sx={{ color: colors.primary }} />
                ) : null
              }
            />
          </Grid>
          <Grid item>
            <AppButton
              id="activitySelectionModal_button_save"
              color={colors.primary}
              hover={colors.primary}
              label={t("buttons.close")}
              width="100%"
              onClick={handleDialogClose}
              disabled={savedActivitiesStatus === ApiStatus.Pending}
            />
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
