import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEditionsSectionCardStyles } from "./editionsSectionCardStyles";
import AppButton from "../../components/appButton/appButton";
import { colors } from "../../app/theme/colors";
import DownloadIcon from "@mui/icons-material/Download";
import { useEditionsData } from "../../mockData/mockData";
import { useTranslation } from "react-i18next";
import { routes } from "../../app/router/routes";
import pdf from "../../pdfFile/katalog_2024.pdf";
import {
  setErrorNotification,
  setSuccessNotification,
} from "../../slices/notificationSlice";
import { useAppDispatch } from "../../app/redux/hooks";
import { useNavigate } from "react-router-dom";

export default function EditionsSectionCard() {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { classes } = useEditionsSectionCardStyles();
  const editions = useEditionsData();

  const [isDownloading, setIsDownloading] = useState(false);

  function handleDownloadPdf() {
    setIsDownloading(true);

    fetch(pdf)
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "katalog_2024.pdf");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error("Error downloading PDF:", error);
        dispatch(
          setErrorNotification(
            t("messageKey.EditionsSectionCard_PdfDownload_Error")
          )
        );
      })
      .finally(() => {
        setIsDownloading(false);
        dispatch(
          setSuccessNotification(
            t("messageKey.EditionsSectionCard_PdfDownload_Success")
          )
        );
      });
  }

  const handleButtonClick = (route: string) => {
    navigate(route);
  };
  return (
    <>
      {editions.map((edition, index) => (
        <Grid mt={index <= 0 ? 0 : 2} key={index}>
          <Card
            className={classes.card}
            key={index}
            id={`editionsSectionCard_card_${index}`}
          >
            <Box className={classes.box}>
              <CardContent>
                <Grid container>
                  <Grid item md={6} xs={12}>
                    <Grid item md={12} xs={12}>
                      <Typography variant={"h4"} className={classes.title}>
                        {edition.title}
                      </Typography>
                    </Grid>
                    <Grid item md={12} xs={12} mt={2}>
                      <Typography variant={"body1"}>
                        {edition.textFirst}
                      </Typography>
                    </Grid>
                    <Grid item md={12} xs={12} mt={2}>
                      <Typography variant={"body1"}>{edition.text}</Typography>
                    </Grid>
                    <Grid item md={12} xs={12} mt={2}>
                      <Typography variant={"body1"}>{edition.text2}</Typography>
                    </Grid>
                    <Grid item md={12} xs={12} mt={2}>
                      <Typography variant={"body1"}>
                        {edition?.text3.split("- ").map((item, index) => (
                          <React.Fragment key={index}>
                            {index > 0 && <br />}
                            {`${index === 0 ? "" : "- "}${item}`}
                          </React.Fragment>
                        ))}
                      </Typography>
                    </Grid>
                    <Grid item md={12} xs={12} mt={2}>
                      <Typography variant={"body1"}>{edition.text4}</Typography>
                    </Grid>
                    {edition.text5 !== "" && (
                      <Grid item md={12} xs={12} mt={2}>
                        <Typography variant={"body1"}>
                          {edition.text5}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Grid
                      container
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {edition.logo !== "" && (
                        <a
                          href={
                            edition.title === t("editionsPage.catalogTitle")
                              ? routes.catalog
                              : undefined
                          }
                          target="_blank"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Tooltip
                            title={
                              edition.title === t("editionsPage.catalogTitle")
                                ? t("editionsPage.tooltip")
                                : ""
                            }
                          >
                            <img
                              src={edition?.logo}
                              alt="catalog"
                              className={classes.catalog}
                              style={{
                                width: edition?.width,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                            />
                          </Tooltip>
                        </a>
                      )}
                      {edition.hasButton !== false && (
                        <Grid
                          item
                          md={12}
                          xs={12}
                          mt={1}
                          justifyContent="center"
                          alignItems="center"
                          textAlign={"center"}
                        >
                          <AppButton
                            color={colors.green}
                            hover={colors.green}
                            label={
                              isDownloading
                                ? t("buttons.downloading")
                                : t("buttons.download")
                            }
                            borderRadius={"20px"}
                            startIcon={
                              isDownloading ? (
                                <CircularProgress
                                  size={15}
                                  sx={{ color: colors.primary }}
                                />
                              ) : (
                                <DownloadIcon />
                              )
                            }
                            onClick={() => {
                              setIsDownloading(true);
                              handleDownloadPdf();
                            }}
                            disabled={isDownloading}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Box>
          </Card>
        </Grid>
      ))}
    </>
  );
}
