import {
  AppBar,
  Toolbar,
  Button,
  Grid,
  Popover,
  MenuList,
  MenuItem,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../app/router/routes";
import { colors } from "../../../app/theme/colors";
import { useSecondaryHeaderStyles } from "./secondaryHeaderStyles";
import logoFull from "../../../images/logoFull.png";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../../../components/languageSwitcher/languageSwitcher";
import { resetCompany } from "../../../slices/companySlice";
import { useAppDispatch } from "../../../app/redux/hooks";

const GradientAppBar = styled(AppBar)(({ theme }) => ({
  alignItems: "center",
  backgroundRepeat: "no-repeat",
  backgroundColor: colors.primary,
  backgroundPosition: "right",
  backgroundSize: "30%",
}));

const SecondaryHeader = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { classes } = useSecondaryHeaderStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleButtonClick = (route: string) => {
    navigate(route);
    dispatch(resetCompany());
  };

  return (
    <GradientAppBar position="static">
      <Toolbar
        id="secondaryHeader_toolbar"
        sx={{
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Grid container>
          <Grid
            item
            md={6}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              padding: "8px",
              alignItems:"center"
            }}
          >
            <img
              id="secondaryHeader_img_logo"
              src={logoFull}
              className={classes.logo}
              onClick={() => handleButtonClick(routes.home)}
            />
            <Typography variant="h4" sx={{ marginLeft: "5px" }}>
              {t("header.telekomBl")}
            </Typography>
          </Grid>
          <Grid
            item
            md={6}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "10px",
            }}
          >
            <Button
              id="secondaryHeader_button_home-page"
              color="inherit"
              className={classes.button}
              onClick={() => handleButtonClick(routes.home)}
            >
              <Typography variant="body1" sx={{ textTransform: "none" }}>
                {t("header.homePage")}
              </Typography>
            </Button>
            <Button
              id="secondaryHeader_button_services"
              color="inherit"
              onClick={handleClick}
              className={classes.button}
              disableRipple
            >
              <Typography variant="body1" sx={{ textTransform: "none" }}>
                {t("header.services")}
              </Typography>
            </Button>
            <Popover
              id="secondaryHeader_popover_services"
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              sx={{
                "& .MuiPaper-root": {
                  backgroundColor: colors.primary,
                  border: `1px solid white`,
                },
              }}
            >
              <MenuList>
                <MenuItem
                  id="secondaryHeader_menu-item_update"
                  onClick={() => handleButtonClick(routes.updateData)}
                  sx={{
                    color: "white",
                    "&:hover": {
                      backgroundColor: "white",
                      color: colors.primary,
                    },
                  }}
                >
                  <Typography variant="body1" sx={{ textTransform: "none" }}>
                    {t("header.updateData")}
                  </Typography>
                </MenuItem>
                <MenuItem
                  id="secondaryHeader_menu-item_advertisement"
                  onClick={() => handleButtonClick(routes.advertisement)}
                  sx={{
                    color: "white",
                    "&:hover": {
                      backgroundColor: "white",
                      color: colors.primary,
                    },
                  }}
                >
                  <Typography variant="body1" sx={{ textTransform: "none" }}>
                    {t("header.advertisement")}
                  </Typography>
                </MenuItem>
                <MenuItem
                  id="secondaryHeader_menu-item_digital-marketing"
                  onClick={() => handleButtonClick(routes.digitalMarketing)}
                  sx={{
                    color: "white",
                    "&:hover": {
                      backgroundColor: "white",
                      color: colors.primary,
                    },
                  }}
                >
                  <Typography variant="body1" sx={{ textTransform: "none" }}>
                    {t("header.digitalMarketing")}
                  </Typography>
                </MenuItem>
              </MenuList>
            </Popover>
            <Button
              id="secondaryHeader_button_editions"
              color="inherit"
              className={classes.button}
              onClick={() => handleButtonClick(routes.editions)}
            >
              <Typography variant="body1" sx={{ textTransform: "none" }}>
                {t("header.editions")}
              </Typography>
            </Button>
            <Button
              id="secondaryHeader_button_about"
              color="inherit"
              className={classes.button}
              onClick={() => handleButtonClick(routes.about)}
            >
              <Typography variant="body1" sx={{ textTransform: "none" }}>
                {t("header.aboutUs")}
              </Typography>
            </Button>
            <Button
              id="secondaryHeader_button_contact"
              color="inherit"
              className={classes.button}
              onClick={() => handleButtonClick(routes.contact)}
            >
              <Typography variant="body1" sx={{ textTransform: "none" }}>
                {t("header.contact")}
              </Typography>
            </Button>
            <LanguageSwitcher />
          </Grid>
        </Grid>
      </Toolbar>
    </GradientAppBar>
  );
};

export default SecondaryHeader;
