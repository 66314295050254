import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { colors } from "../../../app/theme/colors";
import AppButton from "../../../components/appButton/appButton";
import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import { useTranslation } from "react-i18next";
import { theme } from "../../../app/theme/theme";
import CloseIcon from "@mui/icons-material/Close";
import { selectCurrentAccount } from "../../../slices/authSlice";
import { useManageClientsAccountsModalStyles } from "./manageClientsAccountsModalStyles";
import CustomOutlinedInput from "../../../components/outlinedInput/customOutlinedInput";
import AddClientModel from "../../../models/addClient/addClientModel";
import { ApiStatus } from "../../../models/app/apiStatus";
import { useState } from "react";

interface Props {
  open: any;
  handleClose: any;
  addClient: AddClientModel | null;
  handleInputChange: any;
  handleSave: any;
  saveClientStatus: string;
  operationType: string;
}

export default function AddClientAccountModal({
  handleClose,
  open,
  addClient,
  handleInputChange,
  handleSave,
  saveClientStatus,
  operationType,
}: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { classes } = useManageClientsAccountsModalStyles();

  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: ''
  });

  const validate = () => {
    const newErrors = { firstName: '', lastName: '', email: '', phone: '' };
    if (!addClient?.clientAccount.firstName) newErrors.firstName = t("validation.validationFirstName");
    if (!addClient?.clientAccount.lastName) newErrors.lastName = t("validation.validationLastName");
    if (!addClient?.clientAccount.email) newErrors.email = t("validation.validationEmail");
    if (!addClient?.clientAccount.phone) newErrors.phone = t("validation.validationPhoneNumber");
    setErrors(newErrors);
    return !Object.values(newErrors).some(error => error);
  };

  const handleSaveClick = () => {
    if (validate()) {
      handleSave();
    }
  };

  const handleDialogClose = () => {
    handleClose();
  };

  const handleInputChangeWithErrorReset = (e: any) => {
    const { name, value } = e.target;
    handleInputChange(e);

    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: ''
    }));
  };

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      fullWidth
      id="rateUsModal_dialog"
      PaperProps={{ sx: { borderRadius: "15px", overflow: "hidden" } }}
    >
      <IconButton
        edge="end"
        color="inherit"
        onClick={handleDialogClose}
        aria-label="close"
        sx={{
          position: "absolute",
          top: theme.spacing(1),
          right: theme.spacing(2),
        }}
      >
        <CloseIcon className={classes.closeIcon} />
      </IconButton>
      <DialogContent className={classes.dialogContent}>
        <Grid
          container
          justifyContent="center"
          spacing={1}
          alignItems="center"
          sx={{ textAlign: "center" }}
        >
          <Grid item md={12}>
            <Typography
              variant="h4"
              sx={{
                color: colors.primary,
                fontWeight: "bold",
              }}
            >
              {operationType === "Add"
                ? t("manageClientsTable.addClient")
                : `${t("manageClientsTable.updateClient")} ${
                    addClient?.clientAccount.firstName
                  } ${addClient?.clientAccount.lastName}`}
            </Typography>
          </Grid>
          <Grid item md={6} mt={2}>
            <CustomOutlinedInput
              label={t("manageClientsTable.firstName")}
              width="100%"
              labelColor={colors.primary}
              value={addClient?.clientAccount.firstName}
              name="firstName"
              onChange={handleInputChangeWithErrorReset}
              error={!!errors.firstName}
              errorMessage={errors.firstName}
            />
          </Grid>
          <Grid item md={6} mt={2}>
            <CustomOutlinedInput
              label={t("manageClientsTable.lastName")}
              width="100%"
              labelColor={colors.primary}
              value={addClient?.clientAccount.lastName}
              name="lastName"
              onChange={handleInputChangeWithErrorReset}
              error={!!errors.lastName}
              errorMessage={errors.lastName}
            />
          </Grid>
          <Grid item md={6} mt={2}>
            <CustomOutlinedInput
              label={t("manageClientsTable.email")}
              width="100%"
              labelColor={colors.primary}
              value={addClient?.clientAccount.email}
              name="email"
              onChange={handleInputChangeWithErrorReset}
              error={!!errors.email}
              errorMessage={errors.email}
            />
          </Grid>
          <Grid item md={6} mt={2}>
            <CustomOutlinedInput
              label={t("manageClientsTable.phone")}
              width="100%"
              labelColor={colors.primary}
              value={addClient?.clientAccount.phone}
              name="phone"
              onChange={handleInputChangeWithErrorReset}
              error={!!errors.phone}
              errorMessage={errors.phone}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        className={classes.dialogActions}
        sx={{ justifyContent: "center" }}
      >
        <Grid
          container
          spacing={1}
          display={"flex"}
          justifyContent={"flex-end"}
        >
          <Grid item>
            <AppButton
              id="rateUsModal_button_save"
              color={colors.green}
              hover={colors.green}
              label={
                operationType === "Add"
                  ? t("buttons.save")
                  : t("buttons.update")
              }
              width="100%"
              onClick={handleSaveClick}
              startIcon={
                saveClientStatus === ApiStatus.Pending ? (
                  <CircularProgress size={15} sx={{ color: colors.primary }} />
                ) : null
              }
              disabled={saveClientStatus === ApiStatus.Pending}
            />
          </Grid>
          <Grid item>
            <AppButton
              id="rateUsModal_button_close"
              color={colors.primary}
              hover={colors.primary}
              label={t("buttons.close")}
              width="100%"
              onClick={handleClose}
            />
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
