import AddClientModel, {
  ClientAccount,
} from "../../models/addClient/addClientModel";
import DeleteClientAccountModel from "../../models/addClient/deleteClientAccountModel";
import { PlaceModel } from "../../models/address/placeModel";
import { StreetModel } from "../../models/address/streetModel";
import { AdvanceSearchFilterModel } from "../../models/advanceSearchFilterModel";
import { AdvertisementRequestModel } from "../../models/advertisementRequestModel";
import { ApiResponse } from "../../models/app/apiResponse";
import { BannerModel } from "../../models/bannerModel";
import CategoryResponseModel from "../../models/company/categoryResponseModel";
import { CategoryShortDetail } from "../../models/company/categoryShortDetail";
import { CompanyFullDetailsModel } from "../../models/company/companyFullDetailsModel";
import { CompanySearchResponse } from "../../models/company/companySearchResponse";
import ContactRequest from "../../models/contactRequest";
import { AdvertisementModel } from "../../models/editions/advertisementModel";
import { UpdateDataModel } from "../../models/editions/updatedDataModel";
import FaqRequest from "../../models/faq/faqRequest";
import { MySiteDetailsResponse } from "../../models/mySite/mySiteDetalisResponse";
import { api_poslovni_imenik } from "./api_poslovni_imenik";
import { api_um } from "./api_um";


export const Companies = {
  GetCompaniesAuthorized: async (request: AdvanceSearchFilterModel) => {
    return api_poslovni_imenik.post(
      "/company/get-companies-for-web-authorized",
      request
    ) as Promise<ApiResponse<CompanySearchResponse>>;
  },
  GetCompaniesUnauthorized: async (request: AdvanceSearchFilterModel) => {
    return api_poslovni_imenik.post(
      "/company/get-companies-for-web",
      request
    ) as Promise<ApiResponse<CompanySearchResponse>>;
  },
  GetCompanyFullDetailsById: async (id: string) => {
    return api_poslovni_imenik.get(
      "/company/get-company-detail-for-dashboard-by-id/" + id
    ) as Promise<ApiResponse<CompanyFullDetailsModel>>;
  },
  GetAllCategories: async () => {
    return api_poslovni_imenik.get("/category/get-all-categories") as Promise<
      ApiResponse<CategoryResponseModel[]>
    >;
  },
  GetAllBanners: async () => {
    return api_poslovni_imenik.get("/banner/get-all-banners") as Promise<
      ApiResponse<BannerModel[]>
    >;
  },
};

export const OrderRequest = {
  SendOrderRequest: async (request: AdvertisementRequestModel) => {
    return api_poslovni_imenik.post(
      "/email/send-order-request",
      request
    ) as Promise<ApiResponse<AdvertisementRequestModel | null>>;
  },
};

export const UpdateDataRequest = {
  SendUpdateDataRequest: async (request: UpdateDataModel) => {
    return api_poslovni_imenik.post(
      "/email/send-update-data-request",
      request
    ) as Promise<ApiResponse<UpdateDataModel | null>>;
  },
};

export const AdvertisementRequest = {
  SendAdvertisementRequest: async (request: AdvertisementModel) => {
    return api_poslovni_imenik.post(
      "/email/send-advertisement-request",
      request
    ) as Promise<ApiResponse<AdvertisementModel | null>>;
  },
};

export const ContactRequestForm = {
  SendContactRequest: async (request: ContactRequest) => {
    return api_poslovni_imenik.post(
      "/email/send-contact-request",
      request
    ) as Promise<ApiResponse<ContactRequest | null>>;
  },
};

export const Categories = {
  GetAllCategoryShortDetails: async () => {
    return api_poslovni_imenik.get(
      "/category/get-all-category-short-details"
    ) as Promise<ApiResponse<CategoryShortDetail[]>>;
  },
};

export const Addresses = {
  GetAllPlaces: async () => {
    return api_poslovni_imenik.get("/place/get-all-places") as Promise<
      ApiResponse<PlaceModel[]>
    >;
  },
  GetStreetsByPlaceId: async (placeId: string) => {
    return api_poslovni_imenik.get(
      "/street/get-streets-by-place-id/" + placeId
    ) as Promise<ApiResponse<StreetModel[]>>;
  },
};

export const getAllBanners = async () => {
  return api_poslovni_imenik.get("/banner/get-all-banners") as Promise<
    ApiResponse<BannerModel[]>
  >;
};

export const getAllBannerPhotoResoultion = async () => {
  return api_poslovni_imenik.get("/banner/get-all-banners") as Promise<
    ApiResponse<BannerModel[]>
  >;
};

export const saveClient = async (request: AddClientModel) => {
  return api_um.post(
    "/client-accounts/save-client-account",
    request
  ) as Promise<ApiResponse<AddClientModel>>;
};

export const deleteClient = async (request: DeleteClientAccountModel) => {
  return api_um.post(
    "/client-accounts/delete-client-account",
    request
  ) as Promise<ApiResponse<string>>;
};

export const getClients = async (companyId: string) => {
  return api_um.get(
    "/client-accounts/get-many-by-company-id?id=" + companyId
  ) as Promise<ApiResponse<ClientAccount[]>>;
};

export const faqMessage = async (request: FaqRequest) => {
  return api_poslovni_imenik.post(
    "/email/ask-us",
    request
  ) as Promise<ApiResponse<any>>;
};
