import { CheckCircle, Error, Info, Warning } from "@mui/icons-material";
import {
  Alert,
  AlertColor,
  CircularProgress,
  Grid,
  Snackbar,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../app/redux/hooks";
import { NotificationType } from "../../models/app/notificationType";
import {
  clearNotification,
  selectNotification,
} from "../../slices/notificationSlice";

export default function AppNotification() {
  const dispatch = useAppDispatch();
  const notification = useAppSelector(selectNotification);

  const getSeverity = (): AlertColor => {
    switch (notification?.type) {
      case NotificationType.Pending:
        return "info";

      case NotificationType.Success:
        return "success";

      case NotificationType.Info:
        return "info";

      case NotificationType.Warning:
        return "warning";

      case NotificationType.Error:
        return "error";

      default:
        return "info";
    }
  };

  const getIcon = (): JSX.Element | boolean => {
    switch (notification?.type) {
      case NotificationType.Pending:
        return <CircularProgress size={"1em"} />;

      case NotificationType.Success:
        return <CheckCircle style={{ fontSize: "1em" }} />;

      case NotificationType.Info:
        return <Info style={{ fontSize: "1em" }} />;

      case NotificationType.Warning:
        return <Warning style={{ fontSize: "1em" }} />;

      case NotificationType.Error:
        return <Error style={{ fontSize: "1em" }} />;

      default:
        return false;
    }
  };

  return (
    <Grid item md={12} sm={12}>
      <Snackbar
        open={notification !== null}
        autoHideDuration={
          notification?.type !== NotificationType.Pending ? 3000 : null
        }
        onClose={() => {
          if (notification?.type !== NotificationType.Pending) {
            dispatch(clearNotification());
          }
        }}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      >
        <Alert
          onClose={() => {
            dispatch(clearNotification());
          }}
          severity={getSeverity()}
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.95)",
            boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
            borderRadius: "8px",
            padding: "16px",
          }}
          icon={getIcon()}
        >
          <span
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {notification?.message}
          </span>
        </Alert>
      </Snackbar>
    </Grid>
  );
}
