import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";
import { colors } from "../../app/theme/colors";

export const useUpdateDataSectionStyles = makeStyles()((theme: Theme) => ({
  text: {
    color: "white",
  },
  img: {
    width: "60%",
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
  },
  title: {
    color: "white",
    fontWeight: "bold",
    marginBottom: "5px",
  },
  description: {
    color: "white",
    fontWeight: "normal",
  },
  imageSectionWrapper: {
    textAlign: "center",
  },
  autocompleteTextField: {
    "& .MuiInputBase-root": {
      borderRadius: "5px",
      background: "white",
    },
  },
  autocomplete: {
    "& .MuiOutlinedInput-root.Mui-focused": {
      "& > fieldset": {
        borderColor: colors.sectionTitle,
      },
    },
  },
}));
