import { Grid, useMediaQuery, useTheme } from "@mui/material";
import MobileHeaderSecondary from "../../features/header/mobileHeaderSecondary/mobileHeaderSecondary";
import SecondaryHeader from "../../features/header/secondaryHeader/secondaryHeader";
import SubHeader from "../../features/header/subHeader/subHeader";
import { useDigitalMarketingPageStyles } from "./digitalMarketingPageStyles";
import DigitalMarketingSection from "../../features/digitalMarketingSection/digitalMarketingSection";
import { useAppSelector } from "../../app/redux/hooks";
import { selectCurrentAccount } from "../../slices/authSlice";
import Sidebar from "../../features/sidebar/sidebar";

export default function DigitalMarketingPage() {
  const { classes } = useDigitalMarketingPageStyles();
  const theme = useTheme();

  const currentAccount = useAppSelector(selectCurrentAccount);

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
    <SubHeader />
    <div className={classes.container}>
      <Grid container>
        {currentAccount && isDesktop && (
          <Grid item md={0.3}>
            <Sidebar />
          </Grid>
        )}
        <Grid item xs={12} md={isDesktop && currentAccount ? 11.7 : 12}>
          <Grid container>
            <Grid item xs={12}>
              {isDesktop && <SecondaryHeader />}
              {isMobile && <MobileHeaderSecondary />}
            </Grid>
            <Grid item xs={12}>
              <DigitalMarketingSection />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  </>
  );
}
